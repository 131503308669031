import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import IconButton from "../../../../../components/icon-button";
import ViewWrapper from "../../../../../components/view-wrapper";
import SummaryTable from "../../../../../components/summary-table";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import {
  cn,
  getSponsorEquityReportSummaryUrl,
} from "../../../../../utils/helpers";
import {
  ISponsorEquityReportSummary,
  ISponsorEquityReportSummaryMaster,
} from "../../../../../interfaces";

interface IProps {
  getSponsorEquityReportSummary: (
    dealUuid: string,
    params: { force_unlevered: boolean },
  ) => Promise<ISponsorEquityReportSummaryMaster>;
  downloadSponsorEquityReportSummary: (
    dealUuid: string,
    params: { force_unlevered: boolean },
  ) => Promise<void>;
}

export default function DealOutputSponsorEquitySummaryView({
  getSponsorEquityReportSummary,
  downloadSponsorEquityReportSummary,
}: IProps): JSX.Element {
  const { urlLoadingPercentages } = useAxios();

  const { caseDealUuid, dealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const dispatch = useDispatch();

  const [activeShowUnlevered, setActiveShowUnlevered] = useSessionStorage<
    null | boolean
  >(`active-deal-${dealIdToUse}-show-unlevered`, false);

  const [sponsorEquityReportSummary, setSponsorEquityReportSummary] =
    React.useState<ISponsorEquityReportSummary>();

  const {
    callAPI: getSponsorEquityReportSummaryCallAPI,
    errored: getSponsorEquityReportSummaryFailed,
    loading: loadingSponsorEquityReportSummary,
    errorObj: getSponsorEquityReportSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealUuid: string, forceUnlevered: boolean) =>
      getSponsorEquityReportSummary(dealUuid, {
        force_unlevered: forceUnlevered,
      }),
    {
      initialLoading: true,
    },
  );

  const { callAPI: downloadSponsorEquityReportSummaryCallAPI } = useAPI(
    (dealUuid: string) =>
      downloadSponsorEquityReportSummary(dealUuid, { force_unlevered: false }),
  );

  React.useEffect(() => {
    !loadingSponsorEquityReportSummary &&
      dispatch(
        updateDealOutputLoadedAction(!getSponsorEquityReportSummaryFailed),
      );
  }, [getSponsorEquityReportSummaryFailed, loadingSponsorEquityReportSummary]);

  React.useEffect(() => {
    if (dealIdToUse) {
      // Get the current value from sessionStorage
      const currentValue = sessionStorage.getItem(
        `active-deal-${dealIdToUse}-show-unlevered`,
      );
      // If there's a value in sessionStorage for the new caseDealUuid, update activeShowUnlevered
      if (currentValue !== null) {
        setActiveShowUnlevered(JSON.parse(currentValue));
      }

      getSponsorEquityReportSummaryCallAPI(
        dealIdToUse,
        JSON.parse(currentValue ?? "false"),
      ).then((response) => {
        if (response) {
          setSponsorEquityReportSummary(response.data);
        }
      });
    }
  }, [dealIdToUse, activeShowUnlevered]);

  const handleSponsorEquitySummaryDownload = async () => {
    await downloadSponsorEquityReportSummaryCallAPI(dealIdToUse);
  };

  const gridCols =
    (sponsorEquityReportSummary?.tables[0]?.headers?.length || 0) < 3 ? 2 : 1;

  return (
    <>
      <Box className={cn("flex justify-end items-center my-4")}>
        <Box>
          <ToggleSizingOutputButton />
        </Box>
      </Box>
      <ViewWrapper
        loading={loadingSponsorEquityReportSummary}
        error={getSponsorEquityReportSummaryFailed}
        errorHeading={getSponsorEquityReportSummaryErrorObj?.heading}
        errorDescription={getSponsorEquityReportSummaryErrorObj?.description}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[
            getSponsorEquityReportSummaryUrl(
              String(dealIdToUse),
              activeShowUnlevered ?? false,
            )
          ]
        }
      >
        <React.Fragment>
          <Box>
            <Box className={cn("flex justify-between")}>
              <Typography fontSize="14" padding={1} fontWeight="bold">
                {sponsorEquityReportSummary?.report_title}
              </Typography>
              <IconButton
                className={cn("!ml-3 !mb-[-2px]")}
                onClick={handleSponsorEquitySummaryDownload}
              >
                {/* <DownloadIcon fontSize="small" /> */}
              </IconButton>
            </Box>
            <Box className={cn(`grid grid-cols-${gridCols} gap-2`)}>
              {sponsorEquityReportSummary?.tables.map((table, idx) => {
                return (
                  <SummaryTable key={idx} usedIn="deal" summaryTable={table} />
                );
              })}
            </Box>
          </Box>
        </React.Fragment>
      </ViewWrapper>
    </>
  );
}
