import Layout from "../../components/layout";
import CollaborationListView from "../../views/collaboration/list";
import { getDeals } from "../../apis/deal/base";
import {
  createCollaboration,
  getCollaborations,
} from "../../apis/collaboration/base";

export default function CollaborationListPage(): JSX.Element {
  return (
    <Layout title="Collaboration">
      <CollaborationListView
        getCollaborations={getCollaborations}
        createCollaboration={createCollaboration}
        getDeals={getDeals}
      />
    </Layout>
  );
}
