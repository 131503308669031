import ErrorIcon from "@mui/icons-material/Error";

import useStyles from "./styles";

export interface IProps {
  errorHeading?: string | JSX.Element;
  errorDescription?: string | JSX.Element;
}

export default function ErrorScreen({
  errorHeading,
  errorDescription,
}: IProps): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.classes.container}>
      <ErrorIcon color="error" fontSize="large" />
      <h1>{errorHeading || "Something Went Wrong"}</h1>
      <p>
        {errorDescription ||
          "Capezero has encountered an error. Please try again shortly or refresh your browser page. If the problem persists, please contact us at support@capezero.com"}
      </p>
    </div>
  );
}
