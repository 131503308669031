import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IProjectProduction,
  IReportTable,
  IUpdateProjectProductionForm,
} from "../../interfaces";

export const getProjectProduction = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.get, { projectUuid });
  return await AxiosHelper.get<IProjectProduction[]>(url);
};

export const updateProjectProduction = async (
  projectUuid: string,
  productionId: number,
  form: IUpdateProjectProductionForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.update, {
    projectUuid,
    productionId,
  });
  return await AxiosHelper.patch<
    IUpdateProjectProductionForm,
    IProjectProduction
  >(url, form);
};

export const getProjectProductionComponents = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.components, {
    projectUuid,
  });

  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadProjectProductionComponents = async (
  projectUuid: string,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.downloadComponents, {
    projectUuid,
  });

  return await AxiosHelper.downloadFile(url);
};
