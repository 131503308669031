import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import { useAPI, useAppSelector } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  IPortfolioSourcesAndUsesCOD,
  IPortfolioSourcesAndUsesConstruction,
  ITableColumn,
  ITableRow,
} from "../../../../../interfaces";
import {
  getOutputPortfolioSourcesAndUsesConstructionUrl,
  getOutputPortfolioSourcesAndUsesCODUrl,
  getOutputPortfolioConstructionRowsUses,
  getOutputPortfolioConstructionRowsSources,
  getOutputPortfolioCODRowsUses,
  getOutputPortfolioCODRowsSources,
  getOutputPortfolioSourcesAndUsesCodBreakdown,
  getOutputPortfolioSourcesAndUsesConBreakdown,
} from "../../../../../utils/helpers";

interface IProps {
  getOutputPortfolioSourcesAndUsesConstruction: (
    dealUuid: string,
  ) => Promise<IPortfolioSourcesAndUsesConstruction>;
  getOutputPortfolioSourcesAndUsesCOD: (
    dealUuid: string,
  ) => Promise<IPortfolioSourcesAndUsesCOD>;
}

const DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_CON_USES_COLS: ITableColumn[] = [
  { id: "name", label: "Uses", minWidth: 150, align: "left" },
  { id: "value", label: "", minWidth: 150, align: "right" },
];

const DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_CON_SOURCES_COLS: ITableColumn[] =
  [
    { id: "name", label: "Sources", minWidth: 150, align: "left" },
    { id: "value", label: "", minWidth: 150, align: "right" },
  ];

const DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_COD_USES_COLS: ITableColumn[] = [
  { id: "uses_name", label: "Uses", minWidth: 150, align: "left" },
  { id: "uses_value", label: "", minWidth: 150, align: "right" },
];

const DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_COD_SOURCES_COLS: ITableColumn[] =
  [
    { id: "name", label: "Sources", minWidth: 150, align: "left" },
    { id: "value", label: "", minWidth: 150, align: "right" },
  ];

export default function DealOutputPortfolioSourcesAndUsesSummaryView({
  getOutputPortfolioSourcesAndUsesCOD,
  getOutputPortfolioSourcesAndUsesConstruction,
}: IProps): JSX.Element {
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { currentDeal } = useAppSelector((s) => s.deal);

  const dispatch = useDispatch();

  const { urlLoadingPercentages } = useAxios();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [showConstructionBreakdown, setShowConstructionBreakdown] =
    React.useState(false);
  const [showCODBreakdown, setShowCODBreakdown] = React.useState(false);

  const {
    callAPI: getConstructionSourcesAndUses,
    response: constructionSourcesAndUses,
    loading: isConstructionLoading,
    errored: hasConstructionError,
    errorObj: getConstructionSourcesAndUsesErrorObj,
    showUsageLimitHitScreen: showConstructionUsageLimitHitScreen,
  } = useAPI(
    () => getOutputPortfolioSourcesAndUsesConstruction(String(dealIdToUse)),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: getCODConstructionSourcesAndUses,
    response: codSourcesAndUses,
    loading: isCODLoading,
    errored: hasCODError,
    errorObj: getCODConstructionSourcesAndUsesErrorObj,
    showUsageLimitHitScreen: showCODUsageLimitHitScreen,
  } = useAPI(() => getOutputPortfolioSourcesAndUsesCOD(String(dealIdToUse)), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getConstructionSourcesAndUses();

    // Hide COD data for SLB deals
    if (currentDeal && currentDeal.structure !== "SL") {
      getCODConstructionSourcesAndUses();
    }
  }, [currentDeal]);

  const sourcesAndUsesCon = getOutputPortfolioSourcesAndUsesConBreakdown(
    constructionSourcesAndUses?.data,
  );
  const sourcesAndUsesCod = getOutputPortfolioSourcesAndUsesCodBreakdown(
    codSourcesAndUses?.data,
  );

  const onConstructionSourcesAndUsesBreakdownClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setShowConstructionBreakdown(!showConstructionBreakdown);
  };

  const onCODConstructionSourcesAndUsesBreakdownClick = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setShowCODBreakdown(!showCODBreakdown);
  };

  React.useEffect(() => {
    !isConstructionLoading &&
      !isCODLoading &&
      dispatch(updateDealOutputLoadedAction(true));
  }, [isConstructionLoading, isCODLoading]);

  const handleClickOutside = (event: MouseEvent) => {
    if (anchorEl && !anchorEl.contains(event.target as Node)) {
      setAnchorEl(null);
      setShowConstructionBreakdown(false);
      setShowCODBreakdown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl]);

  const constructionBreakdownPopper = showConstructionBreakdown &&
    sourcesAndUsesCon && (
      <Popper
        open={Boolean(anchorEl && showConstructionBreakdown)}
        anchorEl={anchorEl}
        placement="top-start"
        className="z-50"
      >
        <ReportTable columns={[]} rows={sourcesAndUsesCon as ITableRow[]} />
      </Popper>
    );

  const codBreakdownPopper = showCODBreakdown && sourcesAndUsesCod && (
    <Popper
      open={Boolean(anchorEl && showCODBreakdown)}
      anchorEl={anchorEl}
      placement="top-start"
      className="z-50"
    >
      <Box className="mt-4">
        <ReportTable columns={[]} rows={sourcesAndUsesCod as ITableRow[]} />
      </Box>
    </Popper>
  );

  const constructionRowsUses = getOutputPortfolioConstructionRowsUses(
    constructionSourcesAndUses?.data,
  );
  const constructinRowSources = getOutputPortfolioConstructionRowsSources(
    constructionSourcesAndUses?.data,
    onConstructionSourcesAndUsesBreakdownClick,
    constructionBreakdownPopper,
  );
  const codRowsUses = getOutputPortfolioCODRowsUses(codSourcesAndUses?.data);
  const codRowsSources = getOutputPortfolioCODRowsSources(
    codSourcesAndUses?.data,
    onCODConstructionSourcesAndUsesBreakdownClick,
    codBreakdownPopper,
    currentDeal?.structure || "",
  );

  return (
    <>
      <ViewWrapper
        loading={isConstructionLoading}
        error={hasConstructionError}
        errorHeading={getConstructionSourcesAndUsesErrorObj?.heading}
        errorDescription={getConstructionSourcesAndUsesErrorObj?.description}
        showLimitHitView={showConstructionUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[
            getOutputPortfolioSourcesAndUsesConstructionUrl(String(dealIdToUse))
          ]
        }
      >
        <Typography className="!my-4" variant="h6">
          Sources and Uses (Construction)
        </Typography>

        <Box className="grid grid-cols-2 gap-4">
          <ReportTable
            columns={DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_CON_USES_COLS}
            rows={constructionRowsUses as ITableRow[]}
          />

          <Box>
            <ReportTable
              columns={DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_CON_SOURCES_COLS}
              rows={constructinRowSources as ITableRow[]}
            />
          </Box>
        </Box>
      </ViewWrapper>

      {currentDeal?.structure !== "SL" && !isConstructionLoading && (
        <ViewWrapper
          loading={isCODLoading}
          error={hasCODError}
          errorHeading={getCODConstructionSourcesAndUsesErrorObj?.heading}
          errorDescription={
            getCODConstructionSourcesAndUsesErrorObj?.description
          }
          showLimitHitView={showCODUsageLimitHitScreen}
          loadingPercentage={
            urlLoadingPercentages[
              getOutputPortfolioSourcesAndUsesCODUrl(String(dealIdToUse))
            ]
          }
        >
          <Typography className="!my-4" variant="h6">
            Sources and Uses (COD)
          </Typography>
          <Box className="grid grid-cols-2 gap-4">
            <ReportTable
              columns={DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_COD_USES_COLS}
              rows={codRowsUses as ITableRow[]}
            />

            <ReportTable
              columns={DEAL_OUTPUT_PORTFOLIO_SOURCES_AND_USES_COD_SOURCES_COLS}
              rows={codRowsSources as ITableRow[]}
            />
          </Box>
        </ViewWrapper>
      )}
    </>
  );
}
