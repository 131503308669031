import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { IDealTiming, IUpdateDealTimingForm } from "../../interfaces";

export const getDealTiming = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.timing.get, { dealUuid });
  return await AxiosHelper.get<IDealTiming[]>(url);
};

export const updateDealTiming = async (
  dealUuid: string,
  timingId: number,
  form: IUpdateDealTimingForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.timing.update, {
    dealUuid,
    timingId,
  });
  return await AxiosHelper.patch<IUpdateDealTimingForm, IDealTiming>(url, form);
};
