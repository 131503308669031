import React from "react";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../components/view-wrapper";
import ReportTableV2 from "../../../../components/report-table-v2";
import { useAPI } from "../../../../utils/hooks";
import { IReportTable } from "../../../../interfaces";

interface IProps {
  getProjectValuationCalculations: (
    projectUuid: string,
  ) => Promise<IReportTable>;
  downloadProjectValuationCalculations_Report: (
    projectUuid: string,
  ) => Promise<void>;
}

export default function ProjectFmvIncomeApproachView({
  getProjectValuationCalculations,
  downloadProjectValuationCalculations_Report,
}: IProps): JSX.Element {
  const { projectUuid } = useParams();

  const [calculations, setCalculations] = React.useState<IReportTable>();

  const {
    callAPI: getCalculationsCallAPI,
    loading: getCalculationsLoading,
    errored: getCalculationsFailed,
  } = useAPI((projectUuid) => getProjectValuationCalculations(projectUuid), {
    initialLoading: true,
  });

  const { callAPI: downloadProjectValuationCalculationsAPI } = useAPI(() =>
    downloadProjectValuationCalculations_Report(String(projectUuid)),
  );

  React.useEffect(() => {
    getCalculationsCallAPI(projectUuid).then((res) => {
      res && setCalculations(res);
    });
  }, [projectUuid]);

  return (
    <ViewWrapper loading={getCalculationsLoading} error={getCalculationsFailed}>
      {calculations ? (
        <ReportTableV2
          data={calculations}
          autoHeight
          groupExpandedDepth={-1}
          download={downloadProjectValuationCalculationsAPI}
          heightInPixels={window.innerHeight - 300}
          styledTitle
        />
      ) : null}
    </ViewWrapper>
  );
}
