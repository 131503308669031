import Layout from "../components/layout";
import DealListView from "../views/deal-list";
import { getDeals, addDeal } from "../apis/deal/base";
import { addProject, getProjects } from "../apis/project/base";
import { getCounties } from "../apis/common";
export default function DealList(): JSX.Element {
  return (
    <Layout title="Deals">
      <DealListView
        getDeals={getDeals}
        getProjects={getProjects}
        addDeal={addDeal}
        addProject={addProject}
        getCounties={getCounties}
      />
    </Layout>
  );
}
