import Layout from "../components/layout";
import ProjectListView from "../views/project-list";
import { addProject, getProjects, getLocations } from "../apis/project/base";
import { getCounties } from "../apis/common";

export default function ProjectList(): JSX.Element {
  return (
    <Layout title="Projects">
      <ProjectListView
        getProjects={getProjects}
        addProject={addProject}
        getCounties={getCounties}
        getProjectsLocations={getLocations}
      />
    </Layout>
  );
}
