import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealComponentPagesLayout from "../../../../components/deal-component-page-layout";
import DealTaxSummaryView from "../../../../views/deal/components/tax/summary";
import { getDealTaxSummary } from "../../../../apis/deal/tax";

export default function DealTaxSummaryPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealComponentPagesLayout>
        <DealTaxSummaryView getDealTaxSummary={getDealTaxSummary} />
      </DealComponentPagesLayout>
    </DealPagesLayout>
  );
}
