import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../components/view-wrapper";
import SummaryTable from "../../../../components/summary-table";
import IconButton from "../../../../components/icon-button";
import ConditionalProtect from "../../../../components/conditional-protect";
import { IsummaryTablesObject } from "../../../../interfaces";
import { useAPI } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";

interface IProps {
  getProjectValuationSummary: (
    projectUuid: string,
  ) => Promise<IsummaryTablesObject>;
  downloadProjectValuationSummary: (projectUuid: string) => Promise<void>;
}

export default function ProjectFmvDashboardView({
  getProjectValuationSummary,
  downloadProjectValuationSummary,
}: IProps): JSX.Element {
  const { projectUuid } = useParams();

  React.useEffect(() => {
    getProjectValuationSummaryCallAPI(projectUuid);
  }, [projectUuid]);

  const {
    callAPI: getProjectValuationSummaryCallAPI,
    loading: loadingProjectValuationSummary,
    errored: getProjectValuationSummaryFailed,
    response: valudationSummary,
  } = useAPI((projectUuid: string) => getProjectValuationSummary(projectUuid), {
    initialLoading: true,
  });

  const { callAPI: downloadProjectValuationSummaryCallAPI } = useAPI(
    (projectUuid: string) => downloadProjectValuationSummary(projectUuid),
  );

  const onDownloadClick = async () => {
    await downloadProjectValuationSummaryCallAPI(projectUuid);
  };

  return (
    <ViewWrapper
      loading={loadingProjectValuationSummary}
      error={getProjectValuationSummaryFailed}
    >
      <Box>
        <Box className={cn("flex justify-between")}>
          <Typography fontSize="15" padding={1} fontWeight="bold">
            {valudationSummary?.data.report_title}
          </Typography>
          <ConditionalProtect type="project">
            <IconButton onClick={onDownloadClick}>
              <DownloadIcon />
            </IconButton>
          </ConditionalProtect>
        </Box>
        <Box className={cn("grid grid-cols-2 gap-2")}>
          {valudationSummary?.data.tables.map((table, idx) => {
            return (
              <SummaryTable
                key={idx}
                usedIn="project"
                summaryTable={table}
                groupExpandedDepth={1}
              />
            );
          })}
        </Box>
      </Box>
    </ViewWrapper>
  );
}
