import TabbedLayout from "../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectTaxDashboardView from "../../../views/project/tax/dashboard";
import {
  downloadProjectTaxCreditPTC_Report,
  downloadProjectTaxDashboardSummary,
  getProjectTaxCreditPTC_Report,
  getProjectTaxDashboardSummary,
} from "../../../apis/project/tax-credit";

export default function ProjectTaxDashboardPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Tax">
        <ProjectTaxDashboardView
          getProjectTaxDashboardSummary={getProjectTaxDashboardSummary}
          downloadProjectTaxDashboardSummary={
            downloadProjectTaxDashboardSummary
          }
          getProjectTaxCreditPTC_Report={getProjectTaxCreditPTC_Report}
          downloadProjectTaxCreditPTC_Report={
            downloadProjectTaxCreditPTC_Report
          }
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
