import { makeStyles } from "tss-react/mui";

export default makeStyles<{ contentLoaded: boolean; hasStickyTabs: boolean }>()(
  (theme, { contentLoaded, hasStickyTabs }) => {
    return {
      children: {
        padding: theme.spacing(2),
        height: contentLoaded ? "auto" : "calc(100% - 155px)",
        maxHeight: hasStickyTabs ? "calc(200vh)" : undefined,
        overflow: hasStickyTabs ? "auto" : undefined,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
    };
  },
);
