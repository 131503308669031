import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealComponentPagesLayout from "../../../../components/deal-component-page-layout";
import DealProformaScheduleView from "../../../../views/deal/components/pro-forma/schedule";
import {
  downloadDealProforma,
  getDealProforma,
} from "../../../../apis/deal/proforma";

export default function DealProformaSchedulePage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealComponentPagesLayout>
        <DealProformaScheduleView
          downloadDealProforma={downloadDealProforma}
          getDealProforma={getDealProforma}
        />
      </DealComponentPagesLayout>
    </DealPagesLayout>
  );
}
