import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useAPI } from "../../utils/hooks";
import { getSolverDetails, getSolverResults } from "../../apis/solvers/base";
import DetailsCard, {
  IDetailsCardSection,
} from "../../components/details-card";
import { useParams, Link } from "react-router-dom";
import { cn } from "../../utils/helpers";
import { SOLVER_FOR_TYPES, SOLVER_GOAL_TYPES } from "../../constants/solver";
import { formatDistanceToNow } from "date-fns";
import {
  ISolver,
  ISolverResultItem,
} from "../../interfaces/deal/solvers.interface";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SolverResultChart from "../../components/solver-result-chart";
import LoaderScreen from "../../components/loader-screen";

const SolversDetailView = ({
  onSolverChange,
}: {
  onSolverChange: (solver: ISolver) => void;
}) => {
  const { uuid } = useParams();

  const [currentCaseValue, setCurrentCaseValue] = React.useState<
    React.ReactNode | string
  >(<span className="italic">Calculating...</span>);

  const { callAPI: fetchSolver, response: solver } = useAPI(() =>
    getSolverDetails(uuid as string),
  );

  const { callAPI: fetchSolverResults, response: solverResults } = useAPI(() =>
    getSolverResults(uuid as string),
  );

  React.useEffect(() => {
    fetchSolver();

    let timeout: NodeJS.Timeout;

    // Calling Solver Status with polling
    const pollStatusAndResults = async () => {
      const results = await fetchSolverResults();

      if (results?.status === "RUNNING" || results?.status === "CREATED") {
        if (results?.status === "CREATED") {
          fetchSolver();
        }
        // Always fetch results regardless of status
        timeout = setTimeout(pollStatusAndResults, 3000);
        if (solver?.uuid) {
          onSolverChange(solver);
        }
      } else {
        clearTimeout(timeout);
      }
    };
    pollStatusAndResults();

    return () => {
      // Cleanup timeout on unmount
      clearTimeout(timeout);
    };
  }, [uuid]);

  // Update solver details when solver is fetched
  React.useEffect(() => {
    if (solver) {
      // Change Solver on top level
      if (solverResults?.status === "RUNNING") {
        onSolverChange({ ...solver, status: "RUNNING" });
      } else {
        onSolverChange(solver);
      }
    }
  }, [solver, solver?.status, solverResults?.status]);

  // Refetch solver details when status is finished
  React.useEffect(() => {
    if (solverResults?.status && solverResults?.status !== "RUNNING") {
      fetchSolver();
    }
  }, [solverResults?.status]);

  React.useEffect(() => {
    // Set current value
    const isCurrentValueNotNumber = Number.isNaN(Number(currentCaseValue));
    if (solver?.status === "ERROR" && isCurrentValueNotNumber) {
      setCurrentCaseValue("N/A");
    }

    if (solver?.status === "FINISHED" && isCurrentValueNotNumber) {
      setCurrentCaseValue("N/A");
    }
  }, [solver?.status, currentCaseValue]);

  const getBackgroundColor = (result: ISolverResultItem) => {
    if (result.solve_for_value === solverResults?.current_assumption)
      return "rgba(171, 92, 0, 0.1)";

    if (result.result_value === solverResults?.closest_to_goal)
      return "rgba(71, 57, 243, 0.1)";

    return null;
  };

  const getTextColor = (result: ISolverResultItem) => {
    if (result.solve_for_value === solverResults?.current_assumption)
      return {
        // color: "rgba(171, 92, 0, 1)",
        // text: (
        //   <div className="flex items-center gap-2">
        //     <div className="w-2 h-2 bg-[#AB5C00] rotate-45"></div>
        //     <div>Current Assumption</div>
        //   </div>
        // ),
      };

    if (result.result_value === solverResults?.closest_to_goal)
      return {
        color: "rgba(71, 57, 243, 1)",
        text: (
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-[#4739F3] rotate-45"></div>
            <div>Closest to Goal</div>
          </div>
        ),
      };

    return null;
  };

  const headers = (
    <>
      <Box className="w-full">
        <h2 className="mb-2 text-[20px]">Summary</h2>
        <div className="flex items-center justify-between bg-gray-100 p-2 py-3">
          <div>Item</div>
          <div>Value</div>
        </div>
      </Box>
    </>
  );

  const runInfoHeaders = (
    <>
      <Box className="w-full">
        <h2 className="mb-2 text-[20px]">Run Info</h2>
        <div className="flex items-center justify-between bg-gray-100 p-2 py-3">
          <div>
            {
              SOLVER_FOR_TYPES[
                solver?.solver_for as keyof typeof SOLVER_FOR_TYPES
              ]
            }
          </div>
          <div>
            {
              SOLVER_GOAL_TYPES[
                solver?.goal_type as keyof typeof SOLVER_GOAL_TYPES
              ]
            }{" "}
            (%)
          </div>
        </div>
      </Box>
    </>
  );

  const solverDetails = React.useMemo(() => {
    let result: IDetailsCardSection[] = [];
    if (solver) {
      result = [
        {
          fields: [
            {
              label: "Date Created",
              value: {
                text: formatDistanceToNow(new Date(solver.created), {
                  addSuffix: true,
                }),
              },
            },
            {
              label: "Deal Name",
              value: {
                text: (
                  <Link
                    target="_blank"
                    className="underline"
                    to={`/deal/${solver.deal_details.uuid}/case-deal/${solver.deal_details.uuid}/general`}
                  >
                    {solver.deal_details.name}{" "}
                    <OpenInNewIcon fontSize="small" className="mr-1" />
                  </Link>
                ),
              },
            },
            {
              label: "Case Name",
              value: {
                text: (
                  <Link
                    target="_blank"
                    className="underline"
                    to={
                      solver.case
                        ? `/deal/${solver.deal_details.uuid}/case/${solver.case}`
                        : `/deal/${solver.deal_details.uuid}/case-deal/${solver.deal_details.uuid}/general`
                    }
                  >
                    {solver.case_name}{" "}
                    <OpenInNewIcon fontSize="small" className="mr-1" />
                  </Link>
                ),
              },
            },
            {
              label: "Solver For",
              value: {
                text: SOLVER_FOR_TYPES[solver.solver_for],
              },
            },
            ...(solver.project && solver.project_name
              ? [
                  {
                    label: "Project",
                    value: {
                      text: (
                        <Link
                          target="_blank"
                          className="underline"
                          to={`/project/${solver.project}/general`}
                        >
                          {solver.project_name}{" "}
                          <OpenInNewIcon fontSize="small" className="mr-1" />
                        </Link>
                      ),
                    },
                  },
                ]
              : []),
            {
              label: solver.solver_for_field_details.field_name,
              value: {
                text: (
                  <Link
                    target="_blank"
                    className="underline"
                    to={solver.solver_for_field_details.frontend_url}
                  >
                    {solver.solver_for_field_name}{" "}
                    <OpenInNewIcon fontSize="small" className="mr-1" />
                  </Link>
                ),
              },
            },
            {
              label: "Goal Metric",
              value: {
                text: SOLVER_GOAL_TYPES[solver.goal_type],
              },
            },
            {
              backgroundColor: "rgba(171, 92, 0, 0.1)",
              label: (
                <div
                  style={{
                    color: "rgba(171, 92, 0, 1)",
                    fontWeight: 600,
                  }}
                >
                  Goal Value
                </div>
              ),
              value: {
                text: (
                  <span className="text-[#AB5C00] font-bold">{`${solver.goal_value}%`}</span>
                ),
              },
            },
            {
              label: "Selected Case Value (as of solver run time)",
              value: {
                text: !Number.isNaN(Number(currentCaseValue))
                  ? `${currentCaseValue}%`
                  : `${currentCaseValue}`,
              },
            },
          ],
        },
      ];
    }
    return result;
  }, [solver, solver?.status, currentCaseValue]);

  const runInfoDetails = React.useMemo(() => {
    return solverResults?.result.length
      ? solverResults.result.map((result) => {
          if (result.is_selected_case_value) {
            setCurrentCaseValue(`${result.result_value}`);
          }
          return {
            fields: [
              {
                backgroundColor: getBackgroundColor(result),
                label: getTextColor(result) ? (
                  <div className="flex items-center justify-between w-[400px]">
                    <div>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(result.solve_for_value)}
                    </div>
                    <div
                      style={{
                        color: getTextColor(result)?.color || undefined,
                      }}
                    >
                      {getTextColor(result)?.text}
                    </div>
                  </div>
                ) : (
                  <div>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(result.solve_for_value)}
                  </div>
                ),
                value: {
                  text: result.result_value,
                },
              },
            ],
          };
        })
      : solverResults?.status === "RUNNING"
        ? [
            {
              fields: Array.from({ length: 5 })
                .fill(0)
                .map(() => {
                  return {
                    label: (
                      <Skeleton
                        width={150}
                        height={32}
                        sx={{ bgcolor: "#5335fa45" }}
                      />
                    ),
                    value: {
                      text: (
                        <Skeleton
                          width={150}
                          height={32}
                          sx={{ bgcolor: "#5335fa45" }}
                        />
                      ),
                    },
                  };
                }),
            },
          ]
        : [];
  }, [solverResults]);

  const solverResultChartData = React.useMemo(() => {
    const chatData: {
      labels: number[];
      data: number[];
      closestToGoal: number;
      goalValue: number;
      solverFor: string;
      goalType: string;
    } = {
      labels: [],
      data: [],
      closestToGoal: 0,
      goalValue: 0,
      solverFor: "",
      goalType: "",
    };
    solverResults?.result.forEach((result) => {
      chatData.labels.push(result.solve_for_value);
      chatData.data.push(result.result_value);
    });

    if (solverResults?.closest_to_goal) {
      chatData.closestToGoal = solverResults.closest_to_goal;
    }

    if (solver?.goal_value) {
      chatData.goalValue = solver.goal_value;
    }

    if (solver?.solver_for) {
      chatData.solverFor =
        SOLVER_FOR_TYPES[solver.solver_for as keyof typeof SOLVER_FOR_TYPES];
    }

    if (solver?.goal_type) {
      chatData.goalType =
        SOLVER_GOAL_TYPES[solver.goal_type as keyof typeof SOLVER_GOAL_TYPES];
    }

    return chatData;
  }, [solver, solverResults]);

  return (
    <Box className={cn("!grid !grid-cols-2 !gap-4")}>
      {solver && (
        <>
          <DetailsCard
            autoHeight={true}
            heading={headers}
            sections={solverDetails}
          />

          {
            <>
              <Box>
                {solverResults?.status === "RUNNING" && (
                  <LoaderScreen
                    ignoreHeight={runInfoDetails.length > 0}
                    supportingText="Please wait while the solver is running..."
                  />
                )}
                {runInfoDetails.length > 0 && (
                  <DetailsCard
                    autoHeight={true}
                    heading={runInfoHeaders}
                    sections={runInfoDetails}
                  />
                )}
              </Box>

              <div className="col-span-2 my-[50px]">
                <SolverResultChart data={solverResultChartData} />
              </div>
            </>
          }
        </>
      )}
    </Box>
  );
};

export default SolversDetailView;
