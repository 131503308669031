import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealComponentPagesLayout from "../../../../components/deal-component-page-layout";
import DealTaxScheduleView from "../../../../views/deal/components/tax/schedule";
import {
  downloadDealTaxCreditPTC_Data,
  getDealTaxCreditPTC_Data,
  getTaxCredit,
} from "../../../../apis/deal/tax";

export default function DealTaxSchedulePage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealComponentPagesLayout>
        <DealTaxScheduleView
          downloadTaxCreditPTC_Data={downloadDealTaxCreditPTC_Data}
          // getTaxCredit={getTaxCredit}
          getTaxCreditPTC_Data={getDealTaxCreditPTC_Data}
        />
      </DealComponentPagesLayout>
    </DealPagesLayout>
  );
}
