import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealSizingOtherPageLayout from "../../../../components/deal-sizing-other-page-layout";
import TabbedLayout from "../../../../components/tabbed-layout";
import DealSizingOtherView from "../../../../views/deal/sizing/other";

export default function DealSizingOtherViewPage() {
  return (
    <DealPagesLayout>
      <DealSizingOtherPageLayout>
        <DealSizingOtherView />
      </DealSizingOtherPageLayout>
    </DealPagesLayout>
  );
}
