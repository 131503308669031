import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputDeveloperCashView from "../../../../views/deal/output/developer/cash";
import {
  downloadDealTimeSeriesReport,
  getDealTimeSeriesReport,
} from "../../../../apis/report/deal";

export default function DealOutputDeveloperCashPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputDeveloperCashView
          getDealTimeSeriesReport={getDealTimeSeriesReport}
          downloadDealTimeSeriesReport={downloadDealTimeSeriesReport}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
