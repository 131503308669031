import React from "react";
import { useOrganization } from "@clerk/clerk-react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Tabs from "../tabs";
import LoaderScreen from "../loader-screen";
import TabbedLayout from "../tabbed-layout";
import { ITab, IDealComponentPageTabs } from "../../interfaces";
import { useAppSelector } from "../../utils/hooks";
import {
  DEAL_COMPONENT_PAGE_TABS,
  PERSONAL_ACCOUNT_DEAL_COMPONENT_TABS,
} from "../../constants";

interface IProps {
  children: JSX.Element;
}

export default function DealComponentPagesLayout({
  children,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { currentDeal } = useAppSelector((s) => s.deal);

  const selectedSidebarItem = React.useMemo(() => {
    const idxToUse = organization ? 6 : 5;
    return pathname.split("/")[idxToUse] || "";
  }, [pathname]);

  const ptcProjectExist = React.useMemo(() => {
    return currentDeal?.projects.some((p) => p.tax_credit_type === "PTC");
  }, [dealIdToUse, currentDeal]);

  const tabsOfPage = React.useMemo(() => {
    const tabsToUse = organization
      ? DEAL_COMPONENT_PAGE_TABS
      : PERSONAL_ACCOUNT_DEAL_COMPONENT_TABS;

    const tabs = tabsToUse[selectedSidebarItem as keyof IDealComponentPageTabs];

    if (!ptcProjectExist && pathname.includes("/component/tax/")) {
      return tabs.filter((t) => t.label !== "Schedule");
    }

    return tabs;
  }, [currentDeal, selectedSidebarItem, caseDealUuid, ptcProjectExist]);

  const selectedTab = React.useMemo(() => {
    const tab = tabsOfPage.find((tab) => {
      const path = tab.path;

      return pathname.includes(
        path
          ?.replace(":dealUuid", String(dealUuid))
          .replace(":caseDealUuid", String(caseDealUuid))
          .replace(":activePage", selectedSidebarItem) || "",
      );
    });

    return tab?.value;
  }, [pathname, tabsOfPage]);

  const goToPage = (tab: ITab) => {
    if (tab.value !== selectedTab) {
      let path = tab.path;
      path =
        path
          ?.replace(":dealUuid", String(dealUuid))
          .replace(":caseDealUuid", String(caseDealUuid))
          .replace(":activePage", selectedSidebarItem) || "";

      navigate(path);
    }
  };

  return !currentDeal || currentDeal.uuid !== dealIdToUse ? (
    <LoaderScreen />
  ) : (
    <TabbedLayout module="Deals" pages="Components">
      <>
        <Tabs onClick={goToPage} tabs={tabsOfPage} selectedTab={selectedTab} />
        {children}
      </>
    </TabbedLayout>
  );
}
