import {
  IReportTable,
  IDealTaxCredit,
  IDealTaxCreditTransfer,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferResults,
  IsummaryTablesObject,
} from "../../interfaces";
import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getDealTaxCreditPTC_Data = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.taxCredit.getPTC_Data, {
    dealUuid,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadDealTaxCreditPTC_Data = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.taxCredit.downloadPTC_Data, {
    dealUuid,
  });
  return await AxiosHelper.downloadFile(url);
};

export const getTaxCredit = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.taxCredit.get, { dealUuid });
  return await AxiosHelper.get<IDealTaxCredit[]>(url);
};

export const getDealTaxCreditTransferDetails = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.dealTaxCreditTransfer.get, {
    dealUuid,
  });
  return await AxiosHelper.get<IDealTaxCreditTransfer[]>(url);
};

export const getDealTaxCreditDirectPayDetails = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.dealTaxCreditDirectPay.get, {
    dealUuid,
  });
  return await AxiosHelper.get<IDealTaxCreditTransfer[]>(url);
};

export const updateDealTaxCreditTransferDetails = async (
  dealUuid: string,
  transferSizingId: number,
  form: IDealTaxCreditTransferForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealTaxCreditTransfer.update, {
    dealUuid,
    transferSizingId,
  });
  return await AxiosHelper.patch<
    IDealTaxCreditTransferForm,
    IDealTaxCreditTransferResults
  >(url, form);
};

export const getDealTaxSummary = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.tax.summary, {
    dealUuid,
  });
  return await AxiosHelper.get<IsummaryTablesObject>(url);
};
