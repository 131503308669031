import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import CopyIcon from "@mui/icons-material/ContentCopy";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "../icon-button";
import { SelectChangeEvent } from "@mui/material/Select";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { cloneDeep } from "lodash";

import Modal from "../modal";
import Tooltip from "../tooltip";
import useStyles from "./styles";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import SchedulerInput from "../scheduler-input";
import CheckboxInput from "../checkbox-input";
import { useCopyToClipboard } from "../../utils/hooks";
import {
  cn,
  formatNumberForView,
  generateHourlyTimestamps,
  keepNumbersDotAndMinus,
} from "../../utils/helpers";
import {
  PROJECT_GROSS_PRODUCTION_METHOD_OPTIONS,
  PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE_OPTIONS,
  PERIODICITY,
  PERIODICITY_OPTIONS,
  PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES_OPTIONS,
  PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES_OPTIONS,
  PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES_OPTIONS,
  PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES_OPTIONS,
  PROJECT_PRODUCTION_INPUT_TYPE_OPTIONS,
  PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE,
  PROJECT_ENERGY_TYPES,
  UPDATE_PROJECT_PRODUCTION_FORM_DEFAULT_STATE,
  PROJECT_PRODUCTION_DEGRADATION_METHODS_OPTIONS,
  PROJECT_PRODUCTION_METHOD_INPUT_UNIT_MAP,
  PROJECT_PRODUCTION_INPUT_TYPE_OPTIONS_HOURLY_8760,
  PROJECT_PRODUCTION_INPUT_METHOD_OPTIONS,
} from "../../constants";
import {
  IUpdateProjectProductionForm,
  IUpdateProjectProductionFormErrors,
  IProjectProduction,
  SetStateAction,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  dateSchedule: string[];
  formErrors?: IUpdateProjectProductionFormErrors;
  setFormErrors: SetStateAction<IUpdateProjectProductionFormErrors | undefined>;
  seasonalityAdjustmentFactors: Array<{ name: string; value: string }> | null;
  setSeasonalityAdjustmentFactors: SetStateAction<Array<{
    name: string;
    value: string;
  }> | null>;
  productionInputValues: { [key: string]: string };
  setProductionInputValues: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  form: IUpdateProjectProductionForm;
  setForm: SetStateAction<IUpdateProjectProductionForm>;
  onClose: () => void;
  onConfirm: (
    form: IUpdateProjectProductionForm,
  ) => Promise<IProjectProduction | undefined>;
  projectEnergyType?: keyof typeof PROJECT_ENERGY_TYPES;
}

export default function ProjectProductionFormModal({
  open,
  headerLabel,
  formErrors,
  loading,
  dateSchedule,
  setFormErrors,
  seasonalityAdjustmentFactors,
  setSeasonalityAdjustmentFactors,
  productionInputValues,
  setProductionInputValues,
  form,
  setForm,
  onClose,
  onConfirm,
  projectEnergyType,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { copyToClipboard } = useCopyToClipboard();

  const grossProductionType = React.useMemo(() => {
    if (!form?.gross_production_method) return undefined;
    return PROJECT_PRODUCTION_METHOD_INPUT_UNIT_MAP[
      form.gross_production_method
    ];
  }, [form.gross_production_method]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProductionInputCopy = () => {
    copyToClipboard(
      Object.values(productionInputValues).map((item) => item) || [],
    );
  };

  const handleSeasonalityAdjustment = (value: keyof typeof PERIODICITY) => {
    switch (value) {
      case "MO": {
        const months = Array.from({ length: 12 }, (_, i) => ({
          name: format(new Date(2021, i, 1), "MMMM"),
          value: "",
        }));
        setSeasonalityAdjustmentFactors([...months]);

        break;
      }
      case "QU": {
        const quarters = Array.from({ length: 4 }, (_, i) => ({
          name: `Quarter ${i + 1}`,
          value: "",
        }));
        setSeasonalityAdjustmentFactors([...quarters]);
        break;
      }
      case "SA": {
        setSeasonalityAdjustmentFactors([
          { name: "H1", value: "" },
          { name: "H2", value: "" },
        ]);
        break;
      }
      case "AN": {
        setSeasonalityAdjustmentFactors([]);
        break;
      }
      default: {
      }
    }
  };

  const getArrayValueFromPaste = (
    e: React.ClipboardEvent<HTMLInputElement | HTMLDivElement>,
  ) => {
    const copiedValue = e.clipboardData.getData("text/plain");
    const splitChar = copiedValue.includes("\t") ? "\t" : "\n";
    const arrayValues = copiedValue.split(splitChar);

    if (arrayValues[arrayValues.length - 1] === "") {
      arrayValues.pop();
    }

    return arrayValues.map((v) => {
      const cleanedValue = keepNumbersDotAndMinus(v);
      return cleanedValue === "" ? null : cleanedValue;
    });
  };

  const onSeasonalityFactorPaste = (
    e: React.ClipboardEvent<HTMLInputElement | HTMLDivElement>,
    pastedAtIndex: number,
  ) => {
    e.preventDefault();

    const cleanedArray = getArrayValueFromPaste(e);

    const value: (string | null)[] =
      seasonalityAdjustmentFactors?.map((sF) => sF.value) || [];

    const newCurve = cloneDeep(value);
    newCurve.splice(pastedAtIndex, cleanedArray.length, ...cleanedArray);

    if (newCurve.length > value.length) {
      toast.info(
        "Entries were truncated, since there were more than the permitted number of values.",
        { toastId: "seasonality-input-entries-truncation-info" },
      );
    }

    setSeasonalityAdjustmentFactors((prev) => {
      if (!prev) return prev;
      const updated = prev.map((sF, idx) => ({
        name: sF.name,
        value: newCurve[idx] as string,
      }));
      return updated;
    });
  };

  const onProductionInputPaste = (
    e: React.ClipboardEvent<HTMLInputElement | HTMLDivElement>,
    pastedAtIndex: number,
  ) => {
    e.preventDefault();

    const cleanedArray = getArrayValueFromPaste(e);

    let startIndexToPaste = 0;
    const totalInputs = Object.keys(
      PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE,
    ).length;

    if (cleanedArray.length + pastedAtIndex > totalInputs) {
      toast.info(
        "Entries were truncated, since there were more than the permitted number of values.",
        { toastId: "production-input-entries-truncation-info" },
      );
    }

    Object.keys(PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE).forEach(
      (factorType: string, index: number) => {
        if (index >= pastedAtIndex) {
          setProductionInputValues((prev) => {
            if (startIndexToPaste in cleanedArray) {
              const value = cleanedArray[startIndexToPaste++] ?? "";
              return {
                ...prev,
                [factorType]: getFormattedValue(value),
              };
            }
            return prev;
          });
        }
      },
    );
  };

  const handleSeasonalityAdjustmentFactorsChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const { value } = e.target;
    const regex = /^\d*\.?\d*$/; // regex to allow only floating numbers
    if (!regex.test(value)) {
      return;
    }
    if (
      value === "-" ||
      parseInt(value) < 0 ||
      typeof parseInt(value) !== "number"
    ) {
      return;
    }

    if (value.includes(".")) {
      const decimalPlaces = value.split(".")[1];
      if (decimalPlaces.length > 6) {
        return;
      }
    }

    setSeasonalityAdjustmentFactors((prev) => {
      if (!prev) return prev;
      const updated = [...prev];
      updated[index].value = value;
      return updated;
    });
  };

  React.useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      seasonality_adjustment_factors_total:
        seasonalityAdjustmentFactors?.reduce(
          (acc, curr) => acc + Number(curr.value),
          0,
        ) || 0,
    }));
  }, [seasonalityAdjustmentFactors]);

  // New state to track production input values
  // const [productionInputValues, setProductionInputValues] = React.useState({});

  // Function to handle production input change
  const handleProductionInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    const formattedValue = getFormattedValue(value);
    setProductionInputValues((prevValues: { [key: string]: string }) => ({
      ...prevValues,
      [name]: formattedValue,
    }));
  };

  const getFormattedValue = (value: string) => {
    const cleanedValue = keepNumbersDotAndMinus(value);
    // If it is not NCF (input is not a percentage) then format the numbers
    return form.gross_production_method === "NCF"
      ? cleanedValue
      : formatNumberForView(cleanedValue);
  };

  const handleCurveChange = (
    value: (number | string | null)[],
    field: string,
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "seasonality_adjustment_type") {
      handleSeasonalityAdjustment(e.target.value as keyof typeof PERIODICITY);
    }

    if (field === "degradation_input_type") {
      form.degradation_rate_percentage = "";
      form.degradation_rate_percentage_curve = null;
    }

    if (field === "curtailment_input_type") {
      form.curtailment_percentage = "";
      form.curtailment_percentage_curve = null;
    }

    if (field === "other_losses_input_type") {
      form.other_losses_percentage = "";
      form.other_losses_percentage_curve = null;
    }

    if (field === "availability_factor_input_type") {
      form.availability_factor_percentage = "";
      form.availability_factor_percentage_curve = null;
    }

    if (field === "probability_factor_type") {
      if (e.target.value !== "P50_10YR" && e.target.value !== "P50_1YR") {
        form.standard_deviation = null;
      }
    }

    if (field === "gross_production_method") {
      if (e.target.value === "HOURLY_8760") {
        form.production_input_type = "HOURLY_8760";
        form.seasonality_adjustment_factors = null;
        form.seasonality_adjustment_type = null;
        form.gross_production_input = null;
        setSeasonalityAdjustmentFactors(null);
      } else {
        form.production_input_type = "";
        if (!form.seasonality_adjustment_type) {
          form.seasonality_adjustment_type = "AN";
        }
      }
    }
    if (field === "production_input_type" && e.target.value === "TI") {
      form.production_input_method = "VAL";
    }

    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    form.seasonality_adjustment_factors =
      seasonalityAdjustmentFactors?.map((v) => Number(v.value)) || null;

    if (form.production_input_type === "TI") {
      const productionInputValuesToSend = Object.keys(
        productionInputValues,
      ).reduce(
        (acc, currentKey) => {
          return {
            ...acc,
            [currentKey]: keepNumbersDotAndMinus(
              productionInputValues[currentKey],
            ), // converting formatted strings to cleaned numbers to send to backend
          };
        },
        {} as Record<string, string>,
      );
      form.production_table_input = JSON.stringify(productionInputValuesToSend);
      form.gross_production_input = null;
    } else {
      // form.production_input = JSON.stringify({[form.probability_factor_type]: form.gross_production_input})
      form.production_table_input = JSON.stringify({});
    }
    const production = await onConfirm(form);
    production && handleOnClose();
  };

  const formatErr = (err?: string[] | string) => {
    return Array.isArray(err) ? err.join(" ") : err;
  };

  const handleResetform = () => {
    setForm(UPDATE_PROJECT_PRODUCTION_FORM_DEFAULT_STATE);
    setSeasonalityAdjustmentFactors([]);
    setProductionInputValues({});
    setFormErrors({});
  };

  const handleCopySeasonalityFactor = () => {
    copyToClipboard(
      seasonalityAdjustmentFactors?.map((item) => item?.value) || [],
    );
  };

  const isThirdColumnVisible =
    projectEnergyType !== "BESS" &&
    form.gross_production_method !== "HOURLY_8760";

  return (
    <Modal
      open={open}
      maxWidth="md"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      classes={{ paper: styles.classes.modal }}
      resetForm={handleResetform}
    >
      <Grid container spacing={2} rowSpacing="none">
        <Grid item xs={12} md={isThirdColumnVisible ? 4 : 6}>
          <Typography variant="body1" className={styles.classes.sectionHeading}>
            Production
          </Typography>
          <TextInput
            required
            isNumeric
            label={
              projectEnergyType !== "BESS"
                ? "Project Capacity (AC)"
                : "Storage Power Capacity"
            }
            name="capacity_ac"
            endAdornment={<>MW</>}
            value={form.capacity_ac}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.capacity_ac)}
            helperText={formErrors?.capacity_ac}
            disabled={loading}
            fullWidth={false}
          />
          {projectEnergyType === "BESS" ? (
            <TextInput
              required
              isNumeric
              label="Duration"
              name="duration_hrs"
              endAdornment={<>Hrs</>}
              value={form.duration_hrs}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.duration_hrs)}
              helperText={formErrors?.duration_hrs}
              disabled={loading}
              fullWidth={false}
            />
          ) : null}
          {projectEnergyType !== "BESS" ? (
            <>
              <TextInput
                required
                isNumeric
                label="Project Capacity (DC)"
                name="capacity_dc"
                endAdornment={<>MW</>}
                value={form.capacity_dc}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.capacity_dc)}
                helperText={formErrors?.capacity_dc}
                disabled={loading}
                fullWidth={false}
              />
              <SelectInput
                required
                label="Production Method"
                selected={form.gross_production_method}
                items={PROJECT_GROSS_PRODUCTION_METHOD_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("gross_production_method")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "gross_production_method")
                }
                error={Boolean(formErrors?.gross_production_method)}
                helperText={formErrors?.gross_production_method}
                disabled={loading}
                tooltip="<b>NCF</b> = Net Capacity Factor \n<b>AEP</b> = Annual Energy Production \n<b>Yield</b> = Specific yield (kWh/kWp)\n<b>Hourly Production 8760</b> = Hourly production for each hour of the year"
                fullWidth={false}
              />
              <SelectInput
                required
                label="Production Input Type"
                selected={form.production_input_type ?? ""}
                items={
                  form.gross_production_method === "HOURLY_8760"
                    ? PROJECT_PRODUCTION_INPUT_TYPE_OPTIONS_HOURLY_8760
                    : PROJECT_PRODUCTION_INPUT_TYPE_OPTIONS
                }
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("production_input_type")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "production_input_type")
                }
                error={Boolean(formErrors?.production_input_type)}
                helperText={formErrors?.production_input_type}
                disabled={loading}
                tooltip="<b>Single Input</b> = Enter a single value. (i.e. from a PVsyst run). \n<b>Table Input</b> = Enter a table of values across various p-factors (i.e. from a resource consultant report)."
                fullWidth={false}
              />
              {form.production_input_type === "TI" ? (
                <SelectInput
                  required
                  label="Input Methods"
                  selected={form.production_input_method ?? ""}
                  items={PROJECT_PRODUCTION_INPUT_METHOD_OPTIONS}
                  onFocus={() =>
                    clearNonTextFieldErrorOnFocus("production_input_method")
                  }
                  onChange={(e) =>
                    handleSelectInputChange(e, "production_input_method")
                  }
                  error={Boolean(formErrors?.production_input_method)}
                  helperText={formErrors?.production_input_method}
                  disabled={loading}
                  fullWidth={false}
                  tooltip="<b>% of P50</b> = Enter a percentage of the P50 value. \n<b>Value</b> = Enter a value."
                />
              ) : null}
            </>
          ) : null}
          {form.production_input_type === "HOURLY_8760" ? (
            <SchedulerInput
              label="8760 Input (KWh)"
              name="hourly_8760_curve"
              dateSchedule={generateHourlyTimestamps("2025") || []} // Passed a arbitrary non leap year year
              value={form?.hourly_8760_curve || []}
              error={formErrors?.hourly_8760_curve || ""}
              clearErrorOnFocus={clearErrorOnFocus}
              onChange={(v) => handleCurveChange(v, "hourly_8760_curve")}
              disabled={loading}
              fullWidth={false}
              tooltip="Hourly Production for a year i.e. 8760 hours in (KWh)"
              isTimestamps
            />
          ) : null}
          {projectEnergyType !== "BESS" &&
          form.production_input_type === "SI" ? (
            <TextInput
              required
              isNumeric
              label="Production Input"
              name="gross_production_input"
              value={form.gross_production_input}
              endAdornment={<>{grossProductionType}</>}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.gross_production_input)}
              helperText={formErrors?.gross_production_input}
              disabled={loading}
              fullWidth={false}
            />
          ) : null}
          {projectEnergyType !== "BESS" ? (
            <SelectInput
              required
              label="Benchmark Probability Factor"
              selected={form.probability_factor_type ?? ""}
              items={PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE_OPTIONS}
              onFocus={() =>
                clearNonTextFieldErrorOnFocus("probability_factor_type")
              }
              onChange={(e) =>
                handleSelectInputChange(e, "probability_factor_type")
              }
              error={Boolean(formErrors?.probability_factor_type)}
              helperText={formErrors?.probability_factor_type}
              disabled={loading}
              fullWidth={false}
              tooltip="Used as a reference point for other probability factors."
            />
          ) : null}
          {form.production_input_type === "TI" ? (
            <>
              <TableContainer
                component={Paper}
                classes={{ root: cn("[&_th]:px-1") }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Probability Factor</TableCell>
                      <TableCell align="left">
                        {
                          <>
                            Production Input
                            <IconButton onClick={handleProductionInputCopy}>
                              <CopyIcon fontSize="small" />
                            </IconButton>
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(
                      PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE,
                    ).map((factorType: string, index: number) => (
                      <TableRow key={index} className={styles.classes.tableRow}>
                        <TableCell>
                          {PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE[
                            factorType as keyof typeof PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE
                          ] +
                            (form.production_input_method == "P50" &&
                            factorType !== "P50_10YR" &&
                            factorType !== "P50_1YR"
                              ? " (% of P50)"
                              : "")}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            classes={{
                              root: styles.classes.pFactorTableTextField,
                            }}
                            name={factorType}
                            value={
                              productionInputValues[
                                factorType as keyof typeof productionInputValues
                              ] || ""
                            }
                            error={Boolean(formErrors?.production_table_input)}
                            onChange={handleProductionInputChange}
                            onFocus={() =>
                              clearNonTextFieldErrorOnFocus(
                                "production_table_input",
                              )
                            }
                            onPaste={(e) => onProductionInputPaste(e, index)}
                            InputProps={{
                              endAdornment: (
                                <>
                                  {form.production_input_method == "P50" &&
                                  factorType !== "P50_10YR" &&
                                  factorType !== "P50_1YR"
                                    ? "%"
                                    : grossProductionType}
                                </>
                              ),
                            }}
                            disabled={loading}
                            onWheel={(e) =>
                              (e.target as HTMLInputElement).blur()
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <FormHelperText
                error={Boolean(formErrors?.production_table_input)}
              >
                {formatErr(formErrors?.production_table_input)}
              </FormHelperText>
              <Box marginTop={2} />
            </>
          ) : null}
          {projectEnergyType !== "BESS" &&
          (form.production_input_type === "SI" ||
            form.production_input_type === "HOURLY_8760") &&
          (form.probability_factor_type === "P50_10YR" ||
            form.probability_factor_type === "P50_1YR") ? (
            <TextInput
              isNumeric
              label="Standard Deviation"
              name="standard_deviation"
              value={form.standard_deviation}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.standard_deviation)}
              helperText={formErrors?.standard_deviation}
              endAdornment={<>%</>}
              disabled={loading}
              tooltip="Production uncertainty, assuming normal distribution, for the Benchmark Probability Factor."
              fullWidth={false}
            />
          ) : null}
          {projectEnergyType !== "BESS" ? (
            <>
              <SelectInput
                required
                label="Case Probability Factor"
                selected={form.case_probability_factor_type}
                items={PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("case_probability_factor_type")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "case_probability_factor_type")
                }
                error={Boolean(formErrors?.case_probability_factor_type)}
                helperText={formErrors?.case_probability_factor_type}
                disabled={loading}
                fullWidth={false}
                tooltip="Active input used for current case."
              />
              <CheckboxInput
                label="Adjust for Leap Years"
                name="adjust_for_leap_years"
                checked={form.adjust_for_leap_years}
                disabled={loading}
                tooltip="When leap year adjustment is turned on, production for February in a leap year is multiplied by 29/28"
                onChange={handleCheckboxChange}
              />
            </>
          ) : null}
        </Grid>

        <Grid item xs={12} md={isThirdColumnVisible ? 4 : 6}>
          <Typography variant="body1" className={styles.classes.sectionHeading}>
            Loss Factors
          </Typography>
          <SelectInput
            required
            label="Degradation Method"
            selected={form.degradation_method}
            items={PROJECT_PRODUCTION_DEGRADATION_METHODS_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("degradation_method")}
            onChange={(e) => handleSelectInputChange(e, "degradation_method")}
            error={Boolean(formErrors?.degradation_method)}
            helperText={formErrors?.degradation_method}
            disabled={loading}
            fullWidth={false}
            tooltip="Linear method assumes increases by the same amount each period (Degradation = 1 - Degradation Rate x Number of Periods); Geometric method assumes increases by multiplying the preceding value by the new value, or calculated exponentially (Degradation = (1 - Degradation Rate ^ Number of Periods)."
          />
          <SelectInput
            required
            label="Degradation Input Type"
            selected={form.degradation_input_type}
            items={PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("degradation_input_type")
            }
            onChange={(e) =>
              handleSelectInputChange(e, "degradation_input_type")
            }
            error={Boolean(formErrors?.degradation_input_type)}
            helperText={formErrors?.degradation_input_type}
            disabled={loading}
            fullWidth={false}
          />
          {form.degradation_input_type === "DRP" ? (
            <TextInput
              required
              isNumeric
              label="Degradation Rate"
              name="degradation_rate_percentage"
              endAdornment={<>%</>}
              value={form.degradation_rate_percentage}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.degradation_rate_percentage)}
              helperText={formErrors?.degradation_rate_percentage}
              disabled={loading}
              fullWidth={false}
            />
          ) : null}
          {form.degradation_input_type === "DRPC" ? (
            <SchedulerInput
              label="Cumulative Degradation Rate Percentage Curve"
              name="degradation_rate_percentage_curve"
              dateSchedule={dateSchedule || []}
              value={form?.degradation_rate_percentage_curve || []}
              error={formErrors?.degradation_rate_percentage_curve || ""}
              clearErrorOnFocus={clearErrorOnFocus}
              onChange={(v) =>
                handleCurveChange(v, "degradation_rate_percentage_curve")
              }
              disabled={loading}
              endAdornment={"%"}
              tooltip="This is cumulative Degradation (not periodic). This means each future periods Degradation should be an equal or higher number than the previous periods."
              fullWidth={false}
            />
          ) : null}
          {projectEnergyType !== "BESS" ? (
            <>
              <SelectInput
                required
                label="Availability Factor Input Type"
                selected={form.availability_factor_input_type}
                items={
                  PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES_OPTIONS
                }
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus(
                    "availability_factor_input_type",
                  )
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "availability_factor_input_type")
                }
                error={Boolean(formErrors?.availability_factor_input_type)}
                helperText={formErrors?.availability_factor_input_type}
                disabled={loading}
                fullWidth={false}
              />
              {form.availability_factor_input_type === "AFP" ? (
                <TextInput
                  required
                  isNumeric
                  label="Availability Factor"
                  name="availability_factor_percentage"
                  endAdornment={<>%</>}
                  value={form.availability_factor_percentage}
                  onFocus={clearErrorOnFocus}
                  onChange={handleTextChange}
                  error={Boolean(formErrors?.availability_factor_percentage)}
                  helperText={formErrors?.availability_factor_percentage}
                  disabled={loading}
                  fullWidth={false}
                />
              ) : null}
              {form.availability_factor_input_type === "AFPC" ? (
                <SchedulerInput
                  label="Availability Factor Percentage Curve"
                  name="availability_factor_percentage_curve"
                  dateSchedule={dateSchedule || []}
                  value={form?.availability_factor_percentage_curve || []}
                  error={formErrors?.availability_factor_percentage_curve || ""}
                  clearErrorOnFocus={clearErrorOnFocus}
                  onChange={(v) =>
                    handleCurveChange(v, "availability_factor_percentage_curve")
                  }
                  disabled={loading}
                  endAdornment={"%"}
                  fullWidth={false}
                />
              ) : null}
              <SelectInput
                required
                label="Curtailment Input Type"
                selected={form.curtailment_input_type}
                items={PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("curtailment_input_type")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "curtailment_input_type")
                }
                error={Boolean(formErrors?.curtailment_input_type)}
                helperText={formErrors?.curtailment_input_type}
                disabled={loading}
                fullWidth={false}
              />
              {form.curtailment_input_type === "CP" ? (
                <TextInput
                  required
                  isNumeric
                  label="Curtailment Loss Percentage"
                  name="curtailment_percentage"
                  endAdornment={<>%</>}
                  value={form.curtailment_percentage}
                  onFocus={clearErrorOnFocus}
                  onChange={handleTextChange}
                  error={Boolean(formErrors?.curtailment_percentage)}
                  helperText={formErrors?.curtailment_percentage}
                  disabled={loading}
                  fullWidth={false}
                />
              ) : null}
              {form.curtailment_input_type === "CPC" ? (
                <SchedulerInput
                  label="Curtailment Loss Percentage Curve"
                  name="curtailment_percentage_curve"
                  dateSchedule={dateSchedule || []}
                  value={form?.curtailment_percentage_curve || []}
                  error={formErrors?.curtailment_percentage_curve || ""}
                  clearErrorOnFocus={clearErrorOnFocus}
                  onChange={(v) =>
                    handleCurveChange(v, "curtailment_percentage_curve")
                  }
                  disabled={loading}
                  endAdornment={"%"}
                  fullWidth={false}
                />
              ) : null}
              <SelectInput
                required
                label="Other Losses Input Type"
                selected={form.other_losses_input_type}
                items={PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("other_losses_input_type")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "other_losses_input_type")
                }
                error={Boolean(formErrors?.other_losses_input_type)}
                helperText={formErrors?.other_losses_input_type}
                disabled={loading}
                fullWidth={false}
              />
              {form.other_losses_input_type === "OLP" ? (
                <TextInput
                  required
                  isNumeric
                  label="Other Losses Percentage"
                  name="other_losses_percentage"
                  endAdornment={<>%</>}
                  value={form.other_losses_percentage}
                  onFocus={clearErrorOnFocus}
                  onChange={handleTextChange}
                  error={Boolean(formErrors?.other_losses_percentage)}
                  helperText={formErrors?.other_losses_percentage}
                  disabled={loading}
                  fullWidth={false}
                />
              ) : null}
              {form.other_losses_input_type === "OLPC" ? (
                <SchedulerInput
                  label="Other Losses Percentage Curve"
                  name="other_losses_percentage_curve"
                  dateSchedule={dateSchedule || []}
                  value={form?.other_losses_percentage_curve || []}
                  error={formErrors?.other_losses_percentage_curve || ""}
                  clearErrorOnFocus={clearErrorOnFocus}
                  onChange={(v) =>
                    handleCurveChange(v, "other_losses_percentage_curve")
                  }
                  disabled={loading}
                  endAdornment={"%"}
                  fullWidth={false}
                />
              ) : null}
            </>
          ) : null}
        </Grid>
        {isThirdColumnVisible ? (
          <Grid item xs={12} md={4}>
            <Box className={cn("flex justify-between items-center my-2")}>
              <Typography
                variant="body1"
                className={styles.classes.sectionHeading}
              >
                Seasonality Factor
              </Typography>
              {form.seasonality_adjustment_type !== "AN" && (
                <IconButton onClick={handleCopySeasonalityFactor}>
                  <CopyIcon />
                </IconButton>
              )}
            </Box>
            <SelectInput
              required
              label="Periodicity"
              selected={form.seasonality_adjustment_type ?? ""}
              items={PERIODICITY_OPTIONS}
              onChange={(e) =>
                handleSelectInputChange(e, "seasonality_adjustment_type")
              }
              onFocus={() =>
                clearNonTextFieldErrorOnFocus("seasonality_adjustment_type")
              }
              error={Boolean(formErrors?.seasonality_adjustment_type)}
              helperText={formErrors?.seasonality_adjustment_type}
              disabled={loading}
            />

            {form.seasonality_adjustment_type === "AN" ? (
              <Typography variant="body1" align="center">
                No Adjustment Needed
              </Typography>
            ) : null}
            {form.seasonality_adjustment_type === "MO" ||
            form.seasonality_adjustment_type === "QU" ||
            form.seasonality_adjustment_type === "SA" ? (
              <Box className={cn("flex gap-4")}>
                <Box className={cn("flex-1")}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {seasonalityAdjustmentFactors?.map((row, idx) => (
                          <TableRow
                            key={idx}
                            className={styles.classes.tableRow}
                          >
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="right">
                              <TextField
                                type="text"
                                name={row.name}
                                value={row.value}
                                classes={{ root: styles.classes.textField }}
                                error={Boolean(
                                  (formErrors?.seasonality_adjustment_factors ||
                                    [])[idx],
                                )}
                                onPaste={(e) =>
                                  onSeasonalityFactorPaste(e, idx)
                                }
                                onChange={(e) =>
                                  handleSeasonalityAdjustmentFactorsChange(
                                    e,
                                    idx,
                                  )
                                }
                                onFocus={() =>
                                  clearNonTextFieldErrorOnFocus(
                                    "seasonality_adjustment_factors",
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={loading}
                                onWheel={(e) =>
                                  (e.target as HTMLInputElement).blur()
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <FormHelperText
                    error={Boolean(formErrors?.seasonality_adjustment_factors)}
                  >
                    {formatErr(formErrors?.seasonality_adjustment_factors)}
                  </FormHelperText>
                </Box>
                <Tooltip
                  title="You can paste multiple lines into this field by pressing Ctrl + V (Cmd + V on Mac). Each line will be treated as a separate entry."
                  placement="top-end"
                >
                  <InfoIcon classes={{ root: cn("text-light-gray") }} />
                </Tooltip>
              </Box>
            ) : null}
            {form.seasonality_adjustment_type !== "AN" ? (
              <Box marginTop={2} justifyContent="space-between" display="flex">
                <Typography variant="caption" fontWeight={600}>
                  Total
                </Typography>
                <Typography
                  variant="caption"
                  fontWeight={600}
                  color={
                    form.seasonality_adjustment_factors_total < 99.99
                      ? "red"
                      : ""
                  }
                >
                  {form.seasonality_adjustment_factors_total?.toFixed(6)}%
                </Typography>
              </Box>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </Modal>
  );
}
