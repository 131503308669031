import DealPagesLayout from "../../../components/deal-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import DealSizingTermDebtView from "../../../views/deal/sizing/term-debt";
import { getCurves } from "../../../apis/configuration/curves";
import {
  deleteDealDebt,
  addDealDebt,
  updateDealDebt,
} from "../../../apis/deal/debt";

export default function TermDebtPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealSizingTermDebtView
          deleteDealDebt={deleteDealDebt}
          addDealDebt={addDealDebt}
          updateDealDebt={updateDealDebt}
          getCurves={getCurves}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
