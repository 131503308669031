import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ReportTableV2 from "../../../../../../components/report-table-v2";
import useStyles from "../../details-page-styles";
import Logs from "../../../../../../components/logs";
import Button from "../../../../../../components/button";
import LogsWrapper from "../../../../../../components/logs-wrapper";
import LogsButton from "../../../../../../components/logs-button";
import ViewWrapper from "../../../../../../components/view-wrapper";
import DetailsCard from "../../../../../../components/details-card";
import DateSchedule from "../../../../../../components/date-schedule";
import CurveFieldLabel from "../../../../../../components/curve-field-label";
import ConditionalProtect from "../../../../../../components/conditional-protect";
import ProjectMerchantRevenueFormModal from "../../../../../../components/project-merchant-revenue-form-modal";
import { cn, numberToUSD } from "../../../../../../utils/helpers";
import {
  setCurrentOrgCurvesAction,
  setDeleteModalPropsAction,
} from "../../../../../../utils/redux/slices";
import {
  useAPI,
  useAppSelector,
  useDrawer,
  useLogs,
} from "../../../../../../utils/hooks";
import {
  PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES,
  PROJECT_MERCHANT_PRICE_INPUT_TYPES,
  PROJECT_REVENUE_MERCHANT_FORM_DEFAULT_STATE,
  PROJECT_REVENUE_MERCHANT_TYPE,
  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES,
} from "../../../../../../constants";
import {
  IProjectTiming,
  IProjectMerchantRevenue,
  IProjectMerchantRevenueForm,
  IProjectMerchantRevenueFormErrors,
  ISelectOption,
  IGetCurvesParams,
  ServerPaginatedResponse,
  IOrganizationCurve,
  ILogsConfiguration,
  IReportTable,
} from "../../../../../../interfaces";

interface IProps {
  getProjectTiming: (projectUuid: string) => Promise<IProjectTiming[]>;
  getProjectMerchantRevenueDetails: (
    projectUuid: string,
    revenueId: number,
  ) => Promise<IProjectMerchantRevenue>;
  updateProjectMerchantRevenue: (
    projectUuid: string,
    revenueId: number,
    form: IProjectMerchantRevenueForm,
  ) => Promise<IProjectMerchantRevenue>;
  deleteProjectMerchantRevenueContract: (
    projectUuid: string,
    revenueContractId: number,
  ) => Promise<boolean>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
  getProjectMerchantRevenueComponents: (
    projectUuid: string,
    revenueId: number,
  ) => Promise<IReportTable>;
  downloadMerchantRevenueComponents: (
    projectUuid: string,
    revenueId: number,
  ) => Promise<void>;
}

export default function ProjectMerchantRevenueDetails({
  getProjectTiming,
  updateProjectMerchantRevenue,
  getProjectMerchantRevenueDetails,
  deleteProjectMerchantRevenueContract,
  getCurves,
  getProjectMerchantRevenueComponents,
  downloadMerchantRevenueComponents,
}: IProps): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();
  const { projectUuid, revenueId } = useParams();

  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);
  const { currentOrgCurves } = useAppSelector((s) => s.org);

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [basisCurveToggle, setBasisCurveToggle] =
    React.useState<boolean>(false);
  const [eligibieCurveToggle, setEligibieCurveToggle] =
    React.useState<boolean>(false);
  const [revenueCurveToggle, setRevenueCurveToggle] =
    React.useState<boolean>(false);
  const [priceCurveToggle, setPriceCurveToggle] =
    React.useState<boolean>(false);
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();
  const [revenue, setRevenue] = React.useState<IProjectMerchantRevenue | null>(
    null,
  );

  const [editRevenueModalOpen, setEditRevenueModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IProjectMerchantRevenueForm>(
    PROJECT_REVENUE_MERCHANT_FORM_DEFAULT_STATE,
  );

  const [
    projectMerchantRevenueComponents,
    setProjectMerchantRevenueComponents,
  ] = React.useState<IReportTable>();

  React.useEffect(() => {
    getProjectMerchantRevenueDetailsCallAPI(
      projectUuid,
      Number(revenueId),
    ).then((response) => {
      response && setRevenue(response);
    });
    getProjectTimingCallAPI(projectUuid).then((response) => {
      response && setProjectTiming(response[0]);
    });

    handleGetProjectMerchantRevenueComponents(
      String(projectUuid),
      Number(revenueId),
    );
  }, [projectUuid, revenueId]);

  const {
    callAPI: getProjectMerchantRevenueDetailsCallAPI,
    errored: getRevenueFailed,
    loading: loadingRevenue,
  } = useAPI(
    (projectUuid: string, revenueId: number) =>
      getProjectMerchantRevenueDetails(String(projectUuid), Number(revenueId)),
    { initialLoading: true },
  );

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectUuid: string) =>
    getProjectTiming(String(projectUuid)),
  );

  const {
    callAPI: updateProjectMerchantRevenueCallAPI,
    fieldErrors: updateProjectMerchantRevenueFormErrors,
    setFieldErrors: setUpdateProjectMerchantRevenueFormErrors,
    loading: updateProjectMerchantRevenueLoading,
  } = useAPI<IProjectMerchantRevenue, IProjectMerchantRevenueFormErrors>(
    (
      projectUuid: string,
      revenueId: number,
      form: IProjectMerchantRevenueForm,
    ) => updateProjectMerchantRevenue(projectUuid, revenueId, form),
  );

  const { callAPI: downloadMerchantRevenueComponentsCallAPI } = useAPI(
    (projectUuid: string, revenueId: number) =>
      downloadMerchantRevenueComponents(projectUuid, revenueId),
  );

  const { callAPI: deleteProjectMerchantRevenueContractCallAPI } = useAPI(
    (projectUuid: string, revenueId: number) =>
      deleteProjectMerchantRevenueContract(projectUuid, revenueId),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: getProjectMerchantRevenueComponentsCallAPI,
    loading: loadingProjectMerchantRevenueComponents,
    errored: getProjectMerchantRevenueComponentsFailed,
  } = useAPI((projectUuid, revenueId) =>
    getProjectMerchantRevenueComponents(projectUuid, revenueId),
  );

  const handleDelete = async () => {
    const deleted = await deleteProjectMerchantRevenueContractCallAPI(
      projectUuid,
      Number(revenueId),
    );

    if (deleted) {
      navigate(`/project/${projectUuid}/pro-forma/revenue/merchant`);
    }
  };

  const handleEditRevenueContract = async (
    form: IProjectMerchantRevenueForm,
  ) => {
    const merchantRevenue = await updateProjectMerchantRevenueCallAPI(
      projectUuid,
      Number(revenueId),
      form,
    );
    if (merchantRevenue) {
      await handleGetProjectMerchantRevenueComponents(
        String(projectUuid),
        Number(revenueId),
      );
      setRevenue(merchantRevenue);
    }

    return merchantRevenue;
  };

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: ["PC", "ELCC"] }).catch(
      () => null,
    );
    curves && dispatch(setCurrentOrgCurvesAction(curves.results));
  };

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleEditClick = async () => {
    await handleGetCurves();
    const { created, created_by, id, modified, project, ...formData } =
      revenue!;
    setForm({
      ...formData,
      base_year: formData.base_year || getDefaultBaseYear(),
    });
    setEditRevenueModalOpen(true);
  };

  const handleCloseEditRevenueModal = () => {
    setEditRevenueModalOpen(false);
  };

  const handleOnDeleteClick = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Revenue",
        description: "Are you sure you want to delete this revenue?",
        onConfirm: handleDelete,
      }),
    );
  };

  const priceCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves
      .filter((c) => c.curve_type === "PC")
      .map((c) => ({
        label: c.name,
        value: c.uuid,
      }));
  }, [currentOrgCurves]);

  const elccCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves
      .filter((c) => c.curve_type === "ELCC")
      .map((c) => ({
        label: c.name,
        value: c.uuid,
      }));
  }, [currentOrgCurves]);

  const gotoCurveDetailPage = (url: string) => {
    window.open(url);
  };

  const projectRevenueLogConfiguration: ILogsConfiguration = {
    id: Number(revenue?.id),
    type: "projectmerchantrevenue",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectRevenueLogConfiguration.type,
      projectRevenueLogConfiguration.id,
    );
  };

  const handleGetProjectMerchantRevenueComponents = async (
    projectUuid: string,
    revenueId: number,
  ) => {
    const components = await getProjectMerchantRevenueComponentsCallAPI(
      projectUuid,
      revenueId,
    );

    if (components) {
      setProjectMerchantRevenueComponents(components);
    }
  };

  const handleDownloadMerchantRevenueComponents = async () => {
    await downloadMerchantRevenueComponentsCallAPI(
      String(projectUuid),
      Number(revenueId),
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingRevenue} error={getRevenueFailed}>
        {revenue ? (
          <Box>
            <Box className={cn("flex justify-between gap-2 mt-2 mb-4")}>
              <IconButton
                data-pw="go-back-btn"
                classes={{ root: styles.classes.iconButton }}
                onClick={() => window.history.back()}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box className={cn("flex gap-2")}>
                <LogsButton onClick={handleOnOpenLogs} />

                <ConditionalProtect type="project">
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleEditClick}
                  />
                  <Button
                    canOpenUpgrade
                    label="Delete"
                    btnType="danger"
                    onClick={handleOnDeleteClick}
                  />
                </ConditionalProtect>
              </Box>
            </Box>
            <Box className={cn("grid md:grid-cols-2 gap-4")}>
              <DetailsCard
                heading=""
                autoHeight
                sections={[
                  {
                    fields: [
                      {
                        label: "Type",
                        value: {
                          text:
                            revenue.type === "CAPACITY" &&
                            currentProject?.energy_type === "BESS"
                              ? "Merchant Capacity"
                              : PROJECT_REVENUE_MERCHANT_TYPE[revenue.type],
                        },
                      },
                      {
                        label: "Name",
                        value: { text: revenue.name || "" },
                      },
                      ...(revenue.type !== "OTHER"
                        ? [
                            ...(revenue.type === "CAPACITY"
                              ? [
                                  {
                                    label: "Eligible Capacity Input Type",
                                    value: {
                                      text: PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                        revenue.eligible_capacity_input_type
                                      ],
                                    },
                                  },
                                  ...(PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                    revenue.eligible_capacity_input_type
                                  ] ===
                                  PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES.FIXED
                                    ? [
                                        {
                                          label: "Eligible Capacity Percentage",
                                          value: {
                                            text:
                                              revenue.eligible_capacity_percentage +
                                              "%",
                                          },
                                        },
                                      ]
                                    : []),
                                  ...(PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                    revenue.eligible_capacity_input_type
                                  ] ===
                                  PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES.CURVE
                                    ? [
                                        {
                                          label: (
                                            <CurveFieldLabel
                                              label="Eligible Capacity Percentage Curve"
                                              primaryAction={
                                                setEligibieCurveToggle
                                              }
                                              value={
                                                revenue.eligible_capacity_percentage_curve
                                              }
                                              toggled={eligibieCurveToggle}
                                            />
                                          ),
                                          value: {
                                            text: (
                                              <DateSchedule
                                                curve={
                                                  revenue.eligible_capacity_percentage_curve ||
                                                  []
                                                }
                                                dateSchedule={
                                                  projectTiming?.date_schedule ||
                                                  []
                                                }
                                                toggle={eligibieCurveToggle}
                                                handleToggle={
                                                  setEligibieCurveToggle
                                                }
                                              />
                                            ),
                                          },
                                        },
                                      ]
                                    : []),
                                  ...(PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                    revenue.eligible_capacity_input_type
                                  ] ===
                                  PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES.OC
                                    ? [
                                        {
                                          label:
                                            "Eligible Capacity Organization Curve Name",
                                          value: {
                                            text: (
                                              <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() =>
                                                  gotoCurveDetailPage(
                                                    `/configuration/data/curves/${revenue.eligible_capacity_org_curve_detail?.curve_group}/${revenue.eligible_capacity_org_curve_detail?.uuid}`,
                                                  )
                                                }
                                              >
                                                {
                                                  revenue
                                                    ?.eligible_capacity_org_curve_detail
                                                    ?.name
                                                }
                                                <OpenInNewIcon fontSize="small" />
                                              </Link>
                                            ),
                                          },
                                        },
                                      ]
                                    : []),
                                ]
                              : []),

                            {
                              label: "Price Input Type",
                              value: {
                                text: PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                  revenue.price_input_type
                                ],
                              },
                            },
                            {
                              label: "Escalator (Calendar Year)",
                              value: { text: revenue.escalator + "%" },
                            },
                            {
                              label: "Escalator Base Year",
                              value: { text: String(revenue.base_year) },
                            },
                            {
                              label: "Price Haircut / (Adder)",
                              value: { text: revenue.price_haircut + "%" },
                            },
                            {
                              label: "Cash Lag (Months)",
                              value: {
                                text:
                                  revenue.cash_basis_lag !== null
                                    ? String(revenue.cash_basis_lag)
                                    : "N/A",
                              },
                            },
                            ...(revenue.price !== null &&
                            PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                              revenue.price_input_type
                            ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED
                              ? [
                                  {
                                    label:
                                      PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED,
                                    value: {
                                      text:
                                        numberToUSD.format(revenue.price) +
                                        (revenue.type === "CAPACITY"
                                          ? " / kW-Month"
                                          : " / MWh"),
                                    },
                                  },
                                ]
                              : []),
                            ...(PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                              revenue.price_input_type
                            ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.CURVE
                              ? [
                                  {
                                    label: (
                                      <CurveFieldLabel
                                        label="Price Curve"
                                        primaryAction={setPriceCurveToggle}
                                        value={revenue.price_curve}
                                        toggled={priceCurveToggle}
                                      />
                                    ),
                                    value: {
                                      text: (
                                        <DateSchedule
                                          curve={revenue.price_curve || []}
                                          dateSchedule={
                                            projectTiming?.date_schedule || []
                                          }
                                          inUSD
                                          toggle={priceCurveToggle}
                                          handleToggle={setPriceCurveToggle}
                                          endAdornment={`${
                                            revenue.type === "CAPACITY"
                                              ? " /kW-Month"
                                              : " /MWh"
                                          }`}
                                        />
                                      ),
                                    },
                                  },
                                ]
                              : []),
                            ...(PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                              revenue.price_input_type
                            ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.OC
                              ? [
                                  {
                                    label: "Organization Curve Name",
                                    value: {
                                      text: (
                                        <Link
                                          component="button"
                                          variant="body2"
                                          onClick={() =>
                                            gotoCurveDetailPage(
                                              `/configuration/data/curves/${revenue.org_curve_detail?.curve_group}/${revenue.org_curve_detail?.uuid}`,
                                            )
                                          }
                                        >
                                          {revenue?.org_curve_detail?.name}
                                          <OpenInNewIcon fontSize="small" />
                                        </Link>
                                      ),
                                    },
                                  },
                                  {
                                    label: "Curve Start Date",
                                    value: {
                                      text:
                                        revenue?.org_curve_detail?.start_date ??
                                        "N/A",
                                    },
                                  },
                                  {
                                    label: "Curve Term Years",
                                    value: {
                                      text:
                                        revenue?.org_curve_detail?.term_years.toString() +
                                        " Yrs",
                                    },
                                  },
                                ]
                              : []),
                          ]
                        : [
                            ...(revenue.type !== "OTHER"
                              ? [
                                  ...(revenue.type === "CAPACITY"
                                    ? [
                                        {
                                          label: "Eligible Capacity Input Type",
                                          value: {
                                            text: PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                              revenue
                                                .eligible_capacity_input_type
                                            ],
                                          },
                                        },
                                        ...(PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                          revenue.eligible_capacity_input_type
                                        ] ===
                                        PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES.FIXED
                                          ? [
                                              {
                                                label:
                                                  "Eligible Capacity Percentage",
                                                value: {
                                                  text:
                                                    revenue.eligible_capacity_percentage +
                                                    "%",
                                                },
                                              },
                                            ]
                                          : [
                                              {
                                                label: (
                                                  <CurveFieldLabel
                                                    label="Eligible Capacity Percentage Curve"
                                                    primaryAction={
                                                      setEligibieCurveToggle
                                                    }
                                                    value={
                                                      revenue.eligible_capacity_percentage_curve
                                                    }
                                                    toggled={
                                                      eligibieCurveToggle
                                                    }
                                                  />
                                                ),
                                                value: {
                                                  text: (
                                                    <DateSchedule
                                                      curve={
                                                        revenue.eligible_capacity_percentage_curve ||
                                                        []
                                                      }
                                                      dateSchedule={
                                                        projectTiming?.date_schedule ||
                                                        []
                                                      }
                                                      toggle={
                                                        eligibieCurveToggle
                                                      }
                                                      handleToggle={
                                                        setEligibieCurveToggle
                                                      }
                                                    />
                                                  ),
                                                },
                                              },
                                            ]),
                                      ]
                                    : []),

                                  {
                                    label: "Price Input Type",
                                    value: {
                                      text: PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                        revenue.price_input_type
                                      ],
                                    },
                                  },
                                  {
                                    label: "Escalator (Calendar Year)",
                                    value: { text: revenue.escalator + "%" },
                                  },
                                  {
                                    label: "Escalator Base Year",
                                    value: { text: String(revenue.base_year) },
                                  },
                                  {
                                    label: "Price Haircut / (Adder)",
                                    value: {
                                      text: revenue.price_haircut + "%",
                                    },
                                  },
                                  {
                                    label: "Cash Lag (Months)",
                                    value: {
                                      text:
                                        revenue.cash_basis_lag !== null
                                          ? String(revenue.cash_basis_lag)
                                          : "N/A",
                                    },
                                  },
                                  ...(revenue.price !== null &&
                                  PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                    revenue.price_input_type
                                  ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED
                                    ? [
                                        {
                                          label: "Price",
                                          value: {
                                            text:
                                              numberToUSD.format(
                                                revenue.price,
                                              ) +
                                              (revenue.type === "CAPACITY"
                                                ? " / kW-Month"
                                                : " / MWh"),
                                          },
                                        },
                                      ]
                                    : [
                                        {
                                          label: (
                                            <CurveFieldLabel
                                              label="Price Curve"
                                              primaryAction={
                                                setPriceCurveToggle
                                              }
                                              value={revenue.price_curve}
                                              toggled={priceCurveToggle}
                                            />
                                          ),
                                          value: {
                                            text: (
                                              <DateSchedule
                                                curve={
                                                  revenue.price_curve || []
                                                }
                                                dateSchedule={
                                                  projectTiming?.date_schedule ||
                                                  []
                                                }
                                                inUSD
                                                toggle={priceCurveToggle}
                                                handleToggle={
                                                  setPriceCurveToggle
                                                }
                                                endAdornment={`${
                                                  revenue.type === "CAPACITY"
                                                    ? " /kW-Month"
                                                    : " /MWh"
                                                }`}
                                              />
                                            ),
                                          },
                                        },
                                      ]),
                                ]
                              : [
                                  {
                                    label: "Price Haircut / (Adder)",
                                    value: {
                                      text: revenue.price_haircut + "%",
                                    },
                                  },
                                  {
                                    label: "Cash Lag (Months)",
                                    value: {
                                      text:
                                        revenue.cash_basis_lag !== null
                                          ? String(revenue.cash_basis_lag)
                                          : "N/A",
                                    },
                                  },
                                  {
                                    label: (
                                      <CurveFieldLabel
                                        label="Revenue Schedule"
                                        primaryAction={setRevenueCurveToggle}
                                        value={revenue.revenue_curve}
                                        toggled={revenueCurveToggle}
                                      />
                                    ),
                                    value: {
                                      text: (
                                        <DateSchedule
                                          inUSD
                                          showTotal={{
                                            total: revenue.schedule_total,
                                          }}
                                          curve={revenue.revenue_curve || []}
                                          dateSchedule={
                                            projectTiming?.date_schedule || []
                                          }
                                          toggle={revenueCurveToggle}
                                          handleToggle={setRevenueCurveToggle}
                                        />
                                      ),
                                    },
                                  },
                                ]),
                          ]),
                      ...(revenue.type === "ENERGY"
                        ? [
                            {
                              label: "Basis Differential Method",
                              value: {
                                text:
                                  revenue.differential_method &&
                                  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES[
                                    revenue.differential_method
                                  ],
                              },
                            },
                            ...(revenue.differential_method === "BDP"
                              ? [
                                  {
                                    label: "Basis Differential",
                                    value: {
                                      text:
                                        "$" +
                                        revenue.basis_differential +
                                        " / MWh",
                                    },
                                  },
                                ]
                              : [
                                  {
                                    label: (
                                      <CurveFieldLabel
                                        label="Basis Curve"
                                        primaryAction={setBasisCurveToggle}
                                        value={revenue.basis_curve}
                                        toggled={basisCurveToggle}
                                      />
                                    ),
                                    value: {
                                      text: (
                                        <DateSchedule
                                          curve={revenue.basis_curve || []}
                                          dateSchedule={
                                            projectTiming?.date_schedule || []
                                          }
                                          inUSD
                                          toggle={basisCurveToggle}
                                          handleToggle={setBasisCurveToggle}
                                        />
                                      ),
                                    },
                                  },
                                ]),
                          ]
                        : []),
                    ],
                  },
                ]}
              />
            </Box>
            <ViewWrapper
              loading={loadingProjectMerchantRevenueComponents}
              error={getProjectMerchantRevenueComponentsFailed}
            >
              {projectMerchantRevenueComponents ? (
                <Box className={cn("mt-4")}>
                  <ReportTableV2
                    data={projectMerchantRevenueComponents}
                    download={handleDownloadMerchantRevenueComponents}
                  />
                </Box>
              ) : null}
            </ViewWrapper>
          </Box>
        ) : null}
      </ViewWrapper>

      <ProjectMerchantRevenueFormModal
        headerLabel="Edit Merchant Revenue"
        open={editRevenueModalOpen}
        loading={updateProjectMerchantRevenueLoading}
        formErrors={updateProjectMerchantRevenueFormErrors}
        setFormErrors={setUpdateProjectMerchantRevenueFormErrors}
        form={form}
        dateSchedule={projectTiming?.date_schedule || []}
        setForm={setForm}
        onClose={handleCloseEditRevenueModal}
        onConfirm={handleEditRevenueContract}
        priceCurves={priceCurvesOptions}
        elccCurves={elccCurvesOptions}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectRevenueLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectRevenueLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
