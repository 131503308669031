import DealPagesLayout from "../../components/deal-pages-layout";
import DealGeneralView from "../../views/deal/general";
import { getDealTiming } from "../../apis/deal/timing";
import { getDealProforma } from "../../apis/deal/proforma";
import { addProject, getLocations, getProjects } from "../../apis/project/base";
import { updateDeal, duplicateDeal } from "../../apis/deal/base";
import { getCounties } from "../../apis/common";

export default function DealGeneral(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealGeneralView
        updateDeal={updateDeal}
        addProject={addProject}
        duplicateDeal={duplicateDeal}
        getProjects={getProjects}
        getDealTiming={getDealTiming}
        getDealProforma={getDealProforma}
        getLocations={getLocations}
        getCounties={getCounties}
      />
    </DealPagesLayout>
  );
}
