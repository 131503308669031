import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";

import ActionButton from "../components/action-button";
import { cn } from "../utils/helpers";

interface IProps {
  featureImageSrc?: string;
}

export default function UpsellView({ featureImageSrc }: IProps): JSX.Element {
  return (
    <Box className={cn("p-10")}>
      <Card variant="outlined" classes={{ root: cn("!border-red-500") }}>
        <CardContent>
          This feature is not currently active for your organization, please
          contact us at{" "}
          <Link className="underline" to="mailto:support@capezero.com">
            support@capezero.com
          </Link>
          <Box className="mt-4 flex justify-self-center">
            <ActionButton actionType="add">
              <Link to="https://capezero.com/contact" target="_blank">
                CONTACT US
              </Link>
            </ActionButton>

            <Box className="ml-4">
              <ActionButton actionType="duplicate">
                <Link to="/configuration/organization/billing-and-usage">
                  BILLING AND USAGE
                </Link>{" "}
              </ActionButton>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <img alt="" src={featureImageSrc} className={cn("w-full mt-4")} />
    </Box>
  );
}
