import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IDealProjectDepreciationByYear,
  IUpdateDealDepreciationResponse,
  IUpdateDealDepreciationElectionForm,
} from "../../interfaces";

export const getDealProjectDepreciation = async (
  dealUuid: string,
): Promise<IDealProjectDepreciationByYear> => {
  const url = replaceParams(DEAL_API_ROUTES.dealProjectDepreciation.get, {
    dealUuid,
  });
  return await AxiosHelper.get<IDealProjectDepreciationByYear>(url);
};

export const updateDealProjectDepreciation = async (
  dealUuid: string,
  form: IUpdateDealDepreciationElectionForm,
): Promise<IUpdateDealDepreciationResponse> => {
  const url = replaceParams(DEAL_API_ROUTES.dealProjectDepreciation.update, {
    dealUuid,
  });
  return await AxiosHelper.post<
    IUpdateDealDepreciationElectionForm,
    IUpdateDealDepreciationResponse
  >(url, form);
};
