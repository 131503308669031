import DealFMV_View from "../../../views/deal/components/fmv";
import DealPagesLayout from "../../../components/deal-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import { getDealFMV_Summary } from "../../../apis/deal/fmv";

export default function DealFMV_Page(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Components">
        <DealFMV_View getDealFMV_Summary={getDealFMV_Summary} />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
