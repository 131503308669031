import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IProjectProformaResponse,
  IsummaryTablesObject,
  ProformaReportType,
} from "../../interfaces";

export const getDealProforma = async (
  dealUuid: string,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealProforma.get, {
    dealUuid,
    periodicity,
    basis,
  });
  return await AxiosHelper.get<IProjectProformaResponse>(url);
};

export const downloadDealProforma = async (
  dealUuid: string,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  return await AxiosHelper.downloadFile(`deal-proforma/${dealUuid}/download/`, {
    params: {
      periodicity,
      basis,
    },
  });
};

export const getDealProformaSummary = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.dealProforma.summary, {
    dealUuid,
  });
  return await AxiosHelper.get<IsummaryTablesObject>(url);
};
