import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../components/view-wrapper";
import ReportTableV2 from "../../../../components/report-table-v2";
import ToggleSizingOutputButton from "../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../utils/hooks";
import { useAxios } from "../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../utils/redux/slices";
import { cn, getDealTimeSeriesReportUrl } from "../../../../utils/helpers";
import { ReportType, IDealReport } from "../../../../interfaces";
import {
  getDealTimeSeriesReport,
  downloadDealTimeSeriesReport,
} from "../../../../apis/report/deal";

interface IProps {
  reportType: ReportType;
}

export default function DealOutputConstructionDebtCommonView({
  reportType,
}: IProps): JSX.Element {
  const { caseDealUuid, dealUuid } = useParams();

  const dispatch = useDispatch();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IDealReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const {
    callAPI: getDealTimeSeriesReportCallAPI,
    errored: getDealTimeSeriesReportFailed,
    loading: loadingDealTimeSeriesReport,
    errorObj: getDealTimeSeriesReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealUuid: string, reportType: ReportType) =>
      getDealTimeSeriesReport(
        dealUuid,
        "constructiondebt",
        reportType,
        "default",
        "MO",
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    if (dealIdToUse) {
      getDealTimeSeriesReportCallAPI(dealIdToUse, reportType).then((res) => {
        res && setReport(res);
      });
    }
  }, [dealIdToUse]);

  React.useEffect(() => {
    !loadingDealTimeSeriesReport &&
      dispatch(updateDealOutputLoadedAction(!getDealTimeSeriesReportFailed));
  }, [getDealTimeSeriesReportFailed, loadingDealTimeSeriesReport]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadDealTimeSeriesReport(
        dealIdToUse,
        "constructiondebt",
        reportType,
        "default",
        "MO",
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const reportLoadingPercentage =
    urlLoadingPercentages[
      getDealTimeSeriesReportUrl(
        String(dealIdToUse),
        "constructiondebt",
        reportType,
        "default",
        "MO",
      )
    ];

  return (
    <ViewWrapper
      loading={loadingDealTimeSeriesReport}
      error={getDealTimeSeriesReportFailed}
      errorHeading={getDealTimeSeriesReportErrorObj?.heading}
      errorDescription={getDealTimeSeriesReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={reportLoadingPercentage}
    >
      <Box>
        <Box className={cn("flex justify-end gap-2 my-4")}>
          <ToggleSizingOutputButton />
          <IconButton
            title="Download Report"
            onClick={handleDownloadReport}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        </Box>

        {report?.data ? (
          <Box className={cn("mt-4")}>
            <ReportTableV2
              data={report?.data}
              groupExpandedDepth={-1}
              autoHeight={true}
            />
          </Box>
        ) : null}
      </Box>
    </ViewWrapper>
  );
}
