import ConfigurationPageLayout from "../../../components/configuration-page-layout";
import CounterPartiesView from "../../../views/configuration/data/counterparties";
import {
  addCounterpartyUser,
  createCounterparty,
  deleteCounterparty,
  deleteCounterpartyUser,
  getCounterparties,
  updateCounterparty,
} from "../../../apis/configuration/counterparties";

export default function CounterpartiesPage(): JSX.Element {
  return (
    <ConfigurationPageLayout showOrgTabs>
      <CounterPartiesView
        getCounterparties={getCounterparties}
        createCounterparty={createCounterparty}
        updateCounterparty={updateCounterparty}
        addCounterpartyUser={addCounterpartyUser}
        deleteCounterpartyUser={deleteCounterpartyUser}
        deleteCounterparty={deleteCounterparty}
      />
    </ConfigurationPageLayout>
  );
}
