import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { COUNTY_API_ROUTES, LOG_API_ROTES, OBJECT_TYPE } from "../../constants";
import {
  ICounty,
  ILog,
  INote,
  INoteForm,
  ServerPaginatedResponse,
} from "../../interfaces";

export const getLogs = async (
  object_type: OBJECT_TYPE,
  object_id: number | string,
  limit: number,
  offset: number,
  parentId?: number | string | undefined,
) => {
  return await AxiosHelper.get<ServerPaginatedResponse<ILog[]>>(
    LOG_API_ROTES.logs.get,
    {
      params: {
        object_type,
        object_id,
        limit,
        offset,
        ...(parentId && { parent_object_id: parentId }),
      },
    },
  );
};

export const getNotes = async (
  object_type: OBJECT_TYPE,
  object_id: number | string,
) => {
  return await AxiosHelper.get<ServerPaginatedResponse<INote[]>>(
    LOG_API_ROTES.notes.get,
    {
      params: {
        object_type,
        object_id,
      },
    },
  );
};

export const createNote = async (note: INoteForm) => {
  return await AxiosHelper.post<INoteForm, INote>(
    LOG_API_ROTES.notes.create,
    note,
  );
};

export const updateNote = async (id: number, note: INoteForm) => {
  const url = replaceParams(LOG_API_ROTES.notes.update, { id });
  return await AxiosHelper.put<INoteForm, INote>(url, note);
};

export const deleteNote = async (id: number) => {
  const url = replaceParams(LOG_API_ROTES.notes.delete, { id });
  return await AxiosHelper.delete(url);
};

export const getCounties = async (search: string = "", state: string = "") => {
  return await AxiosHelper.get<ServerPaginatedResponse<ICounty[]>>(
    COUNTY_API_ROUTES.base.get,
    {
      params: {
        search,
        state,
      },
    },
  );
};
