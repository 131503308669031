import React from "react";
import { format, isValid } from "date-fns";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import useStyles from "./styles";
import SelectInput from "../select-input";
import DatePicker from "../date-picker";
import CurrencyInput from "../currency-input";
import {
  SetStateAction,
  IAddProjectCreditSupportFormErrors,
  IAddProjectCreditSupportForm,
  IUpdateProjectCreditSupportForm,
  IProjectCreditSupport,
  IUpdateProjectCreditSupportFormErrors,
} from "../../interfaces";
import {
  PROJECT_OBLICATION_TYPE_OPTIONS,
  PROJECT_CREDIT_SUPPORT_TYPE_OPTIONS,
  PROJECT_CREDIT_SUPPORT_COST_METHOD_OPTIONS,
  DATE_SELECTION_TYPE_OPTIONS,
  MILESTONE_DATE_TYPE_OPTIONS,
  PROJECT_CREDIT_SUPPORT_TYPE,
  YEAR_FRAC_CONVENTION_TYPE_OPTIONS,
} from "../../constants";
import TextInput from "../text-input";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?:
    | IAddProjectCreditSupportFormErrors
    | IUpdateProjectCreditSupportFormErrors;
  setFormErrors: SetStateAction<
    | IAddProjectCreditSupportFormErrors
    | IUpdateProjectCreditSupportFormErrors
    | undefined
  >;
  form: IAddProjectCreditSupportForm | IUpdateProjectCreditSupportForm;
  setForm: SetStateAction<
    IAddProjectCreditSupportForm | IUpdateProjectCreditSupportForm
  >;
  onClose: () => void;
  onConfirm: (
    form: IAddProjectCreditSupportForm | IUpdateProjectCreditSupportForm,
  ) => Promise<IProjectCreditSupport | undefined>;
}

export default function ProjectCreditSupportFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "credit_support_method" && e.target.value === "LC") {
      setForm((prevState) => ({
        ...prevState,
        letter_of_credit_fee: "",
      }));
    }
    if (field === "start_date_type" && e.target.value === "SPD") {
      setForm((prevState) => ({
        ...prevState,
        credit_support_start_point: "",
      }));
    }
    if (field === "end_date_type" && e.target.value === "SPD") {
      setForm((prevState) => ({
        ...prevState,
        credit_support_end_point: "",
      }));
    }
    if (field === "start_date_type" && e.target.value === "MD") {
      setForm((prevState) => ({
        ...prevState,
        credit_support_start_date: null,
      }));
    }
    if (field === "end_date_type" && e.target.value === "MD") {
      setForm((prevState) => ({
        ...prevState,
        credit_support_end_date: null,
      }));
    }
    setForm((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    setForm({
      obligation_type: "",
      name: "",
      credit_support_method: "",
      input_type: "",
      credit_support_amount: "",
      start_date_type: "",
      credit_support_start_date: null,
      credit_support_start_point: "",
      end_date_type: "",
      credit_support_end_date: null,
      credit_support_end_point: "",
      letter_of_credit_fee: "",
      yearfrac_convention: "",
    });
    setFormErrors({});
    onClose();
  };

  const handleOnConfirm = async () => {
    const credit_support = await onConfirm(form);
    credit_support && handleOnClose();
  };

  const getCreditSupportAmountUnit = (): string => {
    switch (form.input_type) {
      case "DL":
        return PROJECT_CREDIT_SUPPORT_TYPE.DL;
      case "DLPAD":
        return PROJECT_CREDIT_SUPPORT_TYPE.DLPAD;
      default:
        return PROJECT_CREDIT_SUPPORT_TYPE.DL;
    }
  };

  const isAmountStartAdornment = ["DL", ""].includes(form.input_type);
  const isAmountEndAdornment = form.input_type === "DLPAD";

  return (
    <Modal
      open={open}
      maxWidth="xs"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
    >
      <>
        <SelectInput
          required
          label="Credit Support Type"
          selected={form.obligation_type}
          items={PROJECT_OBLICATION_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("obligation_type")}
          onChange={(e) => handleSelectInputChange(e, "obligation_type")}
          error={Boolean(formErrors?.obligation_type)}
          helperText={formErrors?.obligation_type}
          disabled={loading}
          fullWidth={false}
        />

        <TextInput
          label="Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
          fullWidth={false}
          required
        />

        <SelectInput
          required
          label="Input Type"
          selected={form.input_type}
          items={PROJECT_CREDIT_SUPPORT_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("input_type")}
          onChange={(e) => handleSelectInputChange(e, "input_type")}
          error={Boolean(formErrors?.input_type)}
          helperText={formErrors?.input_type}
          disabled={loading}
          fullWidth={false}
        />

        <CurrencyInput
          type="text"
          label="Amount"
          name="credit_support_amount"
          value={form.credit_support_amount.toString()}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.credit_support_amount)}
          helperText={formErrors?.credit_support_amount}
          disabled={loading}
          fullWidth={false}
          startAdornment={
            isAmountStartAdornment ? <>{getCreditSupportAmountUnit()}</> : <></>
          }
          endAdornment={
            isAmountEndAdornment ? <>{getCreditSupportAmountUnit()}</> : <></>
          }
        />

        <SelectInput
          required
          label="Start Date Type"
          selected={form.start_date_type}
          items={DATE_SELECTION_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("start_date_type")}
          onChange={(e) => handleSelectInputChange(e, "start_date_type")}
          error={Boolean(formErrors?.start_date_type)}
          helperText={formErrors?.start_date_type}
          disabled={loading}
          fullWidth={false}
          tooltip="<b>Milestone Date</b> - a named date related to key development and construction events.\n
                   <b>Specified Date</b> - Custom input date."
        />

        {form.start_date_type === "MD" && (
          <SelectInput
            required
            label="Start Point"
            selected={form.credit_support_start_point}
            items={MILESTONE_DATE_TYPE_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("credit_support_start_point")
            }
            onChange={(e) =>
              handleSelectInputChange(e, "credit_support_start_point")
            }
            error={Boolean(formErrors?.credit_support_start_point)}
            helperText={formErrors?.credit_support_start_point}
            disabled={loading}
            fullWidth={false}
          />
        )}
        {form.start_date_type === "SPD" && (
          <DatePicker
            label="Start Date"
            value={
              form.credit_support_start_date
                ? new Date(form.credit_support_start_date)
                : null
            }
            onChange={(v) => handleDateChange(v, "credit_support_start_date")}
            onOpen={() =>
              clearNonTextFieldErrorOnFocus("credit_support_start_date")
            }
            error={Boolean(formErrors?.credit_support_start_date)}
            helperText={formErrors?.credit_support_start_date}
            disabled={loading}
            fullWidth={false}
          />
        )}
        <SelectInput
          required
          label="End Date Type"
          selected={form.end_date_type}
          items={DATE_SELECTION_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("end_date_type")}
          onChange={(e) => handleSelectInputChange(e, "end_date_type")}
          error={Boolean(formErrors?.end_date_type)}
          helperText={formErrors?.end_date_type}
          disabled={loading}
          fullWidth={false}
          tooltip="
                  <b>Milestone Date</b> - a named date related to key development and construction events.\n
                  <b>Specified Date</b> - Custom input date.\n
                  <b>Note</b> - Keep in mind that the end date for credit support is to be aligned 
                  with the construction debt financial close date of a linked deal by the user"
        />

        {form.end_date_type === "MD" && (
          <SelectInput
            required
            label="End Point"
            selected={form.credit_support_end_point}
            items={MILESTONE_DATE_TYPE_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("credit_support_end_point")
            }
            onChange={(e) =>
              handleSelectInputChange(e, "credit_support_end_point")
            }
            error={Boolean(formErrors?.credit_support_end_point)}
            helperText={formErrors?.credit_support_end_point}
            disabled={loading}
            fullWidth={false}
          />
        )}

        {form.end_date_type === "SPD" && (
          <DatePicker
            label="End Date"
            value={
              form.credit_support_end_date
                ? new Date(form.credit_support_end_date)
                : null
            }
            onChange={(v) => handleDateChange(v, "credit_support_end_date")}
            onOpen={() =>
              clearNonTextFieldErrorOnFocus("credit_support_end_date")
            }
            error={Boolean(formErrors?.credit_support_end_date)}
            helperText={formErrors?.credit_support_end_date}
            disabled={loading}
            fullWidth={false}
          />
        )}
        <SelectInput
          required
          label="Security Type"
          selected={form.credit_support_method}
          items={PROJECT_CREDIT_SUPPORT_COST_METHOD_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("credit_support_method")}
          onChange={(e) => handleSelectInputChange(e, "credit_support_method")}
          error={Boolean(formErrors?.credit_support_method)}
          helperText={formErrors?.credit_support_method}
          disabled={loading}
          fullWidth={false}
          tooltip="<b>Cash Collateral</b> - Support funded by cash.\n
                   <b>Letter of Credit</b> - Support provided by a third party charging a fee.\n
                   <b>Parent Gaurantee</b> - Support provided by gaurantee from owner."
        />
        {form.credit_support_method === "LC" && (
          <TextInput
            type="number"
            label="Letter of Credit Fee"
            name="letter_of_credit_fee"
            value={form.letter_of_credit_fee.toString()}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            endAdornment={<>%</>}
            error={Boolean(formErrors?.letter_of_credit_fee)}
            helperText={formErrors?.letter_of_credit_fee}
            disabled={loading}
            fullWidth={false}
          />
        )}
        {form.credit_support_method === "LC" && (
          <SelectInput
            required
            label="Yearfrac Convention"
            selected={form.yearfrac_convention}
            items={YEAR_FRAC_CONVENTION_TYPE_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("yearfrac_convention")}
            onChange={(e) => handleSelectInputChange(e, "yearfrac_convention")}
            error={Boolean(formErrors?.yearfrac_convention)}
            helperText={formErrors?.yearfrac_convention}
            disabled={loading}
            fullWidth={false}
          />
        )}
      </>
    </Modal>
  );
}
