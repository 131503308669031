import {
  DEAL_API_ROUTES,
  DEAL_OTHER_FLOW_REPORT_BASIS,
  PERIODICITY,
} from "../../constants";
import { IDealSeparateAllocation, IReportTable } from "../../interfaces";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getDealSeparateAllocation = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.dealSeparateAllocation.get, {
    dealUuid,
  });
  return await AxiosHelper.get<IDealSeparateAllocation>(url);
};

export const updateDealSeparateAllocation = async (
  dealUuid: string,
  form: IDealSeparateAllocation,
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealSeparateAllocation.update, {
    dealUuid,
  });
  return await AxiosHelper.patch<
    IDealSeparateAllocation,
    IDealSeparateAllocation
  >(url, form);
};

export const getDealAllocationProfroma = async (
  dealUuid: string,
  periodicity: string,
  basis: string,
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealSeparateAllocation.report, {
    dealUuid,
    periodicity,
    basis,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadDealAllocationProforma = async (
  dealUuid: string,
  periodicity: PERIODICITY,
  basis: DEAL_OTHER_FLOW_REPORT_BASIS,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.dealSeparateAllocation.reportDownload,
    {
      dealUuid,
      periodicity,
      basis,
    },
  );

  return await AxiosHelper.downloadFile(url, {
    params: {
      periodicity,
      basis,
    },
  });
};
