import Badge from "@mui/material/Badge";

import HomeIcon from "../components/logos-and-icons/home-icon";
import ProjectsIcon from "../components/logos-and-icons/projects-icon";
import DealsIcon from "../components/logos-and-icons/deals-icon";
import CompareIcon from "../components/logos-and-icons/compare-icon";
import CozeIcon from "../components/logos-and-icons/coze-icon";
import ConfigurationIcon from "../components/logos-and-icons/configuration-icon";
import CollaborationIcon from "../components/logos-and-icons/collaboration-icon";
import { IConfirmDeleteModalProps } from "../components/confirm-delete-modal";
import {
  ISidebarLink,
  IProformaChartDetailConfig,
  TAppEnvironment,
} from "../interfaces";
import {
  cn,
  enumToOptions,
  getProformaChartDetailField,
  generateSidebarForPersoalAccount,
} from "../utils/helpers";
import SolversIcon from "../components/logos-and-icons/solvers-icon";

export enum US_STATES {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  DC = "District of Columbia",
  FL = "Florida",
  GA = "Georgia",
  GU = "Guam",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  PR = "Puerto Rico",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VI = "Virgin Islands",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export enum MW_IN_OPERATION {
  CSML = "0-100",
  SML = "100-500",
  MED = "500-1000",
  LAR = "1000-3000",
  VLAR = "3000+",
}

export enum MARKETS {
  CAISO = "CAISO",
  ERCOT = "ERCOT",
  PJM = "PJM",
  SPP = "SPP",
  MISO = "MISO",
  NW = "Northwest",
  NYISO = "NYISO",
  ISONE = "ISO-NE",
  SW = "Southwest",
  SE = "Southeast",
  NA = "N/A",
}

export const MARKETS_OPTIONS = enumToOptions(MARKETS);

export enum ORGANIZATION_TYPE {
  DEV = "Developer",
}

export const US_STATES_OPTIONS = enumToOptions(US_STATES);

const renderBetaBadge = (label: string) => (
  <Badge
    badgeContent={<span style={{ fontSize: "12px" }}>Beta</span>}
    color="warning"
    className={cn("mr-[5px]")}
  >
    <span style={{ paddingRight: "12.5px" }}>{label}&ensp;&ensp;</span>
  </Badge>
);

const PROJECT_SIDEBAR_SUB_PAGES: ISidebarLink[] = [
  {
    label: "General",
    path: "/project/:projectUuid/general",
  },
  { label: "Timing", path: "/project/:projectUuid/timing" },
  {
    label: "Cost",
    path: "/project/:projectUuid/cost",
    subLinks: [
      {
        label: "Dashboard",
        value: "cost-summary",
        path: "/project/:projectUuid/cost",
      },
      {
        label: "Project Costs",
        value: "cost-inputs",
        path: "/project/:projectUuid/cost-inputs",
      },
      {
        label: "Credit Support",
        value: "cost-credit-support",
        path: "/project/:projectUuid/cost-credit-support",
      },
    ],
  },
  {
    label: "Pro Forma",
    path: "/project/:projectUuid/pro-forma",
    pathToMatch: "/project/:projectUuid/pro-forma",
    subLinks: [
      {
        label: "Dashboard",
        value: "pro-forma",
        path: "/project/:projectUuid/pro-forma",
      },
      {
        label: "Production",
        value: "production",
        path: "/project/:projectUuid/pro-forma/production",
      },
      {
        label: "Revenues",
        value: "revenue",
        path: "/project/:projectUuid/pro-forma/revenue",
      },
      {
        label: "Expenses",
        value: "expenses",
        path: "/project/:projectUuid/pro-forma/expenses",
      },
      {
        label: "Other",
        value: "other-flows",
        path: "/project/:projectUuid/pro-forma/other-flows",
        pathToMatch: "/project/:projectUuid/pro-forma/other-flows",
      },
    ],
  },
  {
    label: "Tax",
    path: "/project/:projectUuid/tax/dashboard",
    pathToMatch: "/project/:projectUuid/tax",
    subLinks: [
      {
        label: "Dashboard",
        value: "dashboard",
        path: "/project/:projectUuid/tax/dashboard",
      },
      {
        label: "Depreciation",
        value: "depreciation",
        path: "/project/:projectUuid/tax/depreciation",
      },
      {
        label: "Tax Credits",
        value: "tax-credit",
        path: "/project/:projectUuid/tax/tax-credit",
      },
    ],
  },

  {
    label: "FMV",
    path: "/project/:projectUuid/fmv/dashboard",
    pathToMatch: "/project/:projectUuid/fmv",
    subLinks: [
      {
        label: "Dashboard",
        value: "dashboard",
        path: "/project/:projectUuid/fmv/dashboard",
      },
      {
        label: "FMV Step Up",
        value: "step-up",
        path: "/project/:projectUuid/fmv/step-up",
      },
      {
        label: "Income Approach",
        value: "income-approach",
        path: "/project/:projectUuid/fmv/income-approach",
        disable: {
          AND: {
            project_valuation_exist: [false],
          },
        },
      },
    ],
  },
];

const DEAL_SIDEBAR_SUB_PAGES: ISidebarLink[] = [
  {
    label: "General",
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/general",
  },
  {
    label: "Timing",
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/timing",
  },
  {
    label: "Components",
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/cost/summary",
    pathToMatch: "/deal/:dealUuid/case-deal/:caseDealUuid/component",
    subLinks: [
      {
        label: "Cost",
        value: "cost",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/cost/summary",
      },
      {
        label: "Pro Forma",
        value: "pro-forma",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/pro-forma/summary",
      },
      {
        label: "Tax",
        value: "tax",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/tax/summary",
      },
      {
        label: "FMV",
        value: "fmv",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/fmv",
      },
    ],
  },
  {
    label: "Sizing",
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/tax-equity",
    pathToMatch: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing",
    subLinks: [
      {
        label: "Tax Equity",
        value: "tax-equity",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/tax-equity",
        visibility: {
          OR: {
            structure: ["TEP", "IL"],
          },
        },
      },
      {
        label: "Cash Equity",
        value: "cash-equity",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/cash-equity",
        visibility: {
          OR: {
            structure: ["CEP"],
          },
        },
      },
      {
        label: "Sponsor Equity",
        value: "sponsor-equity",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/sponsor-equity",
        visibility: {
          OR: {
            structure: ["TEP", "CEP", "EO"],
          },
        },
      },
      {
        label: "Term Debt",
        value: "term-debt",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/term-debt",
        visibility: {
          OR: {
            structure: ["TEP", "CEP", "EO"],
          },
        },
      },
      {
        label: "Construction Debt",
        value: "construction-debt",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/construction-debt",
        visibility: {
          OR: {
            structure: ["TEP", "CEP", "EO"],
          },
        },
      },
      {
        label: "Transfer",
        value: "transfer",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/transfer",
        visibility: {
          OR: {
            tax_credit_structure: ["TR"],
          },
        },
      },
      {
        label: "Direct Pay",
        value: "direct-pay",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/direct-pay",
        visibility: {
          OR: {
            tax_credit_structure: ["DP"],
          },
        },
      },
      {
        label: "Lessor",
        value: "sale-leaseback",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/sale-leaseback",
        visibility: {
          OR: {
            structure: ["SL"],
          },
        },
      },
      {
        label: "Fees",
        value: "deal-fee",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/deal-fee",
      },
      {
        label: "Other",
        value: "other",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other",
      },
    ],
  },
  {
    label: "Output",
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership/summary",
    pathToMatch: "/deal/:dealUuid/case-deal/:caseDealUuid/output",
    subLinks: [
      {
        label: "Dashboard",
        value: "portfolio",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/portfolio",
      },
      {
        label: "Partnership",
        value: "partnership",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership",
        visibility: {
          OR: {
            structure: ["TEP", "CEP"],
          },
        },
      },
      {
        label: "Single Owner",
        value: "partnership",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership",
        visibility: {
          OR: {
            structure: ["EO", "IL"],
          },
        },
      },
      {
        label: "Tax Equity",
        value: "tax-equity",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity",
        visibility: {
          OR: {
            structure: ["TEP"],
          },
        },
      },
      {
        label: "Cash Equity",
        value: "tax-equity",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity",
        visibility: {
          OR: {
            structure: ["CEP"],
          },
        },
      },
      {
        label: "Sponsor Equity",
        value: "sponsor-equity",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity",
        visibility: {
          OR: {
            structure: ["TEP", "CEP", "EO"],
          },
        },
      },
      {
        label: "Sponsor Equity (BOL)",
        value: "sponsor-equity-bol",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity-bol",
        visibility: {
          OR: {
            structure: ["TEP", "CEP", "EO"],
          },
        },
      },
      {
        label: "Developer",
        value: "developer",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/developer",
      },
      {
        label: "Construction Debt",
        value: "construction-debt",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt",
        visibility: {
          OR: {
            is_construction_debt_added: [true],
          },
        },
      },
      {
        label: "Term Debt",
        value: "term-debt",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/term-debt",
        visibility: {
          OR: {
            debt: ["1"],
          },
        },
      },
      {
        label: "Transfer",
        value: "transfer",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/transfer",
        visibility: {
          OR: {
            tax_credit_structure: ["TR"],
          },
        },
      },
      {
        label: "Direct Pay",
        value: "transfer",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/transfer",
        visibility: {
          OR: {
            tax_credit_structure: ["DP"],
          },
        },
      },
      // TODO: re-enable for SLB when "real" ?
      //{
      //  label: "Portfolio",
      //  value: "lease",
      //  path: "/deal/:dealId/case-deal/:caseDealUuid/output/lease",
      //  visibility: {
      //    OR: {
      //      structure: ["SL"],
      //    },
      //  },
      //},
      {
        label: "Lessor",
        value: "lessor",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessor",
        visibility: {
          OR: {
            structure: ["SL"],
          },
        },
      },
      {
        label: "Lessee",
        value: "lessee",
        path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessee",
        visibility: {
          OR: {
            structure: ["SL"],
          },
        },
      },
    ],
  },
  {
    label: "divider", // This is used as a divider in UI
    path: "--",
  },
  {
    label: "Cases",
    path: "/deal/:dealUuid/cases",
    pathToMatch: "/deal/:dealUuid/case",
    visibility: {
      OR: {
        structure: ["TEP", "CEP", "EO", "IL"], // disable for SL
      },
    },
  },
  {
    label: "Analysis",
    path: "/deal/:dealUuid/analysis",
    visibility: {
      OR: {
        structure: ["TEP", "CEP", "EO", "IL"], // disable for SL
      },
    },
  },
];

const environment = process.env.REACT_APP_NODE_ENV as TAppEnvironment;

export const PRIMARY_SIDEBAR_NAVIGATION: ISidebarLink[] = [
  { label: "Home", path: "/", icon: HomeIcon },
  {
    label: "Projects",
    path: "/project-list",
    value: "projects",
    icon: ProjectsIcon,
    subLinks: [...PROJECT_SIDEBAR_SUB_PAGES],
  },
  {
    label: "Deals",
    path: "/deal-list",
    value: "deals",
    icon: DealsIcon,
    subLinks: [...DEAL_SIDEBAR_SUB_PAGES],
  },
  {
    label: renderBetaBadge("Solvers"),
    path: "/solvers",
    icon: SolversIcon,
    subLinks: [
      {
        label: "Solvers Details",
        path: "/solvers/:uuid",
        pathToMatch: "/solvers/",
      },
    ],
  },
  {
    label: "Compare",
    path: "/analysis/deal-comparison-list",
    pathToMatch: "/analysis/deal-comparison",
    icon: CompareIcon,
  },
  { label: "CoZe", path: "/coze", icon: CozeIcon },
  {
    label: "Configuration",
    path: "/configuration/data/curves",
    pathToMatch: "/configuration/",
    icon: ConfigurationIcon,
    subLinks: [
      {
        label: "Organization Data",
        value: "data",
        path: "/configuration/data/curves",
        pathToMatch: "/configuration/data",
      },
      {
        label: "Account Settings",
        value: "organization",
        path: "/configuration/organization/profile",
        pathToMatch: "/configuration/organization",
      },
    ],
  },
  {
    label: "divider", // This is used as a divider in UI
    path: "--",
  },
  {
    label: renderBetaBadge("Collaboration"),
    path: "/collaboration",
    icon: CollaborationIcon,
    value: "collaboration",
    subLinks: [
      {
        label: "General",
        path: "/collaboration/:collaborationUuid/general",
        pathToMatch: "/general",
      },
      {
        label: "Cases",
        path: "/collaboration/:collaborationUuid/cases",
        pathToMatch: "/cases",
      },
      {
        label: "Access",
        path: "/collaboration/:collaborationUuid/access",
        pathToMatch: "/access",
      },
    ],
  },
];

export const PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION: ISidebarLink[] = [
  {
    label: "Collaboration",
    path: "/personal/shared-deals",
    icon: CollaborationIcon,
    subLinks: [
      {
        label: "General",
        path: "/personal/shared-deal/:collaborationUuid/general",
        pathToMatch: "/general",
      },
      {
        label: "Cases",
        path: "/personal/shared-deal/:collaborationUuid/cases",
        pathToMatch: "/cases",
      },
      {
        label: "Analysis",
        path: "/personal/shared-deal/:collaborationUuid/analysis",
        pathToMatch: "/analysis",
      },
    ],
  },
  {
    label: "Case",
    path: "/personal/deal/:dealUuid/general",
    icon: DealsIcon,
    subLinks: generateSidebarForPersoalAccount(DEAL_SIDEBAR_SUB_PAGES, "deal"),
  },
  {
    label: "Project",
    path: "/personal/project/:projectUuid/general",
    icon: ProjectsIcon,
    subLinks: generateSidebarForPersoalAccount(
      PROJECT_SIDEBAR_SUB_PAGES,
      "project",
    ),
  },
];

export const CONFIRM_DELETE_MODAL_DEFAULT_PROPS: IConfirmDeleteModalProps = {
  open: false,
  title: "",
  loading: false,
  description: "",
  onClose: () => undefined,
  onConfirm: () => Promise.resolve(),
};

export enum MONTHS {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export const MONTH_OPTIONS = Object.keys(MONTHS).map((key) => {
  const monthNumber = Object.keys(MONTHS).indexOf(key) + 1;
  return {
    label: key,
    value: monthNumber.toString(),
  };
});

export enum DASHBOARD_MARKETS {
  CAISO = "CAISO",
  ERCOT = "ERCOT",
  PJM = "PJM",
  SPP = "SPP",
  MISO = "MISO",
  NW = "Northwest",
  NYISO = "NYISO",
  "ISO-NE" = "ISO-NE",
  SW = "Southwest",
  SE = "Southeast",
  NA = "N/A",
}

// The list of backend object types that are there, used mainly for activity logs.
export type OBJECT_TYPE =
  | "deal"
  | "project"
  | "projectproduction"
  | "dealtiming"
  | "dealtermdebt"
  | "dealfee"
  | "projectmembership"
  | "transfersizing"
  | "slbsizing"
  | "partnershipinvestorconfiguration"
  | "dealothersizing"
  | "portfolio"
  | "projectmerchantrevenue"
  | "projectexpense"
  | "project"
  | "storageprojectproduction"
  | "projectrevenuecontractotherrevenue"
  | "projecttaxcredit"
  | "projectrevenuecontract"
  | "projectdepreciation"
  | "projecttiming"
  | "projectcost"
  | "projectdepreciationstream"
  | "projectproduction"
  | "projectcreditsupport"
  | "projectvaluationconfiguration"
  | "sponsorequityconfiguration"
  | "dealconstructiondebtgeneralsizing"
  | "dealconstructiondebt"
  | "dealinvestorbridgedebt"
  | "dealtransferbridgedebt"
  | "projectcostitem"
  | "dealtransferconfig";

export type ObjectTypeMap = {
  [key in OBJECT_TYPE]: { name: string };
};

export const objectTypeMap: ObjectTypeMap = {
  deal: { name: "Deal" },
  project: { name: "Project" },
  dealtermdebt: { name: "Term Debt" },
  projectproduction: { name: "Project Production" },
  dealconstructiondebtgeneralsizing: {
    name: "Construction Debt General Sizing",
  },
  dealconstructiondebt: { name: "Construction Loan" },
  dealinvestorbridgedebt: { name: "Investor Bridge Loan" },
  dealtransferbridgedebt: { name: "Transfer Bridge Loan" },
  dealtiming: { name: "Deal Timing" },
  dealfee: { name: "Deal Fee" },
  projectmembership: { name: "Project Membership" },
  transfersizing: { name: "Transfer Sizing" },
  slbsizing: { name: "SLB Sizing" },
  partnershipinvestorconfiguration: {
    name: "Partnership Investor Configuration",
  },
  dealothersizing: { name: "Other Sizing" },
  portfolio: { name: "Portfolio" },
  projectmerchantrevenue: { name: "Merchant Revenue" },
  projectexpense: { name: "Project Expense" },
  storageprojectproduction: { name: "Storage Project Production" },
  projectrevenuecontractotherrevenue: {
    name: "Revenue Contract Other Revenue",
  },
  projecttaxcredit: { name: "Project Tax Credit" },
  projectrevenuecontract: { name: "Revenue Contract" },
  projectdepreciation: { name: "Project Depreciation" },
  projecttiming: { name: "Project Timing" },
  projectcost: { name: "Cost" },
  projectdepreciationstream: { name: "Project Depreciation Stream" },
  projectvaluationconfiguration: { name: "Project Valuation Configuration" },
  sponsorequityconfiguration: { name: "Sponsor Equity Configuration" },
  projectcreditsupport: { name: "Project Credit Support" },
  projectcostitem: { name: "Project Cost" },
  dealtransferconfig: { name: "Transfer Configuration" },
};

export const PROFORMA_CHART_DETAIL_CONFIG: IProformaChartDetailConfig = {
  revenue_contract_type_hedge: getProformaChartDetailField(
    "revenue_contract_type_hedge",
    "Hedge",
    80,
    "right",
    true,
    "#8064A2",
  ),
  revenue_contract_type_capacity: getProformaChartDetailField(
    "revenue_contract_type_capacity",
    "Capacity",
    80,
    "right",
    true,
    "#4AACC6",
  ),
  revenue_merchant_type_energy: getProformaChartDetailField(
    "revenue_merchant_type_energy",
    "Merchant Energy",
    80,
    "right",
    true,
    "#2C4D75",
  ),
  revenue_merchant_type_rec: getProformaChartDetailField(
    "revenue_merchant_type_rec",
    "Merchant REC",
    80,
    "right",
    true,
    "#772C2A",
  ),
  revenue_merchant_type_capacity: getProformaChartDetailField(
    "revenue_merchant_type_capacity",
    "Merchant Capacity",
    80,
    "right",
    true,
    "#5E7430",
  ),
  expense_type_oandm: getProformaChartDetailField(
    "expense_type_oandm",
    "O&M (Scheduled)",
    80,
    "right",
    true,
    "#B65607",
  ),
  expense_type_oandm_un: getProformaChartDetailField(
    "expense_type_oandm_un",
    "O&M (Unscheduled)",
    80,
    "right",
    true,
    "#719ACA",
  ),
  expense_type_assmgmt: getProformaChartDetailField(
    "expense_type_assmgmt",
    "Asset Management",
    80,
    "right",
    true,
    "#CD7271",
  ),
  expense_type_admin: getProformaChartDetailField(
    "expense_type_admin",
    "Administrative",
    80,
    "right",
    true,
    "#AFC97A",
  ),
  expense_type_ins: getProformaChartDetailField(
    "expense_type_ins",
    "Insurance",
    80,
    "right",
    true,
    "#9883B5",
  ),
  expense_type_ins_un: getProformaChartDetailField(
    "expense_type_ins_un",
    "Insurance (Unscheduled)",
    80,
    "right",
    true,
    "#9f3c38",
  ),

  expense_type_land: getProformaChartDetailField(
    "expense_type_land",
    "Land Lease",
    80,
    "right",
    true,
    "#6EBDD0",
  ),
  expense_type_proptx: getProformaChartDetailField(
    "expense_type_proptx",
    "Property Tax",
    80,
    "right",
    true,
    "#F9AA6B",
  ),
  expense_type_majmain: getProformaChartDetailField(
    "expense_type_majmain",
    "Major Maintenance",
    80,
    "right",
    true,
    "#3A679C",
  ),
  expense_type_loc: getProformaChartDetailField(
    "expense_type_loc",
    "Letter of Credit",
    80,
    "right",
    true,
    "#9E3B38",
  ),
  expense_type_taxes: getProformaChartDetailField(
    "expense_type_taxes",
    "Taxes",
    80,
    "right",
    true,
    "#7E9D40",
  ),
  expense_type_other: getProformaChartDetailField(
    "expense_type_other",
    "Other Operations",
    80,
    "right",
    true,
    "#654F83",
  ),
  expense_type_other_no: getProformaChartDetailField(
    "expense_type_other_no",
    "Other Non-Operational",
    80,
    "right",
    true,
    "#368EA6",
  ),
  expense_type_cont: getProformaChartDetailField(
    "expense_type_cont",
    "Contingency",
    80,
    "right",
    true,
    "#8B4513",
  ),
  gross_production: getProformaChartDetailField(
    "gross_production",
    "Gross Production",
    80,
    "right",
    true,
    "#4E81BD",
  ),
  production_losses: getProformaChartDetailField(
    "production_lossees",
    "Production Losses",
    80,
    "right",
    true,
    "#BF504D",
  ),
  net_production: getProformaChartDetailField(
    "net_production",
    "Net Production",
    80,
    "right",
    true,
    "#9ABB59",
  ),
  gross_capacity: getProformaChartDetailField(
    "gross_capacity",
    "Gross Capacity",
    80,
    "right",
    false,
    "#4E81BD",
  ),
  net_capacity: getProformaChartDetailField(
    "net_capacity",
    "Net Capacity",
    80,
    "right",
    false,
    "#4E81BD",
  ),
  date_formatted: getProformaChartDetailField(
    "date_formatted",
    "Date",
    120,
    "left",
    true,
  ),
  revenue_contract_type_ppa: getProformaChartDetailField(
    "revenue_contract_type_ppa",
    "PPA",
    80,
    "right",
    true,
    "#6A5ACD",
  ),
  revenue_contract_type_vppa: getProformaChartDetailField(
    "revenue_contract_type_vppa",
    "VPPA",
    80,
    "right",
    true,
    "#8064a2",
  ),
  revenue_contract_type_rec: getProformaChartDetailField(
    "revenue_contract_type_rec",
    "REC",
    80,
    "right",
    true,
    "#FF4500",
  ),
  revenue_contract_type_other: getProformaChartDetailField(
    "revenue_contract_type_other",
    "Other",
    80,
    "right",
    true,
    "#772c2a",
  ),
  revenue_contract_total: getProformaChartDetailField(
    "revenue_contract_total",
    "Contracted Revenue",
    80,
    "right",
    true,
    "#5f7531",
  ),

  revenue_merchant_type_other: getProformaChartDetailField(
    "revenue_merchant_type_other",
    "Merchant Other",
    80,
    "right",
    true,
    "#8B4513",
  ),
  revenue_merchant_total: getProformaChartDetailField(
    "revenue_merchant_total",
    "Merchant Revenue",
    80,
    "right",
    true,
    "#cd7372",
  ),
  revenue_total: getProformaChartDetailField(
    "revenue_total",
    "Total Revenue",
    80,
    "right",
    true,
    "#afc97a",
  ),
  expenses: getProformaChartDetailField(
    "expenses",
    "Total Expenses",
    80,
    "right",
    true,
  ),
  other_flow_cash_total: getProformaChartDetailField(
    "other_flow_cash_total",
    "Total Other Flow Cash",
    80,
    "right",
    true,
    "#aaaaaa",
  ),
  other_flow_tax_total: getProformaChartDetailField(
    "other_flow_tax_total",
    "Total Other Flow Tax",
    80,
    "right",
    true,
    "#aaaaaa",
  ),
};

export enum PROFORMA_BASIS {
  cash = "Cash Basis",
  accrual = "Accrual",
}

export const PROFORMA_BASIS_OPTIONS = enumToOptions(PROFORMA_BASIS);

export enum PERIODICITY {
  MO = "Monthly",
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export const PERIODICITY_OPTIONS = enumToOptions(PERIODICITY);

export const DATE_FORMAT_REGEX =
  /^((0?[1-9])|(1[0-2]))\/((0?[1-9])|([1-2][0-9])|(3[0-1]))\/\d{4}$/;

export const FORM_TYPES = Object.freeze({
  CREATE: "create",
  UPDATE: "update",
});
