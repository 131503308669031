import Layout from "../../../components/layout";
import DealComparisonDetailView from "../../../views/analysis/deal-comparison-detail";
import CollaborationPageLayout from "../../../components/collaboration-page-layout";
import { useAppSelector } from "../../../utils/hooks";
import { getDeals } from "../../../apis/deal/base";
import {
  deleteDealComparison,
  getDealAnalysisInputs,
  getDealAnalysisOutputs,
  getDealComparison,
  updateDealComparison,
} from "../../../apis/deal-comparison/base";

export default function SharedDealAnalysisPage(): JSX.Element | null {
  const { currentCollaboration } = useAppSelector((s) => s.collaboration);

  return (
    <Layout title="Shared Deal Analysis">
      <CollaborationPageLayout>
        <DealComparisonDetailView
          getDeals={getDeals}
          getDealComparison={getDealComparison}
          updateDealComparison={updateDealComparison}
          deleteDealComparison={deleteDealComparison}
          getDealAnalysisInputs={getDealAnalysisInputs}
          getDealAnalysisOutputs={getDealAnalysisOutputs}
          comparisonId={currentCollaboration?.deal_comparison_uuid}
          inPersonalAccount
          type="deal"
        />
      </CollaborationPageLayout>
    </Layout>
  );
}
