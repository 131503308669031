import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Tabs from "../tabs";
import { ITab } from "../../interfaces";

interface IProps {
  children: JSX.Element;
}

export default function ProjectRevenueLayout({
  children,
}: IProps): JSX.Element {
  const navigate = useNavigate();

  const { projectUuid } = useParams();
  const { pathname } = useLocation();

  const onTabChange = (tab: ITab) => {
    if (tab.path && projectUuid) {
      const updatedPath = tab.path.replace(":projectUuid", projectUuid);
      navigate(updatedPath);
    }
  };

  const selected = React.useMemo(() => {
    if (pathname.includes("merchant")) {
      return "merchant";
    }
    return "revenue";
  }, []);

  return (
    <>
      <Tabs
        tabs={[
          {
            label: "Revenue Contract",
            value: "revenue",
            path: "/project/:projectUuid/pro-forma/revenue",
          },
          {
            label: "Merchant Revenue",
            value: "merchant",
            path: "/project/:projectUuid/pro-forma/revenue/merchant",
          },
        ]}
        onClick={onTabChange}
        selectedTab={selected}
      />
      {children}
    </>
  );
}
