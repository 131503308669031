import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import { IDealTaxEquity, IDealTaxEquityForm } from "../../interfaces";

export const getDealTaxEquity = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.taxEquity.get, { dealUuid });
  return await AxiosHelper.get<IDealTaxEquity[]>(url);
};

export const addDealTaxEquity = async (
  dealUuid: string,
  form: IDealTaxEquityForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.taxEquity.create, { dealUuid });
  return await AxiosHelper.post<IDealTaxEquityForm, IDealTaxEquity>(url, form);
};

export const updateDealTaxEquity = async (
  dealUuid: string,
  taxEquityId: number,
  form: IDealTaxEquityForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.taxEquity.update, {
    dealUuid,
    taxEquityId,
  });
  return await AxiosHelper.patch<IDealTaxEquityForm, IDealTaxEquity>(url, form);
};
