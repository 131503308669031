import React from "react";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import { COUNTERPARTY_TYPE_OPTIONS } from "../../constants";
import {
  SetStateAction,
  ICounterparty,
  ICounterpartyForm,
  ICounterpartyFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  loading: boolean;
  headerLabel: string;
  form: ICounterpartyForm;
  setForm: SetStateAction<ICounterpartyForm>;
  formErrors?: ICounterpartyFormErrors;
  setFormErrors: SetStateAction<ICounterpartyFormErrors | undefined>;
  onConfirm: (form: ICounterpartyForm) => Promise<ICounterparty | undefined>;
  onClose: () => void;
}

export default function CounterpartyFormModal({
  open,
  loading,
  headerLabel,
  form,
  setForm,
  formErrors,
  setFormErrors,
  onConfirm,
  onClose,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnConfirm = async () => {
    const counterparty = await onConfirm(form);
    if (counterparty) {
      onClose();
      handleResetForm();
    }
  };

  const handleResetForm = () => {
    setForm({ name: "", type: "" });
    setFormErrors({});
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <>
        <TextInput
          type="text"
          label="Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
        />
        <SelectInput
          required
          label="Role"
          selected={form.type}
          items={COUNTERPARTY_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("type")}
          onChange={(e) => handleSelectInputChange(e, "type")}
          error={Boolean(formErrors?.type)}
          helperText={formErrors?.type}
          disabled={loading}
        />
        <Alert severity="info">
          <Typography>Information</Typography>
          You can add user after "Add Counterparty"
        </Alert>
      </>
    </Modal>
  );
}
