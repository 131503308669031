import {
  ITableColumn,
  ITaxEquitySummaryItem,
  IDeveloperReportSummary,
  SponsorEquityBOL_ReportSummaryMetricsForView,
  IConstructionDebtSummary,
} from "../interfaces";
import { enumToOptions } from "../utils/helpers";
import { CARRYFORWARD_CREDITS } from "./deal";
import { CONSTRUCTION_DEBT_BASE_RATE_TYPE } from "./deal/construction-debt";

export const IRR_PARTNERSHIP_SUMMARY_COLUMN: ITableColumn[] = [
  { id: "item", label: "Item", minWidth: 100, align: "left" },
  { id: "total", label: "Result", minWidth: 100, align: "right" },
];

export const PORTFOLIO_SUMMARY_TABLE_COLUMNS: ITableColumn[] = [
  { id: "item", label: "Item", minWidth: 100, align: "left" },
  { id: "partnership", label: "Partnership", minWidth: 100, align: "right" },
  { id: "tax_equity", label: "Tax Equity", minWidth: 100, align: "right" },
  {
    id: "sponsor_equity",
    label: "Sponsor Equity",
    minWidth: 100,
    align: "right",
  },
  // {
  //   id: "debt",
  //   label: "Debt",
  //   minWidth: 100,
  //   align: "right",
  // },
];

export const ITEMS_AND_TOTAL_TABLE_COLUMN: ITableColumn[] = [
  { id: "items", label: "Item", minWidth: 100, align: "left" },
  { id: "totals", label: "Result", minWidth: 100, align: "right" },
];

export const CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_COLUMNS: ITableColumn[] = [
  { id: "loan_type", label: "Loan Type", minWidth: 100, align: "left" },
  {
    id: "construction_loan",
    label: "Construction Loan",
    minWidth: 100,
    align: "left",
  },
  {
    id: "tax_equity_bridge_loan",
    label: "Investor Bridge Loan",
    minWidth: 100,
    align: "left",
  },
  // {
  //   id: "transfer_bridge_loan",
  //   label: "Transfer Bridge Loan",
  //   minWidth: 100,
  //   align: "left",
  // },
];

export const CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_ITEMS: ITableColumn<
  | keyof IConstructionDebtSummary["data"]["construction_loan"]
  | keyof IConstructionDebtSummary["data"]["tax_equity_bridge_loan"]
  | "admin_agent_fee"
>[] = [
  {
    id: "advance_rate",
    label: "Advance Rate",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "base_rate_type",
    label: "Base Rate Type",
    minWidth: 100,
    align: "left",
    enum: CONSTRUCTION_DEBT_BASE_RATE_TYPE,
  },
  {
    id: "base_rate",
    label: "Base Rate",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "spread",
    label: "Spread",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  { id: "hedge", label: "Hedge %", minWidth: 100, align: "left", suffix: "%" },
  {
    id: "swap_rate",
    label: "Swap Rate",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "swap_credit_spread",
    label: "Swap Credit Spread",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "commitment_fee",
    label: "Commitment Fee",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "upfront_fee",
    label: "Upfront Fee",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
];

export enum OUTPUT_REPORT_TERM {
  default = "Full-Term",
  buyout = "Buyout",
  taxflip = "Flip-Term",
  ebo = "EBO",
}

export enum OUTPUT_REPORT_LEVERAGE {
  levered = "Levered",
  unlevered = "Unlevered",
}

export const PARTNERSHIP_COMBINED_TABLE_ROWS = [
  "deal_fmv",
  "deal_fees",
  "capital_contributions_draws",
  "cash_distributions",
  "transfer_proceeds",
  "pre_tax_cash",
  "tax_credits",
  "pre_tax_cash_with_tax_credits",
  "depreciation_benefits",
  "tax_detriments",
  "after_tax_cash",
];

const COMMON_SUMMARY_REPORT_ITEMS = {
  cash_distributions: {
    name: "Cash Distributions",
    suffix: "",
    prefix: "(+) ",
  },
  cash_paid_to_investor: {
    name: "Cash Paid to Investor",
    suffix: "",
    prefix: "",
  },
  cash_paid_to_sponsor: {
    name: "Cash Paid to Sponsor",
    suffix: "",
    prefix: "",
  },
  tax_credits: { name: "Tax Credits", suffix: "", prefix: "(+) " },
  cash_taxes: { name: "Cash Taxes", suffix: "", prefix: "(-) " },
  depreciation: { name: "Depreciation", suffix: "", prefix: "" },
  depreciation_benefits: {
    name: "Depreciation Benefits",
    suffix: "",
    prefix: "(+) ",
  },
  tax_detriments: { name: "Tax Detriments", suffix: "", prefix: "(-) " },
  pre_tax_cash: { name: "Pre-Tax Cash", suffix: "", prefix: "" },
  pre_tax_cash_with_tax_credits: {
    name: "Pre-Tax Cash (with Credits)",
    suffix: "",
    prefix: "",
  },
  after_tax_cash: { name: "After-Tax Cash", suffix: "", prefix: "" },
  tax_efficient_value: { name: "Total Value", suffix: "", prefix: "" },
  draws: { name: "Draws", suffix: "", prefix: "" },
  principal: { name: "Principal Repayments", suffix: "", prefix: "" },
  interest: { name: "Interest Payments", suffix: "", prefix: "" },
  debt_amount: { name: "Debt Amount", suffix: "", prefix: "" },
  debt_service_reserve: {
    name: "Debt Service Reserve",
    suffix: "",
    prefix: "",
  },
  debt_term: { name: "Amortization Tenor", suffix: " Yrs", prefix: "" },
  min_debt_service_coverage_ratio: {
    name: "Min Debt Service Coverage Ratio",
    suffix: "x",
    prefix: "",
  },
  avg_debt_service_coverage_ratio: {
    name: "Avg Debt Service Coverage Ratio",
    suffix: "x",
    prefix: "",
  },

  fmv_step_up: { name: "FMV Step Up", suffix: "", prefix: "(+) " },
  debt_proceeds: { name: "Term Debt Proceeds", suffix: "", prefix: "(+) " },
  debt_service: { name: "Term Debt Service", suffix: "", prefix: "(-) " },
  transfer_proceeds: { name: "Transfer Cash", suffix: "", prefix: "(+) " },
  direct_pay_proceeds: {
    name: "Direct Pay Cash",
    suffix: "",
    prefix: "(+) ",
  },
  equity: { name: "Net Equity", suffix: "", prefix: "" },
  investment_amount: { name: "Investment Amount", suffix: "", prefix: "" },
};

export const SUMMARY_REPORT_ITEMS = {
  deal_fmv: { name: "Share of Deal FMV", suffix: "", prefix: "(-) " },
  deal_fees: { name: "Share of Deal Fees", suffix: "", prefix: "(-) " },
  capital_contributions: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "(-) ",
  },
  capital_contributions_draws: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "(-) ",
  },
  ...COMMON_SUMMARY_REPORT_ITEMS,
};

export const TRANSFER_SUMMARY_REPORT_ITEMS = {
  payment_total: {
    name: "Payment Total",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
  payment_rate: {
    name: "Payment Rate",
    suffix: "",
    is_dollar: false,
    toFixed: 4,
  },
  discount_rate: {
    name: "Discount Rate",
    suffix: "%",
    is_dollar: false,
    toFixed: 2,
  },
  roi: { name: "ROI", suffix: "%", is_dollar: false, toFixed: 2 },
  npv_payment_total: {
    name: "NPV of Payments",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
  npv_tax_credits: {
    name: "NPV of Credits",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
  adjusted_payment_rate: {
    name: "Adjusted Payment Rate",
    suffix: "",
    is_dollar: false,
    toFixed: 4,
  },
  adjusted_payment_total: {
    name: "Adjusted Payment Total",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
};

export const TRANSFER_SUMMARY_REPORT_NPV_ITEMS = {
  purchase: { name: "Purchase", suffix: "" },
  cash_distributions: { name: "Cash Distributions", suffix: "" },
  tax_credits: { name: "Tax Credits", suffix: "" },
  depreciation_benefits: { name: "Depreciation Benefits", suffix: "" },
  tax_detriments: { name: "Tax Detriments", suffix: "" },
  tax_efficient_value: { name: "Total Value", suffix: "" },
};

export const TAX_EQUITY_SUMMARY_IRR_ITEMS: Record<
  string,
  ITaxEquitySummaryItem
> = {
  investment_amount: { name: "Investment Amount", suffix: "", isDollar: true },
  after_tax_irr_flip_term: {
    name: "After-Tax IRR (Flip-Term)",
    suffix: "%",
    isDollar: false,
  },
  after_tax_irr: { name: "After-Tax IRR", suffix: "%", isDollar: false },
  after_tax_irr_buyout_term: {
    name: "After-Tax IRR (Buyout)",
    suffix: "%",
    isDollar: false,
    visibleInCoZe: true,
  },

  after_tax_moic: { name: "After-Tax MOIC", suffix: "x", isDollar: false },
  after_tax_moic_buyout_term: {
    name: "After-Tax MOIC (Buyout)",
    suffix: "x",
    isDollar: false,
    visibleInCoZe: true,
  },

  roi: { name: "ROI", suffix: "%", isDollar: false },
  roi_buyout_term: {
    name: "ROI (Buyout)",
    suffix: "%",
    isDollar: false,
    visibleInCoZe: true,
  },
  pre_tax_irr_flip_term: {
    name: "Pre-Tax IRR (Flip-Term)",
    suffix: "%",
    isDollar: false,
  },
  pre_tax_irr: { name: "Pre-Tax IRR", suffix: "%", isDollar: false },
  pre_tax_irr_buyout_term: {
    name: "Pre-Tax IRR (Buyout)",
    suffix: "%",
    isDollar: false,
  },

  pre_tax_moic: { name: "Pre-Tax MOIC", suffix: "x", isDollar: false },

  pre_tax_moic_buyout_term: {
    name: "Pre-Tax MOIC (Buyout)",
    suffix: "x",
    isDollar: false,
    visibleInCoZe: true,
  },

  pre_tax_irr_with_tax_credit: {
    name: "Pre-Tax IRR (with Credits)",
    suffix: "%",
    isDollar: false,
  },
  pre_tax_irr_floor: {
    name: "Pre-Tax IRR (with Credits) Floor",
    suffix: "%",
    isDollar: false,
  },
  pre_tax_irr_shortfall: {
    name: "Pre-Tax IRR (with Credits) Shortfall",
    suffix: "%",
    isDollar: false,
  },
  pre_flip_cash_split_percent: {
    name: "Pre-Flip Cash Split",
    suffix: "%",
    isDollar: false,
  },
  pre_flip_tax_split_percent: {
    name: "Pre-Flip Tax Split",
    suffix: "%",
    isDollar: false,
  },
  target_flip_tenor: {
    name: "Target Flip Term",
    suffix: " Yrs",
    isDollar: false,
  },
  actual_flip_tenor: {
    name: "Actual Flip Term",
    suffix: " Yrs",
    isDollar: false,
  },
  flip_delay: { name: "Flip Delay", suffix: " Yrs", isDollar: false },
  dro_cap: { name: "DRO Cap", suffix: "%", isDollar: false },
  max_dro: { name: "Max DRO", suffix: "%", isDollar: false },
  dro_cap_exceedance: {
    name: "DRO Cap Exceedance",
    suffix: "%",
    isDollar: false,
  },
  dro_term: { name: "DRO Term", suffix: " Yrs", isDollar: false },
  preferred_return_type: {
    name: "Preferred Return Type",
    suffix: "",
    isDollar: false,
    hideWhenNull: true,
  },
  preferred_return_amount: {
    name: "Preferred Return %",
    suffix: "%",
    isDollar: false,
    hideWhenNull: true,
  },
  federal_tax_rate: { name: "Federal Tax Rate", suffix: "%", isDollar: false },
};

export const PARTNERSHIP_SUMMARY_IRR_ITEMS = {
  pre_tax_irr: { name: "Pre-Tax IRR", suffix: "%" },
  pre_tax_irr_with_tax_credit: {
    name: "Pre-Tax IRR (with Credits)",
    suffix: "%",
  },
  after_tax_irr: { name: "After-Tax IRR", suffix: "%" },
};

export const PARTNERSHIP_ALLOC_DATE_NAMES: { [key: string]: ITableColumn } = {
  // set bold to true
  row_labels: { id: "row_labels", label: "Date", minWidth: 100, align: "left" },
  tax_equity_funding_date: {
    id: "tax_equity_funding_date",
    label: "Investor Funding",
    minWidth: 100,
    align: "right",
  },
  target_flip_date: {
    id: "target_flip_date",
    label: "Target Flip",
    minWidth: 100,
    align: "right",
  },
  actual_flip_date: {
    id: "actual_flip_date",
    label: "Actual Flip",
    minWidth: 100,
    align: "right",
  },
  tax_step_down_date: {
    id: "tax_step_down_date",
    label: "Tax Step Down",
    minWidth: 100,
    align: "right",
  },
  tax_compliance_period_date: {
    id: "tax_compliance_period_date",
    label: "Tax Compliance Period",
    minWidth: 100,
    align: "right",
  },
  deal_end_date: {
    id: "deal_end_date",
    label: "Deal End",
    minWidth: 100,
    align: "right",
  },
};

export const PARTNERSHIP_ALLOC_ITEM_NAMES = {
  starting_on: "Starting On",
  ending_on: "Ending On",
  te_cash_distributions: "TE Cash Distributions",
  se_cash_distributions: "SE Cash Distributions",
  te_tax_allocations: "TE Tax Allocations",
  se_tax_allocations: "SE Tax Allocations",
  te_tax_credit_allocations: "TE Tax Credit Allocations",
  se_tax_credit_allocations: "SE Tax Credit Allocations",
  te_transfer_cash: "TE Transfer Cash",
  se_transfer_cash: "SE Transfer Cash",
  te_preferred_return_type: "TE Preferred Return Type",
  te_preferred_return: "TE Preferred Return",
  se_return_of_capital: "SE Return of Capital",
};

export const DEBT_SUMMARY_IRR_ITEMS = {
  // all_in_interest_rate: { name: "All-In Interest Rate", suffix: "%" },
  debt_amount: { name: "Debt Amount", suffix: "", prefix: "", precision: 0 },
  principal: {
    name: "Principal Repayments",
    suffix: "",
    prefix: "",
    precision: 0,
  },
  interest: { name: "Interest Payments", suffix: "", prefix: "", precision: 0 },
  base_rate: { name: "Base Rate", suffix: "%", precision: 3 },
  spread: { name: "Spread", suffix: "%", precision: 3 },
  spread_step_up: { name: "Step Up", suffix: "%", precision: 3 },
  spread_step_up_frequency: {
    name: "Step Up Frequency (Yrs)",
    suffix: " Yrs",
    precision: 2,
  },
  debt_irr: { name: "Debt IRR", suffix: "%", precision: 3 },
  wal: { name: "WAL", suffix: " Yrs", precision: 2 },
  duration: { name: "Duration", suffix: " Yrs", precision: 2 },
};

export const SPONSOR_EQUITY_BOL_SUMMARY_ITEMS: Record<
  SponsorEquityBOL_ReportSummaryMetricsForView,
  {
    name: string;
    format?: boolean;
    suffix?: string;
    prefix?: string;
    enum?: { [k: string]: string };
  }
> = {
  net_equity: { name: "Investment Amount", format: true },
  pre_tax_irr: {
    name: "Pre-Tax IRR",
    suffix: "%",
  },
  after_tax_irr: {
    name: "After-Tax IRR",
    suffix: "%",
  },
  pre_tax_irr_buyout_term: {
    name: "Pre-Tax IRR (Buyout)",
    suffix: "%",
  },
  after_tax_irr_buyout_term: {
    name: "After-Tax IRR (Buyout)",
    suffix: "%",
  },
  pre_tax_cash_yield_5_yr: {
    name: "Pre-Tax Cash Yield (5-Yr)",
    suffix: "%",
  },
  after_tax_cash_yield_5_yr: {
    name: "After-Tax Cash Yield (5-Yr)",
    suffix: "%",
  },
  pre_tax_cash_yield_10_yr: {
    name: "Pre-Tax Cash Yield (10-Yr)",
    suffix: "%",
  },
  after_tax_cash_yield_10_yr: {
    name: "After-Tax Cash Yield (10-Yr)",
    suffix: "%",
  },
  pre_tax_moic: {
    name: "Pre-Tax MOIC",
    suffix: "x",
  },
  after_tax_moic: {
    name: "After-Tax MOIC",
    suffix: "x",
  },
  pre_tax_moic_buyout_term: {
    name: "Pre-Tax MOIC (Buyout)",
    suffix: "x",
  },
  after_tax_moic_buyout_term: {
    name: "After-Tax MOIC (Buyout)",
    suffix: "x",
  },
  pre_tax_payback_period: {
    name: "Pre-Tax Payback Period",
    suffix: " Yrs",
  },
  after_tax_payback_period: {
    name: "After-Tax Payback Period",
    suffix: " Yrs",
  },
  pre_tax_discount_rate: {
    name: "Pre-Tax Discount Rate",
    suffix: "%",
  },
  after_tax_discount_rate: {
    name: "After-Tax Discount Rate",
    suffix: "%",
  },
  pre_tax_npv: { name: "Pre-Tax NPV", format: true },
  after_tax_npv: { name: "After-Tax NPV", format: true },
  pre_tax_npv_buyout_term: { name: "Pre-Tax NPV (Buyout)", format: true },
  after_tax_npv_buyout_term: {
    name: "After-Tax NPV (Buyout)",
    format: true,
  },
  carryforward_credits_and_losses: {
    name: "Carryforward Credits and Losses",
    enum: CARRYFORWARD_CREDITS,
  },
};

export const DEVELOPER_REPORT_SUMMARY_ITEMS: Record<
  keyof IDeveloperReportSummary["data"]["summary"],
  { name: string; suffix?: string; prefix?: string }
> = {
  pre_tax_irr: { name: "Pre-Tax IRR", suffix: "%" },
  after_tax_irr: { name: "After-Tax IRR", suffix: "%" },
  pre_tax_moic: { name: "Pre-Tax MOIC", suffix: "x" },
  after_tax_moic: { name: "After-Tax MOIC", suffix: "x" },
  developer_fee_payment: { name: "Developer Fee Payment", prefix: "$" },
  developer_fee_payment_per_watt_dc: {
    name: "Developer Fee Payment $/W",
    prefix: "$",
  },
  // pre_tax_npv: { name: "Pre-Tax NPV" },
  // after_tax_npv: { name: "After Tax NPV" },
};

export const OUTPUT_REPORT_TERM_OPTIONS = enumToOptions(OUTPUT_REPORT_TERM);

export const OUTPUT_REPORT_LEVERAGE_OPTIONS = enumToOptions(
  OUTPUT_REPORT_LEVERAGE,
);

export const IGNORE_SPONSOR_EQUITY_IRR_TABLE_FIELDS_FOR_EO = [
  "Pre-Tax IRR (Buyout)",
  "After-Tax IRR (Buyout)",
  "Pre-Tax MOIC (Buyout)",
  "After-Tax MOIC (Buyout)",
  "Pre-Tax NPV (Buyout)",
  "After-Tax NPV (Buyout)",
];

export const IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_DP = ["(+) Transfer Cash"];

export const IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_TRANSFER = [
  "(+) Direct Pay Cash",
];

export const PARTNERSHIP_SUMMARY_REPORT_ITEMS = {
  deal_fmv: { name: "Deal FMV", suffix: "", prefix: "(-) " },
  deal_fees: { name: "Deal Fees", suffix: "", prefix: "(-) " },
  capital_contributions: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "",
  },
  capital_contributions_draws: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "",
  },
  ...COMMON_SUMMARY_REPORT_ITEMS,
};

export const TAX_EQUITY_SUMMARY_REPORT_ITEMS = {
  deal_fmv: { name: "Share of Deal FMV", suffix: "", prefix: "(-) " },
  deal_fees: { name: "Share of Deal Fees", suffix: "", prefix: "(-) " },
  capital_contributions: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "",
  },
  capital_contributions_draws: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "",
  },
  ...COMMON_SUMMARY_REPORT_ITEMS,
};
