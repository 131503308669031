import Layout from "../../../components/layout";
import CollaborationCasesView from "../../../views/collaboration/cases/shared";
import CollaborationPageLayout from "../../../components/collaboration-page-layout";
import { getDealCases } from "../../../apis/deal/case";
import { deleteDeal } from "../../../apis/deal/base";
import {
  createCollaborationSharedCase,
  getCollaborationSharedCases,
  updateCollaborationSharedCase,
} from "../../../apis/collaboration/base";

export default function CollaborationCasesPage(): JSX.Element {
  return (
    <Layout title="Collaboration">
      <CollaborationPageLayout>
        <CollaborationCasesView
          createCollaborationSharedCase={createCollaborationSharedCase}
          getCollaborationSharedCases={getCollaborationSharedCases}
          updateCollaborationSharedCase={updateCollaborationSharedCase}
          getDealCases={getDealCases}
          deleteDeal={deleteDeal}
        />
      </CollaborationPageLayout>
    </Layout>
  );
}
