import { makeStyles } from "tss-react/mui";

const ITEM_HEIGHT = 36;

export default makeStyles<{ fullWidth: boolean }>()((theme, { fullWidth }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    width: fullWidth ? "100%" : "auto",
  },
  formControl: {
    width: fullWidth ? "100%" : "calc(100% - 40px)",
  },
  select: {
    height: "32px !important",
  },
  selectRoot: {
    lineHeight: "2em",
  },
  menuPaper: {
    maxHeight: `${ITEM_HEIGHT * 14 + 10}px !important`,
    width: 250,
  },
  groupedItem: {
    paddingLeft: theme.spacing(4),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
