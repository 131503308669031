import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealDepreciationView from "../../../../views/deal/sizing/other/depreciation";
import {
  getDealProjectDepreciation,
  updateDealProjectDepreciation,
} from "../../../../apis/deal/depreciation";
import DealSizingOtherPageLayout from "../../../../components/deal-sizing-other-page-layout";

export default function DealDepreciationViewPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealSizingOtherPageLayout>
        <DealDepreciationView
          getDealProjectDepreciation={getDealProjectDepreciation}
          editDealDepreciation={updateDealProjectDepreciation}
        />
      </DealSizingOtherPageLayout>
    </DealPagesLayout>
  );
}
