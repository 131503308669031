import React from "react";
import Paper from "@mui/material/Paper";
import { SelectChangeEvent } from "@mui/material";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import SummaryTable from "../../../../../components/summary-table";
import SelectInput from "../../../../../components/select-input";
import { useAPI } from "../../../../../utils/hooks";
import { cn } from "../../../../../utils/helpers";
import {
  DEAL_COST_UNIT,
  DEAL_COST_UNIT_OPTIONS,
  DEAL_CREDIT_SUPPORT_UNIT,
  DEAL_CREDIT_SUPPORT_UNIT_OPTIONS,
} from "../../../../../constants";
import {
  IDealCreditSupportSummary,
  IDealCostSummary,
} from "../../../../../interfaces";

interface IProps {
  getDealCostSummary: (
    dealUuid: string,
    cost_input_type: string,
  ) => Promise<IDealCostSummary>;
  getCreditSupportSummary: (
    dealUuid: string,
    cost_input_type: string,
  ) => Promise<IDealCreditSupportSummary>;
}

export default function DealCostSummaryView({
  getDealCostSummary,
  getCreditSupportSummary,
}: IProps): JSX.Element {
  const { organization } = useOrganization();

  const { dealUuid, caseDealUuid } = useParams();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const [dealCostUnit, setDealCostUnit] =
    React.useState<keyof typeof DEAL_COST_UNIT>("DL");
  const [dealCreditUnit, setDealCreditUnit] =
    React.useState<keyof typeof DEAL_CREDIT_SUPPORT_UNIT>("DL");

  const onShownAsChange = (event: SelectChangeEvent<unknown>) => {
    getDealCostSummaryCallAPI(dealIdToUse, event.target.value);
    setDealCostUnit(event.target.value as keyof typeof DEAL_COST_UNIT);
  };

  const onShownAsCreditSupportChange = (event: SelectChangeEvent<unknown>) => {
    getCreditSupportSummaryCallAPI(dealIdToUse, event.target.value);
    setDealCreditUnit(
      event.target.value as keyof typeof DEAL_CREDIT_SUPPORT_UNIT,
    );
  };

  React.useEffect(() => {
    getDealCostSummaryCallAPI(dealIdToUse, dealCostUnit);
    getCreditSupportSummaryCallAPI(dealIdToUse, dealCostUnit);
  }, [dealIdToUse]);

  const {
    callAPI: getCreditSupportSummaryCallAPI,
    loading: getCreditSupportSummaryLoading,
    errored: getCreditSupportSummaryFailed,
    response: creditSupportSummary,
  } = useAPI(
    (id, credit_support_input_type) =>
      getCreditSupportSummary(id, credit_support_input_type),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: getDealCostSummaryCallAPI,
    loading: getDealCostSummaryLoading,
    errored: getDealCostSummaryFailed,
    response: dealCostSummary,
  } = useAPI((id, cost_input_type) => getDealCostSummary(id, cost_input_type), {
    initialLoading: true,
  });

  return (
    <>
      <ViewWrapper
        loading={getDealCostSummaryLoading}
        error={getDealCostSummaryFailed}
      >
        <Paper classes={{ root: cn("p-4 flex flex-col gap-4 mt-4") }}>
          <SelectInput
            label="Shown as"
            items={DEAL_COST_UNIT_OPTIONS}
            selected={dealCostUnit}
            onChange={onShownAsChange}
            className={cn("w-64 h-9")}
          />
          <SummaryTable
            usedIn="deal"
            summaryTable={dealCostSummary?.data.tables[0]}
          />
        </Paper>
      </ViewWrapper>

      <ViewWrapper
        loading={getCreditSupportSummaryLoading}
        error={getCreditSupportSummaryFailed}
      >
        <Paper classes={{ root: cn("p-4 mt-4") }}>
          <SelectInput
            label="Shown as"
            items={DEAL_CREDIT_SUPPORT_UNIT_OPTIONS}
            selected={dealCreditUnit}
            onChange={onShownAsCreditSupportChange}
            className={cn("w-64 h-9")}
          />
          <SummaryTable
            usedIn="deal"
            summaryTable={creditSupportSummary?.data.tables[0]}
          />
        </Paper>
      </ViewWrapper>
    </>
  );
}
