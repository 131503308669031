import { makeStyles } from "tss-react/mui";
import { Property as CssProperty } from "csstype";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{
  loaderPlacement?: CssProperty.AlignItems;
  ignoreHeight?: boolean;
}>()((theme, { loaderPlacement, ignoreHeight }) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: ignoreHeight ? "auto" : "100%",
      flexDirection: "column",
      width: "100%",
    },
    circularProgressContainer: {
      position: "relative",
      display: "inline-flex",
    },
    circleColor: {
      color: selectedTheme.color.secondary,
    },
    circleLabelContainer: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});
