import CurvesView from "../../../views/configuration/data/curves";
import ConfigurationPageLayout from "../../../components/configuration-page-layout";
import {
  addCurve,
  createCurveGroup,
  deleteCurveGroup,
  getCurveGroups,
  getCurves,
  getDateSchedule,
  getUser,
  updateCurveGroup,
} from "../../../apis/configuration/curves";

export default function CurvesPage(): JSX.Element {
  return (
    <ConfigurationPageLayout showOrgTabs>
      <CurvesView
        getCurveGroups={getCurveGroups}
        getCurves={getCurves}
        createCurveGroup={createCurveGroup}
        addCurve={addCurve}
        getDateSchedule={getDateSchedule}
        updateCurveGroup={updateCurveGroup}
        deleteCurveGroup={deleteCurveGroup}
        getUser={getUser}
      />
    </ConfigurationPageLayout>
  );
}
