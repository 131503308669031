import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import CheckboxInput from "../checkbox-input";
import Tooltip from "../tooltip";
import {
  PROJECT_ENERGY_TYPES,
  PROJECT_TAX_CREDIT_TYPE_OPTIONS,
  UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  SetStateAction,
  IProjectTaxCredit,
  IUpdateProjectTaxCreditForm,
  IUpdateProjectTaxCreditFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  formErrors?: IUpdateProjectTaxCreditFormErrors;
  loading: boolean;
  setFormErrors: SetStateAction<IUpdateProjectTaxCreditFormErrors | undefined>;
  form: IUpdateProjectTaxCreditForm;
  setForm: SetStateAction<IUpdateProjectTaxCreditForm>;
  onClose: () => void;
  onConfirm: (
    form: IUpdateProjectTaxCreditForm,
  ) => Promise<IProjectTaxCredit | undefined>;
  projectEnergyType?: keyof typeof PROJECT_ENERGY_TYPES;
}

export default function ProjectTaxCreditFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  projectEnergyType,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));

    if (field === "credit_type" && e.target.value === "ITC") {
      setForm((prev) => ({
        ...prev,
        ptc_inflation: null,
      }));
    }

    if (field === "credit_type" && e.target.value === "PTC") {
      setForm((prev) => ({
        ...prev,
        is_ira_environmental_justice_adder: false,
        is_ira_special_qualfied_ej_adder: false,
      }));
    }

    if (field === "credit_type" && e.target.value === "NONE") {
      setForm((prev) => ({
        ...prev,
        pwa_requirements_met: false,
        ptc_inflation: null,
        is_ira_energy_community_adder: false,
        is_ira_environmental_justice_adder: false,
        is_ira_special_qualfied_ej_adder: false,
      }));
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const taxCredit = await onConfirm(form);
    taxCredit && handleOnClose();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleResetForm = () => {
    setForm(UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <Divider classes={{ root: styles.classes.divider }} textAlign="left">
          Key Selections
        </Divider>
        <SelectInput
          required
          label="Type"
          selected={form.credit_type}
          items={
            projectEnergyType !== "BESS"
              ? PROJECT_TAX_CREDIT_TYPE_OPTIONS
              : PROJECT_TAX_CREDIT_TYPE_OPTIONS.filter(
                  (option) => option.value !== "PTC",
                )
          }
          onFocus={() => clearNonTextFieldErrorOnFocus("credit_type")}
          onChange={(e) => handleSelectInputChange(e, "credit_type")}
          error={Boolean(formErrors?.credit_type)}
          helperText={formErrors?.credit_type}
          disabled={loading}
          tooltip="<b>ITC</b> = Section 48 Investment Tax Credit \n<b>PTC</b> = Section 45 Production Tax Credit"
          fullWidth={false}
        />
        <Typography>
          Placed in Service Date: {form.placed_in_service_date}
        </Typography>
        <br />

        {form.credit_type !== "NONE" ? (
          <>
            <Tooltip title="Prevailing Wage & Apprenticeship">
              <div>
                <CheckboxInput
                  label="PWA Requirements Met"
                  name="pwa_requirements_met"
                  checked={form.pwa_requirements_met}
                  onChange={handleCheckboxChange}
                  disabled={loading}
                />
              </div>
            </Tooltip>
            <br />
          </>
        ) : null}

        {form.credit_type === "PTC" ? (
          <TextInput
            required
            isNumeric
            label="PTC Inflation"
            endAdornment={<>%</>}
            name="ptc_inflation"
            value={form.ptc_inflation ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.ptc_inflation)}
            helperText={formErrors?.ptc_inflation}
            disabled={loading}
            fullWidth={false}
          />
        ) : null}
        {form.credit_type !== "NONE" ? (
          <>
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Adders
            </Divider>
            <CheckboxInput
              label="Domestic Content"
              name="is_ira_domestic_content_adder"
              checked={form.is_ira_domestic_content_adder}
              onChange={handleCheckboxChange}
              disabled={loading}
            />
            <br />
            <CheckboxInput
              label="Energy Community"
              name="is_ira_energy_community_adder"
              checked={form.is_ira_energy_community_adder}
              onChange={handleCheckboxChange}
              disabled={loading}
            />
          </>
        ) : null}
        {form.credit_type === "ITC" && projectEnergyType !== "BESS" ? (
          <>
            <CheckboxInput
              label="Environmental Justice (EJ)"
              name="is_ira_environmental_justice_adder"
              checked={form.is_ira_environmental_justice_adder}
              onChange={handleCheckboxChange}
              disabled={loading}
            />
            <CheckboxInput
              label="Special Qualified EJ"
              name="is_ira_special_qualfied_ej_adder"
              checked={form.is_ira_special_qualfied_ej_adder}
              onChange={handleCheckboxChange}
              disabled={loading}
            />
          </>
        ) : null}
      </Box>
    </Modal>
  );
}
