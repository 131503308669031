import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IDealTaxCreditTransferPortfolio,
  IDealTaxCreditTransferPortfolioForm,
} from "../../interfaces";

export const getDealTransferPortfolio = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.transferPortfolio.get, {
    dealUuid,
  });
  return await AxiosHelper.get<IDealTaxCreditTransferPortfolio[]>(url);
};

export const updateDealTransferPortfolio = async (
  dealUuid: string,
  transferConfigId: number,
  form: IDealTaxCreditTransferPortfolioForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.transferPortfolio.update, {
    dealUuid,
    transferConfigId,
  });
  return await AxiosHelper.patch<
    IDealTaxCreditTransferPortfolioForm,
    IDealTaxCreditTransferPortfolio
  >(url, form);
};
