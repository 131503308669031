import React from "react";
import { delay } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import LoaderScreen from "../../../../components/loader-screen";

export default function DealSizingOtherView(): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    delay(() => {
      navigate(`${pathname}/ebitda`, {
        replace: true,
      });
    }, 200);
  }, []);

  return <LoaderScreen />;
}
