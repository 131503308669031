import Layout from "../../components/layout";
import HomePagesLayout from "../../components/home-pages-layout";
import ProjectMapView from "../../views/home/project-map";
const ProjectMap = (): JSX.Element => {
  return (
    <Layout title="Project Map">
      <HomePagesLayout>
        <ProjectMapView />
      </HomePagesLayout>
    </Layout>
  );
};

export default ProjectMap;
