import { combineSlices } from "@reduxjs/toolkit";

import { IProjectSliceState, projectSlice } from "./project.slice";
import { IDealSliceState, dealSlice } from "./deal.slice";
import { ICommonSliceState, commonSlice } from "./common.slice";
import { IOrgSliceState, orgSlice } from "./org.slice";
import { collaborationSlice, ICollaborationSliceState } from "./collaboration";

export interface IReduxStoreState {
  project: IProjectSliceState;
  deal: IDealSliceState;
  common: ICommonSliceState;
  org: IOrgSliceState;
  collaboration: ICollaborationSliceState;
}

export default combineSlices({
  project: projectSlice.reducer,
  deal: dealSlice.reducer,
  common: commonSlice.reducer,
  org: orgSlice.reducer,
  collaboration: collaborationSlice.reducer,
});

export const {
  setCurrentProject: setCurrentProjectAction,
  setCurrentProjectStatus: setCurrentProjectStatusAction,
  setValuationExist: setValuationExistAction,
} = projectSlice.actions;

export const {
  setCurrentDeal: setCurrentDealAction,
  setCurrentDealStatus: setCurrentDealStatusAction,
  setCurrentDealTermDebt: setCurrentDealTermDebtAction,
  setCurrentDealTermDebtStatus: setCurrentDealTermDebtStatusAction,
  updateSelectedDealOutputPageTab: updateSelectedDealOutputPageTabAction,
  updateDealOutputLoaded: updateDealOutputLoadedAction,
  setCasesOfDeal: setCasesOfDealAction,
  resetSelectedDealOutputPageTab: resetSelectedDealOutputPageTabAction,
} = dealSlice.actions;

export const {
  setDeleteModalProps: setDeleteModalPropsAction,
  resetDeleteModalProps: resetDeleteModalPropsAction,
  setConfirmModalProps: setConfirmModalPropsAction,
  resetConfirmModalProps: resetConfirmModalPropsAction,
  toggleDeleteModalLoading: toggleDeleteModalLoadingAction,
  updateCtrlPressed: updateCtrlPressedAction,
  updateShiftPressed: updateShiftPressedAction,
  updateContentLoaded: updateContentLoadedAction,
} = commonSlice.actions;

export const {
  setCurrentOrg: setCurrentOrgAction,
  setCurrentOrgCurveGroups: setCurrentOrgCurveGroupsAction,
  setCurrentOrgCurves: setCurrentOrgCurvesAction,
  setProductUsageDetails: setProductUsageDetailsAction,
  updateShowUsageLimitHitModal: updateShowUsageLimitHitModalAction,
} = orgSlice.actions;

export const { setCurrentCollaboration: setCurrentCollaborationAction } =
  collaborationSlice.actions;
