import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealCostSummaryView from "../../../../views/deal/components/cost/summary";
import DealComponentPagesLayout from "../../../../components/deal-component-page-layout";
import {
  getDealCostSummary,
  getCreditSupportSummary,
} from "../../../../apis/deal/cost";

export default function DealCostSummaryPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealComponentPagesLayout>
        <DealCostSummaryView
          getDealCostSummary={getDealCostSummary}
          getCreditSupportSummary={getCreditSupportSummary}
        />
      </DealComponentPagesLayout>
    </DealPagesLayout>
  );
}
