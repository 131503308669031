import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import { IDealSponsorEquity, IDealSponsorEquityForm } from "../../interfaces";

export const getDealSponsorEquity = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.sponsorEquity.get, { dealUuid });
  return await AxiosHelper.get<IDealSponsorEquity[]>(url);
};

export const addDealSponsorEquity = async (
  dealUuid: string,
  form: IDealSponsorEquityForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.sponsorEquity.create, { dealUuid });
  return await AxiosHelper.post<IDealSponsorEquityForm, IDealSponsorEquity>(
    url,
    form,
  );
};

export const updateDealSponsorEquity = async (
  dealUuid: string,
  sponsorEquityId: number,
  form: IDealSponsorEquityForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.sponsorEquity.update, {
    dealUuid,
    sponsorEquityId,
  });
  return await AxiosHelper.patch<IDealSponsorEquityForm, IDealSponsorEquity>(
    url,
    form,
  );
};
