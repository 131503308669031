import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    tableHeader: {
      ".MuiTableCell-root": {
        fontWeight: 600,
      },
    },
    activeGroup: {
      backgroundColor: `${selectedTheme.color.aboveSurface} !important`,
    },
    group: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      cursor: "pointer",
    },
    searchbar: {
      backgroundColor: `${selectedTheme.color.aboveAll} !important`,
    },
    emptyContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "300px",
      padding: theme.spacing(2),
      backgroundColor: `${selectedTheme.color.aboveSurface} !important`,
      borderRadius: theme.spacing(4),
    },
    createInfo: {
      display: "block",
      marginTop: 20,
      backgroundColor: selectedTheme.color.aboveSurface,
      padding: theme.spacing(1, 2),
      borderRadius: 20,
      color: "#666",
    },
  };
});
