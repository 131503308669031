import ProjectMerchantRevenueContractsView from "../../../../../views/project/pro-forma/revenue/merchant-revenue/list";
import ProjectRevenueLayout from "../../../../../components/project-revenue-layout";
import ProjectPagesLayout from "../../../../../components/project-pages-layout";
import TabbedLayout from "../../../../../components/tabbed-layout";
import { getCurves } from "../../../../../apis/configuration/curves";
import { getProjectTiming } from "../../../../../apis/project/timing";
import {
  deleteProjectMerchantRevenueContract,
  getProjectMerchantRevenues,
  updateMerchantRevenue,
  addMerchantRevenue,
} from "../../../../../apis/project/revenue-contracts";

export default function MerchantRevenueList(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectRevenueLayout>
          <ProjectMerchantRevenueContractsView
            deleteProjectMerchantRevenueContract={
              deleteProjectMerchantRevenueContract
            }
            getProjectMerchantRevenues={getProjectMerchantRevenues}
            updateMerchantRevenue={updateMerchantRevenue}
            addMerchantRevenue={addMerchantRevenue}
            getProjectTiming={getProjectTiming}
            getCurves={getCurves}
          />
        </ProjectRevenueLayout>
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
