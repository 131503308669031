import React from "react";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import MuiInputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { toast } from "react-toastify";
import { Virtuoso } from "react-virtuoso";
import { cloneDeep } from "lodash";

import Tooltip from "../tooltip";
import ThemeContext from "../../utils/context/theme-context";
import ModifyDataAccordion from "./modify-accordion";
import { modifyArrayData } from "../../apis/configuration/curves";
import { useAPI, useAppSelector } from "../../utils/hooks";
import {
  calculateCurveTotal,
  cn,
  formatNumberForView,
  keepNumbersDotAndMinus,
} from "../../utils/helpers";
import {
  IArrayDataModifyErrors,
  IArrayDataModifyPayload,
  IArrayDataModifyResponse,
} from "../../interfaces";
import { format } from "date-fns";

interface IProps {
  label: string;
  name: string;
  dateSchedule: string[];
  value: (number | string | null)[];
  error: string;
  disabled: boolean;
  clearErrorOnFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (v: (number | string | null)[]) => void;
  tooltip?: string;
  formatValue?: boolean;
  startAdornment?: string;
  endAdornment?: string;
  showTotal?: boolean;
  isTimestamps?: boolean;
}

function SchedulerTextInput({
  label,
  name,
  error,
  value,
  disabled,
  onChange,
  clearErrorOnFocus,
  dateSchedule,
  tooltip,
  formatValue,
  startAdornment = "",
  endAdornment = "",
  showTotal = false,
  isTimestamps = false,
}: IProps) {
  const { darkMode } = React.useContext(ThemeContext);
  const { ctrlPressed, shiftPressed } = useAppSelector((s) => s.common);

  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const [selectedRowIdx, setSelectedRowIdx] = React.useState<number[]>([0]);
  const [showUndo, setShowUndo] = React.useState<boolean>(false);

  const {
    callAPI: modifyArrayDataCallAPI,
    loading: modifyArrayDataLoading,
    fieldErrors: modifyArrayDataErrors,
    setFieldErrors: setModifyArrayDataErrors,
  } = useAPI<IArrayDataModifyResponse, IArrayDataModifyErrors>(
    (payload: IArrayDataModifyPayload) => modifyArrayData(payload),
  );

  const onUpdateCurve = (payload: IArrayDataModifyPayload) => {
    modifyArrayDataCallAPI(payload)
      .then((res) => {
        if (res && res.data) {
          onChange(res.data);
          setShowUndo(true);
          timerRef.current = setTimeout(() => setShowUndo(false), 3000);
        }
      })
      .catch(() => null);
  };

  const clearUndoTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  React.useEffect(() => {
    if (!value.length) {
      onChange(new Array(dateSchedule.length).fill(null));
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [ctrlPressed, shiftPressed, selectedRowIdx]);

  const curveTotal = React.useMemo(() => calculateCurveTotal(value), [value]);

  const onKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (!ctrlPressed && (e.key === "ArrowDown" || e.key === "ArrowUp")) {
        e.preventDefault();
      }

      // select/unselect multiple cells
      if (shiftPressed && selectedRowIdx.length) {
        setSelectedRowIdx((prevState) => {
          if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            const lastIdx = prevState[prevState.length - 1];

            let idxToUse = lastIdx;
            if (idxToUse - 1 < -1 || idxToUse + 1 > dateSchedule.length) {
              return prevState;
            }

            if (e.key === "ArrowUp") {
              idxToUse = lastIdx - 1;

              if (prevState.includes(lastIdx - 1)) {
                idxToUse = lastIdx;
              }
            }

            if (e.key === "ArrowDown") {
              idxToUse = lastIdx + 1;

              if (prevState.includes(lastIdx + 1)) {
                idxToUse = lastIdx;
              }
            }

            return prevState.includes(idxToUse)
              ? prevState.filter((i) => i !== idxToUse)
              : [...prevState, idxToUse];
          } else {
            return prevState;
          }
        });
      }

      // copy selected cells
      if (ctrlPressed && e.key === "c") {
        const valuesToCopy = value.filter((_, idx) =>
          selectedRowIdx.includes(idx),
        );
        navigator.clipboard.writeText(valuesToCopy.join("\t"));
      }

      if (!shiftPressed && selectedRowIdx.length) {
        const minIdx = Math.min(...selectedRowIdx);
        const maxIdx = Math.max(...selectedRowIdx);

        if (
          (minIdx === 0 && e.key === "ArrowUp") ||
          (maxIdx === dateSchedule.length - 1 && e.key === "ArrowDown")
        ) {
          return;
        }

        if (e.key === "ArrowDown" || e.key === "Enter") {
          setSelectedRowIdx([maxIdx + 1]);
        } else if (e.key === "ArrowUp") {
          setSelectedRowIdx([minIdx - 1]);
        }
      }
    },
    [ctrlPressed, shiftPressed, selectedRowIdx],
  );

  const onBlur = () => {
    onChange(value.map((v) => (v === "-" ? 0 : v)));
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const copiedValue = e.clipboardData.getData("text/plain");
    const splitChar = copiedValue.includes("\t") ? "\t" : "\n";
    const arrayValues = copiedValue.split(splitChar);

    if (arrayValues[arrayValues.length - 1] === "") {
      arrayValues.pop();
    }

    const valuesToUse = arrayValues.map((v) => {
      const cleanedValue = keepNumbersDotAndMinus(v);
      if (cleanedValue === "") {
        return null;
      }
      if (cleanedValue === "-") {
        return "0";
      }
      return cleanedValue;
    });

    const minIdx = Math.min(...selectedRowIdx);
    const newCurve = cloneDeep(value);
    newCurve.splice(minIdx, valuesToUse.length, ...valuesToUse);

    if (newCurve.length > dateSchedule.length) {
      toast.info(
        "Entries were truncated, since there were more than the permitted number of values.",
        { toastId: "entries-truncation-info" },
      );
    }

    onChange(newCurve.slice(0, dateSchedule.length));

    setSelectedRowIdx([minIdx + valuesToUse.length]);
  };

  const onSelectValueCell = (idx: number) => {
    if (shiftPressed) {
      setSelectedRowIdx((prevState) => {
        return prevState.includes(idx)
          ? prevState.filter((i) => i !== idx)
          : [...prevState, idx];
      });
      return;
    }
    setSelectedRowIdx([idx]);
  };

  const onValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number,
  ) => {
    e.preventDefault();
    const newValue = cloneDeep(value);
    const cleanedValue = keepNumbersDotAndMinus(e.target.value);
    newValue[idx] = cleanedValue === "" ? null : cleanedValue;

    onChange(newValue);
  };

  const styles = React.useMemo(() => {
    let border = "";
    let bgHover = "";
    let bgColor = "";
    const disabledStyles = "opacity-50";

    if (darkMode) {
      border = "border-white";
      bgHover = "hover:bg-dark-gray";
      bgColor = `bg-dark-gray`;
    } else {
      border = "border-black";
      bgHover = "hover:bg-table-gray";
      bgColor = "bg-gray-100";
    }

    return { border, bgHover, bgColor, disabledStyles };
  }, [darkMode]);

  const getValue = (v: string | number | null) => {
    if (!v) return v;

    if (formatValue) {
      return formatNumberForView(String(v));
    }
    return v;
  };

  const formatError = (error: string[] | string) => {
    return Array.isArray(error)
      ? error.find((element) => typeof element === "string")
      : error;
  };

  const handleClearFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setModifyArrayDataErrors((prev) => ({ ...prev, input_array: "" }));
    clearErrorOnFocus(e);
  };

  const areFieldsDisabled = disabled || modifyArrayDataLoading || showUndo;

  const dateScheduleHeight =
    dateSchedule.length > 13 ? "400px" : `${dateSchedule.length * 29.8}px`;

  const renderDateSchedule = (idx: number) => {
    const date = dateSchedule[idx];

    return (
      <Box
        key={idx}
        className={cn(`flex border-t-[1px] ${styles.bgHover} select-none`)}
      >
        <Typography align="right" minWidth={200} padding={0.3} paddingRight={5}>
          {date}
        </Typography>
        {!selectedRowIdx.includes(idx) && (
          <Box
            onClick={() => onSelectValueCell(idx)}
            className={cn(
              `px-2 cursor-text border-[1px] border-transparent overflow-hidden text-ellipsis ${
                styles.bgColor
              } w-full !max-w-[250px] ${
                selectedRowIdx.includes(idx) && styles.border
              }`,
            )}
          >
            <Box
              className={cn(
                `flex justify-between gap-2 ${
                  areFieldsDisabled && styles.disabledStyles
                }`,
              )}
            >
              <div>{startAdornment}</div>
              <div
                className={cn(
                  "max-w-min whitespace-nowrap overflow-hidden text-ellipsis",
                )}
              >
                {getValue(value[idx])}
              </div>
              <div className={cn("ml-auto text-nowrap")}>{endAdornment}</div>
            </Box>
          </Box>
        )}

        {selectedRowIdx.includes(idx) && (
          <MuiInputBase
            autoFocus
            name={name}
            autoComplete="off"
            value={getValue(value[idx])}
            onChange={(e) => onValueChange(e, idx)}
            onPaste={onPaste}
            onBlur={onBlur}
            inputProps={{ className: cn(`!px-2 !py-0`) }}
            disabled={areFieldsDisabled}
            onClick={() => onSelectValueCell(idx)}
            onFocus={handleClearFocus}
            className={cn(
              `!px-2 border-[1px] border-transparent shadow-sm w-full ${styles.border}`,
            )}
            classes={{
              disabled: styles.disabledStyles,
            }}
            startAdornment={
              <div className={cn("text-nowrap")}>{startAdornment}</div>
            }
            endAdornment={
              <div className={cn("text-nowrap")}>{endAdornment}</div>
            }
          />
        )}
      </Box>
    );
  };

  return (
    <React.Fragment>
      <ModifyDataAccordion
        value={value}
        onChange={onChange}
        showUndo={showUndo}
        setShowUndo={setShowUndo}
        onUpdateCurve={onUpdateCurve}
        clearUndoTimer={clearUndoTimer}
        loadingModify={modifyArrayDataLoading}
        modifyErrors={modifyArrayDataErrors}
        setModifyErrors={setModifyArrayDataErrors}
      />

      <Box className={cn("flex")}>
        <Box className={cn("flex-1")}>
          <Box
            className={cn(
              `border-[1px] rounded ${
                (error || modifyArrayDataErrors?.input_array) &&
                "border-rose-500"
              }`,
            )}
          >
            <Box className={cn("flex p-[2px]")}>
              <Typography
                align="right"
                fontWeight="bold"
                minWidth={200}
                paddingRight={5}
              >
                Date
              </Typography>
              <Typography align="left" fontWeight="bold" paddingLeft={1}>
                {label}
              </Typography>
            </Box>
            <Virtuoso
              data={dateSchedule}
              itemContent={(index) => renderDateSchedule(index)}
              style={{ height: dateScheduleHeight }}
            />
          </Box>

          {showTotal && (
            <Box className={cn("flex justify-between font-semibold my-2")}>
              <div>Total</div>
              <div>
                {startAdornment}
                {curveTotal}
                {endAdornment}
              </div>
            </Box>
          )}

          <FormHelperText
            data-pw={`helper-text-${label}`}
            error={Boolean(error)}
          >
            {formatError(error)}
          </FormHelperText>

          {modifyArrayDataErrors?.input_array && (
            <FormHelperText
              data-pw={`modify-curve-helper-text-${label}`}
              error={Boolean(modifyArrayDataErrors)}
            >
              {formatError(modifyArrayDataErrors?.input_array)}
            </FormHelperText>
          )}
        </Box>

        {tooltip && (
          <Tooltip title={tooltip} placement="top-end">
            <InfoIcon className={cn("ml-2 text-light-gray")} />
          </Tooltip>
        )}
      </Box>
    </React.Fragment>
  );
}

export default React.memo(SchedulerTextInput);
