import ProjectRevenueContractsView from "../../../../../views/project/pro-forma/revenue/revenue-contract/list";
import ProjectRevenueLayout from "../../../../../components/project-revenue-layout";
import ProjectPagesLayout from "../../../../../components/project-pages-layout";
import TabbedLayout from "../../../../../components/tabbed-layout";
import { getCurves } from "../../../../../apis/configuration/curves";
import { getProjectTiming } from "../../../../../apis/project/timing";
import {
  getProjectRevenueContracts,
  addProjectRevenueContract,
  editProjectRevenueContract,
  deleteProjectRevenueContract,
} from "../../../../../apis/project/revenue-contracts";

export default function RevenueList(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectRevenueLayout>
          <ProjectRevenueContractsView
            getProjectRevenueContracts={getProjectRevenueContracts}
            addProjectRevenueContract={addProjectRevenueContract}
            editProjectRevenueContract={editProjectRevenueContract}
            deleteProjectRevenueContract={deleteProjectRevenueContract}
            getProjectTiming={getProjectTiming}
            getCurves={getCurves}
          />
        </ProjectRevenueLayout>
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
