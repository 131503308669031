import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Refresh";
import MuiButton from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MapIcon from "@mui/icons-material/Map";

import { useNavigate, useSearchParams } from "react-router-dom";

import useStyles from "./styles";
import Searchbar from "../../components/search-bar";
import Button from "../../components/button";
import ProjectFormModal from "../../components/project-form-modal";
import ProjectsTable from "../../components/projects-table";
import ProjectFilters from "../../components/project-filters";
import Tabs from "../../components/tabs";
import ConditionalProtect from "../../components/conditional-protect";
import ViewWrapper from "../../components/view-wrapper";
import { useAPI, useSearchBar } from "../../utils/hooks";
import {
  cn,
  getFormattedProjectLocations,
  updateStageInProjectList,
} from "../../utils/helpers";
import {
  FORM_TYPES,
  DEAL_STATUS_OPTIONS,
  PROJECTS_TABLE_COLUMNS,
  PROJECT_FORM_DEFAULT_STATE,
  PROJECT_STAGE_CONSTRUCTION,
  PROJECT_STAGE_DEVELOPMENT,
  PROJECT_STAGE_OPERATION,
} from "../../constants";
import {
  IProject,
  ITableSort,
  IProjectForm,
  ServerPaginatedResponse,
  IProjectFormErrors,
  IGetDealsParams,
  ProjectStageMap,
  IAddUpdateProjectResponse,
  ICounty,
  ISelectOption,
  IProjectLocation,
} from "../../interfaces";
import Map from "../../components/map";
import Typography from "@mui/material/Typography";

interface IProps {
  getProjects: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]>>;
  addProject: (form: IProjectForm) => Promise<IAddUpdateProjectResponse>;
  getCounties: (
    search: string,
    state: string,
  ) => Promise<ServerPaginatedResponse<ICounty[]>>;
  getProjectsLocations: (
    status: string,
    uuids: string,
    filterParams?: Record<string, string>,
  ) => Promise<IProjectLocation[]>;
}

const mapProjectStage = {
  DEV: PROJECT_STAGE_DEVELOPMENT,
  CON: PROJECT_STAGE_CONSTRUCTION,
  OP: PROJECT_STAGE_OPERATION,
};

const VIEW_OPTIONS = Object.freeze({
  MAP_VIEW: "map",
  LIST_VIEW: "list",
});

const mapSubStage = (stage: keyof ProjectStageMap) =>
  Object.keys(mapProjectStage[stage]).join(",");

export default function ProjectList({
  getProjects,
  addProject,
  getCounties,
  getProjectsLocations,
}: IProps): JSX.Element {
  const isFirstLoad = React.useRef(true);
  const styles = useStyles();
  const navigate = useNavigate();
  const { searchString, onSearchStringChange, handleClearSearchString } =
    useSearchBar();

  const [filterParams, setFilterParams] = useSearchParams();
  const [projects, setProjects] = React.useState<IProject[]>([]);
  const [totalProjects, setTotalProjects] = React.useState<number>(0);

  const [addProjectModalOpen, setAddProjectModalOpen] =
    React.useState<boolean>(false);

  const [projectForm, setProjectForm] = React.useState<IProjectForm>(
    PROJECT_FORM_DEFAULT_STATE,
  );
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const [listView, setListView] = React.useState<string>(
    VIEW_OPTIONS.LIST_VIEW,
  );

  const isFiltersApplied = React.useMemo(() => {
    const energy_type = filterParams.get("energy_type");
    const state = filterParams.get("state");
    const sub_stage = filterParams.get("sub_stage");
    const cod = filterParams.get("cod");
    const tax_credit__credit_type = filterParams.get("tax_credit__credit_type");
    const power_market = filterParams.get("power_market");
    return Boolean(
      energy_type ||
        state ||
        sub_stage ||
        cod ||
        tax_credit__credit_type ||
        power_market,
    );
  }, [filterParams]);

  const [isFilterOpen, setIsFilterOpen] = React.useState(isFiltersApplied);

  React.useEffect(() => {
    const param = filterParams.get("view");
    if (param && param === VIEW_OPTIONS.MAP_VIEW) {
      setListView(VIEW_OPTIONS.MAP_VIEW);
    }
  }, []);

  React.useEffect(() => {
    let status = DEAL_STATUS_OPTIONS[0].value;
    const param = filterParams.get("status");

    if (param) {
      status = param;
    }

    const filters = getFilterParams();

    if (listView === VIEW_OPTIONS.MAP_VIEW) {
      getProjectsLocationsCallAPI(status, "", filters);
    }
  }, [listView, filterParams]);

  React.useEffect(() => {
    if (!isFirstLoad.current) {
      const status = filterParams.get("status");
      if (filterParams.size === 1 && status) return;
    }
    isFirstLoad.current = false;

    const {
      energy_type,
      state,
      sub_stage,
      cod,
      tax_credit__credit_type,
      power_market,
    } = getFilterParams();

    getProjectsCallAPI({
      energy_type,
      state,
      sub_stage,
      cod,
      tax_credit__credit_type,
      power_market,
    }).then((response) => {
      response && setProjects(updateStageInProjectList(response.results));
      response && setTotalProjects(response.results.length);
    });
  }, [filterParams]);

  const {
    callAPI: getProjectsCallAPI,
    errored: getProjectsFailed,
    loading: loadingProjects,
  } = useAPI((params) => getProjects({ ...params }), { initialLoading: true });

  const {
    callAPI: getProjectsLocationsCallAPI,
    errored: getProjectsLocationsFailed,
    loading: loadingProjectsLocations,
    response: projectsLocations,
  } = useAPI(
    (status, uuids, filters) => getProjectsLocations(status, uuids, filters),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: addProjectCallAPI,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
    loading: addProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (form: IProjectForm) => addProject(form),
  );

  const { callAPI: getCountiesCallAPI, response: counties } = useAPI(
    (params) => getCounties(params.search, params.state),
    {
      initialLoading: true,
    },
  );

  const getFilterParams = () => {
    const energy_type = filterParams.get("energy_type");
    const stage = filterParams.get("project_stage");
    const state = filterParams.get("state");
    const cod = filterParams.get("cod");
    const tax_credit__credit_type = filterParams.get("tax_credit__credit_type");
    const power_market = filterParams.get("power_market");

    let sub_stage = filterParams.get("sub_stage");
    if (mapProjectStage[stage as keyof ProjectStageMap]) {
      sub_stage = mapSubStage(stage as keyof ProjectStageMap);
    }

    return {
      energy_type,
      state,
      sub_stage,
      cod,
      tax_credit__credit_type,
      power_market,
    };
  };

  const handleAddProject = async (form: IProjectForm) => {
    const project = await addProjectCallAPI(form);
    project && goToProject(project.uuid);
    return project;
  };

  const filteredBySearchProjects: IProject[] = React.useMemo(() => {
    return projects.filter(
      (d) =>
        d.name.toLowerCase().includes(searchString.toLowerCase()) &&
        d.status ===
          (filterParams.get("status")
            ? filterParams.get("status")
            : DEAL_STATUS_OPTIONS[0].value),
    );
  }, [projects, searchString, filterParams]);

  const handleOpenAddProjectModal = () => {
    setAddProjectModalOpen(true);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModalOpen(false);
  };

  const goToProject = (projectUuid: string) => {
    navigate(`/project/${projectUuid}/general`);
  };

  const clearFilter = () => {
    setFilterParams();
    setIsFilterOpen(false);
  };

  const countyOptions = React.useMemo(() => {
    const results = counties?.results.map((county) => ({
      label: county.name,
      value: String(county.id),
    }));
    return (results as unknown as ISelectOption[]) || ([] as ISelectOption[]);
  }, [projectForm.state, counties]);

  const mapData = React.useMemo(() => {
    if (!projectsLocations) return [];
    return getFormattedProjectLocations(
      projectsLocations as IProjectLocation[],
      searchString,
    );
  }, [projectsLocations, searchString]);

  return (
    <>
      <Box className={cn("p-4")}>
        <Box
          className={cn(
            "flex flex-col md:flex-row md:justify-between md:items-start gap-4 mb-4",
          )}
        >
          <Box className={cn("flex flex-col md:flex-row md:items-start gap-4")}>
            <Searchbar
              searchString={searchString}
              onSearchStringChange={onSearchStringChange}
              handleClearSearchString={handleClearSearchString}
            />
            <MuiButton
              className={`${styles.classes.button} ${
                isFiltersApplied && styles.classes.activeButton
              }`}
              variant="outlined"
              size="large"
              endIcon={<FilterIcon />}
              onClick={() => setIsFilterOpen((v) => !v)}
            >
              Filters
            </MuiButton>
            {isFilterOpen && (
              <MuiButton
                className={cn("!capitalize")}
                variant="outlined"
                size="large"
                endIcon={<ClearIcon />}
                onClick={clearFilter}
              >
                Clear All
              </MuiButton>
            )}
          </Box>
          <ConditionalProtect type="project">
            <Button
              canOpenUpgrade
              startIcon={<AddIcon />}
              btnType="primary"
              label="Add Project"
              onClick={handleOpenAddProjectModal}
            />
          </ConditionalProtect>
        </Box>
        <Box>{isFilterOpen && <ProjectFilters />}</Box>
        <Box className={cn(`h-[calc(100vh_-_${isFilterOpen ? 148 : 165}px)]`)}>
          <ViewWrapper loading={loadingProjects} error={getProjectsFailed}>
            <Box className="flex flex-row justify-between items-end">
              <Tabs
                selectedTab={
                  filterParams.get("status") || DEAL_STATUS_OPTIONS[0].value
                }
                borderBottom={false}
                tabs={DEAL_STATUS_OPTIONS}
                onClick={(tab) => {
                  setFilterParams(
                    { ...Object.fromEntries(filterParams), status: tab.value },
                    { replace: false },
                  );
                }}
              />

              <ToggleButtonGroup
                value={listView}
                exclusive
                onChange={(_, value) => {
                  if (!value) return;
                  setListView(value);
                  setFilterParams(
                    { ...Object.fromEntries(filterParams), view: value },
                    { replace: false },
                  );
                }}
                aria-label="text alignment"
                className="mb-[5px]"
              >
                <ToggleButton
                  value={VIEW_OPTIONS.LIST_VIEW}
                  aria-label="left aligned"
                >
                  <FormatListBulletedIcon />
                </ToggleButton>
                <ToggleButton
                  value={VIEW_OPTIONS.MAP_VIEW}
                  aria-label="centered"
                >
                  <MapIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {listView === VIEW_OPTIONS.LIST_VIEW && (
              <ProjectsTable
                columns={PROJECTS_TABLE_COLUMNS}
                sortTable={sortTable}
                setSortTable={setSortTable}
                rows={filteredBySearchProjects}
                heightOptions={{
                  fixedHeight: true,
                  isFilterOpen,
                }}
                isFiltersApplied={isFiltersApplied}
                isSearchApplied={searchString.length > 0}
                totalProjects={totalProjects}
              />
            )}

            {listView === VIEW_OPTIONS.MAP_VIEW && (
              <ViewWrapper
                loading={loadingProjectsLocations}
                error={getProjectsLocationsFailed}
              >
                {mapData.length > 0 ? (
                  <Map data={mapData} height={"76vh"} />
                ) : (
                  <Box className="flex flex-col items-center justify-center h-full mt-10">
                    <Typography variant="h6">
                      There are no Projects to display for this status and
                      filters (if any)
                    </Typography>
                  </Box>
                )}
              </ViewWrapper>
            )}
          </ViewWrapper>
        </Box>
      </Box>

      <ProjectFormModal
        open={addProjectModalOpen}
        headerLabel="Add a new Project"
        form={projectForm}
        loading={addProjectLoading}
        setForm={setProjectForm}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        onClose={handleCloseAddProjectModal}
        onConfirm={handleAddProject}
        formType={FORM_TYPES.CREATE}
        countyOptions={countyOptions || []}
        onStateChange={(value) => {
          getCountiesCallAPI({
            search: "",
            state: value,
          });
        }}
      />
    </>
  );
}
