import React from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";
import AutocompleteField from "../autocomplete-field";

import Modal from "../modal";
import useStyles from "./styles";
import SelectInput from "../select-input";
import {
  SetStateAction,
  ISelectOption,
  ServerPaginatedResponse,
  IDealCase,
  IProject,
} from "../../interfaces";
import {
  IAddSolverForm,
  IAddSolverFormErrors,
  ISolverLite,
} from "../../interfaces/deal/solvers.interface";
import {
  SOLVER_FOR_OPTIONS,
  SOLVER_GOAL_OPTIONS,
} from "../../constants/solver";
import TextInput from "../text-input";
import InputAdornment from "@mui/material/InputAdornment";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IAddSolverForm;
  setForm: SetStateAction<IAddSolverForm>;
  formErrors?: IAddSolverFormErrors;
  setFormErrors: SetStateAction<IAddSolverFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (form: IAddSolverForm) => Promise<ISolverLite | undefined>;
  caseOptions: ISelectOption[];
  projectOptions: ISelectOption[];
  fetchSolverForFieldOptions: (
    uuid: string,
    solverFor: string,
    caseUuid?: string | null,
  ) => void;
  solverForFieldOptions: ISelectOption[];
  dealOptions: ISelectOption[];
  fetchDealCases: (
    dealUuid: string,
  ) => Promise<ServerPaginatedResponse<IDealCase[]> | undefined>;
  fetchProjectsOfDeal: (dealUuid: string) => Promise<IProject[] | undefined>;
  solverForFieldName: string;
  handleCaseSelection: (caseUuid: string) => void;
}

export default function AddSolverFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  caseOptions,
  projectOptions,
  fetchSolverForFieldOptions,
  solverForFieldOptions,
  dealOptions,
  fetchDealCases,
  fetchProjectsOfDeal,
  solverForFieldName,
  handleCaseSelection,
}: IProps): JSX.Element {
  const styles = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnAdd = async () => {
    const dealCase = await onConfirm(form);
    dealCase && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm({
      name: "",
      deal: null,
      case: null,
      solver_for: "CPRICE",
      goal_type: "SE_BPT",
      goal_value: null,
      solver_for_field_object_id: null,
      project: null,
    });
    setFormErrors({});
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [name]: value?.value,
    }));
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAdd}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          required
          label="Solver Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
        />

        <AutocompleteField
          label="Deal"
          name="deal"
          onChange={(e, value) => {
            handleSingleAutoCompleteChange(e, value, "deal");
            fetchDealCases(value?.value);
            fetchProjectsOfDeal(value?.value);

            if (form.solver_for === "DEALFEE") {
              fetchSolverForFieldOptions(value?.value, form.solver_for);
            }

            setForm((prev) => ({
              ...prev,
              case: "base",
              project: null,
            }));
          }}
          options={dealOptions}
          value={String(form.deal) || ""}
          helperText={formErrors?.deal}
          onFocus={() => {
            clearSelectErrorOnFocus("deal");
          }}
          disabled={loading}
        />

        <AutocompleteField
          label="Select Case"
          name="case"
          onChange={(e, value) => {
            handleSingleAutoCompleteChange(e, value, "case");
            handleCaseSelection(value?.value);
            setForm((prev) => ({
              ...prev,
              project: null,
            }));
          }}
          options={caseOptions}
          value={form.case}
          helperText={formErrors?.case}
          onFocus={() => {
            clearSelectErrorOnFocus("case");
          }}
          disabled={loading || form.deal === null}
        />

        {
          <SelectInput
            label="Solve For"
            selected={form.solver_for}
            items={SOLVER_FOR_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("solver_for")}
            onChange={(e) => {
              handleSelectInputChange(e, "solver_for");
              if (e.target.value === "DEALFEE" && form.deal !== null) {
                fetchSolverForFieldOptions(
                  form.deal as string,
                  e.target.value,
                  form.case !== null && form.case !== "base"
                    ? (form.case as string)
                    : null,
                );
                setForm((prev) => ({
                  ...prev,
                  project: null,
                }));
              } else {
                if (form.project !== null) {
                  fetchSolverForFieldOptions(
                    form.project as string,
                    e.target.value as string,
                  );
                }
              }
            }}
            error={Boolean(formErrors?.solver_for)}
            helperText={formErrors?.solver_for}
            disabled={loading}
          />
        }

        {form.solver_for !== "DEALFEE" && (
          <AutocompleteField
            label="Project"
            name="project"
            onChange={(e, value) => {
              handleSingleAutoCompleteChange(e, value, "project");
              if (form.solver_for !== "DEALFEE") {
                fetchSolverForFieldOptions(
                  value?.value as string,
                  form.solver_for,
                );
              }
            }}
            options={projectOptions}
            value={form.project}
            helperText={formErrors?.project}
            onFocus={() => {
              clearSelectErrorOnFocus("project");
            }}
            disabled={loading}
          />
        )}

        {(form.project !== null ||
          (form.solver_for === "DEALFEE" && form.deal !== null)) && (
          <AutocompleteField
            label={solverForFieldName}
            name="solver_for_field_object_id"
            onChange={handleSingleAutoCompleteChange}
            options={solverForFieldOptions}
            value={String(form.solver_for_field_object_id) || ""}
            helperText={formErrors?.solver_for_field_object_id}
            onFocus={() => {
              clearSelectErrorOnFocus("solver_for_field_object_id");
            }}
            disabled={loading}
          />
        )}

        <SelectInput
          label="Goal Metric"
          selected={form.goal_type}
          items={SOLVER_GOAL_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("goal_type")}
          onChange={(e) => handleSelectInputChange(e, "goal_type")}
          error={Boolean(formErrors?.goal_type)}
          helperText={formErrors?.goal_type}
          disabled={loading}
        />

        <TextInput
          classes={{ root: styles.classes.input }}
          label="Goal Value"
          name="goal_value"
          value={form.goal_value}
          onChange={handleChange}
          error={Boolean(formErrors?.goal_value)}
          helperText={formErrors?.goal_value}
          disabled={loading}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </Box>
    </Modal>
  );
}
