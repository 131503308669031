import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { SelectChangeEvent } from "@mui/material/Select";
import { format, isValid } from "date-fns";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import DatePicker from "../date-picker";
import {
  IUpdateProjectTimingForm,
  IUpdateProjectTimingFormErrors,
  IProjectTiming,
  SetStateAction,
} from "../../interfaces";
import {
  PROJECT_TIMING_ADJUSTMENT_OPTIONS,
  PERIODICITY_OPTIONS,
  PROJECT_TIMING_HORIZON_OPTIONS,
  UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE,
} from "../../constants";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IUpdateProjectTimingFormErrors;
  setFormErrors: SetStateAction<IUpdateProjectTimingFormErrors | undefined>;
  form: IUpdateProjectTimingForm;
  setForm: SetStateAction<IUpdateProjectTimingForm>;
  onClose: () => void;
  onConfirm: (
    form: IUpdateProjectTimingForm,
  ) => Promise<IProjectTiming | undefined>;
}

export default function ProjectTimingFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (
      value === "-" ||
      parseInt(value) < 0 ||
      typeof parseInt(value) !== "number"
    ) {
      return;
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: value === "" ? null : value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => {
      if (field === "horizon" && e.target.value === "OS") {
        // Set COD and PIS to start date explicitely
        prev.cod = prev.start_date;
        prev.placed_in_service_date = prev.start_date;
      }
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const updatedForm = form;

    if (updatedForm.horizon === "OS") {
      updatedForm.mechanical_completion_date = "";
      updatedForm.substantial_completion_date = "";
      updatedForm.placed_in_service_date = "";
      updatedForm.permission_to_operate_date = "";
      updatedForm.ntp_date = "";
    }

    Object.keys(updatedForm).forEach((key) => {
      if (updatedForm[key as keyof IUpdateProjectTimingForm] === "") {
        updatedForm[key as keyof IUpdateProjectTimingForm] = null as never;
      }
    });

    // Set PIS and PTO to COD
    updatedForm.permission_to_operate_date = updatedForm.cod;
    updatedForm.placed_in_service_date = updatedForm.cod;

    const timing = await onConfirm(updatedForm);
    timing && handleOnClose();
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => {
        if (prev.horizon === "OS") {
          prev.cod = format(v, "M/d/yyyy");
          prev.placed_in_service_date = format(v, "M/d/yyyy");
        }
        return {
          ...prev,
          [field]: format(v, "M/d/yyyy"),
        };
      });
    }
  };

  const handleResetForm = () => {
    setForm(UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        {/* Start Timing section start */}
        <SelectInput
          required
          label="Project Evaluation Horizon"
          selected={form.horizon}
          items={PROJECT_TIMING_HORIZON_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("horizon")}
          onChange={(e) => handleSelectInputChange(e, "horizon")}
          error={Boolean(formErrors?.horizon)}
          helperText={formErrors?.horizon}
          disabled={loading}
          fullWidth={false}
        />
        {/* Start Timing section start */}

        {/* Development Timing section start */}
        {form.horizon === "DS" ? (
          <>
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Development
            </Divider>
            <DatePicker
              label="Development Start Date (BOL)"
              value={form.start_date ? new Date(form.start_date) : null}
              onChange={(v) => handleDateChange(v, "start_date")}
              onOpen={() => clearNonTextFieldErrorOnFocus("start_date")}
              error={
                formErrors &&
                Boolean(
                  formErrors["start_date" as keyof IUpdateProjectTimingForm],
                )
              }
              helperText={
                formErrors &&
                formErrors["start_date" as keyof IUpdateProjectTimingForm]
              }
              disabled={loading}
              tooltip="<b>Beginning of Life.</b> Start of development spending.​"
              fullWidth={false}
            />
          </>
        ) : null}
        {/* Development Timing section start */}

        {/* Construction Timing section start */}
        {form.horizon === "CS" || form.horizon === "DS" ? (
          <>
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Construction
            </Divider>
            <DatePicker
              label="Notice to Proceed Date (NTP)"
              name="ntp_date"
              value={form.ntp_date ? new Date(form.ntp_date) : null}
              onChange={(v) => handleDateChange(v, "ntp_date")}
              onOpen={() => clearNonTextFieldErrorOnFocus("ntp_date")}
              error={Boolean(formErrors?.ntp_date)}
              helperText={formErrors?.ntp_date}
              disabled={loading}
              tooltip="<b>Notice to Proceed.</b> All permits received to commence construction."
              fullWidth={false}
            />
            <DatePicker
              label="Mechanical Completion Date (MC)"
              name="mechanical_completion_date"
              value={
                form.mechanical_completion_date
                  ? new Date(form.mechanical_completion_date)
                  : null
              }
              onChange={(v) =>
                handleDateChange(v, "mechanical_completion_date")
              }
              onOpen={() =>
                clearNonTextFieldErrorOnFocus("mechanical_completion_date")
              }
              error={Boolean(formErrors?.mechanical_completion_date)}
              helperText={formErrors?.mechanical_completion_date}
              disabled={loading}
              tooltip="Completion of construction, but prior to energization."
              fullWidth={false}
            />
            <DatePicker
              label="Substantial Completion Date (SC)"
              name="substantial_completion_date"
              value={
                form.substantial_completion_date
                  ? new Date(form.substantial_completion_date)
                  : null
              }
              onChange={(v) =>
                handleDateChange(v, "substantial_completion_date")
              }
              onOpen={() =>
                clearNonTextFieldErrorOnFocus("substantial_completion_date")
              }
              error={Boolean(formErrors?.substantial_completion_date)}
              helperText={formErrors?.substantial_completion_date}
              disabled={loading}
              tooltip="Completion of construction and all substantial testing."
              fullWidth={false}
            />
            <DatePicker
              label="Placed in Service Date (PIS)"
              value={form.cod ? new Date(form.cod) : null}
              onChange={(v) => handleDateChange(v, "cod")}
              onOpen={() => clearNonTextFieldErrorOnFocus("cod")}
              error={Boolean(formErrors?.cod)}
              helperText={formErrors?.cod}
              disabled
              tooltip="Date of in service for IRS/tax purposes."
              fullWidth={false}
            />
            <DatePicker
              label="Permission to Operate Date (PTO)"
              value={form.cod ? new Date(form.cod) : null}
              onChange={(v) => handleDateChange(v, "cod")}
              onOpen={() => clearNonTextFieldErrorOnFocus("cod")}
              error={Boolean(formErrors?.cod)}
              helperText={formErrors?.cod}
              disabled
              tooltip="Receipt of authorization to interconnect to the grid."
              fullWidth={false}
            />
          </>
        ) : null}
        {/* Construction Timing section end */}

        {/* Operation Timing section start */}
        <>
          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Operations
          </Divider>
          <DatePicker
            label="Commercial Operations Date (COD)"
            value={form.cod ? new Date(form.cod) : null}
            onChange={(v) => handleDateChange(v, "cod")}
            onOpen={() => clearNonTextFieldErrorOnFocus("cod")}
            error={Boolean(formErrors?.cod)}
            helperText={formErrors?.cod}
            disabled={loading}
            tooltip="<b>Commercial Operation Date.</b> Date that regular operations begins."
            fullWidth={false}
          />
          <TextInput
            required
            isNumeric
            label="Project Operating Life"
            name="operating_life_years"
            endAdornment={<>Yrs</>}
            value={form.operating_life_years || ""}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.operating_life_years)}
            helperText={formErrors?.operating_life_years}
            disabled={loading}
            fullWidth={false}
          />
        </>
        {/* Operation Timing section end */}
      </Box>
    </Modal>
  );
}
