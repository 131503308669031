import { IIconProps } from "../../interfaces";

export default function SolversIcon({ fillColor }: IIconProps): JSX.Element {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 1.75V3H10.5V1.75C10.5 1.625 10.375 1.5 10.25 1.5H5.75C5.59375 1.5 5.5 1.625 5.5 1.75ZM4 3V1.75C4 0.8125 4.78125 0 5.75 0H10.25C11.1875 0 12 0.8125 12 1.75V3H12.875C13.25 3 13.6562 3.1875 13.9375 3.46875L15.5312 5.0625C15.8125 5.34375 16 5.75 16 6.125V8.5H12V8C12 7.46875 11.5312 7 11 7C10.4375 7 10 7.46875 10 8V8.5H6V8C6 7.46875 5.53125 7 5 7C4.4375 7 4 7.46875 4 8V8.5H0V6.125C0 5.75 0.15625 5.34375 0.4375 5.0625L2.0625 3.46875C2.34375 3.1875 2.71875 3 3.09375 3H4ZM0 12V9.5H4V10C4 10.5625 4.4375 11 5 11C5.53125 11 6 10.5625 6 10V9.5H10V10C10 10.5625 10.4375 11 11 11C11.5312 11 12 10.5625 12 10V9.5H16V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12Z"
        fill={fillColor ?? "black"}
      />
    </svg>
  );
}
