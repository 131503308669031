import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { groupBy } from "lodash";
import { useLocation } from "react-router-dom";

import Button from "../../../../components/button";
import TextInput from "../../../../components/text-input";
import LogsWrapper from "../../../../components/logs-wrapper";
import ProjectCostTable from "./project-cost-table";
import Logs from "../../../../components/logs";
import LogsButton from "../../../../components/logs-button";
import ConditionalProtect from "../../../../components/conditional-protect";
import { useDrawer, useLogs } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";
import { PROJECT_COST_TYPE } from "../../../../constants";
import { ILogsConfiguration, IProjectCostItem } from "../../../../interfaces";

interface IProps {
  projectUuid: string;
  projectCostId: string;
  costItems: IProjectCostItem[];
  viewType: string;
  handleUpdate: () => void;
  handleOpenAddCostModal: () => void;
  withPadding?: boolean;
  title?: string;
}

export default function ProjectCosts({
  projectCostId,
  projectUuid,
  costItems,
  viewType,
  handleUpdate,
  handleOpenAddCostModal,
  withPadding,
  title,
}: IProps) {
  const [searchString, setSearchString] = React.useState("");
  const location = useLocation();
  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const isCasePage = location.pathname.includes("case");

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const getTotalCost = (type: keyof typeof PROJECT_COST_TYPE) => {
    return (
      costItems
        .filter((r) => r.cost_type === type)
        .reduce((acc, item) => {
          return acc + item.cost_in_dollars;
        }, 0) || 0
    );
  };

  const groupedData = React.useMemo(() => {
    if (!searchString) {
      return groupCostsByType(costItems); // No filtering if searchString is empty
    }
    // Filter based on searchString
    const filteredItems = groupCostsByType(
      costItems.filter((item) => {
        const lowerCaseItemName = JSON.stringify(item.name).toLowerCase(); // Convert item to lowercase string
        return lowerCaseItemName.includes(searchString.toLowerCase());
      }),
    );

    return filteredItems;
  }, [costItems, searchString]);

  // Filter based on searchString
  const filteredRows = React.useMemo(() => {
    return costItems.filter((item) =>
      item.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [costItems, searchString]);

  function groupCostsByType(costItems: IProjectCostItem[] | undefined) {
    if (!costItems) return {};
    return groupBy(costItems, "cost_type");
  }

  const totalCosts = React.useMemo(() => {
    return costItems.reduce((acc, item) => acc + item.cost_in_dollars, 0);
  }, [costItems]);

  const projectLogConfiguration: ILogsConfiguration = {
    id: projectCostId,
    type: "projectcost",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(projectLogConfiguration.type, projectLogConfiguration.id);
  };

  return (
    <Box>
      <Box
        className={cn(
          `flex justify-between items-start ${withPadding && "p-2"}`,
        )}
      >
        <Box className={cn("flex flex-col gap-2")}>
          {title && (
            <Typography className={cn("!font-bold")}>{title}</Typography>
          )}
          <TextInput
            label="Search"
            placeholder="Name"
            onChange={onSearchStringChange}
            value={searchString}
          />
        </Box>

        <div>
          <LogsButton onClick={handleOnOpenLogs} />

          <ConditionalProtect type={isCasePage ? "deal" : "project"}>
            <Button
              btnType="primary"
              label="Add Cost"
              onClick={handleOpenAddCostModal}
              startIcon={<AddIcon />}
            />
          </ConditionalProtect>
        </div>
      </Box>
      {costItems && viewType === "single" && (
        <Box className={cn("grid gap-4 my-2")}>
          <Paper>
            <ProjectCostTable
              id="DEV"
              title="Development Cost"
              rows={groupedData["DEV"] || []}
              projectUuid={projectUuid}
              projectCostId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("DEV")}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              id="ACQ"
              title="Acquisition Cost"
              rows={groupedData["ACQ"] || []}
              projectUuid={projectUuid}
              projectCostId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("ACQ")}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              id="INT"
              title="Interconnection Cost"
              rows={groupedData["INT"] || []}
              projectUuid={projectUuid}
              projectCostId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("INT")}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              id="BLD"
              title="Build Cost"
              rows={groupedData["BLD"] || []}
              projectUuid={projectUuid}
              projectCostId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("BLD")}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              id="TRN"
              title="Transaction Cost"
              rows={groupedData["TRN"] || []}
              projectUuid={projectUuid}
              projectCostId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("TRN")}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              id="LND"
              title="Land Cost"
              rows={groupedData["LND"] || []}
              projectUuid={projectUuid}
              projectCostId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("LND")}
            />
          </Paper>
          <Paper>
            <ProjectCostTable
              id="OTH"
              title="Other Cost"
              rows={groupedData["OTH"] || []}
              projectUuid={projectUuid}
              projectCostId={projectCostId.toString()}
              onUpdate={handleUpdate}
              totalCost={getTotalCost("OTH")}
            />
          </Paper>
        </Box>
      )}
      {costItems && viewType === "all" && (
        <ProjectCostTable
          id="all"
          title="Costs"
          rows={filteredRows}
          projectUuid={projectUuid}
          projectCostId={projectCostId.toString()}
          onUpdate={handleUpdate}
          totalCost={totalCosts}
          disableExpand
          disableTitle
          disableNavigation
        />
      )}

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectLogConfiguration.id}
        />
      </LogsWrapper>
    </Box>
  );
}
