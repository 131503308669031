import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { cloneDeep } from "lodash";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import useStyles from "./styles";
import ThemeContext from "../../utils/context/theme-context";
import { useAppSelector, useLocalStorage } from "../../utils/hooks";
import { filterSidebarLinks } from "../../utils/helpers";
import { PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION } from "../../constants";
import { FloatingNavItems, PathToABC } from "./common";
import {
  IMenu,
  ISidebarLink,
  IPersonalAccountNavBackButtonId,
} from "../../interfaces";

interface IProps {
  mobileSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const sidebarWidth = 220;

const backNavigationlinksAndText = [
  {
    name: "Deal Share List",
    whenOn: "/shared-deal/",
    goTo: "/personal/shared-deals",
  },
  {
    name: "Case List",
    whenOn: "/personal/deal/",
    goTo: "/personal/shared-deal/:dealShareId/cases",
  },
  {
    name: "Case",
    whenOn: "/personal/project/",
    goTo: "/personal/deal/:dealId/general",
  },
];

export default function PersonalAccountSidebar({
  mobileSidebarOpen,
  toggleSidebar,
}: IProps): JSX.Element {
  const styles = useStyles({ sidebarWidth });

  const navigate = useNavigate();
  const { pathname: urlPath } = useLocation();
  const { dealUuid, projectUuid, collaborationUuid } = useParams();

  const { currentDeal, currentDealTermDebt } = useAppSelector((s) => s.deal);
  const { ctrlPressed } = useAppSelector((s) => s.common);

  const { darkMode } = React.useContext(ThemeContext);

  const [personalAccountNavBackButtonIds, _] = useLocalStorage<
    IPersonalAccountNavBackButtonId[]
  >("personal-account-nav-back-btn", []);

  const [floatingNavAnchorEl, setFloatingNavAnchorEl] =
    React.useState<null | IMenu>(null);

  const filteredSidebarLinks: ISidebarLink[] = React.useMemo(() => {
    let navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION;
    if (urlPath.includes("/shared-deal/")) {
      navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION.filter(
        (n) => n.label === "Collaboration",
      );
    }
    if (urlPath.includes("/project/")) {
      navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION.filter(
        (n) => n.label === "Project",
      );
    }
    if (urlPath.includes("/deal/")) {
      navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION.filter(
        (n) => n.label === "Case",
      );
    }
    if (currentDeal) {
      return filterSidebarLinks(
        cloneDeep(navLinks),
        {
          structure: currentDeal.structure,
          tax_credit_structure: currentDeal.tax_credit_structure,
          debt: currentDealTermDebt ? "1" : "",
          is_construction_debt_added: currentDeal.is_construction_debt_added,
        },
        currentDeal,
      );
    }
    return navLinks;
  }, [currentDeal, currentDealTermDebt]);

  const replaceIds = (path: string) => {
    return path
      .replace(":dealUuid", String(dealUuid))
      .replace(":projectUuid", String(projectUuid))
      .replace(":collaborationUuid", String(collaborationUuid));
  };

  const onLinkClick = (
    e: React.MouseEvent<HTMLElement>,
    page: ISidebarLink,
    type: "base" | "sub",
  ) => {
    e.stopPropagation();
    const { path } = page;
    let naviagteToUrl = replaceIds(path);

    if (type === "sub") {
      const pathToUse = page.subLinks?.length ? page.subLinks[0].path : path;
      naviagteToUrl = replaceIds(pathToUse);
    }

    if (ctrlPressed) {
      window.open(naviagteToUrl, "_blank");
      return;
    }

    navigate(naviagteToUrl);
  };

  const isOnLink = (
    link: ISidebarLink,
    urlSubStr: string,
    label: string,
    type: "base" | "sub",
  ) => {
    return (
      urlPath.includes(urlSubStr) && link.label === label && type === "base"
    );
  };

  const isLinkActive = (link: ISidebarLink, type: "base" | "sub") => {
    let updatedPath = link.path;
    let pathToMatch = link.pathToMatch || "";

    updatedPath = replaceIds(updatedPath);
    pathToMatch = replaceIds(pathToMatch);

    if (
      isOnLink(link, "/deal/", "Case", type) ||
      isOnLink(link, "/project/", "Project", type) ||
      isOnLink(link, "/shared-deal/", "Collaboration", type)
    ) {
      return true;
    }

    const pathToUse = link.pathToMatch ? pathToMatch : updatedPath;
    return Boolean(urlPath.includes(pathToUse));
  };

  const subLinksToKeepOpen = () => {
    if (urlPath.includes("/shared-deal/")) {
      return "Collaboration";
    } else if (urlPath.includes("/deal/")) {
      return "Case";
    } else if (urlPath.includes("/project/")) {
      return "Project";
    } else {
      return "";
    }
  };

  const onHoverOfSubLink = (
    e: React.MouseEvent<HTMLElement>,
    link: ISidebarLink,
    idx: number,
  ) => {
    if (link.subLinks?.length) {
      setFloatingNavAnchorEl({ id: idx, element: e.currentTarget });
    }
  };

  const onMouseOutOfFloatingLinks = () => {
    setFloatingNavAnchorEl(null);
  };

  const renderBackButton = () => {
    const linksAndTextObj = backNavigationlinksAndText.find((l) =>
      urlPath.includes(l.whenOn),
    );

    let pathToUse = linksAndTextObj?.goTo || "";
    let disableBackButton = false;

    if (linksAndTextObj?.name === "Case List") {
      const collabId = personalAccountNavBackButtonIds.find((n) =>
        n.deals.find((d) => d.uuid === dealUuid),
      )?.collaborationId;

      if (!collabId) {
        disableBackButton = true;
      }

      pathToUse = linksAndTextObj.goTo.replace(":dealShareId", collabId || "");
    }
    if (linksAndTextObj?.name === "Case") {
      let dealIdForPath;
      personalAccountNavBackButtonIds.forEach((n) =>
        n.deals.forEach((d) => {
          if (d.projectUuids.includes(String(projectUuid))) {
            dealIdForPath = d.uuid;
          }
        }),
      );

      if (!dealIdForPath) {
        disableBackButton = true;
      }

      pathToUse = linksAndTextObj.goTo.replace(":dealId", dealIdForPath || "");
    }

    return (
      <ListItemButton
        disabled={disableBackButton}
        onClick={() => navigate(pathToUse)}
      >
        <ChevronLeftIcon /> <ListItemText primary={linksAndTextObj?.name} />
      </ListItemButton>
    );
  };

  const renderDrawer = (type: "mobile" | "desktop") => (
    <>
      <List classes={{ root: styles.classes.linkList }}>
        {filteredSidebarLinks.map((page, idx) => {
          const selected = isLinkActive(page, "base");
          const { icon: Icon } = page;

          return (
            <React.Fragment key={idx}>
              {renderBackButton()}
              <ListItemButton
                selected={selected}
                data-pw={`${type}-${page.label}`}
                onClick={(e) => onLinkClick(e, page, "base")}
                classes={{
                  selected: styles.classes.selectedOption,
                  root: styles.classes.listItemRoot,
                }}
              >
                {Icon && (
                  <div className="w-8">
                    <Icon
                      fillColor={selected || darkMode ? "white" : "black"}
                    />
                  </div>
                )}
                <ListItemText primary={page.label} />
              </ListItemButton>

              {subLinksToKeepOpen() === page.label ? (
                <List>
                  {urlPath.includes("/deal/") &&
                  (!currentDeal || currentDeal.uuid !== dealUuid)
                    ? new Array(4)
                        .fill(0)
                        .map((_, idx) => <Skeleton key={idx} height={30} />)
                    : page.subLinks?.map((subPage, subIdx) => {
                        const selectedSubPage = isLinkActive(subPage, "sub");

                        return (
                          <Box
                            key={subIdx}
                            onMouseLeave={onMouseOutOfFloatingLinks}
                          >
                            <ListItemButton
                              selected={selectedSubPage}
                              data-pw={`${type}-${page.label}-${subPage.label}`}
                              onClick={(e) => onLinkClick(e, subPage, "sub")}
                              classes={{
                                selected: styles.classes.activesubLink,
                                root: styles.classes.subLink,
                              }}
                              onMouseEnter={(e) =>
                                onHoverOfSubLink(e, subPage, subIdx)
                              }
                            >
                              <ListItemText primary={subPage.label} />
                              {subPage.subLinks?.length && <ArrowRightIcon />}
                            </ListItemButton>
                            <FloatingNavItems
                              idx={subIdx}
                              links={subPage.subLinks}
                              onLinkClick={onLinkClick}
                              floatingNavAnchorEl={floatingNavAnchorEl}
                              setFloatingNavAnchorEl={setFloatingNavAnchorEl}
                            />
                          </Box>
                        );
                      })}
                </List>
              ) : null}
            </React.Fragment>
          );
        })}
      </List>

      <PathToABC />
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileSidebarOpen}
        onClose={toggleSidebar}
        component="nav"
        classes={{ paper: styles.classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
        sx={{
          width: { md: sidebarWidth },
          flexShrink: { md: 0 },
          display: { sm: "block", md: "none" },
        }}
      >
        {renderDrawer("mobile")}
      </Drawer>

      <Drawer
        open
        variant="permanent"
        component="nav"
        classes={{ paper: styles.classes.drawerPaper }}
        sx={{
          width: { md: sidebarWidth },
          flexShrink: { md: 0 },
          display: { xs: "none", sm: "none", md: "block" },
          height: "calc(100vh - 68px)",
          ".MuiPaper-root": {
            top: 68,
          },
        }}
      >
        {renderDrawer("desktop")}
      </Drawer>
    </>
  );
}
