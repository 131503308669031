import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";

import IconButton from "../../../../components/icon-button";
import ViewWrapper from "../../../../components/view-wrapper";
import SummaryTable from "../../../../components/summary-table";
import ReportTableV2 from "../../../../components/report-table-v2";
import ConditionalProtect from "../../../../components/conditional-protect";
import { IReportTable, IsummaryTablesObject } from "../../../../interfaces";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";

interface IProps {
  getProjectTaxDashboardSummary: (
    projectUuid: string,
  ) => Promise<IsummaryTablesObject>;
  downloadProjectTaxDashboardSummary: (projectUuid: string) => Promise<void>;
  getProjectTaxCreditPTC_Report: (projectUuid: string) => Promise<IReportTable>;
  downloadProjectTaxCreditPTC_Report: (projectUuid: string) => Promise<void>;
}

export default function ProjectTaxDashboardView({
  getProjectTaxDashboardSummary,
  downloadProjectTaxDashboardSummary,
  downloadProjectTaxCreditPTC_Report,
  getProjectTaxCreditPTC_Report,
}: IProps): JSX.Element {
  const { projectUuid } = useParams();

  const { currentProject } = useAppSelector((s) => s.project);

  React.useEffect(() => {
    getProjectTaxDashboardSummaryCallAPI(projectUuid);
  }, [projectUuid]);

  React.useEffect(() => {
    if (currentProject?.tax_credit_type === "PTC") {
      getProjectTaxCreditPTC_ReportCallAPI(projectUuid);
    }
  }, [currentProject, projectUuid]);

  const {
    callAPI: getProjectTaxDashboardSummaryCallAPI,
    errored: getProjectTaxDashboardSummaryErrored,
    loading: loadingProjectTaxDashboardSummary,
    response: projectTaxDashboardSummary,
  } = useAPI(
    (projectUuid: string) => getProjectTaxDashboardSummary(projectUuid),
    { initialLoading: true },
  );

  const { callAPI: downloadProjectTaxDashboardSummaryCallAPI } = useAPI(
    (projectUuid: string) => downloadProjectTaxDashboardSummary(projectUuid),
  );

  const {
    callAPI: getProjectTaxCreditPTC_ReportCallAPI,
    errored: getProjectTaxCreditPTC_ReportFailed,
    loading: loadingProjectTaxCreditPTC,
    response: taxCreditPTC_Report,
  } = useAPI((projectUuid: string) =>
    getProjectTaxCreditPTC_Report(projectUuid),
  );

  const { callAPI: downloadProjectTaxCreditCallAPI } = useAPI(() =>
    downloadProjectTaxCreditPTC_Report(String(projectUuid)),
  );

  const onDownloadClick = async () => {
    await downloadProjectTaxDashboardSummaryCallAPI(projectUuid);
  };

  return (
    <Box>
      <ViewWrapper
        loading={loadingProjectTaxDashboardSummary}
        error={getProjectTaxDashboardSummaryErrored}
      >
        <Box>
          <Box className={cn("flex justify-between")}>
            <Typography fontSize="15" padding={1} fontWeight="bold">
              {projectTaxDashboardSummary?.data.report_title}
            </Typography>
            <ConditionalProtect type="project">
              <IconButton onClick={onDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </ConditionalProtect>
          </Box>
          <Box className={cn("grid grid-cols-2 gap-2")}>
            {projectTaxDashboardSummary?.data.tables.map((table, idx) => {
              return (
                <SummaryTable key={idx} usedIn="project" summaryTable={table} />
              );
            })}
          </Box>
        </Box>
      </ViewWrapper>

      <ViewWrapper
        loading={loadingProjectTaxCreditPTC}
        error={getProjectTaxCreditPTC_ReportFailed}
      >
        {taxCreditPTC_Report ? (
          <Box className={cn("mt-4")}>
            <ReportTableV2
              data={taxCreditPTC_Report}
              download={downloadProjectTaxCreditCallAPI}
            />
          </Box>
        ) : null}
      </ViewWrapper>
    </Box>
  );
}
