import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES, REPORT_API_ROUTES } from "../../constants";
import {
  IDealSaleLeaseback,
  IDealSaleLeasebackList,
  IDealSaleLeasebackSizingForm,
  IOutputLessorLesseeSummary,
} from "../../interfaces";

export const getDealSaleLeaseback = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.dealSaleLeaseback.get, {
    dealUuid,
  });
  return await AxiosHelper.get<IDealSaleLeasebackList[]>(url);
};

export const editDealSaleLeaseback = async (
  dealUuid: string,
  id: number,
  form: IDealSaleLeasebackSizingForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealSaleLeaseback.edit, {
    dealUuid,
    id,
  });
  return await AxiosHelper.put<
    IDealSaleLeasebackSizingForm,
    IDealSaleLeaseback
  >(url, form);
};

export const getDealSaleLeasebackDetails = async (
  dealUuid: string,
  id: number,
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealSaleLeaseback.details, {
    dealUuid,
    id,
  });
  return await AxiosHelper.get<IDealSaleLeaseback>(url);
};

export const getOutputLessorSummary = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getOutputLessorSummary, {
    dealUuid,
  });
  return await AxiosHelper.get<IOutputLessorLesseeSummary>(url);
};

export const getOutputLesseeSummary = async (dealUuid: string) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getOutputLesseeSummary, {
    dealUuid,
  });
  return await AxiosHelper.get<IOutputLessorLesseeSummary>(url);
};
