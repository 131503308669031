import React from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import AutocompleteField from "../autocomplete-field";
import { CREATION_DEAL_STATUS_OPTIONS } from "../../constants";
import {
  ISelectOption,
  ICollaboration,
  ICollaborationForm,
  ICollaborationFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: ICollaborationFormErrors;
  setFormErrors: React.Dispatch<
    React.SetStateAction<ICollaborationFormErrors | undefined>
  >;
  form: ICollaborationForm;
  setForm: React.Dispatch<React.SetStateAction<ICollaborationForm>>;
  onClose: () => void;
  onConfirm: (form: ICollaborationForm) => Promise<ICollaboration | undefined>;
  dealOptions: ISelectOption[];
  hideFields?: (keyof ICollaborationForm)[];
}

export default function CollaborationFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  dealOptions,
  hideFields = [],
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [name]: value?.value,
    }));
  };

  const handleOnConfirm = async () => {
    const collaboration = await onConfirm(form);
    collaboration && onClose();
  };

  const handleResetForm = () => {
    setForm({
      name: null,
      deal: null,
      description: null,
      status: "ACT",
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="sm"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        {!hideFields.includes("deal") && (
          <AutocompleteField
            label="Select Deal"
            name="deal"
            onChange={handleSingleAutoCompleteChange}
            options={dealOptions}
            value={String(form.deal) || ""}
            helperText={formErrors?.deal}
            onFocus={() => {
              clearSelectErrorOnFocus("deal");
            }}
            disabled={loading}
          />
        )}
        {!hideFields.includes("name") && (
          <TextInput
            required
            label="Collaboration Name"
            name="name"
            value={form.name || ""}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
          />
        )}
        {!hideFields.includes("description") && (
          <TextInput
            multiline
            rows={4}
            label="Description"
            name="description"
            value={form.description || ""}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.description)}
            helperText={formErrors?.description}
            disabled={loading}
            classes={{ root: "[&_textarea]:!p-0" }}
          />
        )}
        {!hideFields.includes("status") && (
          <SelectInput
            required
            label="Status"
            selected={form.status || ""}
            items={CREATION_DEAL_STATUS_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("status")}
            onChange={(e) => handleSelectInputChange(e, "status")}
            error={Boolean(formErrors?.status)}
            helperText={formErrors?.status}
            disabled={loading}
          />
        )}
      </Box>
    </Modal>
  );
}
