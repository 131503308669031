import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../components/view-wrapper";
import SummaryTable from "../../../../components/summary-table";
import { IsummaryTablesObject } from "../../../../interfaces";
import { useAPI } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";

interface IProps {
  getDealFMV_Summary: (dealUuid: string) => Promise<IsummaryTablesObject>;
}

export default function DealFMV_View({
  getDealFMV_Summary,
}: IProps): JSX.Element {
  const { organization } = useOrganization();

  const { dealUuid, caseDealUuid } = useParams();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const {
    callAPI: getDealFMV_SummaryCallAPI,
    loading: loadingSummary,
    errored: getSummaryFailed,
    response: summary,
  } = useAPI((dealUuid: string) => getDealFMV_Summary(dealUuid), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getDealFMV_SummaryCallAPI(String(dealIdToUse));
  }, [dealIdToUse]);

  return (
    <ViewWrapper loading={loadingSummary} error={getSummaryFailed}>
      <Box className={cn("mt-1")}>
        <SummaryTable usedIn="deal" summaryTable={summary?.data.tables[0]} />
      </Box>
    </ViewWrapper>
  );
}
