import { PROJECT_API_ROUTES, PERIODICITY } from "../../constants";
import {
  IProject,
  IProjectDetails,
  IProjectDetailsFat,
  IGetProjectsParams,
  ServerPaginatedResponse,
  IProjectForm,
  IAddUpdateProjectResponse,
  IDuplicateProjectForm,
  IDuplicateProjectResponse,
  IProjectGeneralSummary,
  IProjectLocation,
} from "../../interfaces";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getProjectGeneralSummary = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.getGeneralSummary, {
    projectUuid,
  });

  return await AxiosHelper.get<IProjectGeneralSummary>(url);
};

export const addProject = async (form: IProjectForm) => {
  return await AxiosHelper.post<IProjectForm, IAddUpdateProjectResponse>(
    PROJECT_API_ROUTES.base.create,
    {
      ...form,
    },
  );
};

export const getProjectsOfDeal = async (dealUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.getProjectOfDeal, {
    dealUuid,
  });
  return await AxiosHelper.get<IProject[]>(url);
};

export const getProjects = async (params: IGetProjectsParams) => {
  const {
    limit,
    state,
    sub_stage,
    energy_type,
    cod,
    tax_credit__credit_type,
    power_market,
  } = params;

  const queryParams = {
    limit,
    state__in: state,
    sub_stage__in: sub_stage,
    energy_type__in: energy_type,
    cod__in: cod,
    tax_credit__credit_type__in: tax_credit__credit_type,
    power_market__in: power_market,
  };

  return await AxiosHelper.get<ServerPaginatedResponse<IProject[]>>(
    PROJECT_API_ROUTES.base.get,
    {
      params: queryParams,
    },
  );
};

export const getProjectDetails = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.getProjectDetails, {
    projectUuid,
  });
  return await AxiosHelper.get<IProjectDetails>(url);
};

export const updateProject = async (
  projectUuid: string,
  form: IProjectForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.update, { projectUuid });
  return await AxiosHelper.patch<IProjectForm, IAddUpdateProjectResponse>(
    url,
    form,
  );
};

export const deleteProject = async (projectUuid: string | undefined) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.delete, { projectUuid });
  return await AxiosHelper.delete(url);
};

export const duplicateProject = async (
  projectUuid: string,
  form: IDuplicateProjectForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.duplicate, { projectUuid });
  return await AxiosHelper.post<
    IDuplicateProjectForm,
    IDuplicateProjectResponse
  >(url, form);
};

export const getProjectDetailsFat = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.getDetailsFat, {
    projectUuid,
  });
  return await AxiosHelper.get<IProjectDetailsFat>(url);
};

export const downloadProjectModel = async (
  projectUuid: string,
  periodicity: keyof typeof PERIODICITY,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.downloadProjectModel, {
    projectUuid,
    periodicity,
  });
  return await AxiosHelper.downloadFile(url);
};

export const getLocations = async (
  status: string = "ACT",
  uuids: string = "",
  filterParams: Record<string, string> = {},
) => {
  let params: Record<string, string> = { status };
  if (uuids) {
    params.uuids = uuids;
  }

  if (Object.keys(filterParams).length > 0) {
    // Add __in to all filterParams keys
    const modifiedFilterParams = Object.entries(filterParams).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [`${key}__in`]: value,
      }),
      {},
    );
    params = {
      ...params,
      ...modifiedFilterParams,
    };
  }

  return await AxiosHelper.get<IProjectLocation[]>(
    PROJECT_API_ROUTES.base.getLocations,
    {
      params,
    },
  );
};
