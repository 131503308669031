import React from "react";
import Box from "@mui/material/Box";
import { useOrganization } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";

import SummaryTable from "../../../../../components/summary-table";
import ViewWrapper from "../../../../../components/view-wrapper";
import { IsummaryTablesObject } from "../../../../../interfaces";
import { useAPI } from "../../../../../utils/hooks";
import { cn } from "../../../../../utils/helpers";

interface IProps {
  getDealProformaSummary: (dealUuid: string) => Promise<IsummaryTablesObject>;
}

export default function DealProformaSummaryView({
  getDealProformaSummary,
}: IProps): JSX.Element {
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const {
    callAPI: getDealProformaSummaryCallAPI,
    loading: loadingDealProformaSummary,
    errored: getDealProformaSummaryFailed,
    response: proformaSummary,
  } = useAPI((dealUuid) => getDealProformaSummary(dealUuid), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getDealProformaSummaryCallAPI(String(dealIdToUse));
  }, [dealIdToUse]);

  return (
    <ViewWrapper
      loading={loadingDealProformaSummary}
      error={getDealProformaSummaryFailed}
    >
      <Box className={cn("mt-4")}>
        <SummaryTable
          usedIn="deal"
          summaryTable={proformaSummary?.data.tables[0]}
        />
      </Box>
    </ViewWrapper>
  );
}
