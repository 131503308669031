import { getDealCases } from "../../apis/deal/case";
import { getSolvers, addSolver } from "../../apis/solvers/base";
import SolversView from "../../views/solvers";
import { getProjectsOfDeal } from "../../apis/project/base";
import { getProjectRevenueContracts } from "../../apis/project/revenue-contracts";
import Layout from "../../components/layout";
import { getDeals } from "../../apis/deal/base";

export default function Solvers(): JSX.Element {
  return (
    <Layout title="Solvers">
      <SolversView
        getSolvers={getSolvers}
        addSolver={addSolver}
        getDealCases={getDealCases}
        getProjectsOfDeal={getProjectsOfDeal}
        getProjectRevenueContracts={getProjectRevenueContracts}
        getDeals={getDeals}
      />
    </Layout>
  );
}
