/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  TooltipItem,
  ChartEvent,
  ActiveElement,
  DoughnutController,
  ArcElement,
  ChartOptions,
} from "chart.js";

import { useAppSelector, useScreenWidth } from "../../../utils/hooks";
import {
  generateColorGradient,
  getKeyFromEnumObject,
} from "../../../utils/helpers";
import {
  DASHBOARD_MARKETS,
  DASHBOARD_PROJECT_TYPES,
  MARKETS,
  PROJECT_TAX_CREDIT_TYPE,
  PROJECT_ENERGY_TYPES,
  US_STATES,
} from "../../../constants";
import {
  IPowerMarket,
  IState,
  ITaxCreditType,
  ITechnologyType,
} from "../../../interfaces";

ChartJS.register(DoughnutController, ArcElement);

type ChartType = "technologyType" | "state" | "powerMarket" | "taxCreditType";

interface IProps {
  technologyTypeData: ITechnologyType[];
  stateData: IState[];
  powerMarketData: IPowerMarket[];
  taxCreditTypeData: ITaxCreditType[];
  type: ChartType;
}

const commonChartOptions: ChartOptions = {
  maintainAspectRatio: false,
  devicePixelRatio: 4,
  responsive: true,
  aspectRatio: 1.6,
  onHover: (e: ChartEvent, el: ActiveElement[]) => {
    const target = e?.native?.target as HTMLCanvasElement;
    if (target?.style) {
      if (el.length) {
        target.style.cursor = "pointer";
      } else {
        target.style.cursor = "default";
      }
    }
  },
};

export default function DashboardChart({
  technologyTypeData,
  stateData,
  powerMarketData,
  taxCreditTypeData,
  type,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const width = useScreenWidth();

  const { ctrlPressed } = useAppSelector((s) => s.common);

  const technologyTypeRef = React.useRef<HTMLCanvasElement | null>(null);
  const stateRef = React.useRef<HTMLCanvasElement | null>(null);
  const powerMarketRef = React.useRef<HTMLCanvasElement | null>(null);
  const taxCreditTypeRef = React.useRef<HTMLCanvasElement | null>(null);

  const datalabelFormatter = (_: unknown, ctx: Context) => {
    return ctx.chart?.data?.labels?.[ctx.dataIndex] === "No Data"
      ? ""
      : ctx.chart?.data?.labels?.[ctx.dataIndex];
  };

  const tooltipLabelGetter = (ctx: TooltipItem<"doughnut">) => {
    if (ctx.label === "No Data") return "";
    return ctx.parsed + " Projects";
  };

  React.useEffect(() => {
    const technologyType2DRef = technologyTypeRef?.current?.getContext("2d");

    const datasetValues = technologyTypeData.length
      ? technologyTypeData.map((item) => item.count)
      : [1];
    const datasetLabels = technologyTypeData.length
      ? technologyTypeData.map((item) => item.energy_type)
      : ["No Data"];

    const technologyTypeChartData = {
      labels: datasetLabels,
      datasets: [
        {
          label: "Count",
          data: datasetValues,
          backgroundColor: datasetLabels.includes("No Data")
            ? ["#EAEAEA"]
            : generateColorGradient(
                ["#5335FA", "#00B969", "#DDDDDD"],
                technologyTypeData?.length,
              ),
          borderWidth: 0,
        },
      ],
    };

    const technologyTypeChart =
      technologyType2DRef &&
      new ChartJS(technologyType2DRef as CanvasRenderingContext2D, {
        type: "doughnut",
        data: technologyTypeChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: datalabelFormatter,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelGetter,
                title: (tooltipItems) => {
                  const key = tooltipItems[0]?.label;
                  return PROJECT_ENERGY_TYPES[
                    key as keyof typeof PROJECT_ENERGY_TYPES
                  ];
                },
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                PROJECT_ENERGY_TYPES,
              );
              if (title) {
                const url = `/project-list?energy_type=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      technologyTypeChart?.destroy();
    };
  }, [width, technologyTypeData]);

  React.useEffect(() => {
    const stateData2dRef = stateRef?.current?.getContext("2d");
    const datasetLabels = stateData.length
      ? stateData?.map((item) => item.state)
      : ["No Data"];
    const datasetValues = stateData.length
      ? stateData?.map((item) => item.count)
      : [1];
    const stateChartData = {
      labels: datasetLabels,
      datasets: [
        {
          label: "Count",
          data: datasetValues,
          backgroundColor: datasetLabels.includes("No Data")
            ? ["#EAEAEA"]
            : generateColorGradient(
                ["#5335FA", "#00B969", "#DDDDDD"],
                technologyTypeData?.length,
              ),
          borderWidth: 0,
        },
      ],
    };

    const stateChart =
      stateData2dRef &&
      new ChartJS(stateData2dRef as CanvasRenderingContext2D, {
        type: "doughnut",
        data: stateChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: datalabelFormatter,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelGetter,
                title: (tooltipItems) => {
                  const label = tooltipItems[0]?.label;
                  return US_STATES[label as keyof typeof US_STATES];
                },
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                US_STATES,
              );
              if (title) {
                const url = `/project-list?state=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      stateChart?.destroy();
    };
  }, [width, stateData]);

  React.useEffect(() => {
    const powerMarket2Dref = powerMarketRef?.current?.getContext("2d");
    const datasetLabels = powerMarketData.length
      ? powerMarketData?.map((item) => {
          const value = MARKETS[item?.power_market as keyof typeof MARKETS];
          const key = getKeyFromEnumObject(value, DASHBOARD_MARKETS);
          return key == "NA" ? "N/A" : key;
        })
      : ["No Data"];
    const datasetValues = powerMarketData.length
      ? powerMarketData?.map((item) => item.count)
      : [1];
    const powerMarketChartData = {
      labels: datasetLabels,
      datasets: [
        {
          label: "Count",
          data: datasetValues,
          backgroundColor: datasetLabels.includes("No Data")
            ? ["#EAEAEA"]
            : generateColorGradient(
                ["#5335FA", "#00B969", "#DDDDDD"],
                technologyTypeData?.length,
              ),
          borderWidth: 0,
        },
      ],
    };

    const powerMarketChart =
      powerMarket2Dref &&
      new ChartJS(powerMarket2Dref as CanvasRenderingContext2D, {
        type: "doughnut",
        data: powerMarketChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: datalabelFormatter,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelGetter,
                title(tooltipItems) {
                  const key = tooltipItems[0]?.label;
                  return DASHBOARD_MARKETS[
                    key as keyof typeof DASHBOARD_MARKETS
                  ];
                },
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                MARKETS,
              );
              if (title) {
                const url = `/project-list?power_market=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      powerMarketChart?.destroy();
    };
  }, [width, powerMarketData]);

  React.useEffect(() => {
    const taxCreditTypeData2dRef = taxCreditTypeRef?.current?.getContext("2d");
    const datasetLabels = taxCreditTypeData.length
      ? taxCreditTypeData?.map(
          (item) =>
            PROJECT_TAX_CREDIT_TYPE[
              item?.tax_credit__credit_type as keyof typeof PROJECT_TAX_CREDIT_TYPE
            ],
        )
      : ["No Data"];
    const datasetValues = taxCreditTypeData.length
      ? taxCreditTypeData?.map((item) => item.count)
      : [1];

    const taxCreditTypeChartData = {
      labels: datasetLabels,
      datasets: [
        {
          label: "Count",
          data: datasetValues,
          backgroundColor: datasetLabels.includes("No Data")
            ? ["#EAEAEA"]
            : generateColorGradient(
                ["#5335FA", "#00B969", "#DDDDDD"],
                technologyTypeData?.length,
              ),
          borderWidth: 0,
        },
      ],
    };

    const taxCreditTypeChart =
      taxCreditTypeData2dRef &&
      new ChartJS(taxCreditTypeData2dRef as CanvasRenderingContext2D, {
        type: "doughnut",
        data: taxCreditTypeChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: datalabelFormatter,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: tooltipLabelGetter,
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                PROJECT_TAX_CREDIT_TYPE,
              );
              if (title) {
                const url = `/project-list?tax_credit__credit_type=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      taxCreditTypeChart?.destroy();
    };
  }, [width, taxCreditTypeData]);

  const selectedChart = (chart: ChartType) => {
    switch (chart) {
      case "technologyType":
        return technologyTypeRef;
      case "state":
        return stateRef;
      case "powerMarket":
        return powerMarketRef;
      case "taxCreditType":
        return taxCreditTypeRef;
      default:
        return undefined;
    }
  };

  return (
    <div>
      <canvas id={type} ref={selectedChart(type)} />
    </div>
  );
}
