import React from "react";
import { useOrganization } from "@clerk/clerk-react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Tabs from "../tabs";
import LoaderScreen from "../loader-screen";
import TabbedLayout from "../tabbed-layout";
import { ITab } from "../../interfaces";
import { useAppSelector } from "../../utils/hooks";
import { DEAL_SIZING_OTHER_PAGE_TABS } from "../../constants";

interface IProps {
  children: JSX.Element;
}

export default function DealSizingOtherPageLayout({
  children,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { currentDeal } = useAppSelector((s) => s.deal);

  const selectedTab = React.useMemo(() => {
    const pathParts = pathname.split("/");
    return pathParts[pathParts.length - 1];
  }, [pathname]);

  const goToPage = (tab: ITab) => {
    const pathToUse = tab.path
      ?.replace(":dealUuid", dealUuid || "")
      .replace(":caseDealUuid", caseDealUuid || "");
    if (pathToUse) {
      navigate(pathToUse);
    }
  };

  return !currentDeal || currentDeal.uuid !== dealIdToUse ? (
    <LoaderScreen />
  ) : (
    <TabbedLayout module="Deals" pages="Sizing" hasStickyTabs={false}>
      <>
        <Tabs
          onClick={goToPage}
          tabs={DEAL_SIZING_OTHER_PAGE_TABS}
          selectedTab={selectedTab}
          hasStickyTabs={false}
        />
        {children}
      </>
    </TabbedLayout>
  );
}
