import MuiTab from "@mui/material/Tab";
import MuiTabs, { TabsProps } from "@mui/material/Tabs";

import useStyles from "./styles";
import { ITab } from "../../interfaces";

interface IProps {
  tabs: ITab[];
  selectedTab?: string;
  onClick: (tab: ITab) => void;
  tabProps?: TabsProps;
  borderBottom?: boolean;
  hasStickyTabs?: boolean;
}

export default function Tabs({
  tabs,
  selectedTab,
  onClick,
  tabProps,
  borderBottom = true,
  hasStickyTabs,
}: IProps): JSX.Element {
  const styles = useStyles({ borderBottom, hasStickyTabs });

  return (
    <MuiTabs
      variant="standard"
      value={selectedTab}
      classes={{
        root: styles.classes.tabRoot,
        indicator: styles.classes.selectedTabIndicator,
      }}
      {...tabProps}
    >
      {tabs.map((tab, idx) => (
        <MuiTab
          key={idx}
          label={tab.label}
          value={tab.value}
          onClick={() => onClick(tab)}
          data-pw={`${tab.value}-tab`}
          classes={{
            selected: styles.classes.selectedTab,
            root: styles.classes.tab,
          }}
          disabled={tab?.disabled}
        />
      ))}
    </MuiTabs>
  );
}
