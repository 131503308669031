import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useSearchParams } from "react-router-dom";

import Menu from "../menu";
import ToggleSizingOutputButton from "../toggle-sizing-output-button";
import { cn } from "../../utils/helpers";
import {
  OutputReportPeriodicityType,
  ReportTerm,
  OutputReportLeverage,
} from "../../interfaces";
import {
  PERIODICITY,
  PERIODICITY_OPTIONS,
  OUTPUT_REPORT_TERM,
  OUTPUT_REPORT_TERM_OPTIONS,
  OUTPUT_REPORT_LEVERAGE,
  OUTPUT_REPORT_LEVERAGE_OPTIONS,
} from "../../constants";

interface IProps {
  periodicityType: OutputReportPeriodicityType;
  setPeriodicityType: (periodicityType: OutputReportPeriodicityType) => void;
  buyout: ReportTerm;
  setBuyout: (buyout: ReportTerm) => void;
  leverage?: OutputReportLeverage;
  setLeverage?: (leverage: OutputReportLeverage) => void;
  showPeriodicity?: boolean;
  downloadReport?: () => void;
  showBuyout?: boolean;
  disableDownload?: boolean;
  showEbo?: boolean;
  showTaxFlip?: boolean;
  showSizingRedirectionButton?: boolean;
  showTermDropdown?: boolean;
  showLeveredDropdown?: boolean;
}

export default function DealOutputFilterFields({
  periodicityType,
  setPeriodicityType,
  buyout,
  setBuyout,
  showPeriodicity,
  downloadReport,
  showBuyout,
  disableDownload,
  showEbo,
  showTaxFlip,
  showSizingRedirectionButton,
  showTermDropdown = true,
  showLeveredDropdown = false,
  leverage,
  setLeverage,
}: IProps): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const isTermTaxFlip = searchParams.get("term") === "taxflip";
  const isOutputTaxEquityCash = pathname.includes("/output/tax-equity/cash");

  React.useEffect(() => {
    if (searchParams.has("periodicity")) {
      const periodicity = searchParams.get(
        "periodicity",
      ) as OutputReportPeriodicityType;
      setPeriodicityType(periodicity);
    } else {
      searchParams.append("periodicity", periodicityType);
      setSearchParams(searchParams);
    }

    if (searchParams.has("term")) {
      const buyout = searchParams.get("term") as ReportTerm;
      if (isTermTaxFlip && !isOutputTaxEquityCash) {
        setBuyout("default");
        searchParams.set("term", "default");
        setSearchParams(searchParams);
      } else {
        setBuyout(buyout);
      }
    } else {
      searchParams.append("term", buyout);
      setSearchParams(searchParams);
    }

    if (searchParams.has("leverage")) {
      const leverage = searchParams.get("leverage") as OutputReportLeverage;
      if (setLeverage) {
        setLeverage(leverage);
      }
    } else if (leverage && setLeverage) {
      searchParams.append("leverage", leverage);
      setSearchParams(searchParams);
    }
  }, []);

  const onPeriodicityChange = (v: OutputReportPeriodicityType) => {
    setPeriodicityType(v);
    searchParams.set("periodicity", v);
    setSearchParams(searchParams);
  };

  const onBuyoutChange = (v: ReportTerm) => {
    setBuyout(v);
    searchParams.set("term", v);
    setSearchParams(searchParams);
  };

  const onLeverageChange = (v: OutputReportLeverage) => {
    if (setLeverage) {
      setLeverage(v);
    }
    searchParams.set("leverage", v);
    setSearchParams(searchParams);
  };

  return (
    <Box
      className={cn("flex justify-between items-center my-4 *:flex *:gap-2")}
    >
      <Box>
        {showPeriodicity && (
          <Menu
            selectedItem={periodicityType ? PERIODICITY[periodicityType] : ""}
            placeholder="Periodicity"
            menuItems={PERIODICITY_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () => {
                onPeriodicityChange(o.value as OutputReportPeriodicityType);
              },
            }))}
          />
        )}
        {showTermDropdown && (
          <Menu
            selectedItem={buyout ? OUTPUT_REPORT_TERM[buyout] : ""}
            placeholder="Term"
            menuItems={OUTPUT_REPORT_TERM_OPTIONS.filter((o) => {
              if (o.value === "buyout" && !showBuyout) return false;
              if (o.value === "ebo" && !showEbo) return false;
              if (o.value === "taxflip" && !showTaxFlip) return false;
              return true;
            }).map((o) => ({
              label: o.label,
              onClick: () => {
                onBuyoutChange(o.value as ReportTerm);
              },
            }))}
          />
        )}
        {showLeveredDropdown && (
          <Menu
            selectedItem={leverage ? OUTPUT_REPORT_LEVERAGE[leverage] : ""}
            placeholder="Leverage"
            menuItems={OUTPUT_REPORT_LEVERAGE_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () => {
                onLeverageChange(o.value as OutputReportLeverage);
              },
            }))}
          />
        )}
      </Box>
      <Box>
        {showSizingRedirectionButton && <ToggleSizingOutputButton />}
        {downloadReport && (
          <IconButton
            title="Download Report"
            onClick={downloadReport}
            disabled={disableDownload}
          >
            <DownloadIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
