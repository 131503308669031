import React from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import SchedulerInput from "../scheduler-input";
import CurrencyInput from "../currency-input";
import FormColumnLayout from "../form-column-layout";
import CopyIcon from "@mui/icons-material/ContentCopy";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import {
  cn,
  formatNumberForView,
  keepNumbersDotAndMinus,
} from "../../utils/helpers";
import {
  ICostItemForm,
  ICostItemFormErrors,
  ICostItemResponse,
  SetStateAction,
} from "../../interfaces";
import {
  COST_ITEM_FORM_DEFAULT_STATE,
  COST_METHOD_TYPES_OPTIONS,
  COST_START_DATE_TYPES_OPTIONS,
  PROJECT_COST_INPUT_TYPE,
  PROJECT_COST_INPUT_TYPE_OPTIONS,
  PROJECT_COST_INPUT_CATEGORY_OPTIONS,
  COST_METHOD_TYPES,
  PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE,
  COST_MILESTONE_DATE_TYPES,
} from "../../constants";
import useStyles from "./styles";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: ICostItemFormErrors;
  setFormErrors: SetStateAction<ICostItemFormErrors | undefined>;
  form: ICostItemForm;
  setForm: SetStateAction<ICostItemForm>;
  onClose: () => void;
  onConfirm: (form: ICostItemForm) => Promise<ICostItemResponse | undefined>;
  dateSchedule: string[];
  setMilestoneDatePercentageInputValues: SetStateAction<{
    [key in keyof typeof COST_MILESTONE_DATE_TYPES]: number;
  }>;
  milestoneDatePercentageInputValues: {
    [key in keyof typeof COST_MILESTONE_DATE_TYPES]: number;
  };
}

export default function CostItemFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  dateSchedule,
  setMilestoneDatePercentageInputValues,
  milestoneDatePercentageInputValues,
}: IProps) {
  const styles = useStyles();
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSchedulerErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "cost_method") {
      setMilestoneDatePercentageInputValues({
        BOL: 0,
        NTP: 0,
        COD: 0,
        MC: 0,
        SC: 0,
      });
    }

    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleCurveChange = (value: (number | string | null)[]) => {
    setForm((prevState) => ({
      ...prevState,
      cost_percentage_curve: value,
    }));
  };

  const handleOnClose = () => {
    setForm(COST_ITEM_FORM_DEFAULT_STATE);
    setFormErrors({});
    onClose();
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const getFormattedValue = (value: string) => {
    const cleanedValue = keepNumbersDotAndMinus(value);
    return formatNumberForView(cleanedValue);
  };
  // Function to handle production input change
  const handleMilestoneDatePercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    const formattedValue = getFormattedValue(value);
    if (formattedValue !== "") {
      setMilestoneDatePercentageInputValues(
        (
          prevValues: Record<keyof typeof COST_MILESTONE_DATE_TYPES, number>,
        ) => ({
          ...prevValues,
          [name]: formattedValue,
        }),
      );
    } else {
      setMilestoneDatePercentageInputValues(
        (
          prevValues: Record<keyof typeof COST_MILESTONE_DATE_TYPES, number>,
        ) => ({
          ...prevValues,
          [name]: "0",
        }),
      );
    }
  };

  const getArrayValueFromPaste = (
    e: React.ClipboardEvent<HTMLInputElement | HTMLDivElement>,
  ) => {
    const copiedValue = e.clipboardData.getData("text/plain");
    const splitChar = copiedValue.includes("\t") ? "\t" : "\n";
    const arrayValues = copiedValue.split(splitChar);

    if (arrayValues[arrayValues.length - 1] === "") {
      arrayValues.pop();
    }

    return arrayValues.map((v) => {
      const cleanedValue = keepNumbersDotAndMinus(v);
      return cleanedValue === "" ? null : cleanedValue;
    });
  };

  const handleMilestoneDatePercentagePaste = (
    e: React.ClipboardEvent<HTMLInputElement | HTMLDivElement>,
    pastedAtIndex: number,
  ) => {
    e.preventDefault();

    const cleanedArray = getArrayValueFromPaste(e);

    let startIndexToPaste = 0;
    const totalInputs = Object.keys(COST_METHOD_TYPES).length;

    if (cleanedArray.length + pastedAtIndex > totalInputs) {
      toast.info(
        "Entries were truncated, since there were more than the permitted number of values.",
        { toastId: "cost-item-entries-truncation-info" },
      );
    }

    Object.keys(PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE).forEach(
      (factorType: string, index: number) => {
        if (index >= pastedAtIndex) {
          setForm((prev) => {
            if (startIndexToPaste in cleanedArray) {
              const value = cleanedArray[startIndexToPaste++] ?? "";
              return {
                ...prev,
                [factorType]: getFormattedValue(value),
              };
            }
            return prev;
          });
        }
      },
    );
  };

  const formatErr = (err?: string[] | string) => {
    return Array.isArray(err) ? err.join(" ") : err;
  };

  const handleOnConfirm = async () => {
    const updatedForm: ICostItemForm = {
      ...form,
      cost_percentage_curve:
        form.cost_method === "SCD" ? form.cost_percentage_curve : null,
      cost_start_point: form.cost_method === "SCD" ? "" : form.cost_start_point,
      cost_end_point: form.cost_method === "SCD" ? "" : form.cost_end_point,
      milestone_date_percentage:
        form.cost_method === "MD"
          ? milestoneDatePercentageInputValues
          : {
              BOL: 0,
              NTP: 0,
              COD: 0,
              MC: 0,
              SC: 0,
            },
    };
    const res = await onConfirm(updatedForm);
    res && handleOnClose();
  };

  const getCostUnit = (): string => {
    switch (form.cost_input_type) {
      case "DL":
        return PROJECT_COST_INPUT_TYPE.DL;
      case "DLPWD":
        return PROJECT_COST_INPUT_TYPE.DLPWD;
      default:
        return PROJECT_COST_INPUT_TYPE.DL;
    }
  };

  const costStartAdornment = ["DL", ""].includes(form.cost_input_type)
    ? getCostUnit()
    : undefined;
  const costEndAdornment =
    form.cost_input_type === "DLPWD" ? getCostUnit() : undefined;

  return (
    <Modal
      open={open}
      form={form}
      heading={headerLabel}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      classes={{ paper: cn("!max-w-full") }}
    >
      <FormColumnLayout>
        <Box>
          <SelectInput
            required
            label="Cost Type"
            selected={form.cost_type}
            items={PROJECT_COST_INPUT_CATEGORY_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("cost_type")}
            onChange={(e) => handleSelectInputChange(e, "cost_type")}
            error={Boolean(formErrors?.cost_type)}
            helperText={formErrors?.cost_type}
            disabled={loading}
            fullWidth={false}
            tooltip="<b>Development Cost</b> - Costs incurred for planning, design, feasibility, and credit support during project development.\n
                   <b>Acquisition Cost</b> - Costs paid to an early-stage developer to acquire the project.\n
                   <b>Interconnection Cost</b> - Costs associated with connecting a project to the electrical grid.\n
                   <b>Build Cost</b> - Costs to construct the project, excluding financing fees.\n
                   <b>Transaction Cost</b> - Costs associated with financing and/or selling the project.\n
                   <b>Land Cost</b> - Costs associated with procuring land or site access for the project."
          />
          <TextInput
            required
            label="Name"
            name="name"
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            value={form.name}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
            fullWidth={false}
          />
          <SelectInput
            required
            label="Input Type"
            selected={form.cost_input_type}
            items={PROJECT_COST_INPUT_TYPE_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("cost_input_type")}
            onChange={(e) => handleSelectInputChange(e, "cost_input_type")}
            error={Boolean(formErrors?.cost_input_type)}
            helperText={formErrors?.cost_input_type}
            disabled={loading}
            fullWidth={false}
          />
          <CurrencyInput
            required
            label="Cost"
            name="cost"
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            value={form.cost}
            error={Boolean(formErrors?.cost)}
            helperText={formErrors?.cost}
            disabled={loading}
            startAdornment={costStartAdornment}
            endAdornment={costEndAdornment}
            fullWidth={false}
          />
          <SelectInput
            required
            label="Spend Method"
            selected={form.cost_method}
            items={COST_METHOD_TYPES_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("cost_method")}
            onChange={(e) => handleSelectInputChange(e, "cost_method")}
            error={Boolean(formErrors?.cost_method)}
            helperText={formErrors?.cost_method}
            disabled={loading}
            fullWidth={false}
            tooltip="<b>Milestone Date</b> - a named date related to key development and construction events.\n
                   <b>Linear</b> - Spread evenly over the period.\n
                   <b>Schedule</b> - Custom input over the period."
          />
          {/* When a schedule is chosen a "start date type and end date type" is not needed since we get a schedule from BOL > COD from the user */}
          {form.cost_method === "LNR" && (
            <>
              <SelectInput
                required
                label="Start Point"
                selected={form.cost_start_point}
                items={COST_START_DATE_TYPES_OPTIONS}
                onFocus={() => clearSelectErrorOnFocus("cost_start_point")}
                onChange={(e) => handleSelectInputChange(e, "cost_start_point")}
                error={Boolean(formErrors?.cost_start_point)}
                helperText={formErrors?.cost_start_point}
                disabled={loading}
                fullWidth={false}
              />
              <SelectInput
                required
                label="End Point"
                selected={form.cost_end_point}
                items={COST_START_DATE_TYPES_OPTIONS}
                onFocus={() => clearSelectErrorOnFocus("cost_end_point")}
                onChange={(e) => handleSelectInputChange(e, "cost_end_point")}
                error={Boolean(formErrors?.cost_end_point)}
                helperText={formErrors?.cost_end_point}
                disabled={loading}
                fullWidth={false}
              />
            </>
          )}
          {form.cost_method === "MD" && (
            <>
              <TableContainer
                component={Paper}
                classes={{ root: cn("[&_th]:px-1") }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Milestone</TableCell>
                      <TableCell align="left">{<>Schedule</>}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(COST_MILESTONE_DATE_TYPES).map(
                      (costMethod: string, index: number) => (
                        <TableRow
                          key={index}
                          className={styles.classes.tableRow}
                        >
                          <TableCell>
                            {
                              COST_MILESTONE_DATE_TYPES[
                                costMethod as keyof typeof COST_MILESTONE_DATE_TYPES
                              ]
                            }
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              classes={{
                                root: styles.classes.pFactorTableTextField,
                              }}
                              name={costMethod}
                              value={
                                milestoneDatePercentageInputValues[
                                  costMethod as keyof typeof milestoneDatePercentageInputValues
                                ] || 0
                              }
                              error={Boolean(
                                formErrors?.milestone_date_percentage,
                              )}
                              onChange={handleMilestoneDatePercentageChange}
                              onFocus={() =>
                                clearNonTextFieldErrorOnFocus(
                                  "milestone_date_percentage",
                                )
                              }
                              onPaste={(e) =>
                                handleMilestoneDatePercentagePaste(e, index)
                              }
                              InputProps={{
                                endAdornment: <>%</>,
                              }}
                              disabled={loading}
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <FormHelperText
                error={Boolean(formErrors?.milestone_date_percentage)}
              >
                {formatErr(formErrors?.milestone_date_percentage)}
              </FormHelperText>
              <Box marginTop={2} />
            </>
          )}
          <TextInput
            required
            isNumeric
            label="Cost Multiplier"
            name="cost_multiplier"
            endAdornment={<>%</>}
            value={form.cost_multiplier}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.cost_multiplier)}
            helperText={formErrors?.cost_multiplier}
            disabled={loading}
            fullWidth={false}
            tooltip="Multiplication factor for increasing or reducing cost inputs. Primarily used for scenario and sensitivity analysis."
          />
        </Box>
        {form.cost_method === "SCD" && (
          <Box>
            <SchedulerInput
              label="Cost Schedule"
              name="cost_percentage_curve"
              dateSchedule={dateSchedule || []}
              value={form?.cost_percentage_curve || []}
              error={formErrors?.cost_percentage_curve || ""}
              clearErrorOnFocus={() =>
                clearSchedulerErrorOnFocus("cost_percentage_curve")
              }
              onChange={handleCurveChange}
              disabled={loading}
              endAdornment="%"
              showTotal
              fullWidth
            />
          </Box>
        )}
      </FormColumnLayout>
    </Modal>
  );
}
