import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealCostScheduleView from "../../../../views/deal/components/cost/schedule";
import DealComponentPagesLayout from "../../../../components/deal-component-page-layout";
import { getDealCostsSchedule } from "../../../../apis/deal/cost";

export default function DealCostSchedulePage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealComponentPagesLayout>
        <DealCostScheduleView getDealCostsSchedule={getDealCostsSchedule} />
      </DealComponentPagesLayout>
    </DealPagesLayout>
  );
}
