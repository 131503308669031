import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import {
  ICollaborationCase,
  ICollaborationCaseForm,
  ICollaborationCaseFormErrors,
  ISelectOption,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: ICollaborationCaseFormErrors;
  setFormErrors: React.Dispatch<
    React.SetStateAction<ICollaborationCaseFormErrors | undefined>
  >;
  form: ICollaborationCaseForm;
  setForm: React.Dispatch<React.SetStateAction<ICollaborationCaseForm>>;
  onClose: () => void;
  onConfirm: (
    form: ICollaborationCaseForm,
  ) => Promise<ICollaborationCase | undefined>;
  caseOptions: ISelectOption[];
  baseCaseId?: string;
  hideFields?: (keyof ICollaborationCaseForm)[];
}

export default function CollaborationCaseFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  caseOptions,
  baseCaseId,
  hideFields = [],
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [name]: value?.value,
    }));
  };

  const handleOnConfirm = async () => {
    const caseId = baseCaseId === form.case ? null : form.case;
    const updatedForm = {
      ...form,
      case: caseId,
    };
    const collaboration = await onConfirm(updatedForm);
    collaboration && onClose();
  };

  const handleResetForm = () => {
    setForm({
      name: null,
      case: null,
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        {!hideFields?.includes("case") && (
          <AutocompleteField
            label="Select Case"
            name="case"
            onChange={handleSingleAutoCompleteChange}
            options={caseOptions}
            value={String(form.case) || ""}
            helperText={formErrors?.case}
            onFocus={() => {
              clearSelectErrorOnFocus("case");
            }}
            disabled={loading}
          />
        )}

        {!hideFields?.includes("name") && (
          <TextInput
            required
            label="Shared Case Name"
            name="name"
            value={form.name || ""}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
          />
        )}
      </Box>
    </Modal>
  );
}
