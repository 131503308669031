import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputSponsorEquityBOL_TaxView from "../../../../views/deal/output/sponsor-equity-BOL/tax";
import {
  downloadDealTimeSeriesReport,
  getDealTimeSeriesReport,
} from "../../../../apis/report/deal";

export default function DealOutputSponsorEquityBOL_TaxPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorEquityBOL_TaxView
          getDealTimeSeriesReport={getDealTimeSeriesReport}
          downloadDealTimeSeriesReport={downloadDealTimeSeriesReport}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
