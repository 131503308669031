import { PROJECT_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IProjectOtherFlow,
  IProjectOtherFlowForm,
  ServerPaginatedResponse,
} from "../../interfaces";

export const addProjectOtherFlow = async (
  projectUuid: string,
  form: IProjectOtherFlowForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.other.otherFlows.add, {
    projectUuid,
  });
  return await AxiosHelper.post<IProjectOtherFlowForm, IProjectOtherFlow>(url, {
    ...form,
  });
};

export const editProjectOtherFlow = async (
  projectUuid: string,
  id: number,
  form: IProjectOtherFlowForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.other.otherFlows.edit, {
    projectUuid,
    id,
  });
  return await AxiosHelper.put<IProjectOtherFlowForm, IProjectOtherFlow>(url, {
    ...form,
  });
};

export const deleteProjectOtherFlow = async (
  projectUuid: string,
  id: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.other.otherFlows.delete, {
    projectUuid,
    id,
  });
  return await AxiosHelper.delete(url);
};

export const getProjectOtherFlow = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.other.otherFlows.get, {
    projectUuid,
  });
  return await AxiosHelper.get<ServerPaginatedResponse<IProjectOtherFlow[]>>(
    url,
  );
};

export const getProjectOtherFlowDetails = async (
  projectUuid: string,
  id: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.other.otherFlows.getDetail, {
    projectUuid,
    id,
  });
  return await AxiosHelper.get<IProjectOtherFlow>(url);
};
