import { Protect } from "@clerk/clerk-react";

import { useAppSelector } from "../../utils/hooks";
import { USER_PERMISSIONS } from "../../constants";
import { TUserPermission, UserPermissionType } from "../../interfaces";

interface IProps {
  type: UserPermissionType;
  children: React.ReactNode;
}

export default function ConditionalProtect({
  children,
  type,
}: IProps): JSX.Element | null {
  const { currentProject } = useAppSelector((state) => state.project);
  const { currentDeal } = useAppSelector((state) => state.deal);

  const getPermissions = (): TUserPermission | "" => {
    switch (type) {
      case "deal":
        return USER_PERMISSIONS.DEALS_CRUD;
      case "project":
        return USER_PERMISSIONS.PROJECTS_CRUD;
      case "counterparty":
        return USER_PERMISSIONS.ORG_COUNTERPARTY_CRUD;
      case "externalshare":
        return USER_PERMISSIONS.ORG_EXTERNAL_SHARE_CRUD;
      case "data_delete":
        return USER_PERMISSIONS.DATA_DELETE;
      default:
        return "";
    }
  };

  if (type === "project" && currentProject?.status === "ARCH") return null;
  if (type === "deal" && currentDeal?.status === "ARCH") return null;

  return <Protect permission={getPermissions()}>{children}</Protect>;
}
