import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import SelectInput from "../select-input";
import { PERIODICITY, PERIODICITY_OPTIONS } from "../../constants";
import { SetStateAction } from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  periodicity: keyof typeof PERIODICITY | "";
  setPeriodicity: SetStateAction<keyof typeof PERIODICITY | "">;
  onClose: () => void;
  onConfirm: (periodicity: keyof typeof PERIODICITY | "") => Promise<void>;
}

export default function DownloadModelFormModal({
  headerLabel,
  open,
  loading,
  periodicity,
  setPeriodicity,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setPeriodicity(e.target.value as keyof typeof PERIODICITY | "");
  };

  const handleOnConfirm = async () => {
    await onConfirm(periodicity);
    handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setPeriodicity("");
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <SelectInput
          label="Pro Forma Periodicity"
          selected={periodicity}
          items={PERIODICITY_OPTIONS}
          onChange={(e) => handleSelectInputChange(e, "periodicity")}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
