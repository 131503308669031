import ProjectPagesLayout from "../../../../components/project-pages-layout";
import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectCostInputsView from "../../../../views/project/cost/cost-inputs";
import {
  createProjectCostItem,
  editProjectCostItem,
  getProjectCostList,
  getProjectCostsSummary,
} from "../../../../apis/project/cost";

export default function ProjectCostInputsPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Cost">
        <ProjectCostInputsView
          getProjectCostList={getProjectCostList}
          createProjectCostItem={createProjectCostItem}
          editProjectCostItem={editProjectCostItem}
          getProjectCostSummary={getProjectCostsSummary}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
