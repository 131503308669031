import Layout from "../../components/layout";
import CollaborationPageLayout from "../../components/collaboration-page-layout";
import CollaborationAccessView from "../../views/collaboration/access";
import {
  getCounterparties,
  getSingleCounterparty,
} from "../../apis/configuration/counterparties";
import {
  addDealCounterparty,
  getDealCounterparties,
  updateDealCounterparty,
  deleteCollaborationCounterparty,
} from "../../apis/collaboration/base";

export default function CollaborationAccessPage(): JSX.Element {
  return (
    <Layout title="Collaboration">
      <CollaborationPageLayout>
        <CollaborationAccessView
          getDealCounterparties={getDealCounterparties}
          addDealCounterparty={addDealCounterparty}
          updateDealCounterparty={updateDealCounterparty}
          getCounterparties={getCounterparties}
          getSingleCounterparty={getSingleCounterparty}
          deleteCollaborationCounterparty={deleteCollaborationCounterparty}
        />
      </CollaborationPageLayout>
    </Layout>
  );
}
