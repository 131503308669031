import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import {
  IDealCase,
  IAddDealCaseForm,
  IUpdateDealCaseForm,
  IDuplicateDealCaseForm,
  ServerPaginatedResponse,
  IDealCaseLite,
} from "../../interfaces";

export const getDealCases = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.case.get, { dealUuid });
  return await AxiosHelper.get<ServerPaginatedResponse<IDealCase[]>>(url);
};

export const getDealCasesLite = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.case.getLite, { dealUuid });
  return await AxiosHelper.get<IDealCaseLite[]>(url);
};

export const addDealCase = async (dealUuid: string, form: IAddDealCaseForm) => {
  const url = replaceParams(DEAL_API_ROUTES.case.create, { dealUuid });
  return await AxiosHelper.post<IAddDealCaseForm, IDealCase>(url, form);
};

export const getDealCaseDetails = async (dealUuid: string, caseId: string) => {
  const url = replaceParams(DEAL_API_ROUTES.case.getDetails, {
    dealUuid,
    caseId,
  });
  return await AxiosHelper.get<IDealCase>(url);
};

export const updateDealCase = async (
  dealUuid: string,
  caseId: string,
  form: IUpdateDealCaseForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.case.update, { dealUuid, caseId });
  return await AxiosHelper.put<IUpdateDealCaseForm, IDealCase>(url, form);
};

export const duplicateDealCase = async (
  dealUuid: string,
  caseId: string,
  form: IDuplicateDealCaseForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.case.duplicate, {
    dealUuid,
    caseId,
  });
  return await AxiosHelper.post<IDuplicateDealCaseForm, IDealCase>(url, form);
};

export const deleteDealCase = async (dealUuid: string, caseId: string) => {
  const url = replaceParams(DEAL_API_ROUTES.case.delete, { dealUuid, caseId });
  return await AxiosHelper.delete(url);
};
