import { enumToOptions } from "../../utils/helpers";
import {
  ICostItemForm,
  ITableColumn,
  IUpdateProjectCostForm,
} from "../../interfaces";

export enum PROJECT_COST_PROFILE {
  SP = "Specified",
  PR = "Pro Rata",
  UF = "Upfront",
  ED = "End",
}

export const PROJECT_COST_PROFILE_OPTIONS = enumToOptions(PROJECT_COST_PROFILE);

export enum PROJECT_COST_METHOD {
  TOT = "Total",
  DET = "Detailed",
}

export const PROJECT_COST_METHOD_OPTIONS = enumToOptions(PROJECT_COST_METHOD);

export enum PROJECT_COST_INPUT_TYPE {
  DL = "$",
  DLPWD = "$/Wdc",
}

export const PROJECT_COST_INPUT_TYPE_OPTIONS = enumToOptions(
  PROJECT_COST_INPUT_TYPE,
);

export enum PROJECT_COST_FMV_STEP_UP_INPUT_TYPE {
  PP = "Percent",
  FSA = "Dollar Amount",
}

export const PROJECT_COST_FMV_STEP_UP_INPUT_TYPE_OPTIONS = enumToOptions(
  PROJECT_COST_FMV_STEP_UP_INPUT_TYPE,
);

export const UPDATE_PROJECT_COST_FORM_DEFAULT_STATE: IUpdateProjectCostForm = {
  cost: "",
  profit_percent: "",
  acquisition_cost: "",
  build_cost: "",
  cost_method: "",
  cost_input_type: "",
  development_cost: "",
  transaction_cost: "",
};

export enum PROJECT_COST_TYPE {
  DEV = "Development Cost",
  ACQ = "Acquisition Cost",
  INT = "Interconnection Cost",
  BLD = "Build Cost",
  TRN = "Transaction Cost",
  LND = "Land Cost",
  OTH = "Other Cost",
  LC = "Credit Support Cost",
}

export const PROJECT_COST_TYPE_OPTIONS = enumToOptions(PROJECT_COST_TYPE);

export enum PROJECT_COST_INPUT_CATEGORY {
  DEV = "Development Cost",
  ACQ = "Acquisition Cost",
  INT = "Interconnection Cost",
  BLD = "Build Cost",
  TRN = "Transaction Cost",
  LND = "Land Cost",
  OTH = "Other Cost",
}

export const PROJECT_COST_INPUT_CATEGORY_OPTIONS = enumToOptions(
  PROJECT_COST_INPUT_CATEGORY,
);

export enum COST_METHOD_TYPES {
  MD = "Milestone Dates",
  LNR = "Linear",
  SCD = "Schedule",
}

export const COST_METHOD_TYPES_OPTIONS = enumToOptions(COST_METHOD_TYPES);

export enum COST_START_DATE_TYPES {
  BOL = "BOL",
  NTP = "NTP",
  // MC = "MC",
  // SC = "SC",
  COD = "COD",
}

export enum COST_MILESTONE_DATE_TYPES {
  BOL = "BOL",
  NTP = "NTP",
  MC = "MC",
  SC = "SC",
  COD = "COD",
}

export const COST_MILESTONE_DATE_TYPES_OPTIONS = enumToOptions(
  COST_MILESTONE_DATE_TYPES,
);

export const COST_START_DATE_TYPES_OPTIONS = enumToOptions(
  COST_START_DATE_TYPES,
);

export const COST_ITEM_FORM_DEFAULT_STATE: ICostItemForm = {
  cost: "",
  cost_multiplier: "100",
  cost_input_type: "",
  cost_method: "",
  cost_percentage_curve: null,
  cost_start_point: "",
  cost_end_point: "",
  cost_type: "",
  name: "",
  milestone_date_percentage: {
    BOL: 0,
    NTP: 0,
    COD: 0,
    MC: 0,
    SC: 0,
  },
};

export const COST_SCHEDULE_DETAIL_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "Date", label: "Date", minWidth: 60, align: "right" },
  {
    id: "Development Cost",
    label: "Development Cost",
    minWidth: 180,
    align: "right",
  },
  {
    id: "Acquisition Cost",
    label: "Acquisition Cost",
    minWidth: 180,
    align: "right",
  },
  {
    id: "Land Cost",
    label: "Land Cost",
    minWidth: 180,
    align: "right",
  },
  {
    id: "Interconnection Cost",
    label: "Interconnection Cost",
    minWidth: 200,
    align: "right",
  },
  {
    id: "Build Cost",
    label: "Build Cost",
    minWidth: 160,
    align: "right",
  },
  {
    id: "Transaction Cost",
    label: "Transaction Cost",
    minWidth: 180,
    align: "right",
  },
  {
    id: "Other Cost",
    label: "Other Cost",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Project Cost",
    label: "Project Cost",
    minWidth: 150,
    align: "right",
  },
];

export enum PROJECT_COST_SCHEDULE_REPORT_TYPE {
  cost = "cost",
  credit_support = "credit_support",
}

export enum PROJECT_COST_ITEM_GROUPING {
  single = "single",
  grouped = "grouped",
}
