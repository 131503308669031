import { DEAL_API_ROUTES } from "../../constants";
import { IsummaryTablesObject } from "../../interfaces";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getDealFMV_Summary = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.fmv.summary, {
    dealUuid,
  });
  return await AxiosHelper.get<IsummaryTablesObject>(url);
};
