import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SummaryTable from "../../components/summary-table";
import { cn } from "../../utils/helpers";
import { ISummaryTable } from "../../interfaces";

interface IProps {
  summaryTables?: ISummaryTable[];
  reportTitle?: string;
}

const SponsorEquityTab: React.FC<IProps> = ({ summaryTables, reportTitle }) => {
  const gridCols = ((summaryTables || [])[0]?.headers?.length || 0) < 3 ? 2 : 1;

  return (
    <Box>
      {summaryTables && summaryTables.length > 0 && (
        <Box>
          {reportTitle && (
            <Typography fontSize="14" padding={1} fontWeight="bold">
              {reportTitle}
            </Typography>
          )}
          <Box className={cn(`grid grid-cols-${gridCols} gap-2`)}>
            {summaryTables.map((table, idx) => (
              <SummaryTable key={idx} usedIn="deal" summaryTable={table} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SponsorEquityTab;
