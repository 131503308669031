import { IProjectForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_STAGE_DEVELOPMENT {
  EA = "Early-Stage",
  MI = "Mid-Stage",
  LT = "Late-Stage",
}

export enum PROJECT_STAGE_CONSTRUCTION {
  PMC = "In-Construction (pre-MC)",
  MC = "Mechanically Complete",
  IC = "In-Commissioning (pre-PIS)",
  IT = "In-Testing (pre-SC)",
  SC = "Substantially Complete",
  APTO = "Awaiting-PTO",
  PTOG = "PTO-Granted",
}

export enum PROJECT_STAGE_OPERATION {
  IP = "In-Operation",
  DC = "Decommissioned",
}

export const PROJECT_STAGES = {
  ...PROJECT_STAGE_DEVELOPMENT,
  ...PROJECT_STAGE_CONSTRUCTION,
  ...PROJECT_STAGE_OPERATION,
};

export const PROJECT_STAGE_OPTIONS = {
  Development: enumToOptions(PROJECT_STAGE_DEVELOPMENT),
  Construction: enumToOptions(PROJECT_STAGE_CONSTRUCTION),
  Operations: enumToOptions(PROJECT_STAGE_OPERATION),
};

enum DASHBOARD_PROJECT_TYPES_WIND {
  ONW = "Onshore Wind",
  OFW = "Offshore Wind",
}

enum DASHBOARD_PROJECT_TYPES_SOLAR {
  USS = "Utility-Scale Solar",
  CIS = "C&I Solar",
  RES = "Residential Solar",
}

enum DASHBOARD_PROJECT_TYPES_STORAGE {
  BESS = "Battery Storage",
}

enum DASHBOARD_PROJECT_TYPES_OTHER {
  FC = "Fuel Cell",
  BIO = "Biomass",
  CCS = "CCS",
  HYG = "Hydrogen",
  HYP = "Hydro Power",
  GEO = "Geothermal",
  NUC = "Nuclear",
}

export const DASHBOARD_PROJECT_TYPES = {
  ...DASHBOARD_PROJECT_TYPES_WIND,
  ...DASHBOARD_PROJECT_TYPES_SOLAR,
  ...DASHBOARD_PROJECT_TYPES_STORAGE,
  ...DASHBOARD_PROJECT_TYPES_OTHER,
};

enum PROJECT_TYPES_WIND {
  ONWD = "Onshore Wind",
  OFWD = "Offshore Wind",
}

enum PROJECT_TYPES_SOLAR {
  UTS = "Utility-Scale Solar",
  CIS = "C&I Solar",
  RES = "Residential Solar",
}

enum PROJECT_TYPES_STORAGE {
  BESS = "Battery Storage",
}

enum PROJECT_TYPES_OTHER {
  FUCE = "Fuel Cell",
  BIOM = "Biomass",
  CCS = "CCS",
  HYD = "Hydrogen",
  HYPO = "Hydro Power",
  GEOT = "Geothermal",
  NUCL = "Nuclear",
}

export const PROJECT_ENERGY_TYPES = {
  ...PROJECT_TYPES_WIND,
  ...PROJECT_TYPES_SOLAR,
  ...PROJECT_TYPES_STORAGE,
  ...PROJECT_TYPES_OTHER,
};

export const PROJECT_TYPE_OPTIONS = {
  Wind: enumToOptions(PROJECT_TYPES_WIND),
  Solar: enumToOptions(PROJECT_TYPES_SOLAR, ["RES"]),
  Storage: enumToOptions(PROJECT_TYPES_STORAGE),
  Other: enumToOptions(PROJECT_TYPES_OTHER, [
    "FUCE",
    "BIOM",
    "CCS",
    "HYD",
    "HYPO",
    "GEOT",
    "NUCL",
  ]),
};

export const PROJECT_FORM_DEFAULT_STATE: IProjectForm = {
  name: "",
  sponsor_name: "",
  energy_type: "",
  stage: "",
  sub_stage: "",
  status: "ACT",
  county: "",
};
