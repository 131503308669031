import Layout from "../../components/layout";
import CollaborationPageLayout from "../../components/collaboration-page-layout";
import CollaborationGeneralView from "../../views/collaboration/general";

export default function CollaborationGeneralPage(): JSX.Element {
  return (
    <Layout title="Collaboration">
      <CollaborationPageLayout>
        <CollaborationGeneralView />
      </CollaborationPageLayout>
    </Layout>
  );
}
