import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputSponsorEquityCashView from "../../../../views/deal/output/sponsor-equity/cash";
import {
  getDealTimeSeriesReport,
  downloadDealTimeSeriesReport,
} from "../../../../apis/report/deal";

export default function DealOutputSponsorEquityCash(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorEquityCashView
          getDealTimeSeriesReport={getDealTimeSeriesReport}
          downloadDealTimeSeriesReport={downloadDealTimeSeriesReport}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
