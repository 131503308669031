import React from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material/Select";
import { format, isValid } from "date-fns";

import Modal from "../modal";
import useStyles from "./styles";
import { getDealDebtFields, IFields } from "./fields";
import {
  DEAL_TERM_DEBT_FORM_DEFAULT_STATE,
  DEAL_TERM_TERM_DEBT_TYPES,
} from "../../constants";
import {
  IDealDebt,
  IDealTermDebtForm,
  SetStateAction,
  IDealTermDebtFormErrors,
  ISelectOption,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IDealTermDebtFormErrors;
  setFormErrors: SetStateAction<IDealTermDebtFormErrors | undefined>;
  form: IDealTermDebtForm;
  setForm: SetStateAction<IDealTermDebtForm>;
  onClose: () => void;
  onConfirm: (form: IDealTermDebtForm) => Promise<IDealDebt | undefined>;
  ircCurves: ISelectOption[];
  debtFundingDate: {
    first_cod: string;
    last_cod: string;
  };
}

export default function DealTermDebtFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  ircCurves,
  debtFundingDate,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "type") {
      setForm((prev) => ({
        ...prev,
        [field]: e.target.value as keyof typeof DEAL_TERM_TERM_DEBT_TYPES,
        solve_for: "",
      }));
      return;
    }
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const onSingleAutoCompleteChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [name]: val?.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const dealTermDebt = await onConfirm(form);
    dealTermDebt && handleOnClose();
  };

  const formFields = React.useMemo(() => {
    return getDealDebtFields({
      form,
      formErrors,
      loading,
      clearErrorOnFocus,
      clearNonTextFieldErrorOnFocus,
      onTextChange: handleTextChange,
      onSelectInputChange: handleSelectInputChange,
      onDateChange: handleDateChange,
      onSingleAutoCompleteChange,
      ircCurves,
      debtFundingDate,
    });
  }, [form, formErrors, debtFundingDate]);

  const getStructureFields = (fields: IFields) => {
    return fields.structure;
  };

  const getTimingFields = (fields: IFields) => {
    return fields.timing;
  };

  const getSizingFields = (fields: IFields) => {
    return fields.sizing;
  };

  const getInterestFields = (fields: IFields) => {
    return fields.interest;
  };

  const getInterestRateSwapFields = (fields: IFields) => {
    return fields.interest_rate_swap;
  };

  const handleResetForm = () => {
    setForm(DEAL_TERM_DEBT_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      open={open}
      maxWidth="md"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      classes={{ paper: styles.classes.modal }}
      resetForm={handleResetForm}
    >
      <Box className="grid md:grid-cols-2 gap-4">
        <Box>
          <Typography fontWeight="bold">Key Parameters</Typography>
          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Structure
          </Divider>
          {getStructureFields(formFields).map((field, idx) => (
            <React.Fragment key={idx}>{field.element}</React.Fragment>
          ))}
          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Timing
          </Divider>
          {getTimingFields(formFields).map((field, idx) => (
            <React.Fragment key={idx}>{field?.element}</React.Fragment>
          ))}
          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Sizing
          </Divider>
          {getSizingFields(formFields).map((field, idx) => (
            <React.Fragment key={idx}>{field.element}</React.Fragment>
          ))}
        </Box>
        <Box>
          <Typography fontWeight="bold">Interest and Fees</Typography>

          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Interest
          </Divider>
          {getInterestFields(formFields).map((field, idx) => (
            <React.Fragment key={idx}>{field?.element}</React.Fragment>
          ))}
          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Interest Rate Swap
          </Divider>
          {getInterestRateSwapFields(formFields).map((field, idx) => (
            <React.Fragment key={idx}>{field?.element}</React.Fragment>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}
