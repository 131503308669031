import LogsIcon from "@mui/icons-material/Restore";
import { useOrganization } from "@clerk/clerk-react";

import IconButton from "../icon-button";

interface IProps {
  onClick: () => void;
}

export default function LogsButton({ onClick }: IProps): JSX.Element | null {
  const { organization } = useOrganization();

  if (!organization) {
    return null;
  }

  return (
    <IconButton onClick={onClick}>
      <LogsIcon />
    </IconButton>
  );
}
