import React from "react";
import { useOrganization } from "@clerk/clerk-react";

import PrimarySidebar from "./primary";
import PersonalAccountSidebar from "./personal-account";
import { useSessionStorage } from "../../utils/hooks";

interface IProps {
  mobileSidebarOpen: boolean;
  toggleSidebar: () => void;
}

export default function Sidebar(props: IProps): JSX.Element {
  const { organization } = useOrganization();

  const [_, setCurrentSharedDealOrgId] = useSessionStorage<null>(
    "current-shared-deal-org-uuid",
    null,
  );

  React.useEffect(() => {
    if (organization) {
      setCurrentSharedDealOrgId(null);
    }
  }, []);

  if (organization) {
    return <PrimarySidebar {...props} />;
  } else {
    return <PersonalAccountSidebar {...props} />;
  }
}
