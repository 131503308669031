import ConfigurationPageLayout from "../../../components/configuration-page-layout";
import ConfigurationOrganizationBillingAndUsageView from "../../../views/configuration/organization/billing-and-usage";
import { getProductUsageDetails } from "../../../apis/configuration/curves";

export default function ConfigurationOrganizationBillingAndUsagePage() {
  return (
    <ConfigurationPageLayout showOrgTabs>
      <ConfigurationOrganizationBillingAndUsageView
        getProductUsageDetails={getProductUsageDetails}
      />
    </ConfigurationPageLayout>
  );
}
