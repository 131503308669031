import { IUpdateProjectTimingForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_TIMING_SPONSOR_HORIZON {
  DO = "Develop & Operate",
  DN = "Develop & Sell NTP",
  DC = "Develop & Sell COD",
  AO = "Acq. Ntp & Operate",
  CO = "Acq. COD & Operate",
  CE = "Acq. COD & Equity",
}

export const PROJECT_TIMING_SPONSOR_HORIZON_OPTIONS = enumToOptions(
  PROJECT_TIMING_SPONSOR_HORIZON,
);

export enum PROJECT_TIMING_HORIZON {
  DS = "Development Start",
  CS = "Construction Start",
  OS = "Operations Start",
}

export const PROJECT_TIMING_HORIZON_OPTIONS = enumToOptions(
  PROJECT_TIMING_HORIZON,
);

export enum PROJECT_TIMING_EVALUATION_START {
  COD = "COD",
  BOL = "BOL",
  NTP = "NTP",
  MC = "MC",
  PIS = "PIS",
  SC = "SC",
}

export const PROJECT_TIMING_EVALUATION_START_OPTIONS = enumToOptions(
  PROJECT_TIMING_EVALUATION_START,
);

export enum PROJECT_TIMING_ADJUSTMENT {
  NO = "No Adjustment",
  EP = "End of Period (3, 6, 9, 12 mos)",
}

export const PROJECT_TIMING_ADJUSTMENT_OPTIONS = enumToOptions(
  PROJECT_TIMING_ADJUSTMENT,
);

export const PROJECT_TIMING_HORIZON_FIELD_MAPPING = {
  DS: [
    "horizon",
    "start_date",
    "ntp_date",
    "mechanical_completion_date",
    "substantial_completion_date",
    "placed_in_service_date",
    "permission_to_operate_date",
    "cod",
    "operating_life_years",
    "periodicity",
    "adjustment",
  ],
  CS: [
    "horizon",
    "ntp_date",
    "mechanical_completion_date",
    "substantial_completion_date",
    "placed_in_service_date",
    "permission_to_operate_date",
    "cod",
    "operating_life_years",
    "periodicity",
    "adjustment",
  ],
  OS: ["horizon", "cod", "operating_life_years", "periodicity", "adjustment"],
};

export const UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE: IUpdateProjectTimingForm =
  {
    horizon: "",
    start_date: "",
    operating_life_years: "",
    cod: "",
    ntp_date: "",
    placed_in_service_date: "",
    mechanical_completion_date: "",
    substantial_completion_date: "",
    permission_to_operate_date: "",
  };
