import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealComponentPagesLayout from "../../../../components/deal-component-page-layout";
import DealProformaSummaryView from "../../../../views/deal/components/pro-forma/summary";
import { getDealProformaSummary } from "../../../../apis/deal/proforma";

export default function DealProformaSummaryPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealComponentPagesLayout>
        <DealProformaSummaryView
          getDealProformaSummary={getDealProformaSummary}
        />
      </DealComponentPagesLayout>
    </DealPagesLayout>
  );
}
