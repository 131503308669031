import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputTaxEquityCashView from "../../../../views/deal/output/tax-equity/cash";
import {
  getDealTimeSeriesReport,
  downloadDealTimeSeriesReport,
} from "../../../../apis/report/deal";

export default function DealOutputTaxEquityCash(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputTaxEquityCashView
          getDealTimeSeriesReport={getDealTimeSeriesReport}
          downloadDealTimeSeriesReport={downloadDealTimeSeriesReport}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
