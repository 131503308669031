import React from "react";
import Box from "@mui/material/Box";
import { useOrganization } from "@clerk/clerk-react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import Menu from "../../../../../components/menu";
import ViewWrapper from "../../../../../components/view-wrapper";
import Chart from "../../../../project/pro-forma/dashboard/chart";
import ReportTableV2 from "../../../../../components/report-table-v2";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { cn } from "../../../../../utils/helpers";
import {
  PERIODICITY,
  PERIODICITY_OPTIONS,
  PROFORMA_BASIS,
  PROFORMA_BASIS_OPTIONS,
  PROFORMA_CHART_DETAIL_CONFIG,
} from "../../../../../constants";
import {
  ProformaReportType,
  IProjectProformaResponse,
  IReportTable,
} from "../../../../../interfaces";

interface IProps {
  getDealProforma: (
    id: string,
    periodicity: ProformaReportType,
    basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
  downloadDealProforma: (
    id: string,
    periodicity: ProformaReportType,
    basis: "cash" | "accrual",
  ) => Promise<void>;
}

export default function DealProformaScheduleView({
  getDealProforma,
  downloadDealProforma,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const [selectedPeriodicity, setSelectedPeriodicity] = useSessionStorage<
    ProformaReportType | ""
  >(`deal-periodicity-${dealUuid}`, "");

  const [selectedBasis, setSelectedBasis] = useSessionStorage<
    "cash" | "accrual"
  >(`deal-selected-basis-${caseDealUuid}`, "cash");

  const [dealProforma, setDealProforma] = React.useState<IReportTable | null>(
    null,
  );

  React.useEffect(() => {
    if (searchParams.has("periodicity")) {
      const periodicity = searchParams.get("periodicity") as ProformaReportType;
      setSelectedPeriodicity(periodicity);
    } else {
      setSelectedPeriodicity(selectedPeriodicity || "AN");
      navigate(
        `${location.pathname}?periodicity=${selectedPeriodicity || "AN"}`,
        {
          replace: true,
        },
      );
    }
  }, []);

  React.useEffect(() => {
    if (caseDealUuid) {
      // Get the current value from sessionStorage
      const currentValue = sessionStorage.getItem(
        `deal-selected-basis-${caseDealUuid}`,
      );
      // If there's a value in sessionStorage for the new caseDealUuid, update activeShowUnlevered
      if (currentValue !== null) {
        setSelectedBasis(JSON.parse(currentValue));
      }
    }
  }, [caseDealUuid]);

  const {
    callAPI: getDealProformaCallAPI,
    errored: getDealProformaFailed,
    loading: loadingDealProforma,
  } = useAPI(
    (dealUuid: string, reportType: ProformaReportType, basis) =>
      getDealProforma(dealUuid, reportType, basis),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    getDealProformaCallAPI(
      dealIdToUse,
      selectedPeriodicity,
      selectedBasis,
    ).then((response) => {
      response && setDealProforma(response.data);
    });
  }, [caseDealUuid, selectedPeriodicity, selectedBasis]);

  const handleReportTypeChange = (v: ProformaReportType) => {
    setSelectedPeriodicity(v);
    navigate(`${location.pathname}?periodicity=${v}`, {
      replace: true,
    });
  };

  const handleDownloadDealProforma = async () => {
    if (selectedPeriodicity && caseDealUuid) {
      await downloadDealProforma(
        String(caseDealUuid),
        selectedPeriodicity,
        selectedBasis,
      ).catch(() => null);
    }
  };

  const handleBasisChange = (basis: "cash" | "accrual") => {
    setSelectedBasis(basis);
  };

  return (
    <>
      <Box className={cn("flex justify-between items-center mt-4")}>
        <Box className={cn("flex gap-4")}>
          <Menu
            selectedItem={
              selectedPeriodicity
                ? PERIODICITY[selectedPeriodicity]
                : selectedPeriodicity
            }
            placeholder="Periodicity"
            menuItems={PERIODICITY_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleReportTypeChange(o.value as ProformaReportType),
            }))}
          />
          <Menu
            selectedItem={
              selectedBasis ? PROFORMA_BASIS[selectedBasis] : selectedBasis
            }
            placeholder="Basis"
            menuItems={PROFORMA_BASIS_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleBasisChange(o.value as keyof typeof PROFORMA_BASIS),
            }))}
          />
        </Box>
      </Box>

      <ViewWrapper loading={loadingDealProforma} error={getDealProformaFailed}>
        {dealProforma ? (
          <Box className={cn("mt-4")}>
            <Chart
              chartData={dealProforma}
              chartElementConfig={PROFORMA_CHART_DETAIL_CONFIG}
              selectedBasis={selectedBasis}
            />
            <ReportTableV2
              data={dealProforma}
              groupExpandedDepth={1}
              download={handleDownloadDealProforma}
            />
          </Box>
        ) : null}
      </ViewWrapper>
    </>
  );
}
