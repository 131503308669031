import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Modal from "../modal";
import TextInput from "../text-input";
import {
  SetStateAction,
  ICounterpartyUser,
  ICounterpartyUserForm,
  ICounterpartyUserFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  loading: boolean;
  headerLabel: string;
  form: ICounterpartyUserForm;
  setForm: SetStateAction<ICounterpartyUserForm>;
  formErrors?: ICounterpartyUserFormErrors;
  setFormErrors: SetStateAction<ICounterpartyUserFormErrors | undefined>;
  onConfirm: (
    form: ICounterpartyUserForm,
  ) => Promise<ICounterpartyUser | undefined>;
  onClose: () => void;
  hideFields?: (keyof ICounterpartyUserForm)[];
}

const CounterpartyUserFormModal = ({
  open,
  loading,
  headerLabel,
  form,
  setForm,
  formErrors,
  setFormErrors,
  onConfirm,
  onClose,
  hideFields = [],
}: IProps): JSX.Element => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOnAutocompleteChange = (
    event: React.SyntheticEvent,
    value: string[],
  ) => {
    setForm((prevState) => ({
      ...prevState,
      emails: value,
    }));
  };

  const getCleanedEmails = (text: string): string[] => {
    return text
      .split(/[\s,]+/) // Split by spaces, commas
      .map((email) => email.trim())
      .filter((email) => email); // Remove empty strings
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const emails = getCleanedEmails(e.target.value);
    if (emails.length > 0) {
      setForm((prevState) => ({
        ...prevState,
        emails: Array.from(new Set([...(prevState.emails || []), ...emails])),
      }));
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    const emails = getCleanedEmails(pastedText);

    setForm((prevState) => {
      const currentEmails = prevState?.emails || [];
      return {
        ...prevState,
        emails: Array.from(new Set([...currentEmails, ...emails])),
      };
    });
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleOnConfirm = async () => {
    const user = await onConfirm(form);
    user && onClose();
  };

  const handleResetForm = () => {
    setForm({ counterparty: null, emails: null, email: null });
    setFormErrors({});
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        {!hideFields.includes("emails") && (
          <Autocomplete
            multiple
            freeSolo
            clearOnBlur
            options={[]}
            value={form.emails || []}
            onBlur={handleOnBlur}
            onChange={handleOnAutocompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Emails"
                name="emails"
                type="email"
                helperText={formErrors?.emails}
                error={Boolean(formErrors?.emails)}
                onFocus={clearErrorOnFocus}
                onPaste={handlePaste}
                disabled={loading}
                autoFocus
                fullWidth
              />
            )}
          />
        )}

        {!hideFields.includes("email") && (
          <TextInput
            label="Email"
            name="email"
            type="email"
            value={form.email}
            helperText={formErrors?.email}
            error={Boolean(formErrors?.email)}
            onChange={handleOnChange}
            onFocus={clearErrorOnFocus}
            disabled={loading}
            autoFocus
            fullWidth
          />
        )}
      </Box>
    </Modal>
  );
};

export default React.memo(CounterpartyUserFormModal, (prevProps, nextProps) => {
  const stringifiedPrevProps = JSON.stringify(prevProps);
  const stringifiedNextProps = JSON.stringify(nextProps);
  return stringifiedPrevProps === stringifiedNextProps;
});
