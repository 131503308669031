import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import CheckboxInput from "../checkbox-input";
import ValuationDisclaimer from "../../views/project/fmv/step-up/disclaimer";
import { cn } from "../../utils/helpers";
import {
  PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS_OPTIONS,
  PROJECT_VALUATION_FMV_METHODS_OPTIONS,
  PROJECT_VALUATION_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  SetStateAction,
  IProjectValuationForm,
  IProjectValuationFormErrors,
  IProjectValuation,
} from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IProjectValuationForm;
  setForm: SetStateAction<IProjectValuationForm>;
  formErrors?: IProjectValuationFormErrors;
  setFormErrors: SetStateAction<IProjectValuationFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (
    form: IProjectValuationForm,
  ) => Promise<IProjectValuation | undefined>;
}

export default function ProjectValuationFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const projectValuation = await onConfirm(form);
    projectValuation && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(PROJECT_VALUATION_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{ paper: cn("!min-w-[50%]") }}
    >
      <React.Fragment>
        <ValuationDisclaimer classes={{ root: cn("mb-4") }} />
        <Box className={cn("grid grid-cols-2 gap-4")}>
          <Box>
            <TextInput
              required
              isNumeric
              label="Contracted Discount Rate"
              name="contracted_discount_rate"
              endAdornment={<>%</>}
              value={form.contracted_discount_rate}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.contracted_discount_rate)}
              helperText={formErrors?.contracted_discount_rate}
              disabled={loading}
            />
            <TextInput
              required
              isNumeric
              label="Merchant Discount Rate"
              name="merchant_discount_rate"
              endAdornment={<>%</>}
              value={form.merchant_discount_rate}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.merchant_discount_rate)}
              helperText={formErrors?.merchant_discount_rate}
              disabled={loading}
            />
            <TextInput
              required
              isNumeric
              label="Developer Profit"
              name="developer_profit"
              endAdornment={<>%</>}
              value={form.developer_profit}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.developer_profit)}
              helperText={formErrors?.developer_profit}
              disabled={loading}
            />

            <SelectInput
              label="ITC Eligible Basis Method"
              selected={form.itc_eligible_basis_method}
              items={PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS_OPTIONS}
              onFocus={() =>
                clearSelectErrorOnFocus("itc_eligible_basis_method")
              }
              onChange={(e) =>
                handleSelectInputChange(e, "itc_eligible_basis_method")
              }
              error={Boolean(formErrors?.itc_eligible_basis_method)}
              helperText={formErrors?.itc_eligible_basis_method}
              disabled={loading}
            />

            <SelectInput
              label="FMV Method"
              selected={form.fmv_method}
              items={PROJECT_VALUATION_FMV_METHODS_OPTIONS}
              onFocus={() => clearSelectErrorOnFocus("fmv_method")}
              onChange={(e) => handleSelectInputChange(e, "fmv_method")}
              error={Boolean(formErrors?.fmv_method)}
              helperText={formErrors?.fmv_method}
              disabled={loading}
            />
          </Box>
          <Box>
            <Typography classes={{ root: cn("!text-lg !font-semibold !mb-2") }}>
              Adders to include for Income Approach
            </Typography>
            <Box className={cn("flex flex-col")}>
              <CheckboxInput
                label="Domestic Content"
                name="itc_adder_domestic_content"
                checked={form.itc_adder_domestic_content}
                onChange={handleCheckboxChange}
              />
              <CheckboxInput
                label="Energy Community"
                name="itc_adder_energy_community"
                checked={form.itc_adder_energy_community}
                onChange={handleCheckboxChange}
              />
              <CheckboxInput
                label="Environmental Justice (EJ)"
                name="itc_adder_environmental_justice"
                checked={form.itc_adder_environmental_justice}
                onChange={handleCheckboxChange}
              />
              <CheckboxInput
                label="Special Qualified EJ"
                name="itc_adder_special_qualified_ej"
                checked={form.itc_adder_special_qualified_ej}
                onChange={handleCheckboxChange}
              />
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    </Modal>
  );
}
