import { ITab } from "../../interfaces/component.interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_OTHER_SIZING {
  CON = "Contracted",
  WBR = "Weighted by Revenue",
  EAC = "Excess as Uncontracted",
}

export enum DEAL_ALLOCATION_TYPE {
  CASH = "CASH",
  TAX = "TAX",
}

export enum DEAL_REVENUE_CONTRACT_ALLOCATION_BUCKET {
  CON = "Contracted",
  UNC = "Uncontracted",
  SPC = "Special",
}

export enum DEAL_REVENUE_MERCHANT_ALLOCATION_BUCKET {
  UNC = "Uncontracted",
  SPC = "Special",
}

export enum DEAL_EXPENSE_ALLOCATION_BUCKET {
  CON = "Contracted",
  WBR = "Weighted by Revenue",
  EAC = "Excess as Uncontracted",
}

export enum DEAL_OTHER_FLOWS_ALLOCATION_BUCKET {
  CON = "Contracted",
  UNC = "Uncontracted",
  SPC = "Special",
}

export const DEAL_OTHER_SIZING_OPTIONS = enumToOptions(DEAL_OTHER_SIZING);
export const DEAL_OTHER_SIZING_EXPENSE_OPTIONS = enumToOptions(
  DEAL_EXPENSE_ALLOCATION_BUCKET,
);
export const DEAL_OTHER_SIZING_REVENUE_CONTRACT_OPTIONS = enumToOptions(
  DEAL_REVENUE_CONTRACT_ALLOCATION_BUCKET,
);
export const DEAL_OTHER_SIZING_REVENUE_MERCHANT_OPTIONS = enumToOptions(
  DEAL_REVENUE_MERCHANT_ALLOCATION_BUCKET,
);
export const DEAL_OTHER_FLOWS_ALLOCATION_BUCKET_OPTIONS = enumToOptions(
  DEAL_OTHER_FLOWS_ALLOCATION_BUCKET,
);

export enum DEAL_OTHER_FLOW_REPORT_BASIS {
  cash = "Cash Basis",
  accrual = "Accrual Basis",
}

export const DEAL_OTHER_FLOW_REPORT_BASIS_OPTIONS = enumToOptions(
  DEAL_OTHER_FLOW_REPORT_BASIS,
);

export const DEAL_SIZING_OTHER_PAGE_TABS: ITab[] = [
  {
    label: "EBITDA",
    value: "ebitda",
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other/ebitda",
    pathToMatch: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other",
  },
  {
    label: "Depreciation",
    value: "depreciation",
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other/depreciation",
    pathToMatch: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other",
  },
];
