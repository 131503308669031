import { useOrganization } from "@clerk/clerk-react";

import PersonalAccountDealPagesLayout from "./personal-account";
import PrimaryDealPagesLayout from "./primary";

interface IProps {
  children: JSX.Element;
}

export default function DealPagesLayout({ children }: IProps): JSX.Element {
  const { organization } = useOrganization();

  const Layout = organization
    ? PrimaryDealPagesLayout
    : PersonalAccountDealPagesLayout;

  return <Layout>{children}</Layout>;
}
