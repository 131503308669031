import TabbedLayout from "../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectFmvDashboardView from "../../../views/project/fmv/dashboard";
import {
  downloadProjectValuationSummary,
  getProjectValuationSummary,
} from "../../../apis/project/valuation";

export default function ProjectFmvDashboardPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="FMV">
        <ProjectFmvDashboardView
          getProjectValuationSummary={getProjectValuationSummary}
          downloadProjectValuationSummary={downloadProjectValuationSummary}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
