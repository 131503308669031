import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTableV2 from "../../../../../components/report-table-v2";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import { getDealTimeSeriesReportUrl, cn } from "../../../../../utils/helpers";
import { useAxios } from "../../../../../components/axios-provider";
import {
  useAPI,
  useAppSelector,
  useSessionStorage,
} from "../../../../../utils/hooks";
import {
  IDealReport,
  ReportTerm,
  ReportType,
  ReportPerspective,
  OutputReportLeverage,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealTimeSeriesReport: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
    reportLeverage?: OutputReportLeverage,
  ) => Promise<IDealReport>;
  downloadDealTimeSeriesReport: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    periodicityType: OutputReportPeriodicityType,
    reportLeverage?: OutputReportLeverage,
  ) => Promise<void>;
}

export default function DealOutputSponsorEquityCashView({
  getDealTimeSeriesReport,
  downloadDealTimeSeriesReport,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { urlLoadingPercentages } = useAxios();

  const { currentDealTermDebt } = useAppSelector((s) => s.deal);

  const [report, setReport] = React.useState<IDealReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealUuid}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealUuid}-output-periodicity`,
      "MO",
    );
  const [leverage, setLeverage] = useSessionStorage<OutputReportLeverage>(
    `deal-${dealUuid}-output-leverage`,
    "levered",
  );

  React.useEffect(() => {
    if (dealIdToUse) {
      getDealTimeSeriesReportCallAPI(
        dealIdToUse,
        buyout,
        periodicityType,
        !!currentDealTermDebt ? leverage : "unlevered",
      ).then((response) => {
        if (response) {
          setReport(response);
        }
      });
    }
  }, [dealIdToUse, buyout, periodicityType, leverage]);

  const {
    callAPI: getDealTimeSeriesReportCallAPI,
    errored: getDealTimeSeriesReportFailed,
    loading: loadingDealTimeSeriesReport,
    errorObj: getDealTimeSeriesReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealUuid: string,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
      leverage: OutputReportLeverage,
    ) =>
      getDealTimeSeriesReport(
        dealUuid,
        "sponsorequity",
        "cashdetail",
        term,
        reportPeriodicityType,
        leverage,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingDealTimeSeriesReport &&
      dispatch(updateDealOutputLoadedAction(!getDealTimeSeriesReportFailed));
  }, [getDealTimeSeriesReportFailed, loadingDealTimeSeriesReport]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadDealTimeSeriesReport(
        dealIdToUse,
        "sponsorequity",
        "cashdetail",
        buyout,
        periodicityType,
        !!currentDealTermDebt ? leverage : "unlevered",
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  return (
    <ViewWrapper
      loading={loadingDealTimeSeriesReport}
      error={getDealTimeSeriesReportFailed}
      errorDescription={getDealTimeSeriesReportErrorObj?.description}
      errorHeading={getDealTimeSeriesReportErrorObj?.heading}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealTimeSeriesReportUrl(
            String(dealIdToUse),
            "sponsorequity",
            "cashdetail",
            buyout,
            periodicityType,
            !!currentDealTermDebt ? leverage : "unlevered",
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        periodicityType={periodicityType}
        setBuyout={setBuyout}
        setPeriodicityType={setPeriodicityType}
        showBuyout={report?.has_investor_buyout}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showPeriodicity
        showSizingRedirectionButton
        showLeveredDropdown={!!currentDealTermDebt}
        leverage={leverage}
        setLeverage={setLeverage}
      />
      {report?.data ? (
        <Box className={cn("mt-4")}>
          <ReportTableV2
            data={report?.data}
            groupExpandedDepth={-1}
            autoHeight={true}
          />
        </Box>
      ) : null}
    </ViewWrapper>
  );
}
