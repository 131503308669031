import React from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Logs from "../logs";
import LogsButton from "../logs-button";
import LogsWrapper from "../logs-wrapper";
import ActionButton from "../action-button";
import ConditionalProtect from "../conditional-protect";
import UpdateCaseFormModal from "../update-case-form-modal";
import DealCaseDuplicateFormModal from "../deal-case-duplicate-form-modal";
import { useAPI, useAppSelector, useDrawer, useLogs } from "../../utils/hooks";
import { cn } from "../../utils/helpers";
import {
  setCasesOfDealAction,
  setDeleteModalPropsAction,
} from "../../utils/redux/slices";
import {
  UPDATE_CASE_FORM_DEFAULT_STATE,
  DUPLICATE_CASE_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  IDealCase,
  IDuplicateDealCaseForm,
  IDuplicateDealCaseFormErrors,
  ILogsConfiguration,
  IUpdateDealCaseForm,
  IUpdateDealCaseFormErrors,
} from "../../interfaces";

interface IProps {
  deleteDealCase: (dealUuid: string, caseId: string) => Promise<boolean>;
  duplicateDealCase: (
    dealUuid: string,
    caseId: string,
    form: IDuplicateDealCaseForm,
  ) => Promise<IDealCase>;
  getDealCaseDetails: (dealUuid: string, caseId: string) => Promise<IDealCase>;
  updateDealCase: (
    dealUuid: string,
    caseId: string,
    form: IUpdateDealCaseForm,
  ) => Promise<IDealCase>;
  refetchCasesOfDeal: () => Promise<void>;
}

export default function CaseActionButtons({
  deleteDealCase,
  duplicateDealCase,
  getDealCaseDetails,
  updateDealCase,
  refetchCasesOfDeal,
}: IProps): JSX.Element {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { casesOfDeal } = useAppSelector((s) => s.deal);

  const { caseId, dealUuid } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleOpenDrawer, handleCloseDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  React.useEffect(() => {
    getDealCaseDetailsCallAPI(dealUuid, caseId);
  }, [dealUuid, caseId]);

  const [duplicateCaseModalOpen, setDuplicateCaseModalOpen] =
    React.useState<boolean>(false);
  const [duplicateCaseForm, setDuplicateCaseForm] =
    React.useState<IDuplicateDealCaseForm>(DUPLICATE_CASE_FORM_DEFAULT_STATE);
  const [editCaseModalOpen, setEditCaseModalOpen] =
    React.useState<boolean>(false);
  const [editDealCaseForm, setEditDealCaseForm] =
    React.useState<IUpdateDealCaseForm>(UPDATE_CASE_FORM_DEFAULT_STATE);

  const { callAPI: deleteDealCaseCallAPI } = useAPI(
    (dealUuid, caseId) => deleteDealCase(dealUuid, caseId),
    { setConfirmModalLoading: true },
  );

  const { callAPI: getDealCaseDetailsCallAPI, response: caseDetails } = useAPI(
    (dealUuid: string, caseId: string) => getDealCaseDetails(dealUuid, caseId),
    { initialLoading: true },
  );

  const {
    callAPI: duplicateCaseCallAPI,
    fieldErrors: duplicateCaseFormErrors,
    setFieldErrors: setDuplicateCaseFormErrors,
    loading: duplicateCaseLoading,
  } = useAPI<IDealCase, IDuplicateDealCaseFormErrors>(
    (caseId: string, form: IDuplicateDealCaseForm) =>
      duplicateDealCase(String(dealUuid), caseId, form),
  );

  const {
    callAPI: editDealCaseCallAPI,
    fieldErrors: editDealCaseFormErrors,
    setFieldErrors: setEditDealCaseFormErrors,
    loading: editDealCaseLoading,
  } = useAPI<IDealCase, IUpdateDealCaseFormErrors>(
    (caseId: string, form: IUpdateDealCaseForm) =>
      updateDealCase(String(dealUuid), caseId, form),
  );

  const gotoCasesList = () => {
    navigate(`/deal/${dealUuid}/cases`);
  };

  const onDeleteCase = async (caseUuid: string) => {
    const response = await deleteDealCaseCallAPI(dealUuid, caseUuid);

    if (response) {
      setCasesOfDealAction(casesOfDeal.filter((d) => d.uuid !== caseUuid));
      gotoCasesList();
    }
  };

  const handleOnDeleteClick = (caseUuid: string) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Case",
        description: "Are you sure you want to delete?",
        onConfirm: () => onDeleteCase(caseUuid),
      }),
    );
  };

  const handleOpenDuplicateModal = (caseUuid: string) => {
    setDuplicateCaseModalOpen(true);
    setDuplicateCaseForm((prev) => ({ ...prev, uuid: caseUuid }));
  };

  const handleOpenEditModal = (caseUuid: string) => {
    if (caseDetails) {
      setEditCaseModalOpen(true);

      setEditDealCaseForm({
        uuid: caseUuid,
        name: caseDetails.name,
        description: caseDetails.description,
      });
    }
  };

  const handleCloseDuplicateModal = () => {
    setDuplicateCaseModalOpen(false);
  };

  const handleDuplicateCase = async (form: IDuplicateDealCaseForm) => {
    const { uuid: caseUuid } = form;
    const dealCase = await duplicateCaseCallAPI(caseUuid, form);

    if (dealCase) {
      refetchCasesOfDeal();
      navigate(`/deal/${dealUuid}/case/${dealCase.uuid}`);
    }

    return dealCase;
  };

  const handleCloseEditModal = () => {
    setEditCaseModalOpen(false);
  };

  const handleEditCase = async (form: IUpdateDealCaseForm) => {
    const { uuid: caseUuid } = form;
    const dealCase = await editDealCaseCallAPI(caseUuid, form);
    if (dealCase) {
      refetchCasesOfDeal();
      getDealCaseDetailsCallAPI(dealUuid, caseUuid);
    }
    return dealCase;
  };

  const caseDetailsLogConfiguration: ILogsConfiguration = {
    id: String(caseDetails?.child_deal?.uuid),
    type: "deal",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      caseDetailsLogConfiguration.type,
      caseDetailsLogConfiguration.id,
    );
  };

  return (
    <Box className={cn("flex flex-col items-end gap-2")}>
      <LogsButton onClick={handleOnOpenLogs} />
      <ConditionalProtect type="deal">
        <Box className={cn("flex items-center gap-4")}>
          <ActionButton
            actionType="delete"
            canOpenUpgrade
            onClick={() => handleOnDeleteClick(String(caseDetails?.uuid))}
          >
            Delete
          </ActionButton>

          <ActionButton
            actionType="duplicate"
            canOpenUpgrade
            onClick={() => handleOpenDuplicateModal(String(caseDetails?.uuid))}
          >
            Duplicate
          </ActionButton>

          <ActionButton
            actionType="edit"
            canOpenUpgrade
            onClick={() => handleOpenEditModal(String(caseDetails?.uuid))}
          >
            Edit
          </ActionButton>
        </Box>
      </ConditionalProtect>

      <DealCaseDuplicateFormModal
        headerLabel="Duplicate Case"
        open={duplicateCaseModalOpen}
        loading={duplicateCaseLoading}
        form={duplicateCaseForm}
        setForm={setDuplicateCaseForm}
        formErrors={duplicateCaseFormErrors}
        setFormErrors={setDuplicateCaseFormErrors}
        onClose={handleCloseDuplicateModal}
        onConfirm={handleDuplicateCase}
      />

      <UpdateCaseFormModal
        headerLabel="Edit Case"
        open={editCaseModalOpen}
        loading={editDealCaseLoading}
        form={editDealCaseForm}
        setForm={setEditDealCaseForm}
        formErrors={editDealCaseFormErrors}
        setFormErrors={setEditDealCaseFormErrors}
        onClose={handleCloseEditModal}
        onConfirm={handleEditCase}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={caseDetailsLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={caseDetailsLogConfiguration.id}
        />
      </LogsWrapper>
    </Box>
  );
}
