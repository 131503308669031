import {
  IReportTable,
  IProjectTaxCredit,
  IsummaryTablesObject,
  IUpdateProjectTaxCreditForm,
} from "../../interfaces";
import { PROJECT_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getProjectTaxDashboardSummary = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.summary, {
    projectUuid,
  });

  return await AxiosHelper.get<IsummaryTablesObject>(url);
};

export const downloadProjectTaxDashboardSummary = async (
  projectUuid: string,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.summary, {
    projectUuid,
  });

  return await AxiosHelper.downloadFile(`${url}?output=xlsx`);
};

export const getProjectTaxCredit = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.get, { projectUuid });
  return await AxiosHelper.get<IProjectTaxCredit[]>(url);
};

export const getProjectTaxCreditPTC_Report = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.getPTC_Report, {
    projectUuid,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadProjectTaxCreditPTC_Report = async (
  projectUuid: string,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.downloadPTC_Report, {
    projectUuid,
  });
  return await AxiosHelper.downloadFile(url);
};

export const updateProjectTaxCredit = async (
  projectUuid: string,
  taxCreditId: number,
  form: IUpdateProjectTaxCreditForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.update, {
    projectUuid,
    taxCreditId,
  });
  return await AxiosHelper.patch<
    IUpdateProjectTaxCreditForm,
    IProjectTaxCredit
  >(url, form);
};
