import React from "react";
import { SelectChangeEvent } from "@mui/material/Select";

import TextInput from "../text-input";
import SelectInput from "../select-input";
import DatePicker from "../date-picker";
import CurrencyInput from "../currency-input";
import AutocompleteField from "../autocomplete-field";
import { enumToOptions } from "../../utils/helpers";
import {
  ISelectOption,
  IFormFieldElement,
  IDealTaxEquityForm,
  IDealTaxEquityFormErrors,
} from "../../interfaces";
import {
  DEAL_TE_SOLVE_FORS,
  DEAL_TE_SOLVE_FOR_OPTIONS,
  DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS,
  DEAL_TAX_EQUITY_TYPE_OPTIONS,
  DEAL_TAX_EQUITY_PREF_RETURN_TYPE_OPTIONS,
  DEAL_TE_BUYOUT_TYPES_OPTIONS,
  DEAL_TE_BUYOUT_CALC_METHOD_TYPES_OPTIONS,
  DEAL_TE_BUYOUT_IRR_INPUT_TYPES_OPTIONS,
  DEAL_TE_INPUT_METHOD_OPTIONS,
  DEAL_TE_INPUT_METHOD,
  DEAL_ITC_RECOGNITION_METHOD_OPTIONS,
  DEAL_FUNDING_METHOD_OPTIONS,
  CARRYFORWARD_CREDITS_OPTIONS,
  ACCOUNTING_METHODS_OPTIONS,
  TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME_OPTIONS,
  SUSPEND_PRE_TAX_BOOK_LOSS_OPTIONS,
  DEAL_IC_LOSS_REALLOCATION_METHOD_TYPES_OPTIONS,
  DEAL_TE_FLIP_ROI_INPUT_TYPES_OPTIONS,
  DEAL_TE_BUYOUT_CALC_METHOD_TYPES,
  PERIODICITY_OPTIONS,
  TAX_COMPLIANCE_PERIOD_YEAR_TYPES_OPTIONS,
  PROJECT_ESCALATOR_BASE_YEAR_OPTIONS,
} from "../../constants";

interface IParams {
  form: IDealTaxEquityForm;
  formErrors?: IDealTaxEquityFormErrors;
  loading: boolean;
  clearNonTextFieldErrorOnFocus: (fieldName: string) => void;
  onSelectInputChange: (e: SelectChangeEvent<unknown>, value: string) => void;
  clearErrorOnFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange: (v: Date | null, field: string) => void;
  clearSelectErrorOnFocus: (name: string) => void;
  handleSingleAutoCompleteChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => void;
  onYearChange: (v: Date | null, field: string) => void;
  onTaxEquityInputValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IFields {
  structure: Array<IFormFieldElement<IDealTaxEquityForm>>;
  timing: Array<IFormFieldElement<IDealTaxEquityForm>>;
  returns: Array<IFormFieldElement<IDealTaxEquityForm>>;
  sizing: Array<IFormFieldElement<IDealTaxEquityForm>>;
  taxes: Array<IFormFieldElement<IDealTaxEquityForm>>;
  initial_distributions_and_allocations: Array<
    IFormFieldElement<IDealTaxEquityForm>
  >;
  additional_cash_distributions: Array<IFormFieldElement<IDealTaxEquityForm>>;
  additional_tax_allocations: Array<IFormFieldElement<IDealTaxEquityForm>>;
  tax_methods: Array<IFormFieldElement<IDealTaxEquityForm>>;
  buyout: Array<IFormFieldElement<IDealTaxEquityForm>>;
  accounting: Array<IFormFieldElement<IDealTaxEquityForm>>;
}

export const getDealTaxEquityFields = ({
  form,
  formErrors,
  loading,
  clearErrorOnFocus,
  clearNonTextFieldErrorOnFocus,
  onTextChange,
  onSelectInputChange,
  onDateChange,
  clearSelectErrorOnFocus,
  handleSingleAutoCompleteChange,
  onYearChange,
  onTaxEquityInputValueChange,
}: IParams): IFields => {
  const getSizingMethodOptions = () => {
    switch (form.type) {
      case "YBF": {
        return enumToOptions(DEAL_TE_SOLVE_FORS, [
          // disable options
          // "FROI",
          "AFDATE",
          "IRR",
        ]);
      }
      case "FF": {
        return enumToOptions(DEAL_TE_SOLVE_FORS, [
          // disable all except for Solve for IRR
          "TEQAMT",
          "PFCS",
          "FDATE",
          "AFDATE",
        ]);
      }
      case "PR": {
        return enumToOptions(DEAL_TE_SOLVE_FORS, [
          // disable options
          "TEQAMT",
          "PFCS",
          "FDATE",
          "AFDATE",
        ]);
      }
      default: {
        return DEAL_TE_SOLVE_FOR_OPTIONS;
      }
    }
  };

  const getTaxAllocationMethodOptions = () => {
    switch (form.type) {
      case "PR": {
        return enumToOptions(DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS, [
          // disable options
          "REG",
          "P_99_67_99_5",
        ]);
      }
      default: {
        return enumToOptions(DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS, [
          "PRORATA",
        ]);
      }
    }
  };

  const getTaxEquityInputMethodOptions = () => {
    switch (form.type) {
      case "PR": {
        return enumToOptions(DEAL_TE_INPUT_METHOD, [
          // disable options
          "DLRAMT",
          "ITCMU",
        ]);
      }
      default: {
        return DEAL_TE_INPUT_METHOD_OPTIONS;
      }
    }
  };
  const getBuyoutCalculationMethodOptions = () => {
    switch (form.solve_for) {
      case "TEQAMT": {
        return enumToOptions(DEAL_TE_BUYOUT_CALC_METHOD_TYPES, [
          // disable options
          "POI",
        ]);
      }
      default: {
        return DEAL_TE_BUYOUT_CALC_METHOD_TYPES_OPTIONS;
      }
    }
  };

  return {
    structure: [
      {
        name: "type",
        element: (
          <SelectInput
            required
            label="Type"
            selected={form.type ?? ""}
            items={DEAL_TAX_EQUITY_TYPE_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("type")}
            onChange={(e) => onSelectInputChange(e, "type")}
            error={Boolean(formErrors?.type)}
            helperText={formErrors?.type}
            disabled={loading}
            tooltip={`<b>Yield Based Flip</b> = Partnership Flip where Investor targets a specified after-tax IRR over a horizon (Flip Term) \\n<b>Fixed Flip</b> = Partnership Flip where Investor sets a fixed term for the flip horizon \\n<b>Prorata</b> = Partnership without a "flip", where the initial cash and tax allocations remain constant`}
            fullWidth={false}
          />
        ),
      },
      {
        name: "solve_for",
        element: (
          <SelectInput
            required
            label="Solve for"
            selected={form.solve_for ?? ""}
            items={getSizingMethodOptions()}
            onFocus={() => clearNonTextFieldErrorOnFocus("solve_for")}
            onChange={(e) => onSelectInputChange(e, "solve_for")}
            error={Boolean(formErrors?.solve_for)}
            helperText={formErrors?.solve_for}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
    ],
    timing: [
      {
        name: "deal_funding_method",
        element: (
          <SelectInput
            required
            label="Funding Method"
            selected={form.deal_funding_method ?? ""}
            items={DEAL_FUNDING_METHOD_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("deal_funding_method")}
            onChange={(e) => onSelectInputChange(e, "deal_funding_method")}
            error={Boolean(formErrors?.deal_funding_method)}
            helperText={formErrors?.deal_funding_method}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "tax_equity_funding_date_mcsc",
        element: (
          <TextInput
            label={"Investor 1st Funding Date"}
            value={form.tax_equity_funding_date_mcsc}
            error={Boolean(formErrors?.tax_equity_funding_date_mcsc)}
            helperText={formErrors?.tax_equity_funding_date_mcsc}
            disabled={true}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            deal_funding_method: ["MCSC"],
          },
        },
      },
      {
        name: "tax_equity_funding_date_cod",
        element: (
          <TextInput
            label={"Investor Funding Date"}
            value={form.tax_equity_funding_date_cod}
            error={Boolean(formErrors?.tax_equity_funding_date_cod)}
            helperText={formErrors?.tax_equity_funding_date_cod}
            disabled={true}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            deal_funding_method: ["COD"],
          },
        },
      },
      {
        name: "target_flip_term",
        element: (
          <TextInput
            required
            isNumeric
            label="Target Flip Term"
            name="target_flip_term"
            endAdornment={<>Yrs</>}
            value={form.target_flip_term ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.target_flip_term)}
            helperText={formErrors?.target_flip_term}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            solve_for: ["TEQAMT", "PFCS", "IRR", ""],
            type: ["YBF", "FF"],
          },
        },
      },
      {
        name: "distribution_periodicity",
        element: (
          <SelectInput
            required
            label="Distribution Periodicity"
            selected={form.distribution_periodicity ?? ""}
            items={PERIODICITY_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("distribution_periodicity")
            }
            onChange={(e) => onSelectInputChange(e, "distribution_periodicity")}
            error={Boolean(formErrors?.distribution_periodicity)}
            helperText={formErrors?.distribution_periodicity}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
    ],
    returns: [
      {
        name: "target_flip_irr_type",
        element: (
          <SelectInput
            required
            label="Target IRR Type"
            selected={form.target_flip_irr_type ?? ""}
            items={DEAL_TE_FLIP_ROI_INPUT_TYPES_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("target_flip_irr_type")
            }
            onChange={(e) => onSelectInputChange(e, "target_flip_irr_type")}
            error={Boolean(formErrors?.target_flip_irr_type)}
            helperText={formErrors?.target_flip_irr_type}
            disabled={loading}
            fullWidth={false}
          />
        ),
        // visibile when type is not Prorata(PR)
        visibility: {
          NOT: {
            AND: {
              type: ["PR"],
            },
          },
        },
      },
      {
        name: "target_flip_irr",
        element: (
          <TextInput
            required
            isNumeric
            label="Target Flip IRR"
            name="target_flip_irr"
            endAdornment={<>%</>}
            value={form.target_flip_irr ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.target_flip_irr)}
            helperText={formErrors?.target_flip_irr}
            disabled={loading}
            fullWidth={false}
          />
        ),
        // visibile when type is not Prorata(PR)
        visibility: {
          NOT: {
            AND: {
              type: ["PR", "FF"],
            },
          },
        },
      },
      {
        name: "pre_tax_irr_floor",
        element: (
          <TextInput
            required
            isNumeric
            label="Pre-Tax IRR (with Credits) Floor"
            name="pre_tax_irr_floor"
            endAdornment={<>%</>}
            value={form.pre_tax_irr_floor ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.pre_tax_irr_floor)}
            helperText={formErrors?.pre_tax_irr_floor}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
          },
        },
      },
      {
        name: "roi_floor_full_term",
        element: (
          <TextInput
            isNumeric
            label="ROI Floor (Full-Term)"
            name="roi_floor_full_term"
            endAdornment={<>%</>}
            value={form.roi_floor_full_term ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.roi_floor_full_term)}
            helperText={formErrors?.roi_floor_full_term}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
          },
        },
      },
      {
        name: "roi_floor_buyout_term",
        element: (
          <TextInput
            isNumeric
            label="ROI Floor (Buyout)"
            name="roi_floor_buyout_term"
            endAdornment={<>%</>}
            value={form.roi_floor_buyout_term ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.roi_floor_buyout_term)}
            helperText={formErrors?.roi_floor_buyout_term}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
          },
        },
      },
    ],
    sizing: [
      {
        name: "tax_equity_input_method",
        element: (
          <SelectInput
            required
            label="Investor Input Method"
            selected={form.tax_equity_input_method ?? ""}
            items={getTaxEquityInputMethodOptions()}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("tax_equity_input_method")
            }
            onChange={(e) => onSelectInputChange(e, "tax_equity_input_method")}
            error={Boolean(formErrors?.tax_equity_input_method)}
            helperText={formErrors?.tax_equity_input_method}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          OR: {
            solve_for: ["PFCS", "FDATE", "IRR"],
          },
        },
      },
      {
        name: "tax_equity_input_value",
        element: (
          <TextInput
            required
            isNumeric
            label="% of FMV"
            name="tax_equity_input_value"
            value={form.tax_equity_input_value ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTaxEquityInputValueChange}
            endAdornment={<>%</>}
            error={Boolean(formErrors?.tax_equity_input_value)}
            helperText={formErrors?.tax_equity_input_value}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            solve_for: ["PFCS", "FDATE", "IRR"],
            tax_equity_input_method: ["PCTFMV"],
          },
        },
      },
      {
        name: "tax_equity_input_value", // TODO should rename ?
        element: (
          <CurrencyInput
            required
            label="Dollar Amount"
            name="tax_equity_input_value"
            value={form.tax_equity_input_value?.toString() ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.tax_equity_input_value)}
            helperText={formErrors?.tax_equity_input_value}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            solve_for: ["PFCS", "FDATE", "IRR"],
            tax_equity_input_method: ["DLRAMT"],
          },
        },
      },
      {
        name: "tax_equity_input_value", // TODO should rename ?
        element: (
          <TextInput
            required
            isNumeric
            label="ITC Multiple"
            name="tax_equity_input_value"
            value={form.tax_equity_input_value ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            endAdornment={<>x</>}
            error={Boolean(formErrors?.tax_equity_input_value)}
            helperText={formErrors?.tax_equity_input_value}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            solve_for: ["PFCS", "FDATE", "IRR"],
            tax_equity_input_method: ["ITCMU"],
          },
        },
      },
    ],
    taxes: [
      {
        name: "federal_tax_rate",
        element: (
          <TextInput
            required
            isNumeric
            label="Federal Tax Rate"
            name="federal_tax_rate"
            value={form.federal_tax_rate}
            endAdornment={<>%</>}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.federal_tax_rate)}
            helperText={formErrors?.federal_tax_rate}
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "change_in_federal_tax_rate",
        element: (
          <AutocompleteField
            label="Change in Federal Tax Rate"
            name="change_in_federal_tax_rate"
            onChange={handleSingleAutoCompleteChange}
            options={[
              { label: "Yes", value: "Y" },
              { label: "No", value: "N" },
            ]}
            value={String(form.change_in_federal_tax_rate)}
            helperText={formErrors?.change_in_federal_tax_rate}
            onFocus={() =>
              clearSelectErrorOnFocus("change_in_federal_tax_rate")
            }
            disabled={loading}
            fullWidth={false}
          />
        ),
      },
      {
        name: "new_federal_tax_rate",
        element: (
          <TextInput
            required
            isNumeric
            label="New Federal Tax Rate"
            name="new_federal_tax_rate"
            value={form.new_federal_tax_rate}
            endAdornment={<>%</>}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.new_federal_tax_rate)}
            helperText={formErrors?.new_federal_tax_rate}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            change_in_federal_tax_rate: ["Y"],
          },
        },
      },
      {
        name: "federal_tax_rate_change_year",
        element: (
          <DatePicker
            label="Tax Rate Change Year"
            value={
              form.federal_tax_rate_change_year
                ? new Date(form.federal_tax_rate_change_year, 0, 1)
                : null
            }
            onChange={(v) => onYearChange(v, "federal_tax_rate_change_year")}
            onOpen={() =>
              clearNonTextFieldErrorOnFocus("federal_tax_rate_change_year")
            }
            error={Boolean(formErrors?.federal_tax_rate_change_year)}
            helperText={formErrors?.federal_tax_rate_change_year}
            views={["year"]}
            format="yyyy"
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            change_in_federal_tax_rate: ["Y"],
          },
        },
      },
      {
        name: "carryforward_credits_and_losses",
        element: (
          <SelectInput
            label="Carryforward Credits and Losses"
            selected={form.carryforward_credits_and_losses ?? ""}
            items={CARRYFORWARD_CREDITS_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("carryforward_credits_and_losses")
            }
            onChange={(e) =>
              onSelectInputChange(e, "carryforward_credits_and_losses")
            }
            error={Boolean(formErrors?.carryforward_credits_and_losses)}
            helperText={formErrors?.carryforward_credits_and_losses}
            disabled={loading}
            tooltip="<b>No</b> = Recognize tax credits and losses as earned \n<b>Yes (2018 Tax Reform)</b> = Carryforward tax credits and losses to shelter against taxes due using 2018 Tax Reform limitations \n<b>Yes</b> = Carryforward tax credits and losses to shelter against taxes due without limitation."
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "PR", "FF"],
          },
        },
      },
    ],
    initial_distributions_and_allocations: [
      {
        name: "preferred_return_type",
        element: (
          <SelectInput
            required
            label="Preferred Return Type"
            selected={form.preferred_return_type ?? ""}
            items={DEAL_TAX_EQUITY_PREF_RETURN_TYPE_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("preferred_return_type")
            }
            onChange={(e) => onSelectInputChange(e, "preferred_return_type")}
            error={Boolean(formErrors?.preferred_return_type)}
            helperText={formErrors?.preferred_return_type}
            disabled={loading}
            tooltip="<b>% of Total Cash</b> = Investor receives a priority share of cash based on the percentage of cash available for distribution \n<b>% of Investment/Yr</b> = Investor receives a priority share of cash per year based on the percentage of its initial capital contribution"
            fullWidth={false}
          />
        ),
        visibility: {
          OR: {
            type: ["FF", "YBF"],
          },
        },
      },
      {
        name: "preferred_return_percent",
        element: (
          <TextInput
            required
            isNumeric
            label="Preferred Return %"
            name="preferred_return_percent"
            value={form.preferred_return_percent ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            endAdornment={<>%</>}
            error={Boolean(formErrors?.preferred_return_percent)}
            helperText={formErrors?.preferred_return_percent}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["FF", "YBF"],
            preferred_return_type: ["PFCASH", "PCTINV"],
          },
        },
      },
      {
        name: "sponsor_return_of_capital",
        element: (
          <TextInput
            required
            isNumeric
            label="Sponsor Return of Capital"
            name="sponsor_return_of_capital"
            value={form.sponsor_return_of_capital ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            endAdornment={<>%</>}
            error={Boolean(formErrors?.sponsor_return_of_capital)}
            helperText={formErrors?.sponsor_return_of_capital}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["FF", "YBF"],
            preferred_return_type: ["PFCASH", "PCTINV"],
          },
        },
      },
      {
        name: "pre_flip_cash_split_percent",
        element: (
          <TextInput
            required
            isNumeric
            label="Pre-Flip Cash Split"
            name="pre_flip_cash_split_percent"
            endAdornment={<>%</>}
            value={form.pre_flip_cash_split_percent ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.pre_flip_cash_split_percent)}
            helperText={formErrors?.pre_flip_cash_split_percent}
            disabled={loading}
            fullWidth={false}
            tooltip="Percentage of cash after preferred return, when applicable (e.g. 20% of 90% if preferred return is 10% of cash)"
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
            solve_for: ["TEQAMT", "FDATE", "IRR", ""],
          },
        },
      },
      {
        name: "pre_flip_tax_split_percent",
        element: (
          <TextInput
            required
            isNumeric
            label="Pre-Flip Tax Split"
            name="pre_flip_tax_split_percent"
            endAdornment={<>%</>}
            value={form.pre_flip_tax_split_percent ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.pre_flip_tax_split_percent)}
            helperText={formErrors?.pre_flip_tax_split_percent}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          NOT: {
            AND: {
              type: ["PR"],
            },
          },
        },
      },
      {
        name: "cash_tax_split_percent",
        element: (
          <TextInput
            required
            isNumeric
            label="Cash/Tax Split"
            name="cash_tax_split_percent"
            endAdornment={<>%</>}
            value={
              form.cash_tax_split_percent ??
              form.post_flip_cash_split_percent ??
              ""
            }
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.cash_tax_split_percent)}
            helperText={formErrors?.cash_tax_split_percent}
            disabled={true}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["PR"],
            itc_recognition_method: ["SD"],
          },
        },
      },
    ],
    additional_cash_distributions: [
      {
        name: "post_flip_cash_split_percent",
        element: (
          <TextInput
            required
            isNumeric
            label="Post-Flip Cash Split"
            name="post_flip_cash_split_percent"
            endAdornment={<>%</>}
            value={form.post_flip_cash_split_percent ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.post_flip_cash_split_percent)}
            helperText={formErrors?.post_flip_cash_split_percent}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          NOT: {
            OR: {
              type: ["PR"],
              tax_allocation_method: ["PRORATA"],
            },
          },
        },
      },
    ],
    additional_tax_allocations: [
      {
        name: "tax_allocation_method",
        element: (
          <SelectInput
            required
            label="Tax Allocation Method"
            selected={form.tax_allocation_method ?? ""}
            items={getTaxAllocationMethodOptions()}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("tax_allocation_method")
            }
            onChange={(e) => onSelectInputChange(e, "tax_allocation_method")}
            error={Boolean(formErrors?.tax_allocation_method)}
            helperText={formErrors?.tax_allocation_method}
            disabled={loading}
            tooltip="<b>Regular Flip</b> = A flip allocation struture with a single pre- and post-flip split (e.g. 99/1 to 5/95) \n<b>99/67/99 Flip</b> = A flip allocation struture with three tax flips \n(1) after the first tax year \n(2) on the tax compliance period start \n(3) after the tax compliance period end. \n<b>Percentage Interest</b> = a partnership structure where the cash and tax allocations are aligned."
            fullWidth={false}
          />
        ),
      },

      {
        name: "tax_step_down_period",
        element: (
          <TextInput
            isNumeric
            label="Tax Step Down Period"
            name="tax_step_down_period"
            value={form.tax_step_down_period ?? ""}
            endAdornment={<>Months</>}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.tax_step_down_period)}
            helperText={formErrors?.tax_step_down_period}
            disabled={loading}
            fullWidth={false}
            tooltip="Enter the number of months from Funding Date to step down tax allocation from 99% to 67%."
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
            tax_allocation_method: ["P_99_67_99_5"],
          },
        },
      },

      {
        name: "tax_compliance_period_year",
        element: (
          <SelectInput
            label="Tax Compliance Period Year"
            selected={form.tax_compliance_period_year || ""}
            items={TAX_COMPLIANCE_PERIOD_YEAR_TYPES_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("tax_compliance_period_year")
            }
            onChange={(e) =>
              onSelectInputChange(e, "tax_compliance_period_year")
            }
            error={Boolean(formErrors?.tax_compliance_period_year)}
            helperText={formErrors?.tax_compliance_period_year}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
            tax_allocation_method: ["REG", "P_99_67_99_5"],
          },
        },
      },

      {
        name: "compliance_year",
        element: (
          <SelectInput
            label="Compliance Year"
            selected={form.compliance_year || ""}
            items={PROJECT_ESCALATOR_BASE_YEAR_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("compliance_year")}
            onChange={(e) => onSelectInputChange(e, "compliance_year")}
            error={Boolean(formErrors?.compliance_year)}
            helperText={formErrors?.compliance_year}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
            tax_allocation_method: ["REG", "P_99_67_99_5"],
            tax_compliance_period_year: ["SY"],
          },
        },
      },

      {
        name: "target_flip_cash_split",
        element: (
          <TextInput
            isNumeric
            label="Target Flip Cash Split"
            name="target_flip_cash_split"
            value={form.target_flip_cash_split ?? ""}
            endAdornment={<>%</>}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.target_flip_cash_split)}
            helperText={formErrors?.target_flip_cash_split}
            disabled={loading}
            fullWidth={false}
            tooltip="Enter the Investor's cash split % during the sweep period post-Target Flip Date."
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
            tax_allocation_method: ["REG", "P_99_67_99_5"],
          },
        },
      },
      {
        name: "post_flip_tax_split_percent",
        element: (
          <TextInput
            required
            isNumeric
            label="Post-Flip Tax Split"
            name="post_flip_tax_split_percent"
            endAdornment={<>%</>}
            value={form.post_flip_tax_split_percent ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.post_flip_tax_split_percent)}
            helperText={formErrors?.post_flip_tax_split_percent}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          NOT: {
            OR: {
              type: ["PR"],
              tax_allocation_method: ["PRORATA"],
            },
          },
        },
      },
    ],
    tax_methods: [
      {
        name: "itc_recognition_method",
        element: (
          <SelectInput
            label="ITC Recognition Method"
            selected={form.itc_recognition_method ?? ""}
            items={DEAL_ITC_RECOGNITION_METHOD_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("itc_recognition_method")
            }
            onChange={(e) => onSelectInputChange(e, "itc_recognition_method")}
            error={Boolean(formErrors?.itc_recognition_method)}
            helperText={formErrors?.itc_recognition_method}
            disabled={loading}
            tooltip="<b>Remaininig Tax Dates</b> = Spread tax credits over the remaining tax estimate dates from placed in service \n<b>Specified Date</b> = Recognize the tax credits on a specified date"
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF"],
            has_itc_projects: [true],
          },
        },
      },
      {
        name: "itc_recognition_lag",
        element: (
          <TextInput
            isNumeric
            label="ITC Recognition Lag"
            name="itc_recognition_lag"
            endAdornment={<>Months</>}
            value={form.itc_recognition_lag ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.itc_recognition_lag)}
            helperText={formErrors?.itc_recognition_lag}
            disabled={loading}
            tooltip="The number of months after the recognition timing to recognize the tax credits"
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF"],
            itc_recognition_method: ["SD"],
            has_itc_projects: [true],
          },
        },
      },

      {
        name: "dro_cap",
        element: (
          <TextInput
            required
            isNumeric
            label="DRO Cap"
            name="dro_cap"
            endAdornment={<>%</>}
            value={form.dro_cap ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.dro_cap)}
            helperText={formErrors?.dro_cap}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
          },
        },
      },
      {
        name: "loss_reallocation_method",
        element: (
          <SelectInput
            required
            label="Loss Reallocation Method"
            selected={form.loss_reallocation_method ?? ""}
            items={DEAL_IC_LOSS_REALLOCATION_METHOD_TYPES_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("loss_reallocation_method")
            }
            onChange={(e) => onSelectInputChange(e, "loss_reallocation_method")}
            error={Boolean(formErrors?.loss_reallocation_method)}
            helperText={formErrors?.loss_reallocation_method}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "FF"],
          },
        },
      },
    ],
    buyout: [
      {
        name: "buyout_type",
        element: (
          <SelectInput
            required
            label="Buyout Type"
            selected={form.buyout_type ?? ""}
            items={DEAL_TE_BUYOUT_TYPES_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("buyout_type")}
            onChange={(e) => onSelectInputChange(e, "buyout_type")}
            error={Boolean(formErrors?.buyout_type)}
            helperText={formErrors?.buyout_type}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          NOT: {
            AND: {
              type: ["PR"],
            },
          },
        },
      },
      {
        name: "buyout_calculation_method",
        element: (
          <SelectInput
            label="Calculation Method"
            selected={form.buyout_calculation_method ?? ""}
            items={getBuyoutCalculationMethodOptions()}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("buyout_calculation_method")
            }
            onChange={(e) =>
              onSelectInputChange(e, "buyout_calculation_method")
            }
            error={Boolean(formErrors?.buyout_calculation_method)}
            helperText={formErrors?.buyout_calculation_method}
            disabled={loading}
            tooltip="<b>Target Return</b> = Calculate based on cumulative return including buyout proceeds \n<b>Discount Rate</b> = Calculate based on the remaining after-tax cash flows"
            fullWidth={false}
          />
        ),
        visibility: {
          OR: {
            buyout_type: ["FLDATE"],
          },
        },
      },
      {
        name: "buyout_irr_input_type",
        element: (
          <SelectInput
            label="Target Return Input Type"
            selected={form.buyout_irr_input_type ?? ""}
            items={DEAL_TE_BUYOUT_IRR_INPUT_TYPES_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("buyout_irr_input_type")
            }
            onChange={(e) => onSelectInputChange(e, "buyout_irr_input_type")}
            error={Boolean(formErrors?.buyout_irr_input_type)}
            helperText={formErrors?.buyout_irr_input_type}
            disabled={loading}
            // tooltip="No = Recognize tax credits and losses as earned \nYes (2018 Tax Reform) = Carryforward tax credits and losses to shelter against taxes due using 2018 Tax Reform limitations \nYes = Carryforward tax credits and losses to shelter against taxes due without limitation"
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            buyout_type: ["FLDATE"],
            buyout_calculation_method: ["TGTRET"],
          },
        },
      },
      {
        name: "buyout_percentage_of_investment",
        element: (
          <TextInput
            required
            isNumeric
            label="Percentage of investment"
            name="buyout_percentage_of_investment"
            endAdornment={<>%</>}
            value={form.buyout_percentage_of_investment ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.buyout_percentage_of_investment)}
            helperText={formErrors?.buyout_percentage_of_investment}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            buyout_type: ["FLDATE"],
            buyout_calculation_method: ["POI"],
          },
        },
      },
      {
        name: "buyout_specified_amount",
        element: (
          <CurrencyInput
            required
            label="Specified Amount"
            name="buyout_specified_amount"
            value={form.buyout_specified_amount?.toString() ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.buyout_specified_amount)}
            helperText={formErrors?.buyout_specified_amount}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            buyout_type: ["FLDATE"],
            buyout_calculation_method: ["SPA"],
          },
        },
      },
      {
        name: "buyout_irr",
        element: (
          <TextInput
            required
            isNumeric
            label="Target Return"
            name="buyout_irr"
            endAdornment={<>%</>}
            value={form.buyout_irr ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.buyout_irr)}
            helperText={formErrors?.buyout_irr}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            buyout_type: ["FLDATE"],
            buyout_calculation_method: ["TGTRET"],
            buyout_irr_input_type: ["SPECIRR", "SPECPTC"],
          },
        },
      },
      {
        name: "buyout_amount_discount_rate",
        element: (
          <TextInput
            required
            isNumeric
            label="Discount Rate"
            name="buyout_amount_discount_rate"
            endAdornment={<>%</>}
            value={form.buyout_amount_discount_rate ?? ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.buyout_amount_discount_rate)}
            helperText={formErrors?.buyout_amount_discount_rate}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            buyout_type: ["FLDATE"],
            buyout_calculation_method: ["DISCRT"],
          },
        },
      },
    ],
    accounting: [
      {
        name: "accounting_method",
        element: (
          <SelectInput
            required
            label="Accounting Method"
            selected={form.accounting_method ?? ""}
            items={ACCOUNTING_METHODS_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("accounting_method")}
            onChange={(e) => onSelectInputChange(e, "accounting_method")}
            error={Boolean(formErrors?.accounting_method)}
            helperText={formErrors?.accounting_method}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "PR", "FF"],
          },
        },
      },
      {
        name: "tax_credits_in_pre_tax_book_income",
        element: (
          <SelectInput
            required
            label="Tax Credits in Pre-Tax Book Income"
            selected={form.tax_credits_in_pre_tax_book_income ?? ""}
            items={TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus(
                "tax_credits_in_pre_tax_book_income",
              )
            }
            onChange={(e) =>
              onSelectInputChange(e, "tax_credits_in_pre_tax_book_income")
            }
            error={Boolean(formErrors?.tax_credits_in_pre_tax_book_income)}
            helperText={formErrors?.tax_credits_in_pre_tax_book_income}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "PR", "FF"],
            accounting_method: ["HLBV"],
          },
        },
      },
      {
        name: "accounting_year",
        element: (
          <TextInput
            isNumeric
            label="Amortization Years"
            name="accounting_year"
            value={form.accounting_year ?? ""}
            endAdornment={<>Years</>}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.accounting_year)}
            helperText={formErrors?.accounting_year}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "PR", "FF"],
            accounting_method: ["HLBV"],
          },
        },
      },
      {
        name: "suspend_pre_tax_book_loss",
        element: (
          <SelectInput
            required
            label="Suspend Pre-Tax Book Loss"
            selected={form.suspend_pre_tax_book_loss ?? ""}
            items={SUSPEND_PRE_TAX_BOOK_LOSS_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("suspend_pre_tax_book_loss")
            }
            onChange={(e) =>
              onSelectInputChange(e, "suspend_pre_tax_book_loss")
            }
            error={Boolean(formErrors?.suspend_pre_tax_book_loss)}
            helperText={formErrors?.suspend_pre_tax_book_loss}
            disabled={loading}
            fullWidth={false}
          />
        ),
        visibility: {
          AND: {
            type: ["YBF", "PR", "FF"],
            accounting_method: ["HLBV"],
          },
        },
      },
    ],
  };
};
