import React from "react";
import Box from "@mui/material/Box";
import Modal from "../modal";
import Typography from "@mui/material/Typography";
import { Paper, SelectChangeEvent } from "@mui/material";

import useStyles from "./styles";
import SelectInput from "../select-input";
import {
  DEAL_OTHER_FLOWS_ALLOCATION_BUCKET_OPTIONS,
  DEAL_OTHER_SIZING_REVENUE_CONTRACT_OPTIONS,
  DEAL_OTHER_SIZING_EXPENSE_OPTIONS,
  DEAL_OTHER_SIZING_REVENUE_MERCHANT_OPTIONS,
  PROJECT_REVENUE_CONTRACT_TYPE,
  PROJECT_REVENUE_MERCHANT_TYPE,
} from "../../constants";
import {
  IDealSeparateAllocation,
  IDealSeparateAllocationErrors,
  SetStateAction,
} from "../../interfaces";

interface IProps {
  open: boolean;
  loading: boolean;
  form: IDealSeparateAllocation["allocations"];
  formErrors?: IDealSeparateAllocationErrors;
  hide?: string[];
  setForm: SetStateAction<IDealSeparateAllocation["allocations"]>;
  setFormErrors: SetStateAction<IDealSeparateAllocationErrors | undefined>;
  onClose: () => void;
  onUpdate: (
    form: IDealSeparateAllocation["allocations"],
  ) => Promise<IDealSeparateAllocation | undefined>;
}

export default function UpdateDealSeparateAllocationFormModal({
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onUpdate,
}: IProps): JSX.Element {
  const styles = useStyles();

  const handleOnClose = () => {
    onClose();
  };

  const handleUpdateDealSizing = async () => {
    const otherSizing = await onUpdate(form);
    otherSizing && handleOnClose();
  };

  const handleResetForm = () => {
    setFormErrors({});
  };

  const handleSelectChange = (
    e: SelectChangeEvent<unknown>,
    path: [keyof IDealSeparateAllocation["allocations"], string, string],
  ) => {
    setForm((prev) => {
      const updatedForm = { ...prev };
      // eslint-disable-next-line
      let ref: any = updatedForm;

      for (let i = 0; i < path.length - 1; i++) {
        ref = ref[path[i]];
      }

      ref[path[path.length - 1]] = e.target.value;
      return updatedForm;
    });
  };

  const handleChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
    field2: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: {
        ...prev[field as keyof typeof prev],
        [field2]: e.target.value,
      },
    }));
  };

  return (
    <Modal
      open={open}
      loading={loading}
      heading="Update Sizing"
      onClose={handleOnClose}
      onConfirm={handleUpdateDealSizing}
      classes={{ paper: styles.classes.modal }}
      resetForm={handleResetForm}
    >
      <Box>
        <Box className="grid grid-cols-2 gap-4">
          <Box>
            <Paper classes={{ root: "p-4" }}>
              <Typography marginBottom={2} fontWeight="bold">
                Revenue Contract
              </Typography>
              {Object.keys(PROJECT_REVENUE_CONTRACT_TYPE).map((key) => {
                return (
                  <div className="grid grid-cols-3 gap-4">
                    <span>
                      {
                        PROJECT_REVENUE_CONTRACT_TYPE[
                          key as keyof typeof PROJECT_REVENUE_CONTRACT_TYPE
                        ]
                      }
                    </span>
                    <SelectInput
                      label="Cash Sizing"
                      selected={
                        form?.revenue_contract[
                          key as keyof typeof form.revenue_contract
                        ].CASH
                      }
                      onChange={(e) =>
                        handleSelectChange(e, ["revenue_contract", key, "CASH"])
                      }
                      items={DEAL_OTHER_SIZING_REVENUE_CONTRACT_OPTIONS}
                      disabled={loading}
                    />
                    <SelectInput
                      label="Tax Sizing"
                      selected={
                        form?.revenue_contract[
                          key as keyof typeof form.revenue_contract
                        ].TAX
                      }
                      onChange={(e) =>
                        handleSelectChange(e, ["revenue_contract", key, "TAX"])
                      }
                      items={DEAL_OTHER_SIZING_REVENUE_CONTRACT_OPTIONS}
                      disabled={loading}
                    />
                  </div>
                );
              })}
            </Paper>
          </Box>
          <Box>
            <Paper classes={{ root: "p-4" }}>
              <Typography marginBottom={2} fontWeight="bold">
                Merchant Revenue
              </Typography>
              {Object.keys(PROJECT_REVENUE_MERCHANT_TYPE).map((key) => {
                return (
                  <div className="grid grid-cols-3 gap-4">
                    <span>
                      {
                        PROJECT_REVENUE_MERCHANT_TYPE[
                          key as keyof typeof PROJECT_REVENUE_MERCHANT_TYPE
                        ]
                      }
                    </span>
                    <SelectInput
                      label="Cash Sizing"
                      selected={
                        form?.revenue_merchant[
                          key as keyof typeof form.revenue_merchant
                        ].CASH
                      }
                      onChange={(e) =>
                        handleSelectChange(e, ["revenue_merchant", key, "CASH"])
                      }
                      items={DEAL_OTHER_SIZING_REVENUE_MERCHANT_OPTIONS}
                      disabled={loading}
                    />
                    <SelectInput
                      label="Tax Sizing"
                      selected={
                        form?.revenue_merchant[
                          key as keyof typeof form.revenue_merchant
                        ].TAX
                      }
                      onChange={(e) =>
                        handleSelectChange(e, ["revenue_merchant", key, "TAX"])
                      }
                      items={DEAL_OTHER_SIZING_REVENUE_MERCHANT_OPTIONS}
                      disabled={loading}
                    />
                  </div>
                );
              })}
            </Paper>
          </Box>
        </Box>
        <Box>
          <Box>
            <Paper classes={{ root: "p-4" }}>
              <Typography marginBottom={2} fontWeight="bold">
                Expense Sizing
              </Typography>
              <div className="grid grid-cols-2 gap-4 items-center">
                <SelectInput
                  label="Cash Sizing"
                  selected={form?.expense.CASH}
                  onChange={(e) => handleChange(e, "expense", "CASH")}
                  items={DEAL_OTHER_SIZING_EXPENSE_OPTIONS}
                  disabled={loading}
                />
                <SelectInput
                  label="Tax Sizing"
                  selected={form?.expense.TAX}
                  onChange={(e) => handleChange(e, "expense", "TAX")}
                  items={DEAL_OTHER_SIZING_EXPENSE_OPTIONS}
                  disabled={loading}
                />
              </div>
            </Paper>
          </Box>
          <Box>
            <Paper classes={{ root: "p-4" }}>
              <Typography marginBottom={2} fontWeight="bold">
                Other Sizing
              </Typography>
              <div>
                <div className="grid grid-cols-2 gap-4 items-center">
                  <SelectInput
                    label="Cash Sizing"
                    selected={form?.other_flows.CASH}
                    onChange={(e) => handleChange(e, "other_flows", "CASH")}
                    items={DEAL_OTHER_FLOWS_ALLOCATION_BUCKET_OPTIONS}
                    disabled={loading}
                  />
                  <SelectInput
                    label="Tax Sizing"
                    selected={form?.other_flows.TAX}
                    onChange={(e) => handleChange(e, "other_flows", "TAX")}
                    items={DEAL_OTHER_FLOWS_ALLOCATION_BUCKET_OPTIONS}
                    disabled={loading}
                  />
                </div>
              </div>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
