import React from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiButton from "@mui/material/Button";

import { cn } from "../../utils/helpers";

interface IProps {
  title: string;
  children: JSX.Element;
  collapseButtonLabel?: string;
}

export default function CollapsibleWrapper({
  children,
  title,
  collapseButtonLabel,
}: IProps): JSX.Element {
  const [expanded, setExpanded] = React.useState<boolean>(true);

  return (
    <Paper>
      <Accordion expanded={expanded} classes={{ root: cn("!shadow-none") }}>
        <AccordionSummary
          classes={{
            root: cn("!min-h-[40px]"),
            content: cn("!my-0 text-[16px] font-bold"),
          }}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails classes={{ root: cn("!p-0") }}>
          {children}
        </AccordionDetails>
      </Accordion>
      <Divider />
      <MuiButton
        onClick={() => setExpanded((v) => !v)}
        classes={{ root: cn("!normal-case w-full") }}
      >
        {(expanded ? "Collapse " : "Expand ") + (collapseButtonLabel || "")}
        <ExpandMoreIcon
          fontSize="medium"
          classes={{ root: expanded ? cn("rotate-180") : "" }}
        />
      </MuiButton>
    </Paper>
  );
}
