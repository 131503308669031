import ProjectTimingView from "../../views/project/timing";
import ProjectPagesLayout from "../../components/project-pages-layout";
import {
  getProjectTiming,
  editProjectTiming,
  getProjectTimingDateScheduleTable,
  getProjectTimingSummary,
} from "../../apis/project/timing";

export default function Timing(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <ProjectTimingView
        getProjectTimingSummary={getProjectTimingSummary}
        editProjectTiming={editProjectTiming}
        getProjectTiming={getProjectTiming}
        getProjectTimingDateScheduleTable={getProjectTimingDateScheduleTable}
      />
    </ProjectPagesLayout>
  );
}
