import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import useStyles from "./styles";
import Tooltip from "../tooltip";
import { IExtendedFormHelperTextProps, ISelectOption } from "../../interfaces";

interface IProps {
  label: string;
  name: string;
  options: { [key: string]: ISelectOption[] } | ISelectOption[];
  value: string | null;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption,
    name: string,
  ) => void;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    name: string,
  ) => void;
  onFocus?: () => void;
  helperText?: string;
  disabled: boolean;
  className?: string;
  dataPw?: string;
  tooltip?: string | JSX.Element;
  fullWidth?: boolean;
}

export default function AutocompleteField({
  label,
  name,
  options,
  value,
  onChange,
  onInputChange,
  onFocus,
  helperText,
  disabled,
  className,
  dataPw,
  tooltip,
  fullWidth = true,
}: IProps): JSX.Element {
  const styles = useStyles({ fullWidth: fullWidth });

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
  ) => {
    if (value) {
      onChange(event, value, name);
    }
  };

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    if (onInputChange) {
      onInputChange(event, value, name);
    }
  };

  const flattenOptions = Array.isArray(options)
    ? options
    : Object.values(options)?.reduce((acc, group) => [...acc, ...group], []);

  const trimmedOptions = flattenOptions.slice().map((o) => {
    if (o.label.length > 45) {
      o.label = o.label.substring(0, 45) + "...";
    }
    return o;
  });

  return (
    <Box className={styles.classes.container}>
      <Autocomplete
        className={className}
        options={trimmedOptions}
        value={
          value
            ? (flattenOptions.find(
                (option) => option?.value === value,
              ) as ISelectOption)
            : {
                label: "",
                value: "",
              }
        }
        onChange={handleChange}
        onInputChange={handleInputChange}
        disableClearable
        disabled={disabled}
        classes={{
          option: styles.classes.option,
          root: styles.classes.autoCompleteRoot,
        }}
        renderInput={(params) => (
          <TextField
            label={label}
            onFocus={onFocus}
            helperText={helperText}
            error={Boolean(helperText)}
            {...params}
            inputProps={{
              ...params.inputProps,
              "data-pw": dataPw || label,
            }}
            FormHelperTextProps={
              {
                "data-pw": `helper-text-${dataPw || label}`,
              } as IExtendedFormHelperTextProps
            }
          />
        )}
        groupBy={(option) => {
          if (Array.isArray(options)) {
            return "";
          }
          for (const group in options) {
            if (options[group]?.some((item) => item?.value === option?.value)) {
              return group;
            }
          }
          return "";
        }}
        getOptionDisabled={(option) => Boolean(option?.disabled)}
      />

      {tooltip && (
        <Tooltip title={tooltip} placement="top-end">
          <InfoIcon classes={{ root: styles.classes.infoIcon }} />
        </Tooltip>
      )}
    </Box>
  );
}
