/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Skeleton } from "@mui/material";
import { isEmpty, isNumber } from "lodash";

import {
  IComparisonItem,
  IDealComparisonInputs,
  IDealComparisonOutputs,
} from "../../interfaces";
import {
  DEAL_COMMON_COMPARISON_ITEMS,
  DEAL_GENERAL_COMPARISON_ITEMS,
  DEAL_TIMING_COMPARISON_ITEMS,
  DEAL_COST_COMPARISON_ITEMS,
  PROJECT_COST_TYPE,
  DEAL_COST_PER_WDC_COMPARISON_ITEMS,
  DEAL_CREDIT_SUPPORT_COMPARISON_ITEMS,
  DEAL_FEE_COMPARISON_ITEMS,
  DEAL_DEPRECIATION_FEE_COMPARISON_ITEMS,
  DEAL_TAX_CREDIT_COMPARISON_ITEMS,
  DEAL_PROFORMA_COMPARISON_ITEMS,
  DEAL_SPONSOR_EQUITY_COMPARISON_ITEMS,
  DEAL_DEBT_COMPARISON_ITEMS,
  DEAL_TAX_EQUITY_COMPARISON_ITEMS,
  DEAL_CASH_EQUITY_COMPARISON_ITEMS,
  DEAL_TRANSFER_COMPARISON_ITEMS,
  DEAL_DIRECT_PAY_COMPARISON_ITEMS,
  DATE_FORMAT_REGEX,
  DEAL_GENERAL_COMPARISON_ITEMS_2,
  DEAL_SPONSOR_EQUITY_BOL_COMPARISON_ITEMS,
  DEAL_CONSTRUCTION_DEBT_COMPARISON_ITEMS,
  DEAL_PARTNERSHIP_SINGLE_OWNER_COMPARISON_ITEMS,
} from "../../constants";
import { formatCurrency, numberWithDecimalsAndCommas } from "./common.helpers";
import { differenceInMonths } from "date-fns";

const Error = "Error";
const Loading = (
  <Skeleton width={150} height={50} sx={{ bgcolor: "#5335fa45" }} />
);

const getDataState = (data: any) => {
  if (data === null) {
    return Error; // data is null only when api has failed.
  }
  return Loading;
};

const invertStringIntSign = (val: string) => {
  // if value is 0, return val as it is without any sign
  if (Number(val) === 0) {
    return val;
  }
  return val.includes("-") ? val.replace("-", "+") : "-" + val;
};

interface IGetDifferenceArgs extends Omit<IComparisonItem, "id" | "hierarchy"> {
  baseCaseItemValue?: string | number | null;
  itemValue?: string | number | null;
}

const getDifference = (args: IGetDifferenceArgs): string | number => {
  const {
    baseCaseItemValue,
    itemValue,
    suffix,
    decimalDigits,
    isDollar,
    enum: enumObj,
  } = args;

  if (baseCaseItemValue === itemValue) {
    return "";
  }

  if (
    (baseCaseItemValue === undefined || baseCaseItemValue === null) &&
    (itemValue === undefined || itemValue === null)
  ) {
    return "";
  }

  if (enumObj && itemValue) {
    return enumObj[itemValue];
  }

  if (typeof itemValue === "number") {
    const parsedBaseCaseItemValure = Number(baseCaseItemValue) || 0;
    if (isDollar) {
      const result = formatCurrency(
        parsedBaseCaseItemValure - itemValue,
        decimalDigits,
      );
      return invertStringIntSign(result) + (suffix || "");
    }
    if (typeof decimalDigits === "number") {
      const result = numberWithDecimalsAndCommas(
        parsedBaseCaseItemValure - itemValue,
        decimalDigits,
      );
      return invertStringIntSign(result) + (suffix || "");
    }
  }

  if (
    DATE_FORMAT_REGEX.test(String(baseCaseItemValue)) &&
    DATE_FORMAT_REGEX.test(String(itemValue))
  ) {
    const result = differenceInMonths(
      new Date(String(baseCaseItemValue)),
      new Date(String(itemValue)),
    );

    return invertStringIntSign(String(result)) + " Months";
  }

  if (itemValue !== undefined && itemValue !== null) {
    return itemValue + (suffix || "");
  }

  return itemValue ?? "N/A";
};

export const getDealComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
  type: "deal" | "case",
) => {
  const rows = DEAL_COMMON_COMPARISON_ITEMS.map((item) => {
    const {
      hierarchy,
      id,
      property,
      enum: enumObj,
      type: dealType,
      nullValue,
    } = item;

    return {
      id,
      hierarchy,
      property,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          if (!dealType.includes(type)) return acc;
          let itemValue = input[i]?.deal[id];
          let cellData: string | number = "N/A";
          if (typeof itemValue === "boolean") {
            itemValue = itemValue ? "Yes" : "No";
            cellData = itemValue;
          }
          if (typeof itemValue === "string") {
            cellData = itemValue ?? nullValue;
          }
          if (enumObj && itemValue) {
            cellData = enumObj[itemValue] ?? nullValue;
          } else if (itemValue === null && nullValue) {
            cellData = nullValue;
          }
          if (showDiff && i !== 0 && input[0]) {
            let baseCaseItemValue = input[0]?.deal[id];
            if (typeof baseCaseItemValue === "boolean") {
              baseCaseItemValue = baseCaseItemValue ? "Yes" : "No";
            }
            cellData = getDifference({ baseCaseItemValue, itemValue, ...item });
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
  return rows.filter((row) => Object.keys(row).length > 3);
};

export const getDealGeneralComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
  type: "deal" | "case",
) => {
  const rows = DEAL_GENERAL_COMPARISON_ITEMS_2.map((item) => {
    const { id, hierarchy, type: dealType, enum: enumObj } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          if (!dealType.includes(type)) return acc;
          let itemValue: string[] | string =
            id === "project_energy_type"
              ? ((input[i]?.deal[id] as string) ?? "")
              : ((input[i]?.project_attributes?.[id] as string[]) ?? []);
          let cellData = itemValue.toString() ?? "N/A";

          if (enumObj && itemValue && id !== "project_energy_type") {
            const items = (itemValue as string[]).map((item) => enumObj[item]);
            cellData = items.toString();
            itemValue = cellData;
          }

          itemValue = itemValue.toString();

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue =
              id === "project_energy_type"
                ? input[0]?.deal[id]?.toString()
                : input[0]?.project_attributes?.[id]?.toString();

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            }) as string;
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });

  const rows2 = DEAL_GENERAL_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, suffix } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = input[i]?.deal[id];

          let cellData = itemValue ?? "N/A";

          if (itemValue !== null && itemValue !== undefined) {
            cellData = itemValue + (suffix || "");
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.deal[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
              decimalDigits: 2,
            });
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });

  return [
    ...rows.filter((row) => Object.keys(row).length > 3), // filter out empty rows
    ...rows2,
  ];
};

export const getDealTimingComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_TIMING_COMPARISON_ITEMS.map((item, idx) => {
    const { id, hierarchy, suffix, enum: enumObj, nullValue } = item;

    return {
      id: id + "_TIMING_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = input[i]?.timing[id];

          let cellData = itemValue ?? "N/A";

          if (itemValue !== null && itemValue !== undefined) {
            cellData = itemValue + (suffix || "");
          }

          if (enumObj && itemValue) {
            cellData = enumObj[itemValue] ?? nullValue;
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.timing[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
              decimalDigits: 2,
            });
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCostComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_COST_COMPARISON_ITEMS.map((item, idx) => {
    const { id, hierarchy, nestedObjLevel, isDollar, decimalDigits, suffix } =
      item;

    return {
      id: id + "_COST_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const costObj = input[i]?.cost;

          let cellData: string | number = "N/A";

          if (costObj) {
            if (nestedObjLevel) {
              const itemValue =
                costObj["costs"][id as keyof typeof PROJECT_COST_TYPE];

              cellData = isNumber(itemValue)
                ? formatCurrency(Number(itemValue))
                : "N/A";

              if (showDiff && i !== 0 && input[0]) {
                const baseCaseItemValue =
                  input[0]?.cost["costs"][id as keyof typeof PROJECT_COST_TYPE];

                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            } else {
              const typeBindedId = id as keyof Pick<
                IDealComparisonOutputs["portfolio_costs"],
                | "total_project_costs"
                | "deal_allocated_costs"
                | "construction_interest_fees"
                | "deal_project_cost"
                | "deal_fmv"
                | "fmv_step_up"
                | "credit_support_costs"
              >;
              const baseCaseItemValue =
                output[0]?.portfolio_costs[typeBindedId];
              const itemValue = output[i]?.portfolio_costs[typeBindedId];

              if (isDollar) {
                cellData = isNumber(itemValue)
                  ? `${formatCurrency(itemValue)}${suffix || ""}`
                  : "N/A";
              }

              if (decimalDigits) {
                cellData = isNumber(itemValue)
                  ? `${numberWithDecimalsAndCommas(
                      itemValue,
                      decimalDigits ?? 0,
                    )}${suffix || ""}`
                  : "N/A";
              }

              if (showDiff && i !== 0) {
                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            }
          }

          if (nestedObjLevel) {
            acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          } else {
            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          }
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCostsPerWattDcComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_COST_PER_WDC_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, nestedObjLevel, isDollar, decimalDigits, suffix } =
      item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const costObj = input[i]?.cost_per_watt_dc;

          let cellData: string | number = "N/A";

          if (costObj) {
            if (nestedObjLevel) {
              const itemValue =
                costObj["costs"][id as keyof typeof PROJECT_COST_TYPE];

              cellData = isNumber(itemValue)
                ? formatCurrency(itemValue, decimalDigits) + (suffix || "")
                : "N/A";

              if (showDiff && i !== 0 && input[0]) {
                const baseCaseItemValue =
                  input[0]?.cost_per_watt_dc["costs"][
                    id as keyof typeof PROJECT_COST_TYPE
                  ];

                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            } else {
              const typeBindedId = id as keyof Pick<
                IDealComparisonOutputs["portfolio_costs_per_watt"],
                | "total_project_costs"
                | "deal_allocated_costs"
                | "construction_interest_fees"
                | "deal_project_cost"
                | "deal_fmv"
                | "fmv_step_up"
                | "credit_support_costs"
              >;

              const baseCaseItemValue =
                output[0]?.portfolio_costs_per_watt[typeBindedId];
              const itemValue =
                output[i]?.portfolio_costs_per_watt[typeBindedId];

              if (isDollar) {
                cellData = isNumber(itemValue)
                  ? `${formatCurrency(itemValue, decimalDigits)}${suffix || ""}`
                  : "N/A";
              }

              if (showDiff && i !== 0) {
                cellData = getDifference({
                  baseCaseItemValue,
                  itemValue,
                  ...item,
                });
              }
            }
          }

          if (nestedObjLevel) {
            acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          } else {
            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          }
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCreditSupportComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_CREDIT_SUPPORT_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = input[i]?.credit_support_costs[id].total;

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.credit_support_costs[id].total;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealFeesComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_FEE_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = input[i]?.deal_fees[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.deal_fees[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealDepreciationComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_DEPRECIATION_FEE_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, enum: enumObj } = item;
    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const macrsElection = input[i]?.depreciation?.macrs_election;

          let cellData: string | number = "N/A";

          if (input[i]) {
            if (macrsElection) {
              cellData = Object.entries(macrsElection)
                .map(
                  ([year, election]) =>
                    `${year} - ${(enumObj && enumObj[election]) ?? "N/A"}`,
                )
                .join(", ");
            } else {
              cellData = "N/A";
            }

            if (showDiff && i !== 0 && input[0]) {
              const baseCaseItemValue = Object.entries(
                input[0].depreciation?.macrs_election || {},
              )
                .map(
                  ([year, election]) =>
                    `${year} - ${(enumObj && enumObj[election]) ?? "N/A"}`,
                )
                .join(", ");

              const itemValue = Object.entries(macrsElection || {})
                .map(
                  ([year, election]) =>
                    `${year} - ${(enumObj && enumObj[election]) ?? "N/A"}`,
                )
                .join(", ");

              cellData = getDifference({ baseCaseItemValue, itemValue });
            }
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);

          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealTaxCreditComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_TAX_CREDIT_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.tax_credits[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.tax_credits[id];
            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealProformaComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  showDiff: boolean,
) => {
  return DEAL_PROFORMA_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = input[i]?.proforma[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue);
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && input[0]) {
            const baseCaseItemValue = input[0]?.proforma[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = input[i] ? cellData : getDataState(input[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealSourcesAndUsesAtConstructionComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return [
    {
      id: "construction_loan_construction",
      hierarchy: ["Sources and Uses (Construction)", "Construction Loan"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_construction.construction_loan;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.sources_construction.construction_loan;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "tax_equity_bridge_loan_construction",
      hierarchy: ["Sources and Uses (Construction)", "Investor Bridge Loan"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue =
            output[i]?.sources_construction.tax_equity_bridge_loan;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.sources_construction.tax_equity_bridge_loan;
            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "equity_during_construction",
      hierarchy: [
        "Sources and Uses (Construction)",
        "Equity During Construction",
      ],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue =
            output[i]?.sources_construction.equity_during_construction_absolute;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.sources_construction
                .equity_during_construction_absolute;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "total_sources_construction",
      hierarchy: ["Sources and Uses (Construction)", "TOTAL SOURCES"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_construction.total_sources;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[i]?.sources_construction.total_sources;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "total_project_cost",
      hierarchy: ["Sources and Uses (Construction)", "Total Project Cost"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_construction.total_project_cost;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.uses_construction.total_project_cost;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "construction_interest_and_fees",
      hierarchy: [
        "Sources and Uses (Construction)",
        "Construction Interest & Fees",
      ],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue =
            output[i]?.uses_construction.construction_interest_and_fee + "/Wdc";
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.uses_construction.construction_interest_and_fee +
              "/Wdc";

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "deal_allocated_cost",
      hierarchy: ["Sources and Uses (Construction)", "Deal Allocated Costs"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_construction.deal_allocated_cost;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.uses_construction.deal_allocated_cost;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "cash_collateral_funded",
      hierarchy: ["Sources and Uses (Construction)", "Cash Collateral Funded"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_construction.cash_collateral_funded;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.uses_construction.cash_collateral_funded;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "cash_collateral_released",
      hierarchy: [
        "Sources and Uses (Construction)",
        "Cash Collateral Released",
      ],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue =
            output[i]?.uses_construction.cash_collateral_released;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.uses_construction.cash_collateral_released;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "total_uses_construction",
      hierarchy: ["Sources and Uses (Construction)", "TOTAL USES"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_construction.total_uses;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.uses_construction.total_uses;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
  ];
};

export const getDealSourcesAndUsedAtCodComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return [
    {
      id: "tax_equity",
      hierarchy: ["Sources and Uses (COD)", "Tax Equity"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_cod.tax_equity;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.sources_cod.tax_equity;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "cash_equity",
      hierarchy: ["Sources and Uses (COD)", "Cash Equity"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_cod.cash_equity;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.sources_cod.cash_equity;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "sponsor_equity",
      hierarchy: ["Sources and Uses (COD)", "Sponsor Equity"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_cod.sponsor_equity;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.sources_cod.sponsor_equity;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "debt_proceeds",
      hierarchy: ["Sources and Uses (COD)", "Term Debt"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_cod.term_debt_proceeds;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.sources_cod.term_debt_proceeds;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "fmv_step_up_source",
      hierarchy: ["Sources and Uses (COD)", "FMV Step Up "],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_cod.fmv_step_up;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.sources_cod.fmv_step_up;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "total_sources_cod",
      hierarchy: ["Sources and Uses (COD)", "TOTAL SOURCES"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.sources_cod.total_sources;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.sources_cod.total_sources;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "tax_equity_bridge_loan_cod",
      hierarchy: ["Sources and Uses (COD)", "Investor Bridge Loan Repayment"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue =
            output[i]?.sources_construction.tax_equity_bridge_loan;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.sources_construction.tax_equity_bridge_loan;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }
          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "construction_loan_cod",
      hierarchy: ["Sources and Uses (COD)", "Construction Loan Repayment"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_cod.construction_loan_repayment;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.uses_cod.construction_loan_repayment;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }
          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "equity_during_construction_cod",
      hierarchy: [
        "Sources and Uses (COD)",
        "Equity During Construction Repayment",
      ],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_cod.developer_equity_repayment;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue =
              output[0]?.uses_cod.developer_equity_repayment;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }
          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "deal_fees",
      hierarchy: ["Sources and Uses (COD)", "Deal Fees"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_cod.deal_fees;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.uses_cod.deal_fees;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }
          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "fmv_step_up_uses",
      hierarchy: ["Sources and Uses (COD)", "FMV Step Up"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_cod.fmv_step_up;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.uses_cod.fmv_step_up;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
    {
      id: "total_uses",
      hierarchy: ["Sources and Uses (COD)", "TOTAL USES"],
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.uses_cod.total_uses;
          let cellData: string | number = "N/A";

          if (typeof itemValue === "number") {
            cellData = formatCurrency(itemValue);
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.uses_cod.total_uses;

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              isDollar: true,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    },
  ];
};

export const getDealPartnershipSingleOwnerComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_PARTNERSHIP_SINGLE_OWNER_COMPARISON_ITEMS.map((item, idx) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id: id + "_PARTNERSHIP_PORTFOLIO_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.partnership[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.partnership[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealSponsorEquityComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  const rows: Record<string, any>[] = [];

  let showNewFederalTaxRate = false;
  let showTaxRateChangeYear = false;
  let showNewCompositeTaxRate = false;
  DEAL_SPONSOR_EQUITY_COMPARISON_ITEMS.forEach((item, idx) => {
    const row: Record<string, any> = {};
    const {
      id,
      hierarchy,
      isDollar,
      decimalDigits,
      suffix,
      nullValue,
      enum: enumObj,
    } = item;

    row.id = id + "_SPONSOR_EQUITY_" + idx;
    row.hierarchy = hierarchy;

    dealIds.forEach((dealUuid, i) => {
      if (output[i]) {
        const itemValue = output[i]["sponsor_equity"][id];

        let cellData = itemValue ?? nullValue ?? "N/A";
        if (enumObj && itemValue) {
          cellData = enumObj[itemValue];
        }

        if (typeof itemValue === "number") {
          if (isDollar) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          } else {
            cellData =
              numberWithDecimalsAndCommas(itemValue, decimalDigits ?? 0) +
              (suffix || "");
          }
        }

        if (showDiff && i !== 0 && output[0]) {
          const baseCaseItemValue = output[0]["sponsor_equity"][id];
          cellData = getDifference({
            baseCaseItemValue,
            itemValue,
            ...item,
          });
        }

        row[dealUuid] = cellData;
      } else {
        row[dealUuid] = getDataState(output[i]);
      }
    });
    rows.push(row);

    // Insert newFederalTaxRate and taxRateChangeYear rows after federal_tax_rate
    if (id === "federal_tax_rate") {
      const newFederalTaxRate = {
        id: id + "_SPONSOR_EQUITY_" + idx + "_NEW_FEDERAL_TAX_RATE",
        hierarchy: ["Sponsor Equity (COD)", "New Federal Tax Rate"],
        property: "New Federal Tax Rate",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealUuid: string, i: number) => {
            const itemValue = output[i]?.sponsor_equity?.new_federal_tax_rate;

            let cellData = itemValue ?? "N/A";

            if (typeof itemValue === "number") {
              cellData = itemValue + "%";
            }

            if (itemValue) {
              showNewFederalTaxRate = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.new_federal_tax_rate;

              cellData = getDifference({
                baseCaseItemValue,
                itemValue,
                suffix: "%",
              });
            }

            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      const taxRateChangeYear = {
        id: id + "_SPONSOR_EQUITY_" + idx + "_FEDERAL_TAX_RATE_CHANGE_YEAR",
        hierarchy: ["Sponsor Equity (COD)", "Tax Rate Change Year"],
        property: "Tax Rate Change Year",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealUuid: string, i: number) => {
            const itemValue =
              output[i]?.sponsor_equity?.federal_tax_rate_change_year;
            let cellData = itemValue ?? "N/A";

            if (itemValue) {
              showTaxRateChangeYear = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.federal_tax_rate_change_year;

              cellData = getDifference({ baseCaseItemValue, itemValue });
            }

            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      if (showNewFederalTaxRate) {
        rows.push(newFederalTaxRate);
      }
      if (showTaxRateChangeYear) {
        rows.push(taxRateChangeYear);
      }
    }

    if (id === "composite_tax_rate") {
      const newCompositeTaxRate = {
        id: id + "_SPONSOR_EQUITY_COD_" + idx + "_NEW_COMPOSITE_TAX_RATE",
        hierarchy: ["Sponsor Equity (COD)", "New Composite Tax Rate"],
        property: "New Composite Tax Rate",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealUuid: string, i: number) => {
            const itemValue = output[i]?.sponsor_equity?.new_composite_tax_rate;
            let cellData = itemValue ?? "N/A";

            if (typeof itemValue === "number") {
              cellData = itemValue + "%";
            }

            if (itemValue) {
              showNewCompositeTaxRate = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.new_composite_tax_rate;

              cellData = getDifference({
                baseCaseItemValue,
                itemValue,
                suffix: "%",
              });
            }
            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      if (showNewCompositeTaxRate) {
        rows.push(newCompositeTaxRate);
      }
    }
  });

  return rows;
};

export const getDealSponsorEquityBOLComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  const rows: Record<string, any>[] = [];

  let showNewFederalTaxRate = false;
  let showTaxRateChangeYear = false;
  let showNewCompositeTaxRate = false;

  DEAL_SPONSOR_EQUITY_BOL_COMPARISON_ITEMS.forEach((item, idx) => {
    const row: Record<string, any> = {};
    const {
      id,
      hierarchy,
      isDollar,
      decimalDigits,
      suffix,
      nullValue,
      enum: enumObj,
    } = item;

    row.id = id + "_SPONSOR_EQUITY_BOL_" + idx;
    row.hierarchy = hierarchy;

    dealIds.forEach((dealUuid, i) => {
      if (output[i]) {
        const itemValue = output[i]["sponsor_equity_bol"][id];

        let cellData = itemValue ?? nullValue ?? "N/A";
        if (enumObj && itemValue) {
          cellData = enumObj[itemValue];
        }

        if (typeof itemValue === "number") {
          if (isDollar) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          } else {
            cellData =
              numberWithDecimalsAndCommas(itemValue, decimalDigits ?? 0) +
              (suffix || "");
          }
        }

        if (showDiff && i !== 0 && output[0]) {
          const baseCaseItemValue = output[0]["sponsor_equity_bol"][id];
          cellData = getDifference({
            baseCaseItemValue,
            itemValue,
            ...item,
          });
        }

        row[dealUuid] = cellData;
      } else {
        row[dealUuid] = getDataState(output[i]);
      }
    });
    rows.push(row);

    // Insert newFederalTaxRate and taxRateChangeYear rows after federal_tax_rate
    if (id === "federal_tax_rate") {
      const newFederalTaxRate = {
        id: id + "_SPONSOR_EQUITY_BOL_" + idx + "_NEW_FEDERAL_TAX_RATE",
        hierarchy: ["Sponsor Equity (BOL)", "New Federal Tax Rate"],
        property: "New Federal Tax Rate",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealUuid: string, i: number) => {
            const itemValue = output[i]?.sponsor_equity?.new_federal_tax_rate;

            let cellData = itemValue ?? "N/A";

            if (typeof itemValue === "number") {
              cellData = itemValue + "%";
            }

            if (itemValue) {
              showNewFederalTaxRate = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.new_federal_tax_rate;

              cellData = getDifference({
                baseCaseItemValue,
                itemValue,
                suffix: "%",
              });
            }

            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      const taxRateChangeYear = {
        id: id + "_SPONSOR_EQUITY_BOL_" + idx + "_FEDERAL_TAX_RATE_CHANGE_YEAR",
        hierarchy: ["Sponsor Equity (BOL)", "Tax Rate Change Year"],
        property: "Tax Rate Change Year",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealUuid: string, i: number) => {
            const itemValue =
              output[i]?.sponsor_equity?.federal_tax_rate_change_year;
            let cellData = itemValue ?? "N/A";

            if (itemValue) {
              showTaxRateChangeYear = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.federal_tax_rate_change_year;

              cellData = getDifference({ baseCaseItemValue, itemValue });
            }

            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      if (showNewFederalTaxRate) {
        rows.push(newFederalTaxRate);
      }
      if (showTaxRateChangeYear) {
        rows.push(taxRateChangeYear);
      }
    }

    if (id === "composite_tax_rate") {
      const newCompositeTaxRate = {
        id: id + "_SPONSOR_EQUITY_BOL_" + idx + "_NEW_COMPOSITE_TAX_RATE",
        hierarchy: ["Sponsor Equity (BOL)", "New Composite Tax Rate"],
        property: "New Composite Tax Rate",
        ...dealIds.reduce(
          (acc: Record<string, any>, dealUuid: string, i: number) => {
            const itemValue = output[i]?.sponsor_equity?.new_composite_tax_rate;
            let cellData = itemValue ?? "N/A";

            if (typeof itemValue === "number") {
              cellData = itemValue + "%";
            }

            if (itemValue) {
              showNewCompositeTaxRate = true;
            }

            if (showDiff && i !== 0 && output[0]) {
              const baseCaseItemValue =
                output[0]?.sponsor_equity?.new_composite_tax_rate;

              cellData = getDifference({
                baseCaseItemValue,
                itemValue,
                suffix: "%",
              });
            }
            acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
            return acc;
          },
          {},
        ),
      };

      if (showNewCompositeTaxRate) {
        rows.push(newCompositeTaxRate);
      }
    }
  });

  return rows;
};

export const getDealConstructionDebtComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_CONSTRUCTION_DEBT_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;
    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.construction_debt[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.construction_debt[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealDebtComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_DEBT_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;
    const isBaseRateOrSpread = id === "base_rate" || id === "spread";

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = isBaseRateOrSpread
            ? input[i]?.debt[id]
            : output[i]?.debt[id as keyof IDealComparisonOutputs["debt"]];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = isBaseRateOrSpread
              ? input[0]?.debt[id]
              : output[0]?.debt[id as keyof IDealComparisonOutputs["debt"]];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealTaxEquityComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_TAX_EQUITY_COMPARISON_ITEMS.map((item, idx) => {
    const {
      id,
      hierarchy,
      isDollar,
      suffix,
      enum: enumObj,
      decimalDigits,
      nullValue,
    } = item;

    return {
      id: id + "_TE_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.tax_equity[id];

          let cellData: number | string = itemValue ?? nullValue ?? "N/A";

          if (enumObj && itemValue) {
            cellData = enumObj[itemValue];
          }

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.tax_equity[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealCashEquityComparisonRows = (
  dealIds: string[] = [],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_CASH_EQUITY_COMPARISON_ITEMS.map((item, idx) => {
    const {
      id,
      hierarchy,
      isDollar,
      suffix,
      enum: enumObj,
      decimalDigits,
      nullValue,
    } = item;

    return {
      id: id + "_CE_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.cash_equity[id];

          let cellData: number | string = itemValue ?? nullValue ?? "N/A";

          if (enumObj && itemValue) {
            cellData = enumObj[itemValue];
          }

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.cash_equity[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealTransferComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_TRANSFER_COMPARISON_ITEMS.map((item, idx) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id: id + "_T_" + idx,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.transfer[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (
            isEmpty(output[i]?.transfer) ||
            !(input[i]?.deal?.tax_credit_structure === "TR")
          ) {
            cellData = "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.transfer[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};

export const getDealDirectPayComparisonRows = (
  dealIds: string[] = [],
  input: IDealComparisonInputs[],
  output: IDealComparisonOutputs[],
  showDiff: boolean,
) => {
  return DEAL_DIRECT_PAY_COMPARISON_ITEMS.map((item) => {
    const { id, hierarchy, isDollar, suffix, decimalDigits } = item;

    return {
      id,
      hierarchy,
      ...dealIds.reduce(
        (acc: Record<string, any>, dealUuid: string, i: number) => {
          const itemValue = output[i]?.transfer[id];

          let cellData: number | string = itemValue ?? "N/A";

          if (isDollar && isNumber(itemValue)) {
            cellData = formatCurrency(itemValue) + (suffix || "");
          }

          if (decimalDigits) {
            cellData = isNumber(itemValue)
              ? `${numberWithDecimalsAndCommas(
                  Number(itemValue),
                  decimalDigits ?? 0,
                )}${suffix || ""}`
              : "N/A";
          }

          if (
            isEmpty(output[i]?.transfer) ||
            !(input[i]?.deal?.tax_credit_structure === "DP")
          ) {
            cellData = "N/A";
          }

          if (showDiff && i !== 0 && output[0]) {
            const baseCaseItemValue = output[0]?.transfer[id];

            cellData = getDifference({
              baseCaseItemValue,
              itemValue,
              ...item,
            });
          }

          acc[dealUuid] = output[i] ? cellData : getDataState(output[i]);
          return acc;
        },
        {},
      ),
    };
  });
};
