import Typography from "@mui/material/Typography";
import { useOrganization } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import {
  replaceCaseIdAttachedDealPagePath,
  replacePagePathForPersonalAccount,
} from "../../utils/helpers";

interface IProps {
  path: string;
  text: string;
}

export default function TextAsLink({ path, text }: IProps): JSX.Element {
  const navigate = useNavigate();

  const { organization } = useOrganization();

  const handleNavigation = () => {
    let pathToUse = path;
    if (!organization) {
      if (pathToUse.includes("/deal/")) {
        pathToUse = replaceCaseIdAttachedDealPagePath(pathToUse);
      }
      if (pathToUse.includes("/project/")) {
        pathToUse = replacePagePathForPersonalAccount(
          pathToUse,
          "project",
        ) as string;
      }
    }
    navigate(pathToUse);
  };

  return (
    <Typography
      onClick={handleNavigation}
      component="span"
      variant="body2"
      style={{ cursor: "pointer" }}
    >
      {text}
    </Typography>
  );
}
