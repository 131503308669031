import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTableV2 from "../../../../../components/report-table-v2";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import { getDealTimeSeriesReportUrl, cn } from "../../../../../utils/helpers";
import {
  ReportTerm,
  ReportType,
  IDealReport,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealTimeSeriesReport: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    periodicity: OutputReportPeriodicityType,
  ) => Promise<IDealReport>;
  downloadDealTimeSeriesReport: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    periodicity: OutputReportPeriodicityType,
  ) => Promise<void>;
}

export default function DealOutputPartnershipTaxView({
  getDealTimeSeriesReport,
  downloadDealTimeSeriesReport,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IDealReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealUuid}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealUuid}-output-periodicity`,
      "MO",
    );

  React.useEffect(() => {
    if (dealIdToUse) {
      getDealTimeSeriesReportCallAPI(dealIdToUse, periodicityType).then(
        (response) => {
          if (response) {
            setReport(response);
          }
        },
      );
    }
  }, [dealIdToUse, periodicityType]);

  const {
    callAPI: getDealTimeSeriesReportCallAPI,
    errored: getDealTimeSeriesReportFailed,
    loading: loadingDealTimeSeriesReport,
    errorObj: getDealTimeSeriesReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealUuid: string, reportPeriodicityType: OutputReportPeriodicityType) =>
      getDealTimeSeriesReport(
        dealUuid,
        "project",
        "taxdetail",
        "default",
        reportPeriodicityType,
      ),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    !loadingDealTimeSeriesReport &&
      dispatch(updateDealOutputLoadedAction(!getDealTimeSeriesReportFailed));
  }, [getDealTimeSeriesReportFailed, loadingDealTimeSeriesReport]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadDealTimeSeriesReport(
        dealIdToUse,
        "project",
        "taxdetail",
        "default",
        periodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };
  return (
    <ViewWrapper
      loading={loadingDealTimeSeriesReport}
      error={getDealTimeSeriesReportFailed}
      errorHeading={getDealTimeSeriesReportErrorObj?.heading}
      errorDescription={getDealTimeSeriesReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealTimeSeriesReportUrl(
            String(dealIdToUse),
            "project",
            "taxdetail",
            "default",
            periodicityType,
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        periodicityType={periodicityType}
        setBuyout={setBuyout}
        setPeriodicityType={setPeriodicityType}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showPeriodicity
      />
      {report?.data ? (
        <Box className={cn("mt-4")}>
          <ReportTableV2
            data={report.data}
            groupExpandedDepth={-1}
            autoHeight={true}
          />
        </Box>
      ) : null}
    </ViewWrapper>
  );
}
