import React, { Suspense } from "react";
import "leaflet/dist/leaflet.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { CircularProgress, Typography } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { Link } from "react-router-dom";

// Fix the default icon issue
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

interface IMapProps {
  disablePopup?: boolean;
  height?: number | string;
  zoom?: number;
  data: {
    countyName: string;
    state: string;
    latitude: number;
    longitude: number;
    projects: {
      name: string;
      url: string;
    }[];
  }[];
}

const MapContent = ({
  data,
  disablePopup = false,
  height = 500,
  zoom = 5,
}: IMapProps) => {
  if (data.length === 0) {
    return null;
  }

  // Calculate center based on average of all points
  const calculateCenter = () => {
    if (!data.length) return [39.8283, -98.5795] as [number, number]; // Default US center if no locations

    const sumLat = data.reduce((sum, loc) => sum + loc.latitude, 0);
    const sumLng = data.reduce((sum, loc) => sum + loc.longitude, 0);

    return [sumLat / data.length, sumLng / data.length] as [number, number];
  };

  return (
    <MapContainer
      center={calculateCenter()}
      zoom={zoom}
      style={{ width: "100%", height: height || "500px", borderRadius: "8px" }}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {data.map((item, index) => (
        <Marker key={index} position={[item.latitude, item.longitude]}>
          {!disablePopup && (
            <Popup className="max-h-[400px] overflow-y-auto">
              <Typography variant="h6">
                Projects in {item.countyName} ({item.state})
              </Typography>
              {item.projects.map((project, idx) => (
                <Link
                  key={idx}
                  className="block my-2"
                  to={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {project.name}
                  <OpenInNewIcon className="ml-3" fontSize="small" />
                </Link>
              ))}
            </Popup>
          )}
        </Marker>
      ))}
    </MapContainer>
  );
};

const MapWithSuspense = React.lazy(() =>
  Promise.resolve({ default: MapContent }),
);

export default function Map(props: IMapProps) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <MapWithSuspense {...props} />
    </Suspense>
  );
}
