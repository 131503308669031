import { useParams } from "react-router-dom";

import DealComparisonDetailView from "../../../views/analysis/deal-comparison-detail";
import { getDeals } from "../../../apis/deal/base";
import {
  deleteDealComparison,
  getDealAnalysisInputs,
  getDealAnalysisOutputs,
  getDealComparison,
  updateDealComparison,
} from "../../../apis/deal-comparison/base";

export default function DealCaseComparisonDetail(): JSX.Element {
  const { uuid } = useParams();

  return (
    <DealComparisonDetailView
      getDeals={getDeals}
      getDealComparison={getDealComparison}
      updateDealComparison={updateDealComparison}
      deleteDealComparison={deleteDealComparison}
      getDealAnalysisInputs={getDealAnalysisInputs}
      getDealAnalysisOutputs={getDealAnalysisOutputs}
      comparisonId={uuid}
      type="case"
    />
  );
}
