import { PROJECT_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { IProjectProformaResponse, ProformaReportType } from "../../interfaces";

export const downloadProjectProforma = async (
  projectUuid: string,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  return await AxiosHelper.downloadFile(
    `project-proforma/${projectUuid}/download/`,
    {
      params: {
        periodicity,
        basis,
      },
    },
  );
};

export const getProjectProforma = async (
  projectUuid: string,
  periodicity: ProformaReportType,
  cash_basis: "cash" | "accrual",
) => {
  const url = replaceParams(PROJECT_API_ROUTES.projectProforma.get, {
    projectUuid,
    periodicity,
    basis: cash_basis,
  });
  return await AxiosHelper.get<IProjectProformaResponse>(url);
};
