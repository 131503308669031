import {
  addGeneralAssumption,
  getGeneralAssumption,
  updateGeneralAssumption,
} from "../../../apis/deal/construction-debt";
import DealPagesLayout from "../../../components/deal-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import DealSizingConstructionDebt from "../../../views/deal/sizing/construction-debt";
import { getCurves } from "../../../apis/configuration/curves";

export default function TaxEquity(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealSizingConstructionDebt
          getGeneralAssumption={getGeneralAssumption}
          addGeneralAssumption={addGeneralAssumption}
          updateGeneralAssumption={updateGeneralAssumption}
          getCurves={getCurves}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
