import React from "react";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useLocation, useParams } from "react-router-dom";

import useStyles from "./styles";
import { useAppSelector } from "../../utils/hooks";
import { IMenu, ISidebarLink, SetStateAction } from "../../interfaces";

interface IPathToABCProps {}

export function PathToABC({}: IPathToABCProps): JSX.Element | null {
  const { dealUuid } = useParams();
  const { pathname: urlPath } = useLocation();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const showDownloadButton = React.useMemo(() => {
    return urlPath.includes("/deal/") && urlPath.includes("/output/");
  }, []);

  return showDownloadButton ? (
    <>
      {currentDeal?.abc_file_path && (
        <Typography padding={3} paddingTop={0} fontSize={12} overflow="hidden">
          ABC folder path: {currentDeal.abc_file_path}
        </Typography>
      )}
    </>
  ) : null;
}

interface IFloatingNavItemsProps {
  idx: number;
  links?: ISidebarLink[];
  floatingNavAnchorEl: IMenu | null;
  setFloatingNavAnchorEl: SetStateAction<IMenu | null>;
  onLinkClick: (
    e: React.MouseEvent<HTMLElement>,
    page: ISidebarLink,
    type: "base" | "sub",
  ) => void;
}

export const FloatingNavItems = ({
  idx,
  links,
  onLinkClick,
  floatingNavAnchorEl,
  setFloatingNavAnchorEl,
}: IFloatingNavItemsProps): JSX.Element => {
  const styles = useStyles({ sidebarWidth: 220 });

  const onMouseOutOfFloatingLinks = () => {
    setFloatingNavAnchorEl(null);
  };

  return (
    <Popper
      open={floatingNavAnchorEl?.id === idx}
      anchorEl={floatingNavAnchorEl?.element}
      placement="right-start"
      transition
      onMouseLeave={onMouseOutOfFloatingLinks}
      style={{ zIndex: 26 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper style={{ marginLeft: 10 }}>
            <ClickAwayListener onClickAway={onMouseOutOfFloatingLinks}>
              <MenuList>
                {links?.map((link, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{ root: styles.classes.floatingLink }}
                      onClick={(e) => onLinkClick(e, link, "sub")}
                    >
                      {link.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
