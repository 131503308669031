import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { useOrganization } from "@clerk/clerk-react";
import { Link, useParams } from "react-router-dom";

import useStyles from "./styles";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import EditTaxCreditDirectPay from "../../../../components/edit-tax-credit-direct-form-modal";
import EditDealTaxCreditDirectPay from "../../../../components/edit-deal-tax-credit-direct-pay-form-modal";
import LogsWrapper from "../../../../components/logs-wrapper";
import Logs from "../../../../components/logs";
import LogsButton from "../../../../components/logs-button";
import ConditionalProtect from "../../../../components/conditional-protect";
import { cn } from "../../../../utils/helpers";
import { useAPI, useDrawer, useLogs } from "../../../../utils/hooks";
import {
  DEAL_TAX_CREDIT_TRANSFER_PERIODICITY,
  DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IDealTaxCreditTransfer,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferFormErrors,
  IDealTaxCreditTransferPortfolio,
  IDealTaxCreditTransferPortfolioForm,
  IDealTaxCreditTransferPortfolioFormErrors,
  IDealTaxCreditTransferResults,
  ILogsConfiguration,
} from "../../../../interfaces";

interface IProps {
  getDealTaxCreditDirectPayDetails: (
    dealUuid: string,
  ) => Promise<IDealTaxCreditTransfer[]>;
  updateDealTaxCreditDirectPayDetails: (
    dealUuid: string,
    transferSizingId: number,
    form: IDealTaxCreditTransferForm,
  ) => Promise<IDealTaxCreditTransferResults>;
  updateDealTransferPortfolio: (
    dealUuid: string,
    transferConfigId: number,
    form: IDealTaxCreditTransferPortfolioForm,
  ) => Promise<IDealTaxCreditTransferPortfolio>;
  getDealTransferPortfolio: (
    dealUuid: string,
  ) => Promise<IDealTaxCreditTransferPortfolio[]>;
}

export default function DealDirectPayView({
  getDealTaxCreditDirectPayDetails,
  updateDealTaxCreditDirectPayDetails,
  updateDealTransferPortfolio,
  getDealTransferPortfolio,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const [dealTaxCreditDirectPay, setdealTaxCreditDirectPay] = React.useState<
    IDealTaxCreditTransfer[]
  >([]);
  const [dealTransferPortfolio, setDealTransferPortfolio] =
    React.useState<IDealTaxCreditTransferPortfolio>();
  const [form, setForm] = React.useState<IDealTaxCreditTransferForm>(
    DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  );
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const [openDealEditModal, setOpenDealEditModal] =
    React.useState<boolean>(false);

  const [
    taxCreditTransferConstraintsForm,
    setTaxCreditTransferConstraintsForm,
  ] = React.useState<IDealTaxCreditTransferPortfolioForm>(
    UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
  );

  const isOnCase = React.useMemo(() => {
    return dealUuid !== caseDealUuid;
  }, [dealUuid, caseDealUuid]);

  const {
    callAPI: getDealTaxCreditDirectPayDetailsCallAPI,
    errored: getDealTaxCreditDirectPayFailed,
    loading: loadingDealTaxCreditDirectPay,
  } = useAPI((dealUuid: string) => getDealTaxCreditDirectPayDetails(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealTaxCreditDirectPayDetailsAPI,
    loading: loadingUpdateDealTaxCreditDirectPayDetailsDetails,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealTaxCreditTransferResults, IDealTaxCreditTransferFormErrors>(
    (form: IDealTaxCreditTransferForm, directPayId: number) =>
      updateDealTaxCreditDirectPayDetails(String(dealUuid), directPayId, form),
  );

  const {
    callAPI: updateDealTransfePortfolioCallAPI,
    loading: loadingDealTransferPortfolioUpdate,
    fieldErrors: dealFormErrors,
    setFieldErrors: setDealFormErrors,
  } = useAPI<
    IDealTaxCreditTransferPortfolio,
    IDealTaxCreditTransferPortfolioFormErrors
  >(
    (
      dealUuid: string,
      transferConfigId: number,
      form: IDealTaxCreditTransferPortfolioForm,
    ) => updateDealTransferPortfolio(dealUuid, transferConfigId, form),
  );

  const { callAPI: getDealTransferPortfolioCallAPI } = useAPI(
    (dealUuid: string) => getDealTransferPortfolio(dealUuid),
    {
      initialLoading: true,
    },
  );

  const getDealTaxCreditDetails = (dealUuid: string) => {
    getDealTaxCreditDirectPayDetailsCallAPI(dealUuid).then((response) => {
      response && setdealTaxCreditDirectPay(response);
    });
  };

  React.useEffect(() => {
    getDealTransferPortfolioCallAPI(String(dealIdToUse)).then((res) => {
      res?.length && setDealTransferPortfolio(res[0]);
    });
    getDealTaxCreditDetails(String(dealIdToUse));
  }, [dealIdToUse]);

  const rows = [
    {
      key: "Key Terms",
    },
    {
      key: "Tax Credit Type",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          d?.project_credit_type || "N/A",
        ]),
      ),
    },
    {
      key: "Direct Pay Portion",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d?.project?.name,
          d?.transfer_sizing?.transfer_portion + "%" || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Rate",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d?.project?.name,
          Number(d?.transfer_sizing?.payment_rate).toFixed(4) + " $/Credit" ||
            "N/A",
        ]),
      ),
    },
    {
      key: "Payment Periodicity",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d?.project?.name,
          DEAL_TAX_CREDIT_TRANSFER_PERIODICITY[
            d?.transfer_sizing
              ?.payment_periodicity as keyof typeof DEAL_TAX_CREDIT_TRANSFER_PERIODICITY
          ] || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Reference Date",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.payment_reference_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Dates Lag",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          d?.transfer_sizing?.payment_dates_lag + " months",
        ]),
      ),
    },
    {
      key: "Payment Date Num 1",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.payment_date_num_1), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Key Dates",
    },
    {
      key: "Placed in Service Date",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.placed_in_service_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Pre-Filing Date (per project)",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.transfer_sizing?.pre_filing_date), "M/dd/yyyy") ||
            "N/A",
        ]),
      ),
    },
  ];

  const columns = dealTaxCreditDirectPay.map((d) => {
    return {
      name: d.project.name,
      id: d.transfer_sizing?.id,
      projectId: d?.project?.id,
    };
  });

  const openDealTaxCreditEditModal = (id: number) => {
    setOpenEditModal(true);
    const dealTaxCreditDirect = dealTaxCreditDirectPay.find((d) => {
      return d.transfer_sizing.id === id;
    });
    setForm({
      id: String(id),
      payment_date_num_1: dealTaxCreditDirect?.payment_date_num_1 ?? "",
      payment_dates_lag:
        dealTaxCreditDirect?.transfer_sizing?.payment_dates_lag ?? "",
      payment_periodicity:
        dealTaxCreditDirect?.transfer_sizing?.payment_periodicity ?? "",
      payment_rate: dealTaxCreditDirect?.transfer_sizing?.payment_rate ?? "",
      placed_in_service_date: dealTaxCreditDirect?.placed_in_service_date ?? "",
      pre_filing_date:
        dealTaxCreditDirect?.transfer_sizing?.pre_filing_date ?? "",
      transfer_portion:
        dealTaxCreditDirect?.transfer_sizing?.transfer_portion ?? "",
      isPTC: dealTaxCreditDirect?.project_credit_type === "PTC",
      isITC: dealTaxCreditDirect?.project_credit_type === "ITC",
      creditStartDate: "itc start date",
      creditEndDate: "itc end date",
    });
  };

  const openDealTaxCreditPortfolioEditModal = () => {
    setOpenDealEditModal(true);
    setTaxCreditTransferConstraintsForm({
      transfer_maximum_payment:
        dealTransferPortfolio?.transfer_maximum_payment || "",
      transfer_minimum_roi: dealTransferPortfolio?.transfer_minimum_roi || "",
      transfer_discount_rate:
        dealTransferPortfolio?.transfer_discount_rate || "",
    });
  };

  const handleEditDealTaxCreditDirectPay = async (
    form: IDealTaxCreditTransferForm,
  ) => {
    const updatedForm = {
      payment_dates_lag: form.payment_dates_lag,
      payment_periodicity: form.isPTC ? form.payment_periodicity : null,
      payment_rate: form.payment_rate,
      placed_in_service_date: form.placed_in_service_date,
      pre_filing_date: form.pre_filing_date,
      transfer_portion: form.transfer_portion,
    };
    const { id } = form;
    const dealTaxCreditDirectPay = await updateDealTaxCreditDirectPayDetailsAPI(
      updatedForm,
      Number(id),
    );
    dealTaxCreditDirectPay && getDealTaxCreditDetails(String(caseDealUuid));
    dealTaxCreditDirectPay && setOpenEditModal(false);

    return dealTaxCreditDirectPay;
  };

  const handleEditDealTaxCreditDirectPayPortfolio = async (
    form: IDealTaxCreditTransferPortfolioForm,
  ) => {
    const updatedPortfolio = await updateDealTransfePortfolioCallAPI(
      caseDealUuid,
      dealTransferPortfolio?.id,
      form,
    );
    updatedPortfolio && setDealTransferPortfolio(updatedPortfolio);
    return updatedPortfolio;
  };

  const {
    loadMoreLogs: loadMoreDirectPaySizingLogs,
    loadingLogs: loadingDirectPaySizingLogs,
    logs: directPaySizingLogs,
    onCloseLogs: onCloseDirectPaySizingLogs,
    onOpenLogs: onOpenDirectPaySizingLogs,
    pagination: directPaySizingLogsPagination,
  } = useLogs();

  const {
    handleCloseDrawer: handleCloseDirectPaySizingLogs,
    handleOpenDrawer: handleOpenDirectPaySizingLogs,
    isDrawerOpen: isDirectPaySizingLogsOpen,
  } = useDrawer({
    onOpen: onOpenDirectPaySizingLogs,
    onClose: onCloseDirectPaySizingLogs,
  });

  const dealDirectPayLogsConfiguration: ILogsConfiguration = {
    id: dealTaxCreditDirectPay.map((d) => d.transfer_sizing.id).toString(), // sending all transfer sizing ids in a string separated by commas
    type: "transfersizing",
  };

  const handleOnOpenDirectPaySizingLog = () => {
    handleOpenDirectPaySizingLogs(
      dealDirectPayLogsConfiguration.type,
      dealDirectPayLogsConfiguration.id,
    );
  };

  const directPayPorfolioLogsConfiguration: ILogsConfiguration = {
    id: dealTransferPortfolio?.id ?? "",
    type: "dealtransferconfig",
  };

  const {
    loadMoreLogs: loadMoreDirectPayPortfolioLogs,
    loadingLogs: loadingDirectPayPortfolioLogs,
    logs: directPayPortfolioLogs,
    onCloseLogs: onCloseDirectPayPortfolioLogs,
    onOpenLogs: onOpenDirectPayPortfolioLogs,
    pagination: directPayPortfolioLogsPagination,
  } = useLogs();

  const {
    handleCloseDrawer: handleCloseDirectPayPortfolioLogs,
    handleOpenDrawer: handleOpenDirectPayPortfolioLogs,
    isDrawerOpen: isDirectPayPortfolioLogsOpen,
  } = useDrawer({
    onOpen: onOpenDirectPayPortfolioLogs,
    onClose: onCloseDirectPayPortfolioLogs,
  });

  const handleOnOpenDirectPayPortfolioLogs = () => {
    handleOpenDirectPayPortfolioLogs(
      directPayPorfolioLogsConfiguration.type,
      directPayPorfolioLogsConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingDealTaxCreditDirectPay}
        error={getDealTaxCreditDirectPayFailed}
      >
        <Box>
          {dealTaxCreditDirectPay.length === 0 ? (
            <Box className={styles.classes.message}>
              <Typography variant="body1" className={styles.classes.message}>
                This Deal does not have any Projects that have elected Tax
                Credit Direct Pay.
              </Typography>
            </Box>
          ) : (
            <React.Fragment>
              <Box className={cn("flex justify-end")}>
                <LogsButton onClick={handleOnOpenDirectPaySizingLog} />
              </Box>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead className={styles.classes.header}>
                      <TableRow>
                        <TableCell>
                          <LogsButton
                            onClick={handleOnOpenDirectPayPortfolioLogs}
                          />

                          {!isOnCase && (
                            <Button
                              canOpenUpgrade
                              btnType="primary"
                              label="Portfolio"
                              size="small"
                              startIcon={<EditIcon />}
                              onClick={openDealTaxCreditPortfolioEditModal}
                            />
                          )}
                        </TableCell>
                        {columns.map((c, idx) => {
                          return (
                            <TableCell key={idx}>
                              <Link
                                to={`/project/${c?.projectId}/tax/tax-credit`}
                                target="_blank"
                              >
                                {c?.name}
                              </Link>
                              <br />
                              {!isOnCase && (
                                <ConditionalProtect type="deal">
                                  <Button
                                    canOpenUpgrade
                                    btnType="primary"
                                    label="Edit"
                                    size="small"
                                    startIcon={<EditIcon />}
                                    onClick={() =>
                                      openDealTaxCreditEditModal(c?.id)
                                    }
                                  />
                                </ConditionalProtect>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map((row: { [key: string]: string }) => {
                        return (
                          <TableRow key={row.key}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                fontWeight: ["Key Dates", "Key Terms"].includes(
                                  row?.key,
                                )
                                  ? "bold"
                                  : "",
                              }}
                            >
                              {row.key}
                            </TableCell>
                            {columns.map((c) => {
                              return <TableCell>{row[c?.name]}</TableCell>;
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </React.Fragment>
          )}
        </Box>
      </ViewWrapper>

      <EditTaxCreditDirectPay
        headerLabel="Edit Tax Credit Direct Pay"
        loading={loadingUpdateDealTaxCreditDirectPayDetailsDetails}
        form={form}
        setForm={setForm}
        onClose={() => setOpenEditModal(false)}
        open={openEditModal}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        onConfirm={handleEditDealTaxCreditDirectPay}
      />

      <EditDealTaxCreditDirectPay
        headerLabel="Edit Portfolio Tax Credit Direct Pay"
        loading={loadingDealTransferPortfolioUpdate}
        form={taxCreditTransferConstraintsForm}
        setForm={setTaxCreditTransferConstraintsForm}
        onClose={() => setOpenDealEditModal(false)}
        open={openDealEditModal}
        formErrors={dealFormErrors}
        setFormErrors={setDealFormErrors}
        onConfirm={handleEditDealTaxCreditDirectPayPortfolio}
      />

      <LogsWrapper
        onClose={handleCloseDirectPaySizingLogs}
        open={isDirectPaySizingLogsOpen}
      >
        <Logs
          nextPage={loadMoreDirectPaySizingLogs}
          logs={directPaySizingLogs}
          type={dealDirectPayLogsConfiguration.type}
          loading={loadingDirectPaySizingLogs}
          totalLogs={directPaySizingLogsPagination.totalItems}
          id={""} // disabling notes
        />
      </LogsWrapper>

      <LogsWrapper
        onClose={handleCloseDirectPayPortfolioLogs}
        open={isDirectPayPortfolioLogsOpen}
      >
        <Logs
          nextPage={loadMoreDirectPayPortfolioLogs}
          logs={directPayPortfolioLogs}
          type={directPayPorfolioLogsConfiguration.type}
          loading={loadingDirectPayPortfolioLogs}
          totalLogs={directPayPortfolioLogsPagination.totalItems}
          id={directPayPorfolioLogsConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
