import { CONFIGURATION_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  ServerPaginatedResponse,
  ICounterparty,
  ICounterpartyForm,
  ICounterpartyUserForm,
  ICounterpartyUser,
} from "../../interfaces";

export const getCounterparties = async () => {
  return await AxiosHelper.get<ServerPaginatedResponse<ICounterparty[]>>(
    CONFIGURATION_ROUTES.data.counterparties.get,
  );
};

export const getSingleCounterparty = async (id: number) => {
  const url = replaceParams(
    CONFIGURATION_ROUTES.data.counterparties.getSingle,
    {
      id: id.toString(),
    },
  );
  return await AxiosHelper.get<ICounterparty>(url);
};

export const createCounterparty = async (form: ICounterpartyForm) => {
  return await AxiosHelper.post<ICounterpartyForm, ICounterparty>(
    CONFIGURATION_ROUTES.data.counterparties.create,
    form,
  );
};

export const updateCounterparty = async (
  id: number,
  form: ICounterpartyForm,
) => {
  const url = replaceParams(CONFIGURATION_ROUTES.data.counterparties.update, {
    id: id.toString(),
  });
  return await AxiosHelper.put<ICounterpartyForm, ICounterparty>(url, form);
};

export const addCounterpartyUser = async (form: ICounterpartyUserForm) => {
  return await AxiosHelper.post<ICounterpartyUserForm, ICounterpartyUser>(
    CONFIGURATION_ROUTES.data.counterparties.addUser,
    form,
  );
};

export const deleteCounterparty = async (id: number) => {
  const url = replaceParams(CONFIGURATION_ROUTES.data.counterparties.delete, {
    id: id.toString(),
  });
  return await AxiosHelper.delete(url);
};

export const deleteCounterpartyUser = async (id: number) => {
  const url = replaceParams(
    CONFIGURATION_ROUTES.data.counterparties.deleteUser,
    {
      id: id.toString(),
    },
  );
  return await AxiosHelper.delete(url);
};
