import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useOrganization } from "@clerk/clerk-react";
import Menu from "../../../../../components/menu";
import useStyles from "./styles";
import Logs from "../../../../../components/logs";
import Button from "../../../../../components/button";
import LogsButton from "../../../../../components/logs-button";
import ViewWrapper from "../../../../../components/view-wrapper";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ConditionalProtect from "../../../../../components/conditional-protect";
import UpdateDealSeparateAllocationFormModal from "../../../../../components/update-deal-other-sizing-form-modal";
import ReportTableV2 from "../../../../../components/report-table-v2";
import { cn } from "../../../../../utils/helpers";
import {
  DEAL_ALLOCATION_TYPE,
  DEAL_REVENUE_CONTRACT_ALLOCATION_BUCKET,
  DEAL_REVENUE_MERCHANT_ALLOCATION_BUCKET,
  DEAL_EXPENSE_ALLOCATION_BUCKET,
  DEAL_OTHER_FLOWS_ALLOCATION_BUCKET,
  PROJECT_REVENUE_CONTRACT_TYPE,
  PROJECT_REVENUE_MERCHANT_TYPE,
  PERIODICITY,
  DEAL_OTHER_FLOW_REPORT_BASIS,
  DEAL_OTHER_FLOW_REPORT_BASIS_OPTIONS,
  PERIODICITY_OPTIONS,
} from "../../../../../constants";
import { useAPI, useLogs, useDrawer } from "../../../../../utils/hooks";
import {
  IDealSeparateAllocation,
  IDealSeparateAllocationErrors,
  ILogsConfiguration,
  IReportTable,
  SetStateAction,
} from "../../../../../interfaces";

interface IProps {
  getDealSeparateAllocation: (
    dealUuid: string,
  ) => Promise<IDealSeparateAllocation>;
  updateDealSeparateAllocation: (
    dealUuid: string,
    form: IDealSeparateAllocation,
  ) => Promise<IDealSeparateAllocation>;
  getDealAllocationProfroma: (
    dealUuid: string,
    periodicity: string,
    basis: string,
  ) => Promise<IReportTable>;
  downloadDealAllocationProforma: (
    dealUuid: string,
    periodicity: PERIODICITY,
    basis: DEAL_OTHER_FLOW_REPORT_BASIS,
  ) => Promise<void>;
}

export default function DealSizingOtherEbitdaView({
  getDealSeparateAllocation,
  updateDealSeparateAllocation,
  getDealAllocationProfroma,
  downloadDealAllocationProforma,
}: IProps) {
  const styles = useStyles();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [periodicity, setPeriodicity] =
    React.useState<keyof typeof PERIODICITY>("MO");
  const [basis, setBasis] =
    React.useState<keyof typeof DEAL_OTHER_FLOW_REPORT_BASIS>("cash");

  const [separateAllocation, setSeparateAllocation] =
    React.useState<IDealSeparateAllocation>();

  const [editSeparateAllocationForm, setEditSeparateAllocationForm] =
    React.useState<IDealSeparateAllocation["allocations"]>();
  const [openSizingEditModal, setOpenSizingEditModal] =
    React.useState<boolean>(false);

  const {
    callAPI: updateDealSeparateAllocationCallAPI,
    errored: updateDealSeparateAllocationFailed,
    loading: loadingUpdateDealSeparateAllocation,
    fieldErrors: updateDealSeparateAllocationFormErrors,
    setFieldErrors: setUpdateDealSeparateAllocationFormErrors,
  } = useAPI<IDealSeparateAllocation, IDealSeparateAllocationErrors>(
    (dealUuid: string, form: IDealSeparateAllocation) =>
      updateDealSeparateAllocation(dealUuid, form),
  );

  const {
    callAPI: getDealSeparateAllocationCallAPI,
    errored: getDealSeparateAllocationFailed,
    loading: loadingDealSeparateAllocation,
  } = useAPI((dealUuid: string) => getDealSeparateAllocation(dealUuid), {
    initialLoading: true,
  });

  const { callAPI: downloadDealAllocationProformaCallAPI } = useAPI(
    (
      dealUuid: string,
      periodicity: PERIODICITY,
      basis: DEAL_OTHER_FLOW_REPORT_BASIS,
    ) => downloadDealAllocationProforma(dealUuid, periodicity, basis),
  );

  const {
    callAPI: getDealAllocationProfromaCallAPI,
    errored: getDealAllocationProfromaErrored,
    loading: getDealAllocationProfromaLoading,
    response: dealSeparateAllocationReport,
  } = useAPI(
    (dealUuid: string, periodicity: string, basis: string) =>
      getDealAllocationProfroma(dealUuid, periodicity, basis),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    getDealSeparateAllocationCallAPI(dealIdToUse).then((res) => {
      res && setSeparateAllocation(res);
    });
  }, [dealIdToUse]);

  React.useEffect(() => {
    fetchDealSeparateAllocationProforma(
      String(caseDealUuid),
      periodicity,
      basis,
    );
  }, [separateAllocation, periodicity, basis, caseDealUuid]);

  const fetchDealSeparateAllocationProforma = async (
    caseDealId: string,
    periodicity: keyof typeof PERIODICITY,
    basis: keyof typeof DEAL_OTHER_FLOW_REPORT_BASIS,
  ) => {
    await getDealAllocationProfromaCallAPI(caseDealId, periodicity, basis);
  };

  const isOnCase = React.useMemo(() => {
    return dealUuid !== caseDealUuid;
  }, [dealUuid, caseDealUuid]);

  const onUpdateDealSeparateAllocation = async (
    form: IDealSeparateAllocation["allocations"],
  ) => {
    const res = await updateDealSeparateAllocationCallAPI(caseDealUuid, form);
    res && setSeparateAllocation(res);
    return res;
  };

  const handleOpenEditSizigModal = () => {
    setEditSeparateAllocationForm(separateAllocation?.allocations);
    setOpenSizingEditModal(true);
  };

  const handleCloseEditSizigModal = () => {
    setOpenSizingEditModal(false);
  };

  const dealSizingOtherLogConfiguration: ILogsConfiguration = {
    id: Number(separateAllocation?.id),
    type: "dealothersizing",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      dealSizingOtherLogConfiguration.type,
      dealSizingOtherLogConfiguration.id,
      dealSizingOtherLogConfiguration.parentId,
    );
  };

  const revenueAllocationHeaders = ["Item", "Cash Sizing", "Tax Sizing"];

  const allocations = separateAllocation?.allocations;

  const contractedRevenueAllocation = Object.entries(
    allocations?.revenue_contract || {},
  ).map(([key, value]) => [
    PROJECT_REVENUE_CONTRACT_TYPE[
      key as keyof typeof PROJECT_REVENUE_CONTRACT_TYPE
    ],
    DEAL_REVENUE_CONTRACT_ALLOCATION_BUCKET[
      value[
        DEAL_ALLOCATION_TYPE.CASH
      ] as unknown as keyof typeof DEAL_REVENUE_CONTRACT_ALLOCATION_BUCKET
    ],
    DEAL_REVENUE_CONTRACT_ALLOCATION_BUCKET[
      value[
        DEAL_ALLOCATION_TYPE.TAX
      ] as unknown as keyof typeof DEAL_REVENUE_CONTRACT_ALLOCATION_BUCKET
    ],
  ]);

  const merchantRevenueAllocation = Object.entries(
    allocations?.revenue_merchant || {},
  ).map(([key, value]) => [
    PROJECT_REVENUE_MERCHANT_TYPE[
      key as keyof typeof PROJECT_REVENUE_MERCHANT_TYPE
    ],
    DEAL_REVENUE_MERCHANT_ALLOCATION_BUCKET[
      value[
        DEAL_ALLOCATION_TYPE.CASH
      ] as unknown as keyof typeof DEAL_REVENUE_MERCHANT_ALLOCATION_BUCKET
    ],
    DEAL_REVENUE_MERCHANT_ALLOCATION_BUCKET[
      value[
        DEAL_ALLOCATION_TYPE.TAX
      ] as unknown as keyof typeof DEAL_REVENUE_MERCHANT_ALLOCATION_BUCKET
    ],
  ]);

  const expenseAllocation = [
    [
      "Cash Sizing",
      DEAL_EXPENSE_ALLOCATION_BUCKET[
        allocations?.expense[
          DEAL_ALLOCATION_TYPE.CASH
        ] as unknown as keyof typeof DEAL_EXPENSE_ALLOCATION_BUCKET
      ],
    ],
    [
      "Tax Sizing",
      DEAL_EXPENSE_ALLOCATION_BUCKET[
        allocations?.expense[
          DEAL_ALLOCATION_TYPE.TAX
        ] as unknown as keyof typeof DEAL_EXPENSE_ALLOCATION_BUCKET
      ],
    ],
  ];

  const otherFlowsAllocation = [
    [
      "Cash Sizing",
      DEAL_OTHER_FLOWS_ALLOCATION_BUCKET[
        allocations?.other_flows[
          DEAL_ALLOCATION_TYPE.CASH
        ] as unknown as keyof typeof DEAL_OTHER_FLOWS_ALLOCATION_BUCKET
      ],
    ],
    [
      "Tax Sizing",
      DEAL_OTHER_FLOWS_ALLOCATION_BUCKET[
        allocations?.other_flows[
          DEAL_ALLOCATION_TYPE.TAX
        ] as unknown as keyof typeof DEAL_OTHER_FLOWS_ALLOCATION_BUCKET
      ],
    ],
  ];

  const handleDownloadAllocationReport = async () => {
    await downloadDealAllocationProformaCallAPI(dealUuid, periodicity, basis);
  };

  return (
    <>
      <ViewWrapper
        loading={loadingDealSeparateAllocation}
        error={getDealSeparateAllocationFailed}
      >
        <Paper className="p-2">
          <Box className={cn("flex justify-end mb-4")}>
            <Box className={cn("flex gap-4")}>
              <LogsButton onClick={handleOnOpenLogs} />

              <ConditionalProtect type="deal">
                {!isOnCase && (
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit EBITDA Categories"
                    btnType="primary"
                    onClick={handleOpenEditSizigModal}
                  />
                )}
              </ConditionalProtect>
            </Box>
          </Box>

          <Box className={cn("grid md:grid-cols-2 gap-4")}>
            <Paper classes={{ root: "p-4" }}>
              <Typography variant="h6">Revenue Contract</Typography>
              <TableContainer classes={{ root: styles.classes.tableContainer }}>
                <Table aria-label="sizing details table">
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {revenueAllocationHeaders.map((header) => (
                        <TableCell>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contractedRevenueAllocation.map((row) => (
                      <TableRow>
                        {row.map((cell) => (
                          <TableCell>{cell}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Paper classes={{ root: "p-4" }}>
              <Typography variant="h6">Merchant Revenue</Typography>
              <TableContainer classes={{ root: styles.classes.tableContainer }}>
                <Table aria-label="sizing details table">
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {revenueAllocationHeaders.map((header) => (
                        <TableCell>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {merchantRevenueAllocation.map((row) => (
                      <TableRow>
                        {row.map((cell) => (
                          <TableCell>{cell}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Paper classes={{ root: "p-4" }}>
              <Typography variant="h6">Expense</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    {expenseAllocation.map((row) => (
                      <TableRow>
                        {row.map((cell) => (
                          <TableCell>{cell}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Paper classes={{ root: "p-4" }}>
              <Typography variant="h6">Other Flows</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    {otherFlowsAllocation.map((row) => (
                      <TableRow>
                        {row.map((cell) => (
                          <TableCell>{cell}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Paper>

        <Paper className="my-4 p-2">
          <ViewWrapper
            loading={getDealAllocationProfromaLoading}
            error={getDealAllocationProfromaErrored}
          >
            {dealSeparateAllocationReport && (
              <>
                <Box className="flex gap-2 mb-4">
                  <Menu
                    selectedItem={
                      periodicity ? PERIODICITY[periodicity] : periodicity
                    }
                    placeholder="Periodicity"
                    menuItems={PERIODICITY_OPTIONS.map((o) => ({
                      label: o.label,
                      onClick: () => {
                        setPeriodicity(o.value as keyof typeof PERIODICITY);
                      },
                    }))}
                  />
                  <Menu
                    selectedItem={
                      periodicity ? DEAL_OTHER_FLOW_REPORT_BASIS[basis] : basis
                    }
                    placeholder="Basis"
                    menuItems={DEAL_OTHER_FLOW_REPORT_BASIS_OPTIONS.map(
                      (o) => ({
                        label: o.label,
                        onClick: () => {
                          setBasis(
                            o.value as keyof typeof DEAL_OTHER_FLOW_REPORT_BASIS,
                          );
                        },
                      }),
                    )}
                  />
                </Box>

                <ReportTableV2
                  download={handleDownloadAllocationReport}
                  data={dealSeparateAllocationReport}
                  groupExpandedDepth={0}
                />
              </>
            )}
          </ViewWrapper>
        </Paper>
      </ViewWrapper>

      <UpdateDealSeparateAllocationFormModal
        open={openSizingEditModal}
        loading={loadingUpdateDealSeparateAllocation}
        form={
          editSeparateAllocationForm as IDealSeparateAllocation["allocations"]
        }
        formErrors={updateDealSeparateAllocationFormErrors}
        setFormErrors={setUpdateDealSeparateAllocationFormErrors}
        setForm={
          setEditSeparateAllocationForm as SetStateAction<
            IDealSeparateAllocation["allocations"]
          >
        }
        onClose={handleCloseEditSizigModal}
        onUpdate={onUpdateDealSeparateAllocation}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealSizingOtherLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealSizingOtherLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
