import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => {
  return {
    table: {
      "& .MuiTableCell-head": {
        fontWeight: 600,
      },
      "& td": {
        padding: theme.spacing(1),
        paddingInline: theme.spacing(2),
      },
    },
    chartContainer: {
      marginBlock: theme.spacing(2),
    },
    sectionHeading: {
      margin: theme.spacing(0, 0, 1.2, 0.5),
      fontWeight: 600,
    },
    tableContainer: {
      maxHeight: "calc(100vh - 250px)",
    },
    boldRow: {
      "& td": {
        fontWeight: "bold !important",
      },
    },
    link: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  };
});
