import { enumToOptions } from "../utils/helpers";

export enum CURVE_TYPE {
  PC = "Price Curve",
  IRC = "Interest Rate Curve",
  ELCC = "Eligible Capacity Curve",
  IC = "Inflation Curve",
}
export enum PRICE_TERM_TYPE {
  RL = "Real",
  NOM = "Nominal",
}

export const CURVE_TYPE_OPTIONS = enumToOptions(CURVE_TYPE, ["IC"]);
export const PRICE_TERM_TYPE_OPTIONS = enumToOptions(PRICE_TERM_TYPE);

export enum COUNTERPARTY_TYPE {
  DEV = "Developer",
  DEV_SPONS = "Developer & Sponsor",
  CASH_EQ = "Cash Equity Investor",
  TAX_EQ = "Tax Equity Investor",
  TCL = "Tax Credit Buyer",
  TCS = "Tax Credit Syndicator",
  TEX = "Tax-Exempt Sponsor",
  LEND = "Lender",
  FIN = "Financial Advisor",
  LEG = "Legal Advisor",
  INDE = "Independent Engineer",
  TRANS = "Transmission Consultant",
  MARK = "Market Consultant",
  INSU = "Insurance Consultant",
  INBR = "Insurance Broker",
  INPR = "Insurance Provider",
  ENVI = "Environmental Consultant",
  APPR = "Appraiser",
  COST = "Cost Seg Consultant",
  PROP = "Property Tax Consultant",
  TAX = "Tax Accountant",
  FA = "Financial Accountant",
  ENER = "Energy & REC Buyer",
  EPC = "EPC",
  SUPP = "Supplier",
  OTHER = "Other",
}

export const COUNTERPARTY_TYPE_OPTIONS = enumToOptions(COUNTERPARTY_TYPE);
