import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_COMPARISON_API_ROUTES } from "../../constants";
import {
  IAddDealComparisonForm,
  IDealComparison,
  IDealComparisonInputs,
  IDealComparisonOutputs,
  IUpdateDealComparisonForm,
  ServerPaginatedResponse,
} from "../../interfaces";

export const addDealComparison = async (form: IAddDealComparisonForm) => {
  return await AxiosHelper.post<IAddDealComparisonForm, IDealComparison>(
    DEAL_COMPARISON_API_ROUTES.base.create,
    form,
  );
};

export const getDealComparisons = async (params?: { limit?: number }) => {
  return await AxiosHelper.get<ServerPaginatedResponse<IDealComparison[]>>(
    DEAL_COMPARISON_API_ROUTES.base.get,
    {
      params: {
        limit: params?.limit,
      },
    },
  );
};

export const getDealComparison = async (uuid: string) => {
  const url = replaceParams(DEAL_COMPARISON_API_ROUTES.base.getDetails, {
    uuid,
  });
  return await AxiosHelper.get<IDealComparison>(url);
};

export const updateDealComparison = async (
  uuid: string,
  form: IUpdateDealComparisonForm,
) => {
  const url = replaceParams(DEAL_COMPARISON_API_ROUTES.base.update, {
    uuid,
  });
  return await AxiosHelper.patch<IUpdateDealComparisonForm, IDealComparison>(
    url,
    form,
  );
};

export const deleteDealComparison = async (uuid: string) => {
  const url = replaceParams(DEAL_COMPARISON_API_ROUTES.base.delete, {
    uuid,
  });
  return await AxiosHelper.delete(url);
};

export const getDealAnalysisInputs = async (
  dealComparisonUuid: string,
  dealUuid: string,
) => {
  const url = replaceParams(DEAL_COMPARISON_API_ROUTES.analysis.getInputs, {
    dealComparisonUuid,
    dealUuid,
  });
  return await AxiosHelper.get<{ data: IDealComparisonInputs }>(url);
};

export const getDealAnalysisOutputs = async (
  dealComparisonUuid: string,
  dealUuid: string,
) => {
  const url = replaceParams(DEAL_COMPARISON_API_ROUTES.analysis.getOutputs, {
    dealComparisonUuid,
    dealUuid,
  });
  return await AxiosHelper.get<{ data: IDealComparisonOutputs }>(url);
};
