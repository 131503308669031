import { Property as CssProperty } from "csstype";

import LoaderScreen from "../loader-screen";
import ErrorScreen, { IProps as IErrorScreenProps } from "../error-screen";
import ProductPlanLimitHitView from "../product-plan-limit-hit-view";

interface IProps extends IErrorScreenProps {
  children: React.ReactNode;
  loading: boolean;
  error: boolean;
  loaderPlacement?: CssProperty.AlignItems;
  loadingPercentage?: number;
  showLimitHitView?: boolean;
  errorDescription?: string | JSX.Element;
  errorHeading?: string | JSX.Element;
}

export default function ViewWrapper({
  children,
  error,
  loading,
  loaderPlacement,
  errorDescription,
  errorHeading,
  loadingPercentage,
  showLimitHitView,
}: IProps): JSX.Element {
  return (
    <>
      {loading && (
        <LoaderScreen
          loaderPlacement={loaderPlacement}
          loadingPercentage={loadingPercentage}
        />
      )}
      {!loading && error && !showLimitHitView && (
        <ErrorScreen
          errorDescription={errorDescription}
          errorHeading={errorHeading}
        />
      )}
      {!loading && error && showLimitHitView && <ProductPlanLimitHitView />}
      {!loading && !error && <div id="content-loaded">{children}</div>}
    </>
  );
}
