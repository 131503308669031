import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import { useOrganization } from "@clerk/clerk-react";
import { Link } from "react-router-dom";

import Chip from "../general/Chip";
import Layout from "../layout";
import { cn, getSolverStatusChipFillColor } from "../../utils/helpers";
import { SOLVER_STATUS } from "../../constants/solver";
import { ISolver } from "../../interfaces/deal/solvers.interface";

interface IProps {
  currentSolver: ISolver | null;
  children: JSX.Element;
}

export default function SolverDetailsLayout({
  currentSolver,
  children,
}: IProps): JSX.Element {
  const { organization } = useOrganization();

  return (
    <>
      <Layout title={currentSolver?.name || "Solver Details"}>
        <>
          <Box className={cn("!mx-4 mt-4")}>
            {currentSolver && (
              <Box>
                {organization ? (
                  <Link to="/solvers">
                    <Typography className={cn("hover:!text-secondary")}>
                      <ArrowBackIcon /> Back to Deal Solvers List
                    </Typography>
                  </Link>
                ) : null}

                <Box className="flex items-start justify-start flex-col mt-6">
                  <Box className="flex items-center justify-start gap-4">
                    <Typography variant="h5" component="h5" marginBottom={1}>
                      {currentSolver.name}
                    </Typography>
                    <Chip
                      label={
                        SOLVER_STATUS[
                          currentSolver.status as keyof typeof SOLVER_STATUS
                        ]
                      }
                      color={
                        getSolverStatusChipFillColor(currentSolver.status)
                          .color as string
                      }
                      filledBackgroundColor={
                        getSolverStatusChipFillColor(currentSolver.status)
                          .backgroundColor
                      }
                      variant="filled"
                      className="-mt-2"
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    component="small"
                    marginBottom={1}
                  >
                    Created by {currentSolver.created_by}
                  </Typography>
                </Box>
                <Divider classes={{ root: cn("!mt-4") }} />
              </Box>
            )}
          </Box>
          <Box className={cn("!mx-4 mt-4")}>{children}</Box>
        </>
      </Layout>
    </>
  );
}
