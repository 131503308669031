import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";

import Modal from "../modal";
import AutocompleteField from "../autocomplete-field";
import {
  ISelectOption,
  ICollaborationCounterpartyForm,
  ICollaborationCounterpartyFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: ICollaborationCounterpartyFormErrors;
  setFormErrors: React.Dispatch<
    React.SetStateAction<ICollaborationCounterpartyFormErrors | undefined>
  >;
  form: ICollaborationCounterpartyForm;
  setForm: React.Dispatch<React.SetStateAction<ICollaborationCounterpartyForm>>;
  onClose: () => void;
  onConfirm: (form: ICollaborationCounterpartyForm) => Promise<void>;
  counterpartyOptions: ISelectOption[];
}

export default function CollaborationCounterpartyFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  counterpartyOptions,
}: IProps): JSX.Element {
  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [name]: value?.value,
    }));
  };

  const handleOnConfirm = async () => {
    if (form.counterparty !== null) {
      await onConfirm(form);
      onClose();
    } else {
      setFormErrors({
        counterparty: "Counterparty is required",
      });
    }
  };

  const handleResetForm = () => {
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      classes={{ paper: "!max-w-[600px]" }}
      resetForm={handleResetForm}
    >
      <Box>
        <Alert severity="info" classes={{ root: "mb-4" }}>
          You can manage your counterparties centrally in{" "}
          <Link to="/configuration/data/counterparties" className="underline">
            Configuration
          </Link>
        </Alert>
        <AutocompleteField
          label="Select Counterparty"
          name="counterparty"
          onChange={handleSingleAutoCompleteChange}
          options={counterpartyOptions}
          value={String(form.counterparty)}
          helperText={formErrors?.counterparty}
          onFocus={() => {
            clearSelectErrorOnFocus("counterparty");
          }}
          disabled={loading}
        />
        <Alert severity="success">
          You can manage users after "Add Deal Counterparty"
        </Alert>
      </Box>
    </Modal>
  );
}
