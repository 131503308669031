import { IIconProps } from "../../interfaces";

export default function CollaborationIcon({
  fillColor,
}: IIconProps): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1875 9.71875C12.8125 10.125 12.1562 10.125 11.7812 9.71875C11.375 9.34375 11.375 8.6875 11.7812 8.3125L13.5625 6.5H2C1.4375 6.5 1 6.0625 1 5.5C1 4.96875 1.4375 4.5 2 4.5H13.5625L11.7812 2.71875C11.375 2.34375 11.375 1.6875 11.7812 1.3125C12.1562 0.90625 12.8125 0.90625 13.1875 1.3125L16.6875 4.8125C17.0938 5.1875 17.0938 5.84375 16.6875 6.21875L13.1875 9.71875ZM4.78125 16.7188L1.28125 13.2188C0.875 12.8438 0.875 12.1875 1.28125 11.8125L4.78125 8.3125C5.15625 7.90625 5.8125 7.90625 6.1875 8.3125C6.59375 8.6875 6.59375 9.34375 6.1875 9.71875L4.40625 11.5H16C16.5312 11.5 17 11.9688 17 12.5C17 13.0625 16.5312 13.5 16 13.5H4.40625L6.1875 15.3125C6.59375 15.6875 6.59375 16.3438 6.1875 16.7188C5.8125 17.125 5.15625 17.125 4.78125 16.7188Z"
        fill={fillColor ?? "black"}
      />
    </svg>
  );
}
