import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import Logs from "../../../components/logs";
import Button from "../../../components/button";
import LogsWrapper from "../../../components/logs-wrapper";
import ViewWrapper from "../../../components/view-wrapper";
import ChartWrapper from "../../../components/chart-wrapper";
import ReportTableV2 from "../../../components/report-table-v2";
import CheckboxInput from "../../../components/checkbox-input";
import CollapsibleWrapper from "../../../components/collapsible-wrapper";
import ProjectTimingFormModal from "../../../components/project-timing-form-modal";
import ConditionalProtect from "../../../components/conditional-protect";
import SummaryTable from "../../../components/summary-table";
import LogsButton from "../../../components/logs-button";
import { cn } from "../../../utils/helpers";
import TimingChart, {
  ITimingChartProps,
} from "../../../components/timing-chart";
import {
  resetDeleteModalPropsAction,
  setCurrentProjectAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  useAPI,
  useLogs,
  useDrawer,
  useAppSelector,
} from "../../../utils/hooks";
import {
  IProjectTiming,
  IUpdateProjectTimingForm,
  IUpdateProjectTimingFormErrors,
  ILogsConfiguration,
  IReportTable,
  ProjectTimingPeriodType,
  IProjectTimingSummary,
} from "../../../interfaces";
import {
  PROJECT_TIMING_HORIZON_FIELD_MAPPING,
  UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE,
} from "../../../constants";
import { Paper } from "@mui/material";

interface IProps {
  getProjectTimingSummary: (
    projectUuid: string,
  ) => Promise<IProjectTimingSummary>;
  getProjectTiming: (projectUuid: string) => Promise<IProjectTiming[]>;
  editProjectTiming: (
    projectUuid: string,
    projectTimingId: number,
    form: IUpdateProjectTimingForm,
  ) => Promise<IProjectTiming>;
  getProjectTimingDateScheduleTable: (
    periodType: ProjectTimingPeriodType,
    projectUuid: string,
  ) => Promise<IReportTable>;
}

export default function ProjectTimingView({
  getProjectTimingSummary,
  getProjectTiming,
  editProjectTiming,
  getProjectTimingDateScheduleTable,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { projectUuid } = useParams();

  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();
  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [showOperationPeriod, setShowOperationPeriod] =
    React.useState<boolean>(false);

  const [editTimingModalOpen, setEditTimingModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IUpdateProjectTimingForm>(
    UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE,
  );
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();
  const [
    projectTimingDateSchedulePostCod,
    setProjectTimingDateSchedulePostCod,
  ] = React.useState<IReportTable>();
  const [projectTimingDateSchedulePreCod, setProjectTimingDateSchedulePreCod] =
    React.useState<IReportTable>();

  React.useEffect(() => {
    getProjectTimingSummaryCallAPI(projectUuid);
    getProjectTimingCallAPI(projectUuid).then((response) => {
      if (response) {
        setProjectTiming(response[0]);
        if (response[0]?.horizon === "OS") {
          setShowOperationPeriod(true);
        }
      }
    });
    getProjectTimingDateSchedulePostCodCallAPI(projectUuid).then((response) => {
      if (response) {
        setProjectTimingDateSchedulePostCod(response);
      }
    });
  }, [projectUuid]);

  React.useEffect(() => {
    if (projectTiming) {
      getProjectTimingDateSchedulePostCodCallAPI(projectUuid).then(
        (response) => {
          if (response) {
            setProjectTimingDateSchedulePostCod(response);
          }
        },
      );
    }
    if (projectTiming && projectTiming.horizon !== "OS") {
      getProjectTimingDateSchedulePreCodCallAPI(String(projectUuid)).then(
        (response) => {
          if (response) {
            setProjectTimingDateSchedulePreCod(response);
          }
        },
      );
    }
  }, [projectUuid, projectTiming]);

  const {
    callAPI: getProjectTimingSummaryCallAPI,
    errored: getTimingSummaryFailed,
    loading: loadingTimingSummary,
    response: projectTimingSummary,
  } = useAPI((projectUuid: string) => getProjectTimingSummary(projectUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectTimingCallAPI,
    errored: getTimingFailed,
    loading: loadingTiming,
  } = useAPI((projectUuid: string) => getProjectTiming(projectUuid), {
    initialLoading: true,
  });

  const {
    callAPI: editProjectTimingCallAPI,
    fieldErrors: editProjectTimingFormErrors,
    setFieldErrors: setEditProjectTimingFormErrors,
    loading: editProjectTimingLoading,
  } = useAPI<IProjectTiming, IUpdateProjectTimingFormErrors>(
    (projectUuid: string, timingId: number, form: IUpdateProjectTimingForm) =>
      editProjectTiming(projectUuid, timingId, form),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: getProjectTimingDateSchedulePostCodCallAPI,
    errored: getProjectTimimngDateSchedulePostCodFailed,
    loading: loadingProjectTimingDateSchedulePostCod,
  } = useAPI(
    (projectUuid: string) =>
      getProjectTimingDateScheduleTable("POST_COD", projectUuid),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: getProjectTimingDateSchedulePreCodCallAPI,
    errored: getProjectTimimngDateSchedulePreCodFailed,
    loading: loadingProjectTimingDateSchedulePreCod,
  } = useAPI(
    (projectUuid: string) =>
      getProjectTimingDateScheduleTable("PRE_COD", projectUuid),
    {
      initialLoading: true,
    },
  );

  const handleOnEditProjectTiming = async (form: IUpdateProjectTimingForm) => {
    const updatedForm = { ...form };

    Object.keys(updatedForm).forEach((key) => {
      if (updatedForm[key as keyof IUpdateProjectTimingForm] === "") {
        updatedForm[key as keyof IUpdateProjectTimingForm] = null as never;
      }
    });

    const fieldsToInclude =
      PROJECT_TIMING_HORIZON_FIELD_MAPPING[
        updatedForm.horizon as keyof typeof PROJECT_TIMING_HORIZON_FIELD_MAPPING
      ];

    const formToSubmit = Object.keys(updatedForm).reduce(
      (obj, key) => ({
        ...obj,
        [key as keyof IUpdateProjectTimingForm]: fieldsToInclude.includes(
          key as keyof IUpdateProjectTimingForm,
        )
          ? updatedForm[key as keyof IUpdateProjectTimingForm]
          : null,
      }),
      {} as IUpdateProjectTimingForm,
    );

    if (
      // if anything in projectTiming is changed, show confirm modal
      Object.keys(formToSubmit).some(
        (key) =>
          formToSubmit[key as keyof IUpdateProjectTimingForm] !==
          projectTiming?.[key as keyof IUpdateProjectTimingForm],
      )
    ) {
      dispatch(
        setDeleteModalPropsAction({
          open: true,
          title: "Confirm Timing Changes",
          description:
            "You are modifying timing, operating life, or periodicity, which may impact any price curves or strips of data you entered in expenses, revenue, etc. We will do our best to adjust them automatically, but please re-check or edit those as appropriate",
          confirmBtnLabel: "Confirm",
          confirmBtnType: "primary",
          onConfirm: async () => {
            const timing = await editProjectTimingCallAPI(
              projectUuid,
              Number(projectTiming?.id),
              formToSubmit,
            );
            if (timing && currentProject) {
              setProjectTiming(timing);
              handleCloseEditTimingModal();
              dispatch(
                setCurrentProjectAction({
                  ...currentProject,
                  bol: timing.start_date,
                  ntp: timing.ntp_date,
                  cod: timing.cod,
                }),
              );
              await getProjectTimingSummaryCallAPI(projectUuid);
            }
            dispatch(resetDeleteModalPropsAction());
          },
        }),
      );
    } else {
      const timing = await editProjectTimingCallAPI(
        projectUuid,
        Number(projectTiming?.id),
        formToSubmit,
      );
      if (timing && currentProject) {
        setProjectTiming(timing);
        dispatch(
          setCurrentProjectAction({
            ...currentProject,
            bol: timing.start_date,
            ntp: timing.ntp_date,
            cod: timing.cod,
          }),
        );
      }
      return timing;
    }
    return undefined;
  };

  const handleOpenEditProjectTimingModal = async () => {
    if (projectTiming) {
      const {
        created,
        created_by,
        id,
        modified,
        project,
        date_schedule,
        end_date,
        num_dates_in_schedule,
        ...formDetails
      } = projectTiming;
      setForm({ ...formDetails });
      setEditTimingModalOpen(true);
    }
  };

  const handleCloseEditTimingModal = () => {
    setEditTimingModalOpen(false);
  };

  const timingChartData: ITimingChartProps | undefined = React.useMemo(() => {
    if (projectTiming) {
      const {
        horizon,
        cod,
        start_date,
        end_date,
        ntp_date,
        mechanical_completion_date,
        placed_in_service_date,
        substantial_completion_date,
      } = projectTiming;

      const dataPoints = [
        // {
        //   date: new Date(start_date),
        //   color: "#FFA700",
        //   symbol: "rect",
        //   tooltip: "Start Date",
        //   id: "start",
        // },
        {
          date: new Date(ntp_date),
          color: "#F44336",
          symbol: "rectRot",
          tooltip: "Notice to Proceed",
          id: "ntp",
        },
        {
          date: new Date(mechanical_completion_date),
          color: "#00B7C3",
          symbol: "triangle",
          tooltip: "Mechanical Completion Date",
          rotation: 180,
          id: "mc",
        },
        {
          date: new Date(substantial_completion_date),
          color: "#007EC5",
          symbol: "triangle",
          tooltip: "Substantial Completion Date",
          id: "sc",
        },
        {
          date: new Date(placed_in_service_date),
          color: "#AB47BC",
          symbol: "circle",
          tooltip: "Placed In Service Date",
          id: "pis",
        },
        {
          date: new Date(cod),
          color: "#00B96A",
          symbol: "rect",
          tooltip: "COD",
          id: "cod",
        },
        ...(showOperationPeriod
          ? [
              {
                date: new Date(end_date),
                color: "#F44336",
                symbol: "circle",
                tooltip: "End Date",
                id: "end",
              },
            ]
          : []),
      ];

      const filteredDataPoints = dataPoints.filter((d) => {
        return horizon === "OS" ? d.id === "cod" || d.id === "end" : true;
      });

      return {
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        data: filteredDataPoints,
      };
    }
    return undefined;
  }, [projectTiming, showOperationPeriod]);

  const handleShowOperationPeriodToggle = () => {
    setShowOperationPeriod((s) => !s);
  };

  const projectTimingLogsConfiguration: ILogsConfiguration = {
    id: Number(projectTiming?.id),
    type: "projecttiming",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectTimingLogsConfiguration.type,
      projectTimingLogsConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingTiming} error={getTimingFailed}>
        <Box>
          {projectTiming && (
            <Box>
              <Box className={styles.classes.header}>
                <Box>
                  <LogsButton onClick={handleOnOpenLogs} />

                  <ConditionalProtect type="project">
                    <Button
                      canOpenUpgrade
                      startIcon={<EditIcon />}
                      label="Edit"
                      btnType="primary"
                      onClick={handleOpenEditProjectTimingModal}
                    />
                  </ConditionalProtect>
                </Box>
              </Box>
              <Box>
                {timingChartData && (
                  <Box className={styles.classes.chartContainer}>
                    <CollapsibleWrapper
                      title="Timing"
                      collapseButtonLabel="Chart"
                    >
                      <ChartWrapper className={cn("!shadow-none")}>
                        <Box>
                          <CheckboxInput
                            checked={showOperationPeriod}
                            label="Show Operations Period"
                            onChange={handleShowOperationPeriodToggle}
                          />
                          <TimingChart {...timingChartData} />
                        </Box>
                      </ChartWrapper>
                    </CollapsibleWrapper>
                  </Box>
                )}

                <ViewWrapper
                  loading={loadingTimingSummary}
                  error={getTimingSummaryFailed}
                >
                  <Paper className={cn("p-2")}>
                    <Typography fontSize="15" fontWeight="bold">
                      {projectTimingSummary?.data.report_title}
                    </Typography>
                    <Box className={styles.classes.content}>
                      {projectTimingSummary?.data.tables.map((table, idx) => {
                        return (
                          <SummaryTable
                            key={idx}
                            usedIn="project"
                            summaryTable={table}
                            onEditClick={handleOpenEditProjectTimingModal}
                          />
                        );
                      })}
                    </Box>
                  </Paper>
                </ViewWrapper>

                {projectTiming && projectTiming.horizon !== "OS" && (
                  <ViewWrapper
                    loading={loadingProjectTimingDateSchedulePreCod}
                    error={getProjectTimimngDateSchedulePreCodFailed}
                  >
                    <Box className={cn("my-4")}>
                      {projectTimingDateSchedulePreCod && (
                        <>
                          <ReportTableV2
                            data={projectTimingDateSchedulePreCod}
                            groupExpandedDepth={-1}
                          />
                        </>
                      )}
                    </Box>
                  </ViewWrapper>
                )}
                <ViewWrapper
                  loading={loadingProjectTimingDateSchedulePostCod}
                  error={getProjectTimimngDateSchedulePostCodFailed}
                >
                  <Box className={cn("my-4")}>
                    {projectTimingDateSchedulePostCod && (
                      <>
                        <ReportTableV2
                          data={projectTimingDateSchedulePostCod}
                          groupExpandedDepth={-1}
                        />
                      </>
                    )}
                  </Box>
                </ViewWrapper>
              </Box>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <ProjectTimingFormModal
        open={editTimingModalOpen}
        headerLabel="Edit Timing"
        loading={editProjectTimingLoading}
        formErrors={editProjectTimingFormErrors}
        setFormErrors={setEditProjectTimingFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseEditTimingModal}
        onConfirm={handleOnEditProjectTiming}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectTimingLogsConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectTimingLogsConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
