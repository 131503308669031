import { IProjectExpenseForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_EXPENSE_TYPE {
  OANDM = "O&M (Scheduled)",
  OANDM_UN = "O&M (Unscheduled)",
  INS = "Insurance",
  ASSMGMT = "Asset Management",
  ADMIN = "Administrative",
  LAND = "Land Lease",
  PROPTX = "Property Tax",
  MAJMAIN = "Major Maintenance",
  LOC = "Letter of Credit",
  TAXES = "Taxes",
  CONT = "Contingency",
  OTHER = "Other Operations",
  OTHER_NO = "Other Non-Operational",
}

export enum PROJECT_EXPENSE_MONTHLY_ALLOCATION_TYPE {
  TH = "30 / 360",
  AC = "Actual / Actual",
}

export const PROJECT_EXPENSE_MONTHLY_ALLOCATION_TYPE_OPTIONS = enumToOptions(
  PROJECT_EXPENSE_MONTHLY_ALLOCATION_TYPE,
);

export const PROJECT_EXPENSE_TYPE_OPTIONS = enumToOptions(PROJECT_EXPENSE_TYPE);

export enum PROJECT_EXPENSE_INPUT_TYPE {
  NUMBER = "Number",
  STRIP = "Schedule",
}

export const PROJECT_EXPENSE_INPUT_TYPE_OPTIONS = enumToOptions(
  PROJECT_EXPENSE_INPUT_TYPE,
);

export enum PROJECT_EXPENSE_UNIT_TYPE {
  DOLPERKWYR = "$/kW/Year", // Dollar per Kilowatt per Year
  DOLPERYR = "$/Year", // Dollar per Year
  DOLPERMWYR = "$/MWh", // Dollar per Megawatt hour
  PERCENTREV = "% of Revenue", // Percent of Total Revenue
  PERCENTCOST = "% of Project Cost/Year", // Percent of Total Project Cost
  PERCENTOPEXP = "% of Non-Contingency Expenses", // Percent of Total Expenses, used in Contingency type primarily
  PERCENTBLDCOST = "% of Build Cost/Year", // Percent of Total Build Cost
  DOLPERACRE = "$/Acre/Year", // Dollar per Acre, only for Land Lease
}

export const PROJECT_EXPENSE_UNIT_TYPE_OPTIONS = enumToOptions(
  PROJECT_EXPENSE_UNIT_TYPE,
);

export enum PROJECT_EXPENSE_UNIT_TYPE_END_ADORNMENT {
  DOLPERKWYR = "$/kW/Year",
  DOLPERYR = "$/Year",
  DOLPERMWYR = "$/MWh",
  DOLPERACRE = "$/Acre/Year", // only for Land Lease
  PERCENTREV = "% ", // this space @ the end is intentional to avoid duplicate enum member value error.
  PERCENTCOST = "%/Year",
  PERCENTOPEXP = "%",
  PERCENTBLDCOST = "%/Year ",
}

export const PROJECT_EXPENSE_UNIT_PERCENTAGE_OPTIONS = [
  "PERCENTREV",
  "PERCENTCOST",
  "PERCENTOPEXP",
  "PERCENTBLDCOST",
];

export const PROJECT_EXPENSE_FORM_DEFAULT_STATE: IProjectExpenseForm = {
  base_year: "",
  escalation_method: "OY",
  expense: "",
  acres: "",
  dollars_per_yr_percentage: "",
  cash_basis_lag: "",
  expense_strip: [],
  escalator: "",
  expense_input_type: "",
  start_input_method: "",
  term_start_point: "",
  expense_unit_type: null,
  start_date: "",
  term_input_method: "",
  term_end_point: "",
  term: "",
  type: "",
  name: "",
  rate: "",
  monthly_allocation_type: "TH",
};
