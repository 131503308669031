import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

import useStyles from "./styles";
import ViewWrapper from "../../components/view-wrapper";
import Chip from "../../components/general/Chip";
import Button from "../../components/button";
import Searchbar from "../../components/search-bar";
import AddSolverFormModal from "../../components/add-solver-form-modal";
import ConditionalProtect from "../../components/conditional-protect";
import { CREATION_DEAL_STATUS, DEAL_STATUS } from "../../constants/deal";
import { getDealFees } from "../../apis/deal/fee";
import { getProjectCostList } from "../../apis/project/cost";
import { useAPI, useAppSelector, useSearchBar } from "../../utils/hooks";
import {
  getTableColumnAccordingToStatus,
  getSolverStatusChipFillColor,
  sortArrayOfObjects,
  trimString,
} from "../../utils/helpers";
import {
  IDealCase,
  ITableColumn,
  ITableSort,
  ServerPaginatedResponse,
  ISelectOption,
  IProject,
  IProjectRevenue,
  IDeal,
  IGetDealsParams,
} from "../../interfaces";
import {
  IAddSolverForm,
  IAddSolverFormErrors,
  ISolverLite,
} from "../../interfaces/deal/solvers.interface";
import {
  SOLVER_FOR_TYPES,
  SOLVER_GOAL_TYPES,
  SOLVER_STATUS,
  SOLVER_FOR_FIELD_NAME,
} from "../../constants/solver";

interface IProps {
  getSolvers: (
    dealUuid: string,
  ) => Promise<ServerPaginatedResponse<ISolverLite[]>>;
  addSolver: (form: IAddSolverForm) => Promise<ISolverLite>;
  getDealCases: (
    dealUuid: string,
  ) => Promise<ServerPaginatedResponse<IDealCase[]>>;
  getProjectsOfDeal: (dealUuid: string) => Promise<IProject[]>;
  getProjectRevenueContracts: (
    projectUuid: string,
  ) => Promise<ServerPaginatedResponse<IProjectRevenue[]>>;
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]>>;
}

const columns: ITableColumn[] = [
  { align: "left", id: "name", label: "Name", minWidth: 200 },
  { align: "left", id: "deal", label: "Deal", minWidth: 200 },
  { align: "left", id: "case", label: "Case", minWidth: 200 },
  { align: "left", id: "solver_for", label: "Solve For", minWidth: 200 },
  {
    align: "left",
    id: "project",
    label: "Project",
    minWidth: 200,
  },
  {
    align: "left",
    id: "goal_type",
    label: "Goal",
    minWidth: 200,
  },
  {
    id: "goal_value",
    label: "Goal Value",
    minWidth: 200,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 200,
    align: "left",
  },
  { align: "left", id: "created", label: "Date Created", minWidth: 100 },
];

export default function SolversView({
  getSolvers,
  addSolver,
  getDealCases,
  getProjectsOfDeal,
  getProjectRevenueContracts,
  getDeals,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId } = useParams();
  const navigate = useNavigate();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const [addSolverModalOpen, setAddSolverModalOpen] =
    React.useState<boolean>(false);

  const [addSolverForm, setAddSolverForm] = React.useState<IAddSolverForm>({
    name: "",
    deal: null,
    case: null,
    solver_for: "CPRICE",
    goal_type: "SE_BPT",
    goal_value: null,
    solver_for_field_object_id: null,
    project: null,
  });

  const [solvers, setSolvers] = React.useState<ISolverLite[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [dealOptions, setDealOptions] = React.useState<{
    [key in CREATION_DEAL_STATUS]: ISelectOption[];
  }>({
    Active: [],
    Draft: [],
  });

  const { searchString, onSearchStringChange, handleClearSearchString } =
    useSearchBar();

  const { callAPI: fetchDealCases, response: dealCases } = useAPI(
    (dealUuid: string) => getDealCases(dealUuid),
  );

  const { callAPI: fetchProjectsOfDeal, response: projectsOfDeal } = useAPI(
    (dealUuid: string) => getProjectsOfDeal(dealUuid),
  );

  const { ctrlPressed } = useAppSelector((s) => s.common);

  // Get Revenue Contracts
  const {
    callAPI: fetchProjectRevenueContracts,
    response: projectRevenueContracts,
  } = useAPI((projectUuid: string) => getProjectRevenueContracts(projectUuid));

  // Get Deal Fees
  const { callAPI: getDealFeesCallAPI, response: dealFeeList } = useAPI(
    (dealUuid: string) => getDealFees(dealUuid),
    {
      initialLoading: true,
    },
  );

  // Get Project Costs
  const { callAPI: getProjectCostListCallAPI, response: projectCostList } =
    useAPI((projectUuid) => getProjectCostList(projectUuid), {
      initialLoading: true,
    });

  const {
    callAPI: getSolversCallAPI,
    loading: solversLoading,
    errored: solversLoadingFailed,
  } = useAPI((dealUuid: string) => getSolvers(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: addSolverCallAPI,
    fieldErrors: addSolverFormErrors,
    setFieldErrors: setAddSolverFormErrors,
    loading: addSolverLoading,
  } = useAPI<ISolverLite, IAddSolverFormErrors>((form: IAddSolverForm) =>
    addSolver(form),
  );

  const handleGetDeals = async () => {
    const deals = await getDeals({}).catch(() => null);
    if (deals) {
      const categorizedDeals: {
        [key in CREATION_DEAL_STATUS]: ISelectOption[];
      } = {
        Active: [],
        Draft: [],
      };

      deals?.results?.forEach((d: IDeal) => {
        if (d?.status !== "ARCH") {
          categorizedDeals[DEAL_STATUS[d?.status]]?.push({
            label: d?.name,
            value: String(d?.uuid),
          });
        }
      });

      setDealOptions(categorizedDeals);
    }
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = React.useMemo(() => {
    return solvers.filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [solvers, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleOpenAddSolverModal = async () => {
    setAddSolverForm({
      name: "",
      deal: null,
      case: null,
      solver_for: "CPRICE",
      goal_type: "SE_BPT",
      goal_value: null,
      solver_for_field_object_id: null,
      project: null,
    });
    await handleGetDeals();
    setAddSolverModalOpen(true);
  };

  const handleCloseAddSolverModal = () => {
    setAddSolverModalOpen(false);
  };

  const handleAddSolver = async (form: IAddSolverForm) => {
    const payload = {
      ...form,
      case: form.case === "base" ? null : form.case,
    };
    const solver = await addSolverCallAPI(payload);
    if (solver) {
      setSolvers((prevCases) => [...prevCases, solver]);
      goToDetails(solver.uuid, false);
    }
    return solver;
  };

  const goToDetails = (uuid: string, external: boolean = false) => {
    if (ctrlPressed) {
      window.open(`/solvers/${uuid}`);
      return;
    }
    if (external) {
      window.location.href = `/solvers/${uuid}`;
    } else {
      navigate(`/solvers/${uuid}`);
    }
  };

  React.useEffect(() => {
    getSolversCallAPI(Number(dealId)).then((res) => {
      res && setSolvers(res.results);
    });
  }, [dealId]);

  const fetchSolverForFieldOptions = (
    uuid: string,
    solverFor: string,
    caseUuid: string | null = null,
  ) => {
    switch (solverFor) {
      case "CPRICE":
        fetchProjectRevenueContracts(uuid);
        break;
      case "COST":
        getProjectCostListCallAPI(uuid);
        break;
      case "DEALFEE":
        if (caseUuid) {
          const dealOfCase = dealCases?.results.find(
            (c) => c.uuid === caseUuid,
          );
          getDealFeesCallAPI(dealOfCase?.child_deal.uuid);
        } else {
          getDealFeesCallAPI(uuid);
        }
        break;
      default:
        break;
    }
  };

  const handleCaseSelection = (caseUuid: string | null) => {
    if (caseUuid) {
      const dealOfCase = dealCases?.results.find((c) => c.uuid === caseUuid);
      if (dealOfCase) {
        fetchProjectsOfDeal(dealOfCase.child_deal.uuid);
        getDealFeesCallAPI(dealOfCase.child_deal.uuid);
      }
    }
  };

  const dealCaseOptions: ISelectOption[] = React.useMemo(() => {
    if (dealCases) {
      const cases = dealCases.results.map((c) => ({
        label: c.name,
        value: String(c.uuid),
      }));
      return [
        {
          label: "Base Case",
          value: "base",
        },
        ...cases,
      ];
    }
    return [];
  }, [dealCases]);

  const projectOptions: ISelectOption[] = React.useMemo(() => {
    if (projectsOfDeal) {
      const projects = projectsOfDeal.map((p) => ({
        label: p.name,
        value: String(p.uuid),
      }));
      return projects;
    }
    return [];
  }, [projectsOfDeal]);

  const solverForFieldOptions: ISelectOption[] = React.useMemo(() => {
    let options;
    switch (addSolverForm.solver_for) {
      case "CPRICE":
        options = projectRevenueContracts?.results;
        break;
      case "COST":
        options = projectCostList?.data.cost_items;
        break;
      case "DEALFEE":
        options = dealFeeList?.results;
        break;
      default:
        break;
    }

    if (options) {
      const results = options.map((c) => ({
        label: c.name || "",
        value: String(c.id),
      }));
      return results;
    }
    return [];
  }, [
    projectRevenueContracts,
    projectCostList,
    dealFeeList,
    addSolverForm.solver_for,
  ]);

  return (
    <>
      <ViewWrapper loading={solversLoading} error={solversLoadingFailed}>
        {solvers?.length > 0 ? (
          <Box className="m-4">
            <Box className={styles.classes.topSection}>
              <Box className={styles.classes.searchAndFilterContainer}>
                <Searchbar
                  searchString={searchString}
                  onSearchStringChange={onSearchStringChange}
                  handleClearSearchString={handleClearSearchString}
                />
              </Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Add Solver"
                  onClick={handleOpenAddSolverModal}
                />
              </ConditionalProtect>
            </Box>
            {/* <Typography variant="h6">Case List</Typography> */}
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer classes={{}}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {columns.map((column, idx) => {
                        return (
                          <TableCell
                            key={idx}
                            align={column.align as "left"}
                            style={{ maxWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={sortTable.orderBy === column.id}
                              direction={
                                sortTable.orderBy === column.id
                                  ? sortTable.order
                                  : "asc"
                              }
                              onClick={() => sortRows(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={
                            getTableColumnAccordingToStatus(
                              columns,
                              currentDeal?.status as string,
                            ).length
                          }
                          align="center"
                        >
                          No Solvers Found!
                        </TableCell>
                      </TableRow>
                    )}
                    {visibleRows.map((c, idx) => {
                      return (
                        <TableRow
                          hover
                          key={idx}
                          tabIndex={-1}
                          className={styles.classes.dataRow}
                          onClick={() => goToDetails(c.uuid)}
                          style={{ height: "80px" }}
                        >
                          <TableCell align="left">
                            {trimString(c?.name, 40)}
                          </TableCell>

                          <TableCell align="left">
                            {trimString(c?.deal_name, 40)}
                          </TableCell>

                          <TableCell align="left">
                            {trimString(c?.case_name, 40)}
                          </TableCell>

                          <TableCell align="left">
                            {
                              SOLVER_FOR_TYPES[
                                c?.solver_for as keyof typeof SOLVER_FOR_TYPES
                              ]
                            }
                          </TableCell>

                          <TableCell align="left">
                            {trimString(c?.project_name, 40)}
                          </TableCell>

                          <TableCell align="left">
                            {
                              SOLVER_GOAL_TYPES[
                                c?.goal_type as keyof typeof SOLVER_GOAL_TYPES
                              ]
                            }
                          </TableCell>
                          <TableCell align="left">{c?.goal_value}%</TableCell>
                          <TableCell align="left">
                            <Chip
                              label={
                                SOLVER_STATUS[
                                  c?.status as keyof typeof SOLVER_STATUS
                                ]
                              }
                              color={
                                getSolverStatusChipFillColor(c?.status)
                                  .color as string
                              }
                              filledBackgroundColor={
                                getSolverStatusChipFillColor(c?.status)
                                  .backgroundColor
                              }
                              variant="filled"
                            />
                          </TableCell>
                          <TableCell>
                            {formatDistanceToNow(new Date(c?.created), {
                              addSuffix: true,
                            })}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={solvers.length}
                className={styles.classes.paginationRow}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <Box className={styles.classes.emptyContainer}>
            <Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add Solver"
                  onClick={handleOpenAddSolverModal}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>

              <Typography variant="body1" className={styles.classes.createInfo}>
                No Solvers added yet. Please add a solver to start.
              </Typography>
            </Box>
          </Box>
        )}

        <AddSolverFormModal
          headerLabel="Create Solver"
          open={addSolverModalOpen}
          loading={addSolverLoading}
          caseOptions={dealCaseOptions}
          form={addSolverForm}
          setForm={setAddSolverForm}
          formErrors={addSolverFormErrors}
          setFormErrors={setAddSolverFormErrors}
          onClose={handleCloseAddSolverModal}
          onConfirm={handleAddSolver}
          projectOptions={projectOptions}
          fetchSolverForFieldOptions={fetchSolverForFieldOptions}
          fetchDealCases={fetchDealCases}
          solverForFieldOptions={solverForFieldOptions}
          dealOptions={[...dealOptions.Active, ...dealOptions.Draft]}
          fetchProjectsOfDeal={fetchProjectsOfDeal}
          solverForFieldName={
            SOLVER_FOR_FIELD_NAME[
              addSolverForm.solver_for as keyof typeof SOLVER_FOR_FIELD_NAME
            ]
          }
          handleCaseSelection={handleCaseSelection}
        />
      </ViewWrapper>
    </>
  );
}
