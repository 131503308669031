import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import SummaryTable from "../../../../../components/summary-table";
import { IsummaryTablesObject } from "../../../../../interfaces";
import { useAPI } from "../../../../../utils/hooks";
import { cn } from "../../../../../utils/helpers";

interface IProps {
  getDealTaxSummary: (dealUuid: string) => Promise<IsummaryTablesObject>;
}

export default function DealTaxSummaryView({
  getDealTaxSummary,
}: IProps): JSX.Element {
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  React.useEffect(() => {
    getDealTaxSummaryCallAPI(dealIdToUse);
  }, [dealIdToUse]);

  const {
    callAPI: getDealTaxSummaryCallAPI,
    loading: loadingDealTaxSummary,
    errored: getDealTaxSummaryFailed,
    response: dealTaxSummary,
  } = useAPI((dealUuid) => getDealTaxSummary(dealUuid), {
    initialLoading: true,
  });

  return (
    <ViewWrapper
      loading={loadingDealTaxSummary}
      error={getDealTaxSummaryFailed}
    >
      <Box className={cn("border-[1px] border-[#e0e0e0] rounded-[4px] mt-3")}>
        {dealTaxSummary?.data.tables.map((table, idx) => {
          return (
            <SummaryTable
              key={idx}
              usedIn="deal"
              summaryTable={table}
              rootClasses={cn("!border-none")}
            />
          );
        })}
      </Box>
    </ViewWrapper>
  );
}
