import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import React from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";
import { Link, useParams } from "react-router-dom";

import Button from "../button";
import StatusChip from "../general/Chip";
import ConditionalProtect from "../conditional-protect";
import CollaborationFormModal from "../collaboration-form-modal";
import { getDeals } from "../../apis/deal/base";
import { useAPI, useAppSelector } from "../../utils/hooks";
import { cn, getStatusChipFillColor } from "../../utils/helpers";
import { setCurrentCollaborationAction } from "../../utils/redux/slices";
import {
  getCollaborationDetails,
  updateCollaboration,
} from "../../apis/collaboration/base";
import {
  CREATION_DEAL_STATUS,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
} from "../../constants";
import {
  ISelectOption,
  ICollaboration,
  ICollaborationForm,
  ICollaborationFormErrors,
} from "../../interfaces";

interface IProps {
  children: JSX.Element;
}

export default function CollaborationPageLayout({
  children,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { organization } = useOrganization();

  const { collaborationUuid } = useParams();

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [collaborationForm, setCollaborationForm] =
    React.useState<ICollaborationForm>({
      name: null,
      deal: null,
      description: null,
      status: "",
    });

  const { currentCollaboration } = useAppSelector((s) => s.collaboration);

  const { callAPI: getCollaborationDetailsCallAPI } = useAPI(
    getCollaborationDetails,
  );

  const {
    callAPI: updateCollaborationCallAPI,
    fieldErrors: updateCollaborationFieldErrors,
    setFieldErrors: setUpdateCollaborationFieldErrors,
    loading: updateCollaborationLoading,
  } = useAPI<ICollaboration, ICollaborationFormErrors>(updateCollaboration);

  React.useEffect(() => {
    collaborationUuid && fetchCollaborationDetails(collaborationUuid);
  }, [collaborationUuid]);

  const fetchCollaborationDetails = (uuId: string) => {
    getCollaborationDetailsCallAPI(uuId).then((res) => {
      dispatch(setCurrentCollaborationAction(res));
    });
  };

  const handleEditClick = async () => {
    await getDealsCallAPI();
    if (currentCollaboration) {
      setCollaborationForm({
        name: currentCollaboration?.name,
        deal: currentCollaboration?.deal_details.uuid,
        description: currentCollaboration?.description,
        status: currentCollaboration?.status,
      });
    }
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (form: ICollaborationForm) => {
    const updatedCollaboration = await updateCollaborationCallAPI(
      collaborationUuid as string,
      form,
    );
    if (updatedCollaboration && collaborationUuid) {
      fetchCollaborationDetails(collaborationUuid);
    }
    return updatedCollaboration;
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const { callAPI: getDealsCallAPI, response: deals } = useAPI(() =>
    getDeals({}),
  );

  const dealOptions: ISelectOption[] = React.useMemo(() => {
    return (
      deals?.results.map((deal) => ({
        label: deal.name,
        value: deal.uuid,
      })) || []
    );
  }, [deals]);

  const onDealChipClick = () => {
    window.open(
      `/deal/${currentCollaboration?.deal_details.uuid}/case-deal/${currentCollaboration?.deal_details.uuid}/general`,
      "_blank",
    );
  };

  return (
    <>
      <Box className={cn("!mx-4 mt-4")}>
        {currentCollaboration && (
          <Box>
            {organization ? (
              <Link to="/collaboration">
                <Typography className={cn("hover:!text-secondary")}>
                  <ArrowBackIcon /> Back to Deal Share List
                </Typography>
              </Link>
            ) : null}

            <Box className="flex items-center justify-start gap-4">
              <Typography variant="h5" component="h5" marginBottom={1}>
                {currentCollaboration.name}
              </Typography>
              {organization ? (
                <StatusChip
                  className="!mb-1"
                  label={CREATION_DEAL_STATUS[currentCollaboration.status]}
                  color={
                    getStatusChipFillColor(currentCollaboration.status).color
                  }
                  filledBackgroundColor={
                    getStatusChipFillColor(currentCollaboration.status)
                      .backgroundColor
                  }
                  variant="filled"
                />
              ) : null}
            </Box>
            <Box>
              <Box className={cn("flex justify-between items-center gap-2")}>
                <Box className={cn("flex gap-2")}>
                  <Chip
                    label={
                      DEAL_STRUCTURE_TYPE[
                        currentCollaboration.deal_details.structure
                      ]
                    }
                  />
                  <Chip
                    label={
                      DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                        currentCollaboration.deal_details
                          .tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                      ]
                    }
                  />
                  {organization ? (
                    <Chip
                      label={currentCollaboration.deal_details.name}
                      onClick={onDealChipClick}
                      variant="outlined"
                      icon={<OpenInNewIcon fontSize="small" />}
                    />
                  ) : null}
                </Box>
                <Box>
                  <ConditionalProtect type="externalshare">
                    <Button
                      startIcon={<EditIcon />}
                      btnType="primary"
                      label="Edit Deal Share"
                      onClick={handleEditClick}
                    />
                  </ConditionalProtect>
                </Box>
              </Box>
            </Box>
            <Divider classes={{ root: cn("!mt-4") }} />
          </Box>
        )}
      </Box>
      <Box className={cn("!mx-4 mt-4")}>{children}</Box>

      {currentCollaboration && (
        <CollaborationFormModal
          open={isEditModalOpen}
          onClose={handleEditModalClose}
          onConfirm={handleEditSubmit}
          headerLabel="Edit Deal Share"
          loading={updateCollaborationLoading}
          formErrors={updateCollaborationFieldErrors}
          setFormErrors={setUpdateCollaborationFieldErrors}
          form={collaborationForm}
          setForm={setCollaborationForm}
          dealOptions={dealOptions}
          hideFields={["deal"]}
        />
      )}
    </>
  );
}
