import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import { Protect } from "@clerk/clerk-react";

import useStyles from "./styles";
import Layout from "../../../components/layout";
import DealPagesLayout from "../../../components/deal-pages-layout";
import ViewWrapper from "../../../components/view-wrapper";
import ActionButton from "../../../components/action-button";
import { useNavigate, useParams } from "react-router-dom";
import { USER_PERMISSIONS } from "../../../constants";
import { IDealComparison } from "../../../interfaces";
import { cn, trimString } from "../../../utils/helpers";

interface IProps {
  inPersonalAccount?: boolean;
  type: "case" | "deal";
  loading: boolean;
  errored: boolean;
  comparison?: IDealComparison;
  onDeleteClick: (uuId?: string) => void;
  onEditClick: () => void;
  children: JSX.Element;
}

export default function ComparisonDetailPageLayout({
  children,
  type,
  inPersonalAccount,
  loading,
  errored,
  comparison,
  onDeleteClick,
  onEditClick,
}: IProps): JSX.Element {
  const { dealId } = useParams();
  const navigate = useNavigate();

  const styles = useStyles({ reduceHeight: type === "deal" ? 205 : 295 });

  if (inPersonalAccount) {
    return (
      <ViewWrapper loading={loading} error={errored}>
        {children}
      </ViewWrapper>
    );
  }

  const gotoAnalysisList = () => {
    if (type === "case") {
      navigate(`/deal/${dealId}/analysis`);
    } else {
      navigate("/analysis/deal-comparison-list");
    }
  };

  const TopLevelLayout = type === "case" ? DealPagesLayout : Layout;

  return (
    <TopLevelLayout title={comparison?.name}>
      <ViewWrapper loading={loading} error={errored}>
        <div className={type === "deal" ? styles.classes.container : ""}>
          {type === "deal" && (
            <div
              className={styles.classes.backButtonContainer}
              onClick={gotoAnalysisList}
            >
              <ArrowBackIcon />
              <Typography>Back to Comparison List</Typography>
            </div>
          )}
          <div className={cn("flex justify-between mb-4 items-center w-full")}>
            <p className={cn("text-2xl text-nowrap content-center")}>
              {trimString(comparison?.name, 45)}
            </p>
            <div className={cn("flex gap-4")}>
              <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                <ActionButton
                  actionType="delete"
                  disabled={loading || errored}
                  onClick={() => onDeleteClick(comparison?.uuid)}
                >
                  Delete
                </ActionButton>
                <ActionButton
                  actionType="edit"
                  disabled={loading || errored}
                  onClick={onEditClick}
                >
                  Edit
                </ActionButton>
              </Protect>
            </div>
          </div>
          {children}
        </div>
      </ViewWrapper>
    </TopLevelLayout>
  );
}
