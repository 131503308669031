import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTableV2 from "../../../../../components/report-table-v2";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import { cn, getDealTimeSeriesReportUrl } from "../../../../../utils/helpers";
import { useAxios } from "../../../../../components/axios-provider";
import { useAPI } from "../../../../../utils/hooks";
import {
  ReportTerm,
  ReportType,
  IDealReport,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealTimeSeriesReport: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType?: OutputReportPeriodicityType,
  ) => Promise<IDealReport>;
  downloadDealTimeSeriesReport: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    periodicityType?: OutputReportPeriodicityType,
  ) => Promise<void>;
}

export default function DealOutputDebtCoverageView({
  getDealTimeSeriesReport,
  downloadDealTimeSeriesReport,
}: IProps): JSX.Element {
  const { caseDealUuid, dealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const dispatch = useDispatch();

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IDealReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (dealIdToUse) {
      getDealTimeSeriesReportCallAPI(dealIdToUse).then((response) => {
        if (response) {
          setReport(response);
        }
      });
    }
  }, [dealIdToUse]);

  const {
    callAPI: getDealTimeSeriesReportCallAPI,
    errored: getDealTimeSeriesReportFailed,
    loading: loadingDealTimeSeriesReport,
    errorObj: getDealTimeSeriesReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealUuid: string) =>
      getDealTimeSeriesReport(
        dealUuid,
        "debt",
        "termdebtcoverage",
        "default",
        "MO",
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingDealTimeSeriesReport &&
      dispatch(updateDealOutputLoadedAction(!getDealTimeSeriesReportFailed));
  }, [getDealTimeSeriesReportFailed, loadingDealTimeSeriesReport]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadDealTimeSeriesReport(
        dealIdToUse,
        "debt",
        "termdebtcoverage",
        "default",
        "MO",
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  return (
    <ViewWrapper
      loading={loadingDealTimeSeriesReport}
      error={getDealTimeSeriesReportFailed}
      errorHeading={getDealTimeSeriesReportErrorObj?.heading}
      errorDescription={getDealTimeSeriesReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealTimeSeriesReportUrl(
            String(dealIdToUse),
            "debt",
            "termdebtcoverage",
            "default",
          )
        ]
      }
    >
      <Box>
        <Box className={cn("flex justify-end gap-2 my-4")}>
          <ToggleSizingOutputButton />
          <IconButton
            title="Download Report"
            onClick={handleDownloadReport}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        </Box>

        {report?.data ? (
          <Box className={cn("mt-4")}>
            <ReportTableV2
              data={report?.data}
              groupExpandedDepth={-1}
              autoHeight={true}
            />
          </Box>
        ) : null}
      </Box>
    </ViewWrapper>
  );
}
