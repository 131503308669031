import posthog from "posthog-js";

export function initializePostHog() {
  const posthogTokenKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
  const postHogHost = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

  if (posthogTokenKey && postHogHost) {
    posthog.init(posthogTokenKey, {
      api_host: postHogHost,
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    });
  }
}

export function resetPostHog() {
  posthog.reset();
}

export function identifyUser(distinct_id: string, email: string, name: string) {
  posthog.identify(distinct_id, {
    email: email,
    name: name,
  });
}

export function identifyOrganization(distinct_id: string, name: string) {
  posthog.group("company", distinct_id, {
    name,
  });
}
