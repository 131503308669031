import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealSizingOtherEbitdaView from "../../../../views/deal/sizing/other/ebitda";
import TabbedLayout from "../../../../components/tabbed-layout";
import {
  downloadDealAllocationProforma,
  getDealSeparateAllocation,
  getDealAllocationProfroma,
  updateDealSeparateAllocation,
} from "../../../../apis/deal/other";
import DealSizingOtherPageLayout from "../../../../components/deal-sizing-other-page-layout";

export default function SizingOtherEbitdaPage() {
  return (
    <DealPagesLayout>
      <DealSizingOtherPageLayout>
        <DealSizingOtherEbitdaView
          getDealSeparateAllocation={getDealSeparateAllocation}
          updateDealSeparateAllocation={updateDealSeparateAllocation}
          getDealAllocationProfroma={getDealAllocationProfroma}
          downloadDealAllocationProforma={downloadDealAllocationProforma}
        />
      </DealSizingOtherPageLayout>
    </DealPagesLayout>
  );
}
