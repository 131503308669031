import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { SelectChangeEvent } from "@mui/material/Select";
import { format, isValid } from "date-fns";

import useStyles from "./styles";
import SelectInput from "../select-input";
import Button from "../button";
import TextInput from "../text-input";
import DatePicker from "../date-picker";
import CurrencyInput from "../currency-input";
import AutocompleteField from "../autocomplete-field";
import MultiAutocompleteField from "../multi-autocomplete-field";
import {
  US_STATES_OPTIONS,
  DEAL_TE_BUYOUT_TYPES_OPTIONS,
  DEAL_DEPRECIATION_ELECTION_OPTIONS,
  PROJECT_GROSS_PRODUCTION_METHOD_OPTIONS,
  CO_ZE_TERM_DEBT_TYPE_OPTIONS,
  DEFAULT_CO_ZE_FORM_VAULES,
  CO_ZE_PROJECT_TYPE_OPTIONS,
  CO_ZE_TAX_EQUITY_TYPE_OPTIONS,
  CO_ZE_TAX_CREDIT_BONUS_ADDRES,
  CO_ZE_DEAL_STRUCTURE_TYPE_OPTIONS,
  CO_ZE_DEAL_PREF_RETURN_TYPE_OPTIONS,
  CO_ZE_PROJECT_TAX_CREDIT_TYPE_OPTIONS,
  CO_ZE_TAX_CREDIT_BONUS_ADDRES_OPTIONS,
  CO_ZE_DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS,
  CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS_OPTIONS,
  CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY_OPTIONS,
  PROJECT_PRODUCTION_METHOD_INPUT_UNIT_MAP,
} from "../../constants";
import {
  ICoZe,
  ICoZeForm,
  SetStateAction,
  ICoZeFormErrors,
  ISelectOption,
} from "../../interfaces";

interface IProps {
  type: "add" | "edit";
  loading: boolean;
  form: ICoZeForm;
  setForm: SetStateAction<ICoZeForm>;
  formErrors: ICoZeFormErrors | undefined;
  setFormErrors: SetStateAction<ICoZeFormErrors | undefined>;
  onConfirm: (form: ICoZeForm) => Promise<ICoZe | undefined>;
  onCancelClick: () => void;
}

export default function CoZeForm({
  type,
  loading,
  form,
  setForm,
  formErrors,
  setFormErrors,
  onConfirm,
  onCancelClick,
}: IProps): JSX.Element {
  const styles = useStyles();

  const grossProductionType = React.useMemo(() => {
    if (!form?.production_method) return undefined;
    return PROJECT_PRODUCTION_METHOD_INPUT_UNIT_MAP[form.production_method];
  }, [form.production_method]);

  const [expandedSections, setExpandedSections] = React.useState<string[]>([]);

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => {
      if (e.target.name === "capacity_ac") {
        prevState.capacity_dc = Number(e.target.value) * 1.3;
      }
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleMultiSelect = (
    e: React.SyntheticEvent<Element, Event>,
    value: ISelectOption[],
    name: string,
  ) => {
    const a = (value || []).map((o: ISelectOption) => o.value) as Array<
      keyof typeof CO_ZE_TAX_CREDIT_BONUS_ADDRES
    >;
    setForm((prev) => ({
      ...prev,
      tax_credit_bonus_adders: a,
    }));
  };

  const onConfirmClick = async () => {
    const updatedForm = form;
    if (
      CO_ZE_PROJECT_TYPE_OPTIONS.Solar.map((type) => type.value).includes(
        form.project_energy_type,
      ) === false
    ) {
      updatedForm.degradation_rate_percentage = undefined;
    }

    // Instead of empty strings set to undefined
    if (form.deal_structure === "EO") {
      updatedForm.tax_equity_target_flip_irr = undefined;
      updatedForm.tax_equity_target_flip_term = undefined;
      updatedForm.tax_equity_pre_flip_cash_split = undefined;
      updatedForm.tax_equity_tax_allocation_method = undefined;
      updatedForm.tax_equity_pre_flip_tax_split_percent = undefined;
      updatedForm.tax_equity_preferred_return_type = undefined;
      updatedForm.tax_equity_preferred_return_percent = undefined;
      updatedForm.tax_equity_type = undefined;
    }

    if (form.term_debt_type === "NONE") {
      updatedForm.debt_term = undefined;
      updatedForm.debt_interest_rate = undefined;
      updatedForm.debt_coverage_ratio = undefined;
    }

    // backend doesn't like nulls being sent for these fields
    if (
      form.deal_tax_credit_structure !== "TR" &&
      form.deal_tax_credit_structure !== "DP"
    ) {
      updatedForm.tax_credit_transfer_payment_rate = undefined;
      updatedForm.tax_credit_transfer_lag_period = undefined;
      updatedForm.tax_credit_transfer_portion = undefined;
      updatedForm.tax_credit_payment_periodicity = undefined;
      updatedForm.tax_credit_transfer_discount_rate = undefined;
    }

    // if its not a PTC project then set tax_credit_payment_periodicity to be undefined;
    if (form.tax_credit_type !== "PTC") {
      updatedForm.tax_credit_payment_periodicity = undefined;
    }

    const response = await onConfirm(updatedForm);

    if (response) {
      setForm(DEFAULT_CO_ZE_FORM_VAULES);
    }
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const toggleSection = (section: string) => {
    setExpandedSections((prev) => {
      return prev.includes(section)
        ? prev.filter((s) => s !== section)
        : [...prev, section];
    });
  };

  const renderToggleButtons = (section: string) => {
    return (
      <IconButton onClick={() => toggleSection(section)}>
        {expandedSections.includes(section) ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </IconButton>
    );
  };

  React.useEffect(() => {
    const errorFields = Object.keys(formErrors || {}).filter(
      (key) => formErrors && formErrors[key as keyof ICoZeFormErrors] !== "",
    );
    const errorSections = errorFields.map((field) => {
      switch (field) {
        case "project_state":
          return "project";
        case "capacity_dc":
        case "production_method":
        case "production_input":
        case "degradation_rate_percentage":
        case "availability_factor_percentage":
        case "curtailment_percentage":
          return "production";
        case "operating_life_years":
          return "cod";
        case "fmv_step_up_percentage":
          return "cost";
        case "ppa_term_years":
        case "ppa_escalator":
        case "merchant_price":
        case "merchant_escalator":
          return "ppa";
        case "opex_escalator":
          return "opex";
        case "tax_credit_bonus_adders":
          return "tax-credit-type";
        case "tax_equity_type":
        case "tax_equity_target_flip_irr":
        case "tax_equity_target_flip_term":
        case "tax_equity_pre_flip_cash_split":
        case "tax_equity_tax_allocation_method":
        case "tax_equity_Target_ROI":
        case "tax_equity_preferred_return_type":
        case "tax_equity_preferred_return_percent":
        case "sponsor_return_of_capital":
        case "tax_equity_pre_flip_tax_split_percent":
          return "tax-equity";
        case "tax_credit_transfer_payment_rate":
        case "tax_credit_transfer_lag_period":
        case "tax_equity_direct_pay_lag":
        case "tax_credit_transfer_portion":
        case "tax_credit_payment_periodicity":
          return "tax-credit-structure";
        case "debt_term":
        case "debt_interest_rate":
        case "debt_coverage_ratio":
          return "debt";
        default:
          return "";
      }
    });
    setExpandedSections((sections) => [...sections, ...errorSections]);
  }, [formErrors]);

  React.useEffect(() => {
    if (form.deal_tax_credit_structure === "DP") {
      form.tax_credit_transfer_portion = 100;
      form.tax_credit_transfer_payment_rate = 1;
    }
  }, [form.deal_tax_credit_structure]);

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <Box className={styles.classes.container}>
      <Box className={styles.classes.flexDisplay}>
        <AutocompleteField
          label="Project Type"
          name="project_energy_type"
          onChange={handleSingleAutoCompleteChange}
          options={CO_ZE_PROJECT_TYPE_OPTIONS}
          value={String(form.project_energy_type)}
          helperText={formErrors?.project_energy_type}
          onFocus={() => clearSelectErrorOnFocus("project_energy_type")}
          disabled={loading}
        />
        {renderToggleButtons("project")}
      </Box>
      {expandedSections.includes("project") && (
        <Box className={styles.classes.shortWidthField}>
          <AutocompleteField
            label="Project State"
            name="project_state"
            onChange={handleSingleAutoCompleteChange}
            options={US_STATES_OPTIONS}
            value={String(form.project_state)}
            helperText={formErrors?.project_state}
            onFocus={() => clearSelectErrorOnFocus("project_state")}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <TextInput
          required
          isNumeric
          label="Capacity AC"
          name="capacity_ac"
          endAdornment={<>MW</>}
          value={form.capacity_ac}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.capacity_ac)}
          helperText={formErrors?.capacity_ac}
          disabled={loading}
        />
        {renderToggleButtons("production")}
      </Box>
      {expandedSections.includes("production") && (
        <Box className={styles.classes.shortWidthField}>
          <TextInput
            required
            isNumeric
            label="Capacity DC"
            name="capacity_dc"
            endAdornment={<>MW</>}
            value={form.capacity_dc}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.capacity_dc)}
            helperText={formErrors?.capacity_dc}
            disabled={loading}
            fullWidth={false}
          />
          <SelectInput
            required
            label="Production Method"
            selected={form.production_method}
            items={PROJECT_GROSS_PRODUCTION_METHOD_OPTIONS.filter(
              (option) => option.value !== "HOURLY_8760",
            )}
            onFocus={() => clearNonTextFieldErrorOnFocus("production_method")}
            onChange={(e) => onSelectInputChange(e, "production_method")}
            error={Boolean(formErrors?.production_method)}
            helperText={formErrors?.production_method}
            disabled={loading}
            tooltip="<b>NCF</b> = Net Capacity Factor \n<b>AEP</b> = Annual Energy Production \n<b>Yield</b> = Specific yield (kWh/kWp)"
            fullWidth={false}
          />
          <TextInput
            required
            isNumeric
            label="Production Input"
            name="production_input"
            value={form.production_input}
            endAdornment={<>{grossProductionType}</>}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.production_input)}
            helperText={formErrors?.production_input}
            disabled={loading}
            fullWidth={false}
          />
          {CO_ZE_PROJECT_TYPE_OPTIONS.Solar.map((type) => type.value).includes(
            form.project_energy_type,
          ) && (
            <TextInput
              required
              isNumeric
              label="Degradation Rate"
              name="degradation_rate_percentage"
              endAdornment={<>%</>}
              value={form.degradation_rate_percentage}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.degradation_rate_percentage)}
              helperText={formErrors?.degradation_rate_percentage}
              disabled={loading}
              fullWidth={false}
            />
          )}
          <TextInput
            required
            isNumeric
            label="Availability Factor"
            name="availability_factor_percentage"
            endAdornment={<>%</>}
            value={form.availability_factor_percentage}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.availability_factor_percentage)}
            helperText={formErrors?.availability_factor_percentage}
            disabled={loading}
            fullWidth={false}
          />
          <TextInput
            required
            isNumeric
            label="Curtailment Loss Percentage"
            name="curtailment_percentage"
            endAdornment={<>%</>}
            value={form.curtailment_percentage}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.curtailment_percentage)}
            helperText={formErrors?.curtailment_percentage}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <DatePicker
          label="COD"
          value={form.cod ? new Date(form.cod) : null}
          onChange={(v) => handleDateChange(v, "cod")}
          onOpen={() => clearNonTextFieldErrorOnFocus("cod")}
          error={Boolean(formErrors?.cod)}
          helperText={formErrors?.cod}
          disabled={loading}
        />
        {renderToggleButtons("cod")}
      </Box>
      {expandedSections.includes("cod") && (
        <Box className={styles.classes.shortWidthField}>
          <TextInput
            required
            isNumeric
            label="Project Operating Life"
            name="operating_life_years"
            endAdornment={<>Yrs</>}
            value={form.operating_life_years}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.operating_life_years)}
            helperText={formErrors?.operating_life_years}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <CurrencyInput
          required
          label="Project Cost"
          name="cost"
          value={form.cost.toString() || ""}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.cost)}
          helperText={formErrors?.cost}
          disabled={loading}
          tooltip="Total cost to develop, construct, and finance the project. Includes paid aquisition fees. Excludes FMV Step Up."
          fullWidth={false}
        />
        {renderToggleButtons("cost")}
      </Box>
      {expandedSections.includes("cost") && (
        <Box className={styles.classes.shortWidthField}>
          <TextInput
            required
            isNumeric
            label="FMV Step Up"
            name="fmv_step_up_percentage"
            endAdornment={<>%</>}
            value={form.fmv_step_up_percentage}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.fmv_step_up_percentage)}
            helperText={formErrors?.fmv_step_up_percentage}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <CurrencyInput
          required
          label="PPA price"
          name="ppa_price"
          endAdornment={<>/MWh</>}
          value={form.ppa_price.toString() || ""}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.ppa_price)}
          helperText={formErrors?.ppa_price}
          disabled={loading}
          tooltip="Offtake price, inclusive of environmental attributes"
          fullWidth={false}
        />
        {renderToggleButtons("ppa")}
      </Box>
      {expandedSections.includes("ppa") && (
        <Box className={styles.classes.shortWidthField}>
          <TextInput
            required
            isNumeric
            label="Term"
            name="ppa_term_years"
            endAdornment={<>Yrs</>}
            value={form.ppa_term_years}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.ppa_term_years)}
            helperText={formErrors?.ppa_term_years}
            disabled={loading}
            fullWidth={false}
          />
          <TextInput
            required
            isNumeric
            label="Escalator"
            name="ppa_escalator"
            endAdornment={<>%</>}
            value={form.ppa_escalator}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.ppa_escalator)}
            helperText={formErrors?.ppa_escalator}
            disabled={loading}
            fullWidth={false}
          />
          <CurrencyInput
            required
            label="Merchant Price"
            name="merchant_price"
            endAdornment={<>/MWh</>}
            value={form.merchant_price.toString() || ""}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.merchant_price)}
            helperText={formErrors?.merchant_price}
            disabled={loading}
            fullWidth={false}
          />
          <TextInput
            required
            isNumeric
            label="Escalator"
            name="merchant_escalator"
            endAdornment={<>%</>}
            value={form.merchant_escalator}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.merchant_escalator)}
            helperText={formErrors?.merchant_escalator}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <CurrencyInput
          required
          label="Operating Expense"
          name="opex"
          endAdornment={<>/kW/Yr</>}
          value={form.opex.toString() || ""}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.opex)}
          helperText={formErrors?.opex}
          disabled={loading}
          tooltip="Total operating expenses, including operations & maintenance, insurance, site lease, property taxes, etc."
          fullWidth={false}
        />
        {renderToggleButtons("opex")}
      </Box>
      {expandedSections.includes("opex") && (
        <Box className={styles.classes.shortWidthField}>
          <TextInput
            required
            isNumeric
            label="Escalator"
            name="opex_escalator"
            endAdornment={<>%</>}
            value={form.opex_escalator}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.opex_escalator)}
            helperText={formErrors?.opex_escalator}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}
      {expandedSections.includes("opex") && (
        <Box className={styles.classes.shortWidthField}>
          <TextInput
            required
            isNumeric
            label="Rate"
            name="opex_rate"
            endAdornment={<>%</>}
            value={form.opex_rate}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.opex_rate)}
            helperText={formErrors?.opex_rate}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <SelectInput
          required
          label="Tax Credit Type"
          selected={form.tax_credit_type}
          className={styles.classes.regularWidthField}
          items={CO_ZE_PROJECT_TAX_CREDIT_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("tax_credit_type")}
          onChange={(e) => onSelectInputChange(e, "tax_credit_type")}
          error={Boolean(formErrors?.tax_credit_type)}
          helperText={formErrors?.tax_credit_type}
          disabled={loading}
          tooltip="<b>ITC</b> = Section 48 Investment Tax Credit \n<b>PTC</b> = Section 45 Production Tax Credit"
          fullWidth={false}
        />
        {renderToggleButtons("tax-credit-type")}
      </Box>
      {expandedSections.includes("tax-credit-type") &&
        form.tax_credit_type !== "NONE" && (
          <Box className={styles.classes.shortWidthField}>
            <MultiAutocompleteField
              label="Adders"
              name="tax_credit_bonus_adders"
              onChange={handleMultiSelect}
              options={CO_ZE_TAX_CREDIT_BONUS_ADDRES_OPTIONS}
              value={form.tax_credit_bonus_adders}
              helperText={formErrors?.tax_credit_bonus_adders}
              onFocus={() =>
                clearNonTextFieldErrorOnFocus("tax_credit_bonus_adders")
              }
              disabled={loading}
              fullWidth={false}
            />
          </Box>
        )}

      <Box className={styles.classes.flexDisplay}>
        <SelectInput
          required
          label="Deal Structure"
          selected={form.deal_structure}
          items={CO_ZE_DEAL_STRUCTURE_TYPE_OPTIONS}
          className={styles.classes.regularWidthField}
          onFocus={() => clearNonTextFieldErrorOnFocus("deal_structure")}
          onChange={(e) => onSelectInputChange(e, "deal_structure")}
          error={Boolean(formErrors?.deal_structure)}
          helperText={formErrors?.deal_structure}
          disabled={loading}
          tooltip="<b>Tax Equity Partnership</b> = Tax Equity (Investor) + Sponsor Equity \n<b>Cash Equity Partnership</b> = Cash Equity (Investor) + Sponsor Equity \n<b>Equity Only</b> = Sponsor Equity \n<b>Sale-Leaseback</b> = Lessor Equity and Lessee Equity"
          fullWidth={false}
        />
        {renderToggleButtons("tax-equity")}
      </Box>
      {expandedSections.includes("tax-equity") && (
        <Box className={styles.classes.shortWidthField}>
          {form.deal_structure === "TEP" && (
            <>
              <SelectInput
                required
                label="Type"
                selected={form.tax_equity_type ?? ""}
                items={CO_ZE_TAX_EQUITY_TYPE_OPTIONS}
                onFocus={() => clearNonTextFieldErrorOnFocus("tax_equity_type")}
                onChange={(e) => onSelectInputChange(e, "tax_equity_type")}
                error={Boolean(formErrors?.tax_equity_type)}
                helperText={formErrors?.tax_equity_type}
                disabled={loading}
                fullWidth={false}
              />
              {form.tax_equity_type === "YBF" && (
                <TextInput
                  required
                  isNumeric
                  label="Target Flip IRR"
                  name="tax_equity_target_flip_irr"
                  endAdornment={<>%</>}
                  value={form.tax_equity_target_flip_irr}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.tax_equity_target_flip_irr)}
                  helperText={formErrors?.tax_equity_target_flip_irr}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              {(form.tax_equity_type === "YBF" ||
                form.tax_equity_type === "FF") && (
                <TextInput
                  required
                  isNumeric
                  label="Target Flip Term"
                  name="tax_equity_target_flip_term"
                  endAdornment={<>Yrs</>}
                  value={form.tax_equity_target_flip_term}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.tax_equity_target_flip_term)}
                  helperText={formErrors?.tax_equity_target_flip_term}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              {(form.tax_equity_type === "YBF" ||
                form.tax_equity_type === "FF") && (
                <TextInput
                  required
                  isNumeric
                  label="Pre-Flip Cash Split"
                  name="tax_equity_pre_flip_cash_split"
                  endAdornment={<>%</>}
                  value={form.tax_equity_pre_flip_cash_split}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.tax_equity_pre_flip_cash_split)}
                  helperText={formErrors?.tax_equity_pre_flip_cash_split}
                  disabled={loading}
                  fullWidth={false}
                  tooltip="Percentage of cash after preferred return, when applicable (e.g. 20% of 90% if preferred return is 10% of cash)"
                />
              )}
              {(form.tax_equity_type === "YBF" ||
                form.tax_equity_type === "FF") && (
                <TextInput
                  required
                  isNumeric
                  label="Pre-Flip Tax Split"
                  name="tax_equity_pre_flip_tax_split_percent"
                  endAdornment={<>%</>}
                  value={form.tax_equity_pre_flip_tax_split_percent}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(
                    formErrors?.tax_equity_pre_flip_tax_split_percent,
                  )}
                  helperText={formErrors?.tax_equity_pre_flip_tax_split_percent}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              {
                <SelectInput
                  required
                  label="Tax Allocation Method"
                  name="tax_equity_tax_allocation_method"
                  selected={form.tax_equity_tax_allocation_method || ""}
                  items={
                    form.tax_equity_type !== "PR"
                      ? CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS_OPTIONS
                      : CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS_OPTIONS.filter(
                          (option) => option.value === "PRORATA",
                        )
                  }
                  onFocus={() =>
                    clearNonTextFieldErrorOnFocus(
                      "tax_equity_tax_allocation_method",
                    )
                  }
                  onChange={(e) =>
                    onSelectInputChange(e, "tax_equity_tax_allocation_method")
                  }
                  error={Boolean(formErrors?.tax_equity_tax_allocation_method)}
                  helperText={formErrors?.tax_equity_tax_allocation_method}
                  disabled={loading}
                  tooltip="<b>Regular Flip</b> = A flip allocation struture with a single pre- and post-flip split (e.g. 99/1 to 5/95) \n<b>99/67/99 Flip</b> = A flip allocation struture with three tax flips \n(1) after the first tax year \n(2) on the tax compliance period start \n(3) after the tax compliance period end \n<b>Percentage Interest</b> = a partnership structure where the cash and tax allocations are aligned"
                  fullWidth={false}
                />
              }
            </>
          )}
          {form.deal_structure === "CEP" && (
            <>
              <SelectInput
                required
                label="Type"
                selected={form.tax_equity_type ?? ""}
                items={CO_ZE_TAX_EQUITY_TYPE_OPTIONS}
                onFocus={() => clearNonTextFieldErrorOnFocus("tax_equity_type")}
                onChange={(e) => onSelectInputChange(e, "tax_equity_type")}
                error={Boolean(formErrors?.tax_equity_type)}
                helperText={formErrors?.tax_equity_type}
                disabled={loading}
                tooltip={`<b>Yield Based Flip</b> = Partnership Flip where Investor targets a specified after-tax IRR over a horizon (Flip Term) \\n<b>Fixed Flip</b> = Partnership Flip where Investor sets a fixed term for the flip horizon \\n<b>Prorata</b> = Partnership without a "flip", where the initial cash and tax allocations remain constant`}
                fullWidth={false}
              />
              {form.tax_equity_type === "YBF" && (
                <TextInput
                  required
                  isNumeric
                  label="Target Flip IRR"
                  name="tax_equity_target_flip_irr"
                  endAdornment={<>%</>}
                  value={form.tax_equity_target_flip_irr}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.tax_equity_target_flip_irr)}
                  helperText={formErrors?.tax_equity_target_flip_irr}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              {(form.tax_equity_type === "YBF" ||
                form.tax_equity_type === "FF") && (
                <TextInput
                  required
                  isNumeric
                  label="Target Flip Term"
                  name="tax_equity_target_flip_term"
                  endAdornment={<>Yrs</>}
                  value={form.tax_equity_target_flip_term}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.tax_equity_target_flip_term)}
                  helperText={formErrors?.tax_equity_target_flip_term}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              {(form.tax_equity_type === "YBF" ||
                form.tax_equity_type === "FF") && (
                <TextInput
                  required
                  isNumeric
                  label="Pre-Flip Cash Split"
                  name="tax_equity_pre_flip_cash_split"
                  endAdornment={<>%</>}
                  value={form.tax_equity_pre_flip_cash_split}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.tax_equity_pre_flip_cash_split)}
                  helperText={formErrors?.tax_equity_pre_flip_cash_split}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              {(form.tax_equity_type === "YBF" ||
                form.tax_equity_type === "FF") && (
                <TextInput
                  required
                  isNumeric
                  label="Pre-Flip Tax Split"
                  name="tax_equity_pre_flip_tax_split_percent"
                  endAdornment={<>%</>}
                  value={form.tax_equity_pre_flip_tax_split_percent}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(
                    formErrors?.tax_equity_pre_flip_tax_split_percent,
                  )}
                  helperText={formErrors?.tax_equity_pre_flip_tax_split_percent}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              {
                <SelectInput
                  required
                  label="Tax Allocation Method"
                  name="tax_equity_tax_allocation_method"
                  selected={form.tax_equity_tax_allocation_method || ""}
                  items={
                    form.tax_equity_type !== "PR"
                      ? CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS_OPTIONS
                      : CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS_OPTIONS.filter(
                          (option) => option.value === "PRORATA",
                        )
                  }
                  onFocus={() =>
                    clearNonTextFieldErrorOnFocus(
                      "tax_equity_tax_allocation_method",
                    )
                  }
                  onChange={(e) =>
                    onSelectInputChange(e, "tax_equity_tax_allocation_method")
                  }
                  error={Boolean(formErrors?.tax_equity_tax_allocation_method)}
                  helperText={formErrors?.tax_equity_tax_allocation_method}
                  disabled={loading}
                  tooltip="<b>Regular Flip</b> = A flip allocation struture with a single pre- and post-flip split (e.g. 99/1 to 5/95) \n<b>99/67/99 Flip</b> = A flip allocation struture with three tax flips \n(1) after the first tax year \n(2) on the tax compliance period start \n(3) after the tax compliance period end \n<b>Percentage Interest</b> = a partnership structure where the cash and tax allocations are aligned"
                  fullWidth={false}
                />
              }
            </>
          )}
          {/* {form.deal_structure !== "EO" && form.tax_equity_type === "FF" && (
            <TextInput
              isNumeric
              label="Target ROI"
              name="tax_equity_Target_ROI"
              endAdornment={<>%</>}
              value={form.tax_equity_target_roi}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.tax_equity_target_roi)}
              helperText={formErrors?.tax_equity_target_roi}
              disabled={loading}
              fullWidth={false}
            />
          )} */}
          <SelectInput
            required
            label="Depreciation Election"
            selected={form.macrs_election}
            items={DEAL_DEPRECIATION_ELECTION_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("macrs_election")}
            onChange={(e) => onSelectInputChange(e, "macrs_election")}
            error={Boolean(formErrors?.macrs_election)}
            helperText={formErrors?.macrs_election}
            disabled={loading}
            fullWidth={false}
          />
          {form.deal_structure !== "EO" &&
            (form.tax_equity_type === "FF" ||
              form.tax_equity_type === "YBF") && (
              <SelectInput
                required
                label="Preferred Return Type"
                name="tax_equity_preferred_return_type"
                selected={form.tax_equity_preferred_return_type || ""}
                items={CO_ZE_DEAL_PREF_RETURN_TYPE_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus(
                    "tax_equity_preferred_return_type",
                  )
                }
                onChange={(e) =>
                  onSelectInputChange(e, "tax_equity_preferred_return_type")
                }
                error={Boolean(formErrors?.tax_equity_preferred_return_type)}
                helperText={formErrors?.tax_equity_preferred_return_type}
                disabled={loading}
                tooltip="<b>% of Total Cash</b> = Investor receives a priority share of cash based on the percentage of cash available for distribution \n<b>% of Investment/Yr</b> = Investor receives a priority share of cash per year based on the percentage of its initial capital contribution."
                fullWidth={false}
              />
            )}
          {form.deal_structure !== "EO" &&
            (form.tax_equity_type === "FF" || form.tax_equity_type === "YBF") &&
            form.tax_equity_preferred_return_type &&
            ["PCTINV", "PFCASH"].includes(
              form.tax_equity_preferred_return_type,
            ) && (
              <>
                <TextInput
                  isNumeric
                  required
                  label="Preferred Return %"
                  name="tax_equity_preferred_return_percent"
                  endAdornment={<>%</>}
                  value={form.tax_equity_preferred_return_percent}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(
                    formErrors?.tax_equity_preferred_return_percent,
                  )}
                  helperText={formErrors?.tax_equity_preferred_return_percent}
                  disabled={loading}
                  fullWidth={false}
                />
                <TextInput
                  isNumeric
                  label="Sponsor Return of Capital"
                  name="sponsor_return_of_capital"
                  endAdornment={<>%</>}
                  value={form.sponsor_return_of_capital}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.sponsor_return_of_capital)}
                  helperText={formErrors?.sponsor_return_of_capital}
                  disabled={loading}
                  fullWidth={false}
                />
              </>
            )}
          <SelectInput
            required
            label="Buyout Type"
            selected={form.buyout_type || ""}
            items={DEAL_TE_BUYOUT_TYPES_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("buyout_type")}
            onChange={(e) => onSelectInputChange(e, "buyout_type")}
            error={Boolean(formErrors?.buyout_type)}
            helperText={formErrors?.buyout_type}
            disabled={loading}
            fullWidth={false}
          />
          {form.buyout_type !== "NOBUYO" && (
            <TextInput
              required
              isNumeric
              label="Discount Rate"
              name="buyout_amount_discount_rate"
              endAdornment={<>%</>}
              value={form.buyout_amount_discount_rate ?? ""}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.buyout_amount_discount_rate)}
              helperText={formErrors?.buyout_amount_discount_rate}
              disabled={loading}
              fullWidth={false}
            />
          )}
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <SelectInput
          required
          label="Tax Credit Structure"
          selected={form.deal_tax_credit_structure}
          items={CO_ZE_DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS}
          className={styles.classes.regularWidthField}
          onFocus={() =>
            clearNonTextFieldErrorOnFocus("deal_tax_credit_structure")
          }
          onChange={(e) => onSelectInputChange(e, "deal_tax_credit_structure")}
          error={Boolean(formErrors?.deal_tax_credit_structure)}
          helperText={formErrors?.deal_tax_credit_structure}
          disabled={loading}
          tooltip="<b>Regular</b> = No changes to tax credit election \n<b>Transfer</b> =  Section 6418 transferability \n<b>Direct Pay</b> =  Section 6417 elective pay"
          fullWidth={false}
        />
        {renderToggleButtons("tax-credit-structure")}
      </Box>
      {expandedSections.includes("tax-credit-structure") && (
        <Box className={styles.classes.shortWidthField}>
          {form.deal_tax_credit_structure === "TR" && (
            <>
              <TextInput
                required
                isNumeric
                label="Transfer Portion"
                name="tax_credit_transfer_portion"
                endAdornment={<>%</>}
                value={form.tax_credit_transfer_portion ?? ""}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_portion)}
                helperText={formErrors?.tax_credit_transfer_portion}
                disabled={loading}
                tooltip="Percentage of tax credit transferred to buyer"
                fullWidth={false}
              />
              <TextInput
                required
                isNumeric
                label="Discount Rate"
                name="tax_credit_transfer_discount_rate"
                endAdornment={<>%</>}
                value={form.tax_credit_transfer_discount_rate ?? ""}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_discount_rate)}
                helperText={formErrors?.tax_credit_transfer_discount_rate}
                disabled={loading}
                tooltip="Discount rate for calculating the adjusted payment rate"
                fullWidth={false}
              />
              {form.tax_credit_type === "PTC" && (
                <SelectInput
                  required
                  label="Payment Periodicity"
                  name="tax_credit_payment_periodicity"
                  selected={form.tax_credit_payment_periodicity || ""}
                  items={CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY_OPTIONS}
                  onFocus={() =>
                    clearNonTextFieldErrorOnFocus(
                      "tax_credit_payment_periodicity",
                    )
                  }
                  onChange={(e) =>
                    onSelectInputChange(e, "tax_credit_payment_periodicity")
                  }
                  error={Boolean(formErrors?.tax_credit_payment_periodicity)}
                  helperText={formErrors?.tax_credit_payment_periodicity}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
              <TextInput
                required
                isNumeric
                label="Payment Rate"
                name="tax_credit_transfer_payment_rate"
                endAdornment={<>$/Credit</>}
                value={form.tax_credit_transfer_payment_rate ?? ""}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_payment_rate)}
                helperText={formErrors?.tax_credit_transfer_payment_rate}
                disabled={loading}
                tooltip="The price paid per dollar of transferred tax credit"
                fullWidth={false}
              />
              <TextInput
                required
                isNumeric
                label="Payment Dates Lag"
                name="tax_credit_transfer_lag_period"
                endAdornment={<>Months</>}
                value={form.tax_credit_transfer_lag_period}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_lag_period)}
                helperText={formErrors?.tax_credit_transfer_lag_period}
                disabled={loading}
                tooltip="The number of months after placed in service that cash is received for the tax credit"
                fullWidth={false}
              />
            </>
          )}
          {form.deal_tax_credit_structure === "DP" && (
            <>
              <TextInput
                required
                isNumeric
                label="Transfer Portion"
                name="tax_credit_transfer_portion"
                endAdornment={<>%</>}
                value={form.tax_credit_transfer_portion ?? ""}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_portion)}
                helperText={formErrors?.tax_credit_transfer_portion}
                disabled={true}
                fullWidth={false}
              />
              <TextInput
                required
                isNumeric
                label="Discount Rate"
                name="tax_credit_transfer_discount_rate"
                endAdornment={<>%</>}
                value={form.tax_credit_transfer_discount_rate ?? ""}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_discount_rate)}
                helperText={formErrors?.tax_credit_transfer_discount_rate}
                disabled={loading}
                tooltip="Discount rate for calculating the adjusted payment rate"
                fullWidth={false}
              />
              {form.tax_credit_type === "PTC" && (
                <SelectInput
                  required
                  label="Payment Periodicity"
                  name="tax_credit_payment_periodicity"
                  selected="AN"
                  items={CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY_OPTIONS}
                  onFocus={() =>
                    clearNonTextFieldErrorOnFocus(
                      "tax_credit_payment_periodicity",
                    )
                  }
                  onChange={(e) =>
                    onSelectInputChange(e, "tax_credit_payment_periodicity")
                  }
                  error={Boolean(formErrors?.tax_credit_payment_periodicity)}
                  helperText={formErrors?.tax_credit_payment_periodicity}
                  disabled={true}
                  fullWidth={false}
                />
              )}
              <TextInput
                required
                isNumeric
                label="Payment Rate"
                name="tax_credit_transfer_payment_rate"
                endAdornment={<>$/Credit</>}
                value={form.tax_credit_transfer_payment_rate ?? ""}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_payment_rate)}
                helperText={formErrors?.tax_credit_transfer_payment_rate}
                disabled={true}
                fullWidth={false}
              />
              <TextInput
                required
                isNumeric
                label="Payment Dates Lag"
                name="tax_credit_transfer_lag_period"
                endAdornment={<>Months</>}
                value={form.tax_credit_transfer_lag_period}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.tax_credit_transfer_lag_period)}
                helperText={formErrors?.tax_credit_transfer_lag_period}
                disabled={loading}
                fullWidth={false}
                tooltip="The number of months after placed in service that cash is received for the tax credit"
              />
            </>
          )}
        </Box>
      )}

      <Box className={styles.classes.flexDisplay}>
        <SelectInput
          required
          label="Term Debt Type"
          selected={form.term_debt_type}
          items={CO_ZE_TERM_DEBT_TYPE_OPTIONS}
          className={styles.classes.regularWidthField}
          onFocus={() => clearNonTextFieldErrorOnFocus("term_debt_type")}
          onChange={(e) => onSelectInputChange(e, "term_debt_type")}
          error={Boolean(formErrors?.term_debt_type)}
          helperText={formErrors?.term_debt_type}
          disabled={loading}
          tooltip="<b>Back-Leverage</b> = Sponsor Debt \n<b>Front-Leverage</b> = Project Debt"
          fullWidth={false}
        />
        {renderToggleButtons("debt")}
      </Box>
      {expandedSections.includes("debt") && form.term_debt_type !== "NONE" && (
        <Box className={styles.classes.shortWidthField}>
          <TextInput
            required
            isNumeric
            label="Amortization Tenor"
            name="debt_term"
            endAdornment={<>Yrs</>}
            value={form.debt_term}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.debt_term)}
            helperText={formErrors?.debt_term}
            disabled={loading}
            fullWidth={false}
          />
          <TextInput
            required
            isNumeric
            label="Interest Rate"
            name="debt_interest_rate"
            endAdornment={<>%</>}
            value={form.debt_interest_rate}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.debt_interest_rate)}
            helperText={formErrors?.debt_interest_rate}
            disabled={loading}
            fullWidth={false}
          />
          <TextInput
            required
            isNumeric
            label="Coverage Ratio"
            name="debt_coverage_ratio"
            endAdornment={<>x</>}
            value={form.debt_coverage_ratio}
            onFocus={clearErrorOnFocus}
            onChange={onTextChange}
            error={Boolean(formErrors?.debt_coverage_ratio)}
            helperText={formErrors?.debt_coverage_ratio}
            disabled={loading}
            fullWidth={false}
          />
        </Box>
      )}

      <Box className={styles.classes.buttonContainer}>
        {type !== "add" && (
          <Button btnType="secondary" label="Cancel" onClick={onCancelClick} />
        )}
        <Button
          btnType="primary"
          label="Confirm"
          onClick={onConfirmClick}
          disabled={loading}
        />
      </Box>
    </Box>
  );
}
