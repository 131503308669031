import { PROJECT_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IReportTable,
  IProjectValuation,
  IProjectValuationForm,
  IsummaryTablesObject,
} from "../../interfaces";

export const getProjectValuationSummary = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.summary, {
    projectUuid,
  });
  return await AxiosHelper.get<IsummaryTablesObject>(url);
};

export const downloadProjectValuationSummary = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.summary, {
    projectUuid,
  });
  return await AxiosHelper.downloadFile(`${url}?output=xlsx`);
};

export const getProjectValuation = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.get, {
    projectUuid,
  });
  return await AxiosHelper.get<IProjectValuation[]>(url);
};

export const getProjectValuationCalculations = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.getCalcuations, {
    projectUuid,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadProjectValuationCalculations_Report = async (
  projectUuid: string,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.valuation.downloadCalculations_Report,
    {
      projectUuid,
    },
  );
  return await AxiosHelper.downloadFile(url);
};

export const addProjectValuation = async (
  projectUuid: string,
  form: IProjectValuationForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.create, {
    projectUuid,
  });
  return await AxiosHelper.post<IProjectValuationForm, IProjectValuation>(
    url,
    form,
  );
};

export const editProjectValuation = async (
  projectUuid: string,
  valuationId: number,
  form: IProjectValuationForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.valuation.update, {
    projectUuid,
    valuationId,
  });
  return await AxiosHelper.put<IProjectValuationForm, IProjectValuation>(
    url,
    form,
  );
};
