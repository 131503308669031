import React from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { format, isValid } from "date-fns";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import useStyles from "./styles";
import SelectInput from "../select-input";
import DatePicker from "../date-picker";
import TextInput from "../text-input";
import SchedulerInput from "../scheduler-input";
import FormColumnLayout from "../form-column-layout";
import { cn } from "../../utils/helpers";
import { useAppSelector } from "../../utils/hooks";
import {
  SetStateAction,
  IProjectExpenseFormErrors,
  IProjectExpenseForm,
  IProjectExpense,
} from "../../interfaces";
import {
  PROJECT_ESCALATOR_BASE_YEAR_OPTIONS,
  PROJECT_EXPENSE_INPUT_TYPE_OPTIONS,
  PROJECT_EXPENSE_TYPE_OPTIONS,
  START_INPUT_METHOD_TYPE_OPTIONS,
  PROJECT_EXPENSE_UNIT_TYPE_END_ADORNMENT,
  PROJECT_EXPENSE_UNIT_TYPE_OPTIONS,
  TERM_END_POINT_TYPES_OPTIONS,
  TERM_INPUT_METHOD_TYPE_OPTIONS,
  TERM_START_POINT_TYPES_OPTIONS,
  PROJECT_EXPENSE_FORM_DEFAULT_STATE,
  PROJECT_EXPENSE_UNIT_TYPE,
  ESCALATION_METHOD_TYPE_OPTIONS,
  PROJECT_EXPENSE_MONTHLY_ALLOCATION_TYPE_OPTIONS,
} from "../../constants";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  dateSchedule: string[];
  formErrors?: IProjectExpenseFormErrors;
  setFormErrors: SetStateAction<IProjectExpenseFormErrors | undefined>;
  form: IProjectExpenseForm;
  setForm: SetStateAction<IProjectExpenseForm>;
  onClose: () => void;
  onConfirm: (
    form: IProjectExpenseForm,
  ) => Promise<IProjectExpense | undefined>;
}

export default function ProjectExpensesFormModal({
  open,
  headerLabel,
  loading,
  dateSchedule,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { currentProject } = useAppSelector((s) => s.project);

  const getProjectInputTypeItems = (projectEnergyType?: string) => {
    let items = [...PROJECT_EXPENSE_UNIT_TYPE_OPTIONS];
    if (projectEnergyType === "BESS") {
      items = items.filter((option) => option.value !== "DOLPERMWYR");
    }
    if (form.type === "CONT") {
      items = items.filter((option) => option.value === "PERCENTOPEXP");
    } else {
      items = items.filter((option) => option.value !== "PERCENTOPEXP");
    }
    if (form.type !== "LAND") {
      items = items.filter((option) => option.value !== "DOLPERACRE");
    }
    return items;
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleScheduleChange = (value: (number | string | null)[]) => {
    setForm((prevState) => ({
      ...prevState,
      expense_strip: value,
    }));
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field === "expense_unit_type") {
      // Some unit types don't need escalator and base year, e.g. % of Revenue
      if (
        ["PERCENTREV"].includes(
          e.target.value as keyof typeof PROJECT_EXPENSE_UNIT_TYPE,
        )
      ) {
        form.escalator = null;
        form.base_year = null;
      }
    }

    setForm((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const updatedForm = { ...form };
    if (updatedForm.expense_input_type === "STRIP") {
      updatedForm.expense_input_type = "STRIP";
      updatedForm.base_year = null;
      updatedForm.escalator = null;
      updatedForm.expense = null;
      updatedForm.start_date = null;
      updatedForm.term = null;
      updatedForm.rate = null;
    } else {
      updatedForm.expense_strip = [];
    }

    if (form.expense_unit_type === "PERCENTREV") {
      updatedForm.monthly_allocation_type = "TH";
    }
    const expense = await onConfirm(updatedForm);
    expense && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(PROJECT_EXPENSE_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  const isSecondColumnVisible = form.expense_input_type !== "STRIP";

  return (
    <Modal
      open={open}
      maxWidth="xs"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{ paper: cn("!max-w-full") }}
    >
      <FormColumnLayout>
        <Box>
          <SelectInput
            required
            label="Type"
            selected={form.type}
            items={PROJECT_EXPENSE_TYPE_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("type")}
            onChange={(e) => handleSelectInputChange(e, "type")}
            error={Boolean(formErrors?.type)}
            helperText={formErrors?.type}
            disabled={loading}
            fullWidth={false}
          />

          <TextInput
            required
            type="text"
            label="Name"
            name="name"
            value={form.name}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
            fullWidth={false}
          />

          <SelectInput
            required
            label="Input Type"
            selected={form.expense_input_type}
            items={PROJECT_EXPENSE_INPUT_TYPE_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("expense_input_type")}
            onChange={(e) => handleSelectInputChange(e, "expense_input_type")}
            error={Boolean(formErrors?.expense_input_type)}
            helperText={formErrors?.expense_input_type}
            disabled={loading}
            fullWidth={false}
          />
          {form.expense_input_type !== "STRIP" &&
            form.expense_input_type !== "" && (
              <>
                <SelectInput
                  required
                  label="Unit Type"
                  selected={form.expense_unit_type || ""}
                  items={getProjectInputTypeItems(currentProject?.energy_type)}
                  onFocus={() =>
                    clearNonTextFieldErrorOnFocus("expense_unit_type")
                  }
                  onChange={(e) =>
                    handleSelectInputChange(e, "expense_unit_type")
                  }
                  error={Boolean(formErrors?.expense_unit_type)}
                  helperText={formErrors?.expense_unit_type}
                  disabled={loading}
                  fullWidth={false}
                />
                {form.expense_unit_type === "DOLPERACRE" && (
                  <TextInput
                    required
                    isNumeric
                    label="Acres"
                    name="acres"
                    value={form.acres}
                    onFocus={clearErrorOnFocus}
                    onChange={handleTextChange}
                    error={Boolean(formErrors?.acres)}
                    helperText={formErrors?.acres}
                    disabled={loading}
                    fullWidth={false}
                  />
                )}
                <TextInput
                  required
                  isNumeric
                  label="Input"
                  name="expense"
                  endAdornment={
                    <>
                      {
                        PROJECT_EXPENSE_UNIT_TYPE_END_ADORNMENT[
                          form.expense_unit_type as keyof typeof PROJECT_EXPENSE_UNIT_TYPE_END_ADORNMENT
                        ]
                      }
                    </>
                  }
                  value={form.expense}
                  onFocus={clearErrorOnFocus}
                  onChange={handleTextChange}
                  error={Boolean(formErrors?.expense)}
                  helperText={formErrors?.expense}
                  disabled={loading}
                  fullWidth={false}
                />
                <TextInput
                  required
                  isNumeric
                  label="Rate"
                  name="rate"
                  endAdornment={<>%</>}
                  value={form.rate}
                  onFocus={clearErrorOnFocus}
                  onChange={handleTextChange}
                  error={Boolean(formErrors?.rate)}
                  helperText={formErrors?.rate}
                  disabled={loading}
                  tooltip="Provides the ability to assume an interest rate (e.g. 2.00% when selecting $/Year Unit Type) or a multiplier/increase on an expense (e.g. 110%)."
                  fullWidth={false}
                />

                {/* Some unit types don't need escalator and base year, e.g. % of Revenue */}
                {!["PERCENTREV", "PERCENTOPEXP"].includes(
                  form.expense_unit_type ?? "",
                ) && (
                  <>
                    <TextInput
                      required
                      isNumeric
                      label="Escalator"
                      name="escalator"
                      endAdornment={<>%</>}
                      value={form.escalator}
                      onFocus={clearErrorOnFocus}
                      onChange={handleTextChange}
                      error={Boolean(formErrors?.escalator)}
                      helperText={formErrors?.escalator}
                      disabled={loading}
                      fullWidth={false}
                    />

                    <SelectInput
                      required
                      label="Escalation Method"
                      selected={String(form.escalation_method)}
                      items={ESCALATION_METHOD_TYPE_OPTIONS}
                      onFocus={() =>
                        clearNonTextFieldErrorOnFocus("escalation_method")
                      }
                      onChange={(e) =>
                        handleSelectInputChange(e, "escalation_method")
                      }
                      error={Boolean(formErrors?.escalation_method)}
                      helperText={formErrors?.escalation_method}
                      disabled={loading}
                      fullWidth={false}
                    />
                    <SelectInput
                      required
                      label="Base Year"
                      selected={String(form.base_year)}
                      items={PROJECT_ESCALATOR_BASE_YEAR_OPTIONS}
                      onFocus={() => clearNonTextFieldErrorOnFocus("base_year")}
                      onChange={(e) => handleSelectInputChange(e, "base_year")}
                      error={Boolean(formErrors?.base_year)}
                      helperText={formErrors?.base_year}
                      disabled={loading}
                      fullWidth={false}
                    />
                  </>
                )}
              </>
            )}
          {form.expense_input_type === "STRIP" && (
            <SchedulerInput
              label="Expense Schedule"
              name="expense_strip"
              dateSchedule={dateSchedule || []}
              value={form?.expense_strip || []}
              error={formErrors?.expense_strip || ""}
              clearErrorOnFocus={clearErrorOnFocus}
              onChange={handleScheduleChange}
              disabled={loading}
              tooltip="Enter negative numbers for expenses."
              formatValue
              startAdornment={"$"}
            />
          )}
        </Box>
        {isSecondColumnVisible && (
          <Box>
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Timing
            </Divider>

            <SelectInput
              required
              label="Start Input Method"
              selected={form.start_input_method}
              items={START_INPUT_METHOD_TYPE_OPTIONS}
              onFocus={() =>
                clearNonTextFieldErrorOnFocus("start_input_method")
              }
              onChange={(e) => handleSelectInputChange(e, "start_input_method")}
              error={Boolean(formErrors?.start_input_method)}
              helperText={formErrors?.start_input_method}
              disabled={loading}
              tooltip="Allows you to tie the start of the Expense to a particular start date (e.g. COD) instead of a fixed specific date."
              fullWidth={false}
            />

            {form.start_input_method === "YR" ? (
              <DatePicker
                label="Start Date"
                value={form.start_date ? new Date(form.start_date) : null}
                onChange={(v) => handleDateChange(v, "start_date")}
                onOpen={() => clearNonTextFieldErrorOnFocus("start_date")}
                error={Boolean(formErrors?.start_date)}
                helperText={formErrors?.start_date}
                disabled={loading}
                fullWidth={false}
              />
            ) : form.start_input_method === "SP" ? (
              <SelectInput
                required
                label="Start Point"
                selected={form.term_start_point}
                items={TERM_START_POINT_TYPES_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("term_start_point")
                }
                onChange={(e) => handleSelectInputChange(e, "term_start_point")}
                error={Boolean(formErrors?.term_start_point)}
                helperText={formErrors?.term_start_point}
                disabled={loading}
                fullWidth={false}
              />
            ) : null}

            <SelectInput
              required
              label="Term Input Method"
              selected={form.term_input_method}
              items={TERM_INPUT_METHOD_TYPE_OPTIONS}
              onFocus={() => clearNonTextFieldErrorOnFocus("term_input_method")}
              onChange={(e) => handleSelectInputChange(e, "term_input_method")}
              error={Boolean(formErrors?.term_input_method)}
              helperText={formErrors?.term_input_method}
              disabled={loading}
              tooltip="Allows you to tie the end of the Expense to a particular end date (e.g. Project End of Life Date) instead of a fixed specific date."
              fullWidth={false}
            />

            {form.term_input_method === "YR" ? (
              <TextInput
                required
                isNumeric
                label="Term"
                name="term"
                endAdornment={<>Yrs</>}
                value={form.term}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.term)}
                helperText={formErrors?.term}
                disabled={loading}
                fullWidth={false}
              />
            ) : form.term_input_method === "EP" ? (
              <SelectInput
                required
                label="Term"
                selected={form.term_end_point}
                items={TERM_END_POINT_TYPES_OPTIONS}
                onFocus={() => clearNonTextFieldErrorOnFocus("term_end_point")}
                onChange={(e) => handleSelectInputChange(e, "term_end_point")}
                error={Boolean(formErrors?.term_end_point)}
                helperText={formErrors?.term_end_point}
                disabled={loading}
                fullWidth={false}
              />
            ) : null}
            <TextInput
              required
              isNumeric
              label="Cash Lag"
              name="cash_basis_lag"
              endAdornment={<>Months</>}
              value={form.cash_basis_lag}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.cash_basis_lag)}
              helperText={formErrors?.cash_basis_lag}
              disabled={loading}
              fullWidth={false}
            />
            {form.expense_unit_type !== "PERCENTREV" ? (
              <SelectInput
                required
                label="Monthly Allocation Type"
                tooltip="Determines how monthly amounts are allocated:\n
                        • 30 / 360: Standardized 30-day months (360-day year)\n
                        • Actual / Actual: Based on actual calendar days"
                selected={form.monthly_allocation_type}
                items={PROJECT_EXPENSE_MONTHLY_ALLOCATION_TYPE_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("monthly_allocation_type")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "monthly_allocation_type")
                }
                error={Boolean(formErrors?.monthly_allocation_type)}
                helperText={formErrors?.monthly_allocation_type}
                disabled={loading}
                fullWidth={false}
              />
            ) : null}
          </Box>
        )}
      </FormColumnLayout>
    </Modal>
  );
}
