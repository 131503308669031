import CurvesDetailView from "../../../views/configuration/data/curves-detail";
import {
  deleteCurve,
  editCurve,
  getCurve,
  getCurveGroups,
  getDateSchedule,
  getUser,
} from "../../../apis/configuration/curves";

export default function CurvesDetailPage() {
  return (
    <CurvesDetailView
      getCurveGroups={getCurveGroups}
      getDateSchedule={getDateSchedule}
      editCurve={editCurve}
      deleteCurve={deleteCurve}
      getCurve={getCurve}
      getUser={getUser}
    />
  );
}
