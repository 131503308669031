import React from "react";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ReportTableV2 from "../../../../../components/report-table-v2";
import CollapsibleWrapper from "../../../../../components/collapsible-wrapper";
import CostReportChart from "../../../../../components/charts/cost-report-chart";
import { downloadDealCostsReport } from "../../../../../apis/report/deal";
import { IReportTable } from "../../../../../interfaces";
import { useAPI } from "../../../../../utils/hooks";
import { cn } from "../../../../../utils/helpers";

interface IProps {
  getDealCostsSchedule: (dealUuid: string) => Promise<IReportTable>;
}
export default function DealCostView({
  getDealCostsSchedule,
}: IProps): JSX.Element {
  const { organization } = useOrganization();

  const { dealUuid, caseDealUuid } = useParams();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const [projectCostSchedule, setProjectCostSchedule] =
    React.useState<IReportTable>();

  React.useEffect(() => {
    getProjectCostScheduleCallAPI(dealIdToUse).then((res) => {
      res && setProjectCostSchedule(res);
    });
  }, [dealIdToUse]);

  const {
    callAPI: getProjectCostScheduleCallAPI,
    errored: getCostScheduleFailed,
    loading: loadingCostSchedule,
  } = useAPI((dealUuid: string) => getDealCostsSchedule(dealUuid), {
    initialLoading: true,
  });

  const downloadDealCostReportHandle = async () => {
    await downloadDealCostsReport(String(caseDealUuid)).catch(() => null);
  };

  return (
    <ViewWrapper loading={loadingCostSchedule} error={getCostScheduleFailed}>
      {projectCostSchedule && (
        <div className={cn("mt-4")}>
          <CollapsibleWrapper title="" collapseButtonLabel="Chart">
            <ChartWrapper className={cn("!shadow-none")}>
              <CostReportChart data={projectCostSchedule} />
            </ChartWrapper>
          </CollapsibleWrapper>
        </div>
      )}

      <Paper classes={{ root: cn("mt-4 p-4") }}>
        {projectCostSchedule && (
          <ReportTableV2
            data={projectCostSchedule}
            download={downloadDealCostReportHandle}
            title="Cost Schedule"
            toggleDatesVisibility
            styledTitle
          />
        )}
      </Paper>
    </ViewWrapper>
  );
}
