import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectFmvStepUpView from "../../../views/project/fmv/step-up";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  getProjectCostsSummary,
  updateProjectCosts,
} from "../../../apis/project/cost";
import {
  addProjectValuation,
  editProjectValuation,
  getProjectValuation,
} from "../../../apis/project/valuation";

export default function ProjectFmvStepUpPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="FMV">
        <ProjectFmvStepUpView
          getProjectValuation={getProjectValuation}
          addProjectValuation={addProjectValuation}
          editProjectValuation={editProjectValuation}
          getProjectCostSummary={getProjectCostsSummary}
          updateProjectCosts={updateProjectCosts}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
