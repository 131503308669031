import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import ScrollIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import MuiButton from "@mui/material/Button";
import {
  DataGridPro,
  useGridApiRef,
  DataGridProProps,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";

import IconButton from "../icon-button";
import CheckboxInput from "../checkbox-input";
import { IReportTable } from "../../interfaces";
import { cn, generateReportTableV2Data } from "../../utils/helpers";

interface IProps {
  data: IReportTable;
  groupExpandedDepth?: number;
  dataPw?: string;
  download?: () => Promise<void>;
  heightInPixels?: number;
  title?: string;
  styledTitle?: boolean;
  autoHeight?: boolean;
  toggleDatesVisibility?: boolean;
}

const fadeBgClasses = ["fade-bg", "fade-bg-copy"];

const ReportTableV2 = ({
  data,
  groupExpandedDepth = 0,
  dataPw,
  download,
  heightInPixels = 500,
  title = data.report_title,
  styledTitle,
  autoHeight = false,
  toggleDatesVisibility,
}: IProps): JSX.Element => {
  const dataGridRef = useGridApiRef();

  const { rows, columns } = generateReportTableV2Data(data);

  const [fadeClassUsed, setFadeClassUsed] = React.useState<boolean>(false);
  const [showDates, setShowDates] = React.useState<boolean>(true);
  const [columnToFocus, setColumnToFocus] = React.useState<string>("");

  const visibleColumns = React.useMemo(() => {
    const fadeBgClassToUse = fadeBgClasses[Number(fadeClassUsed)];
    const columnsToSlice = showDates ? undefined : 1;

    return columns.slice(0, columnsToSlice).map((col) => {
      if (col.field === columnToFocus) {
        return { ...col, headerClassName: fadeBgClassToUse };
      }
      return col;
    });
  }, [showDates, columnToFocus, fadeClassUsed]);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => {
    return row.hierarchy;
  };

  const handleScrollButtonClick = (direction: "left" | "right") => {
    const colIndex = direction === "left" ? 0 : columns.length;

    dataGridRef.current.scrollToIndexes({ rowIndex: 0, colIndex });

    const date =
      direction === "left" ? columns[0].field : columns[colIndex - 1].field;
    setFadeClassUsed((v) => !v);
    setColumnToFocus(date);
  };

  const onMileStoneClick = (date: string) => {
    setFadeClassUsed((v) => !v);
    const colIndex = columns.findIndex((col) => {
      return col.field === date;
    });

    if (colIndex > -1) {
      dataGridRef.current.scrollToIndexes({
        rowIndex: 0,
        colIndex: colIndex + 1,
      });
      setColumnToFocus(date);
    }
  };

  const totalColExist = React.useMemo(() => {
    return columns.some((col) => {
      return col.field === "Total";
    });
  }, [columns]);

  const renderCustomToolbar = () => {
    const scrollableRowWidthClassName = totalColExist
      ? `!w-[calc(100%_-_460px)]`
      : `!w-[calc(100%-320px)]`;

    return (
      <GridToolbarContainer>
        <div className={cn("flex justify-between relative w-full m-2")}>
          <div>
            {toggleDatesVisibility && (
              <Box>
                <CheckboxInput
                  label="Show Dates"
                  checked={showDates}
                  onChange={() => setShowDates((v) => !v)}
                />
              </Box>
            )}
          </div>

          {showDates ? (
            <div
              className={cn(
                `flex justify-between items-center ${scrollableRowWidthClassName}`,
              )}
            >
              <div>
                {columns.length > 15 && (
                  <IconButton onClick={() => handleScrollButtonClick("left")}>
                    <ScrollIcon />
                  </IconButton>
                )}
              </div>

              <div>
                {data.meta_info?.milestone_dates?.map((milstone, idx) => {
                  return (
                    <MuiButton
                      key={idx}
                      variant="outlined"
                      onClick={() => onMileStoneClick(milstone.date)}
                      classes={{
                        root: cn(
                          "!rounded-lg !border-secondary !text-secondary !mr-2",
                        ),
                      }}
                    >
                      {milstone.label}
                    </MuiButton>
                  );
                })}
              </div>

              <div>
                {columns.length > 15 && (
                  <IconButton onClick={() => handleScrollButtonClick("right")}>
                    <ScrollIcon classes={{ root: cn("rotate-180") }} />
                  </IconButton>
                )}
                {download && (
                  <IconButton canOpenUpgrade onClick={download}>
                    <DownloadIcon />
                  </IconButton>
                )}
              </div>
            </div>
          ) : (
            download && (
              <IconButton canOpenUpgrade onClick={download}>
                <DownloadIcon />
              </IconButton>
            )
          )}
        </div>
      </GridToolbarContainer>
    );
  };

  const calculatedHeight = React.useMemo(() => {
    // Only calculate the height of the rows that are not nested
    const totalRowsHeightInPixels =
      rows.filter((row) => row.hierarchy.length === 1).length * 42 + 109; // 42 is the row height, 109 is the (grid toolbar height + column header height)

    return totalRowsHeightInPixels > heightInPixels
      ? `${heightInPixels}px`
      : "auto";
  }, [rows, heightInPixels]);

  return (
    <Box
      style={{
        height: autoHeight ? "auto" : calculatedHeight,
        width: !showDates ? (totalColExist ? 472 : 332) : "",
      }}
    >
      {rows.length ? (
        <DataGridPro
          rows={rows}
          columns={visibleColumns}
          apiRef={dataGridRef}
          density="compact"
          getTreeDataPath={getTreeDataPath}
          pinnedColumns={{ left: ["__tree_data_group__", "Total"] }}
          classes={{
            columnHeaderTitle: cn("!font-bold text-[15px]"),
          }}
          slots={{ toolbar: renderCustomToolbar }}
          columnHeaderHeight={70}
          rowHeight={60}
          groupingColDef={{
            headerName: title,
            minWidth: 320,
            hideDescendantCount: true,
            headerClassName: styledTitle
              ? "italic text-[rgba(0,0,0,0.65)]"
              : "",
          }}
          getCellClassName={(params) => {
            return params.colDef.field === "Total" ? cn("font-bold") : "";
          }}
          isGroupExpandedByDefault={(group) => {
            return group.depth === groupExpandedDepth;
          }}
          treeData
          hideFooter
          disableColumnMenu
          disableAutosize
          disableColumnResize
          disableColumnReorder
        />
      ) : (
        <Box className={cn("h-96 content-center text-center")}>
          <Typography
            variant="body1"
            className={cn("mt-[20px] p-4 text-[#666] ")}
            data-pw={dataPw}
          >
            There is no data in this report.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(ReportTableV2);
