import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license";
import { SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import SignInPage from "./pages/sign-in";
import MyClerkProvider from "./components/clerk-provider";
import AppWrapper from "./components/app-wrapper";
import AxiosProvider from "./components/axios-provider";
import ThemeContextProvider from "./components/theme-context-provider";
import MyToastProvider from "./components/toast-provider";
import reduxStore from "./utils/redux";
import { initializeSentry } from "./utils/error-handling";
import { privateRoutes } from "./utils/router/routes";
import {
  initializePostHog,
  validateEnvironmentVariables,
} from "./utils/helpers";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

initializePostHog();
validateEnvironmentVariables();
initializeSentry();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY!);

root.render(
  <>
    <ThemeContextProvider>
      <PostHogProvider client={posthog}>
        <MyClerkProvider>
          <AxiosProvider>
            <CssBaseline />
            <SignedIn>
              <BrowserRouter>
                <Provider store={reduxStore}>
                  <AppWrapper>
                    <Routes>
                      {privateRoutes.map((route, idx) => {
                        const Component = route.element;
                        return (
                          <Route
                            key={idx}
                            path={route.path}
                            element={<Component />}
                          />
                        );
                      })}
                    </Routes>
                  </AppWrapper>
                </Provider>
              </BrowserRouter>
            </SignedIn>
            <SignedOut>
              <RedirectToSignIn redirectUrl={"/sign-in"} />
            </SignedOut>
            <BrowserRouter>
              <Routes>
                <Route path="/sign-in" element={<SignInPage />} />
              </Routes>
            </BrowserRouter>
          </AxiosProvider>
        </MyClerkProvider>
        <MyToastProvider />
      </PostHogProvider>
    </ThemeContextProvider>
  </>,
);
