import React from "react";
import SolversDetailView from "../../views/solvers/detail";
import SolverDetailsLayout from "../../components/solver-details-layout";
import { ISolver } from "../../interfaces/deal/solvers.interface";

const SolversDetailPage = () => {
  const [currentSolver, setCurrentSolver] = React.useState<ISolver | null>(
    null,
  );
  return (
    <SolverDetailsLayout currentSolver={currentSolver}>
      <SolversDetailView onSolverChange={setCurrentSolver} />
    </SolverDetailsLayout>
  );
};

export default SolversDetailPage;
