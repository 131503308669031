import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTableV2 from "../../../../../components/report-table-v2";
import { useAPI, useAppSelector } from "../../../../../utils/hooks";
import { IReportTable } from "../../../../../interfaces";
import { cn } from "../../../../../utils/helpers";

interface IProps {
  getTaxCreditPTC_Data: (dealUuid: string) => Promise<IReportTable>;
  downloadTaxCreditPTC_Data: (dealUuid: string) => Promise<void>;
}

export default function DealTaxScheduleView({
  getTaxCreditPTC_Data,
  downloadTaxCreditPTC_Data,
}: IProps): JSX.Element {
  const { dealUuid, caseDealUuid } = useParams();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  React.useEffect(() => {
    const ptcProjectExist = currentDeal?.projects.some(
      (p) => p.tax_credit_type === "PTC",
    );

    if (ptcProjectExist) {
      getTaxCreditPTC_DataCallAPI(dealIdToUse);
    }
  }, [currentDeal]);

  const {
    callAPI: getTaxCreditPTC_DataCallAPI,
    loading: loadingPTC_Data,
    errored: getPTC_DataFiled,
    response: PTC_Data,
  } = useAPI((id) => getTaxCreditPTC_Data(id));

  const { callAPI: downloadTaxCreditPTC_DataCallAPI } = useAPI(() =>
    downloadTaxCreditPTC_Data(String(dealIdToUse)),
  );

  return (
    <ViewWrapper loading={loadingPTC_Data} error={getPTC_DataFiled}>
      {PTC_Data?.rows.length ? (
        <Box className={cn("mt-4")}>
          <Typography fontWeight={600} sx={{ margin: 2 }}>
            PTC Generated ($)
          </Typography>
          <ReportTableV2
            data={PTC_Data}
            groupExpandedDepth={-1}
            download={downloadTaxCreditPTC_DataCallAPI}
          />
        </Box>
      ) : null}
    </ViewWrapper>
  );
}
