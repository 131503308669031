import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import { IDealDebt, IDealTermDebtForm } from "../../interfaces";

export const getDealDebt = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.debt.get, { dealUuid });
  return await AxiosHelper.get<IDealDebt[]>(url);
};

export const addDealDebt = async (
  dealUuid: string,
  form: IDealTermDebtForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.debt.create, { dealUuid });
  return await AxiosHelper.post<IDealTermDebtForm, IDealDebt>(url, form);
};

export const updateDealDebt = async (
  dealUuid: string,
  debtId: number,
  form: IDealTermDebtForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.debt.update, {
    dealUuid,
    debtId,
  });
  return await AxiosHelper.patch<IDealTermDebtForm, IDealDebt>(url, form);
};

export const deleteDealDebt = async (dealUuid: string, debtId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.debt.delete, {
    dealUuid,
    debtId,
  });
  return await AxiosHelper.delete(url);
};
