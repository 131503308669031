import React from "react";

export const useSearchBar = () => {
  const [searchString, setSearchString] = React.useState("");

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const handleClearSearchString = () => {
    setSearchString("");
  };

  return {
    searchString,
    onSearchStringChange,
    handleClearSearchString,
  };
};
