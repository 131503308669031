import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTableV2 from "../../../../../components/report-table-v2";
import { useAPI } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  IPortfolioCostScheduleReport,
  IPortfolioCostSummaryReport,
} from "../../../../../interfaces";
import {
  cn,
  getPortfolioCostScheduleUrl,
  formatNumberWithDecimals,
  getPortfolioCostSummaryUrl,
} from "../../../../../utils/helpers";

interface IProps {
  getPortfolioCostScheduleData: (
    dealUuid: string,
  ) => Promise<IPortfolioCostScheduleReport>;
  getPortfolioCostSummaryData: (
    dealUuid: string,
  ) => Promise<IPortfolioCostSummaryReport>;
  downloadPortfolioCostScheduleData: (dealUuid: string) => Promise<void>;
}

export default function DealOutputPortfolioSummaryView({
  getPortfolioCostScheduleData,
  getPortfolioCostSummaryData,
  downloadPortfolioCostScheduleData,
}: IProps): JSX.Element {
  const { caseDealUuid, dealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const dispatch = useDispatch();

  const { urlLoadingPercentages } = useAxios();

  const {
    callAPI: getCostScheduleDataCallAPI,
    loading: loadingCostScheduleData,
    errored: getCostScheduleDataFailed,
    response: costScheduleData,
    showUsageLimitHitScreen,
  } = useAPI((id) => getPortfolioCostScheduleData(id));

  const {
    callAPI: getPortfolioCostSummaryDataCallAPI,
    loading: loadingCostSummaryData,
    errored: getCostSummaryDataFailed,
    response: portfolioCostSummaryData,
  } = useAPI((id) => getPortfolioCostSummaryData(id), {
    initialLoading: true,
  });

  React.useEffect(() => {
    !loadingCostSummaryData &&
      dispatch(updateDealOutputLoadedAction(!getCostSummaryDataFailed));
  }, [getCostSummaryDataFailed, loadingCostSummaryData]);

  React.useEffect(() => {
    getPortfolioCostSummaryDataCallAPI(dealIdToUse);
  }, [dealIdToUse]);

  React.useEffect(() => {
    if (portfolioCostSummaryData) {
      getCostScheduleDataCallAPI(dealIdToUse);
    }
  }, [dealIdToUse, portfolioCostSummaryData]);

  const { callAPI: downloadCostScheduleDataCallAPI } = useAPI(() =>
    downloadPortfolioCostScheduleData(String(dealIdToUse)),
  );

  const ProjectCostSummary = ({
    costSummaryData,
  }: {
    costSummaryData: IPortfolioCostSummaryReport;
  }) => {
    // Extract unique project names to create columns, using project_id for mapping
    const projectMap = new Map<string, string>();
    costSummaryData.data.items.forEach((item) => {
      item.projects.forEach((project) => {
        if (!projectMap.has(project.project_uuid)) {
          projectMap.set(project.project_uuid, project.project_name || "");
        }
      });
    });

    const projectNames = Array.from(projectMap.values());

    return (
      <Paper classes={{ root: cn("p-4 flex flex-col gap-4 my-4") }}>
        <Typography variant="h6" fontWeight={"bold"}>
          Cost Summary
        </Typography>
        {costSummaryData?.data.items.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">Item</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold" align="right">
                    Total
                  </Typography>
                </TableCell>
                {projectNames.map((projectName, idx) => (
                  <TableCell key={idx}>
                    <Typography fontWeight="bold" align="right">
                      {projectName}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {costSummaryData.data.items.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="right">
                    {item.name == "FMV Step Up Percent"
                      ? formatNumberWithDecimals(item.total, 2) + "%"
                      : formatNumberWithDecimals(item.total, 0)}
                  </TableCell>
                  {Array.from(projectMap.keys()).map((projectUuid, pIdx) => {
                    const project = item.projects.find(
                      (p) => p.project_uuid === projectUuid,
                    );
                    return (
                      <TableCell key={pIdx} align="right">
                        {project
                          ? item.name == "FMV Step Up Percent"
                            ? formatNumberWithDecimals(project.value, 2) + "%"
                            : formatNumberWithDecimals(project.value, 0)
                          : "N/A"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </Paper>
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingCostSummaryData}
        error={getCostSummaryDataFailed}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[getPortfolioCostSummaryUrl(String(dealIdToUse))]
        }
      >
        {portfolioCostSummaryData ? (
          <ProjectCostSummary costSummaryData={portfolioCostSummaryData} />
        ) : (
          <Typography>No data available</Typography>
        )}
      </ViewWrapper>

      {portfolioCostSummaryData && (
        <ViewWrapper
          loading={loadingCostScheduleData}
          error={getCostScheduleDataFailed}
          showLimitHitView={showUsageLimitHitScreen}
          loadingPercentage={
            urlLoadingPercentages[
              getPortfolioCostScheduleUrl(String(dealIdToUse))
            ]
          }
        >
          <Paper classes={{ root: cn("p-4") }}>
            {costScheduleData?.data.rows.length ? (
              <Box className={cn("flex flex-col gap-4")}>
                <Typography fontWeight={600}>Cost Schedule</Typography>
                <ReportTableV2
                  data={costScheduleData.data}
                  groupExpandedDepth={-1}
                  download={downloadCostScheduleDataCallAPI}
                  heightInPixels={400}
                />
              </Box>
            ) : null}
          </Paper>
        </ViewWrapper>
      )}
    </>
  );
}
