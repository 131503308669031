import React, { useEffect, useState } from "react";
import ViewWrapper from "../../components/view-wrapper";
import { getLocations } from "../../apis/project/base";
import { useAPI } from "../../utils/hooks";
import { IProjectLocation } from "../../interfaces/project/general.interfaces";
import Map from "../../components/map";
import { Typography } from "@mui/material";
import { getFormattedProjectLocations } from "../../utils/helpers/project.helpers";

const ProjectMapView = () => {
  const [locations, setLocations] = useState<IProjectLocation[]>([]);
  const {
    callAPI: getLocationsCallAPI,
    loading: loadingLocations,
    errored: getLocationsFailed,
  } = useAPI(getLocations, { initialLoading: true });

  useEffect(() => {
    getLocationsCallAPI().then((res) => {
      res && setLocations(res);
    });
  }, []);

  const activeLocations = React.useMemo(() => {
    if (!locations) return [];
    return getFormattedProjectLocations(locations as IProjectLocation[]);
  }, [locations]);

  return (
    <ViewWrapper loading={loadingLocations} error={getLocationsFailed}>
      <Typography variant="h6" alignSelf="edn">
        Active Projects
      </Typography>
      <div className="mt-2">
        <Map data={activeLocations} height={"78vh"} />
      </div>
    </ViewWrapper>
  );
};

export default ProjectMapView;
