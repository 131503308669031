import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import Charts from "./charts";
import useStyles from "./styles";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import { useAPI, useAppSelector } from "../../../../../utils/hooks";
import { ICashReportChart } from "../../../../../interfaces";
import { useAxios } from "../../../../../components/axios-provider";
import { getNonTransferCashChartUrl } from "../../../../../utils/helpers";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";

interface IProps {
  getDealPartnershipDepreciationChart: (
    dealUuid: string,
    is_percent: string,
  ) => Promise<ICashReportChart | null>;
  getDealNetOperatingIncomeChart: (
    dealUuid: string,
    is_percent: string,
  ) => Promise<ICashReportChart | null>;
  getDealOtherIncomeChart: (
    dealUuid: string,
    is_percent: string,
  ) => Promise<ICashReportChart | null>;
  getNonTransferCashReportChart: (
    dealUuid: string,
    is_percent: string,
  ) => Promise<ICashReportChart | null>;
  getTransferCashReportChart: (
    dealUuid: string,
    is_percent: string,
  ) => Promise<ICashReportChart | null>;
}

export default function DealOutputPartnershipSplitsView({
  getDealPartnershipDepreciationChart,
  getDealNetOperatingIncomeChart,
  getDealOtherIncomeChart,
  getNonTransferCashReportChart,
  getTransferCashReportChart,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();
  const { currentDeal, casesOfDeal } = useAppSelector((s) => s.deal);

  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { urlLoadingPercentages } = useAxios();

  const [cashReportChart, setCashReportChart] =
    React.useState<ICashReportChart>();
  const [cashReportChartPercent, setcashReportChartPercent] =
    React.useState<ICashReportChart>();

  const [nonTransferCashReportChart, setNonTransferCashReportChart] =
    React.useState<ICashReportChart>();
  const [
    nonTransferCashReportChartPercent,
    setNonTransferCashReportChartPercent,
  ] = React.useState<ICashReportChart>();

  const [transferCashReportChart, setTransferCashReportChart] =
    React.useState<ICashReportChart>();
  const [transferCashReportChartPercent, setTransferCashReportChartPercent] =
    React.useState<ICashReportChart>();

  const [dealNetOperatingIncomeChart, setDealNetOperatingIncomeChart] =
    React.useState<ICashReportChart>();
  const [
    dealNetOperatingIncomeChartPercent,
    setDealNetOperatingIncomeChartPercent,
  ] = React.useState<ICashReportChart>();

  const [dealOtherIncomeChart, setDealOtherIncomeChart] =
    React.useState<ICashReportChart>();
  const [dealOtherIncomeChartPercent, setDealOtherIncomeChartPercent] =
    React.useState<ICashReportChart>();

  const activeDeal = React.useMemo(() => {
    if (caseDealUuid === dealUuid || !organization) {
      return currentDeal;
    }
    return casesOfDeal.find((deal) => deal.child_deal.uuid === caseDealUuid)
      ?.child_deal;
  }, [caseDealUuid, casesOfDeal]);

  React.useEffect(() => {
    if (dealIdToUse) {
      getNonTransferCashReportChartCallAPI(dealIdToUse, "no").then(
        (response) => {
          if (response) {
            setNonTransferCashReportChart(response);
          }
        },
      );
      getNonTransferCashReportChartCallAPI(dealIdToUse, "yes").then(
        (response) => {
          if (response) {
            setNonTransferCashReportChartPercent(response);
          }
        },
      );
      if (activeDeal?.tax_credit_structure === "TR") {
        getTransferCashReportChartCallAPI(dealIdToUse, "no").then(
          (response) => {
            if (response) {
              setTransferCashReportChart(response);
            }
          },
        );
        getTransferCashReportChartCallAPI(dealIdToUse, "yes").then(
          (response) => {
            if (response) {
              setTransferCashReportChartPercent(response);
            }
          },
        );
      }

      getDealPartnershipDepreciationChartCallAPI(dealIdToUse, "no").then(
        (response) => {
          if (response) {
            setCashReportChart(response);
          }
        },
      );
      getDealPartnershipDepreciationChartCallAPI(dealIdToUse, "yes").then(
        (response) => {
          if (response) {
            setcashReportChartPercent(response);
          }
        },
      );

      getDealNetOperatingIncomeChartCallAPI(dealIdToUse, "no").then(
        (response) => {
          if (response) {
            setDealNetOperatingIncomeChart(response);
          }
        },
      );
      getDealNetOperatingIncomeChartCallAPI(dealIdToUse, "yes").then(
        (response) => {
          if (response) {
            setDealNetOperatingIncomeChartPercent(response);
          }
        },
      );
      getDealOtherIncomeChartCallAPI(dealIdToUse, "no").then((response) => {
        if (response) {
          setDealOtherIncomeChart(response);
        }
      });
      getDealOtherIncomeChartCallAPI(dealIdToUse, "yes").then((response) => {
        if (response) {
          setDealOtherIncomeChartPercent(response);
        }
      });
    }
  }, [dealIdToUse]);

  const {
    callAPI: getNonTransferCashReportChartCallAPI,
    errored: getCashReportChartFailed,
    loading: loadingCashReportChartCharts,
    errorObj: getCashReportChartErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealUuid: string, is_percent: string) =>
      getNonTransferCashReportChart(dealUuid, is_percent),
    {
      initialLoading: true,
    },
  );

  const { callAPI: getTransferCashReportChartCallAPI } = useAPI(
    (dealUuid: string, is_percent: string) =>
      getTransferCashReportChart(dealUuid, is_percent),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    !loadingCashReportChartCharts &&
      dispatch(updateDealOutputLoadedAction(!getCashReportChartFailed));
  }, [loadingCashReportChartCharts, getCashReportChartFailed]);

  const { callAPI: getDealNetOperatingIncomeChartCallAPI } = useAPI(
    (dealUuid: string, is_percent: string) =>
      getDealNetOperatingIncomeChart(dealUuid, is_percent),
  );

  const { callAPI: getDealOtherIncomeChartCallAPI } = useAPI(
    (dealUuid: string, is_percent: string) =>
      getDealOtherIncomeChart(dealUuid, is_percent),
  );

  const { callAPI: getDealPartnershipDepreciationChartCallAPI } = useAPI(
    (dealUuid: string, is_percent: string) =>
      getDealPartnershipDepreciationChart(dealUuid, is_percent),
    { initialLoading: true },
  );

  return (
    <ViewWrapper
      loading={loadingCashReportChartCharts}
      error={getCashReportChartFailed}
      errorHeading={getCashReportChartErrorObj?.heading}
      errorDescription={getCashReportChartErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getNonTransferCashChartUrl(String(dealIdToUse), "yes")
        ]
      }
    >
      <Stack gap={2}>
        {nonTransferCashReportChart && nonTransferCashReportChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper title="Cash Distributions">
              <Charts
                cashReportChart={nonTransferCashReportChart}
                stack
                label="Cash Distributions"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
            <ChartWrapper title="Cash Distributions">
              <Charts
                cashReportChart={nonTransferCashReportChartPercent}
                stack
                isPercentage
                label="Cash Distributions"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
          </Box>
        )}
        {activeDeal?.tax_credit_structure === "TR" &&
          transferCashReportChart &&
          transferCashReportChartPercent && (
            <Box className={styles.classes.projectChartsContainer}>
              <ChartWrapper title="Transfer Cash">
                <Charts
                  cashReportChart={transferCashReportChart}
                  stack
                  label="Transfer Cash"
                  activeDeal={activeDeal}
                />
              </ChartWrapper>
              <ChartWrapper title="Transfer Cash">
                <Charts
                  cashReportChart={transferCashReportChartPercent}
                  stack
                  isPercentage
                  label="Transfer Cash"
                  activeDeal={activeDeal}
                />
              </ChartWrapper>
            </Box>
          )}

        {dealNetOperatingIncomeChart && dealNetOperatingIncomeChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper title="Net Operating Income">
              <Charts
                cashReportChart={dealNetOperatingIncomeChart}
                stack
                label="Net Operating Income"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
            <ChartWrapper title="Net Operating Income">
              <Charts
                cashReportChart={dealNetOperatingIncomeChartPercent}
                stack
                isPercentage
                label="Net Operating Income"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
          </Box>
        )}

        {cashReportChart && cashReportChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper title="Depreciation">
              <Charts
                cashReportChart={cashReportChart}
                stack
                label="Depreciation"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
            <ChartWrapper title="Depreciation">
              <Charts
                cashReportChart={cashReportChartPercent}
                stack
                isPercentage
                label="Depreciation"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
          </Box>
        )}

        {dealOtherIncomeChart && dealOtherIncomeChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper title="Other Income / Loss">
              <Charts
                cashReportChart={dealOtherIncomeChart}
                stack
                label="Other Income / Loss"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
            <ChartWrapper title="Other Income / Loss">
              <Charts
                cashReportChart={dealOtherIncomeChartPercent}
                stack
                isPercentage
                label="Other Income / Loss"
                activeDeal={activeDeal}
              />
            </ChartWrapper>
          </Box>
        )}
      </Stack>
    </ViewWrapper>
  );
}
