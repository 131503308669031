import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICollaborationDetails } from "../../../interfaces";

export interface ICollaborationSliceState {
  currentCollaboration: ICollaborationDetails | undefined;
}

export const collaborationSlice = createSlice({
  name: "collaboration",
  initialState: {
    currentCollaboration: undefined,
  },
  reducers: {
    setCurrentCollaboration(
      state: ICollaborationSliceState,
      action: PayloadAction<ICollaborationSliceState["currentCollaboration"]>,
    ) {
      state.currentCollaboration = action.payload;
    },
  },
});
