import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { IProjectCostSummary } from "../../../../interfaces";
import { useScreenWidth } from "../../../../utils/hooks";
import { PROJECT_COST_TYPE } from "../../../../constants";
import { chartTooltipArrayOfNumbersToNumber } from "../../../../utils/helpers";

interface IProps {
  data?: IProjectCostSummary;
}

ChartJS.register(BarElement, BarController);

export default function ProjectCostChart({ data }: IProps): JSX.Element {
  const width = useScreenWidth();
  const costChartRef = React.useRef<HTMLCanvasElement | null>(null);

  function findCostByType(
    type: keyof typeof PROJECT_COST_TYPE,
    data?: IProjectCostSummary,
  ) {
    const item = data?.cost_items.find((item) => item.cost_type === type);
    return item ? item.cost_in_dollars : 0;
  }

  React.useEffect(() => {
    const costChart2DRef = costChartRef?.current?.getContext("2d");

    let cumulativeCost = 0;

    let labelsAndData: Array<{ label: string; data: number | number[] }> = [];

    const cost = {
      development_cost: findCostByType("DEV", data),
      acquisition_cost: findCostByType("ACQ", data),
      build_cost: findCostByType("BLD", data),
      inter_connection: findCostByType("INT", data),
      transaction_cost: findCostByType("TRN", data),
      land_cost: findCostByType("LND", data),
      credit_support_cost: findCostByType("LC", data),
      other_cost: findCostByType("OTH", data),
      project_cost: data!.total_cost,
    };

    if (cost) {
      labelsAndData = [
        {
          label: "Development Cost",
          data: [cumulativeCost, (cumulativeCost += cost.development_cost)],
        },
        {
          label: "Acquisition Cost",
          data: [cumulativeCost, (cumulativeCost += cost.acquisition_cost)],
        },
        {
          label: "Interconnection Cost",
          data: [cumulativeCost, (cumulativeCost += cost.inter_connection)],
        },
        {
          label: "Build Cost",
          data: [cumulativeCost, (cumulativeCost += cost.build_cost)],
        },
        {
          label: "Transaction Cost",
          data: [cumulativeCost, (cumulativeCost += cost.transaction_cost)],
        },
        {
          label: "Land Cost",
          data: [cumulativeCost, (cumulativeCost += cost.land_cost)],
        },
        {
          label: "Other Cost",
          data: [cumulativeCost, (cumulativeCost += cost.other_cost)],
        },
        {
          label: "Credit Support Cost",
          data: [cumulativeCost, (cumulativeCost += cost.credit_support_cost)],
        },
        {
          label: "Project Cost",
          data: [0, cost.project_cost],
        },
      ];
    }

    const costSummaryChart = new ChartJS(
      costChart2DRef as CanvasRenderingContext2D,
      {
        type: "bar",
        data: {
          labels: labelsAndData.map((l) => l.label),
          datasets: [
            {
              data: labelsAndData.map((l) => l.data),
              backgroundColor: "#00B969",
            },
          ],
        },
        options: {
          aspectRatio: 4.2,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return chartTooltipArrayOfNumbersToNumber(ctx);
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              title: {
                display: true,
                text: "Cost ($)",
              },
            },
          },
        },
      },
    );

    return () => {
      costSummaryChart.destroy();
    };
  }, [data, width]);

  return <canvas id="costChart" ref={costChartRef} />;
}
