import TabbedLayout from "../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectFmvIncomeApproachView from "../../../views/project/fmv/income-approach";
import {
  getProjectValuationCalculations,
  downloadProjectValuationCalculations_Report,
} from "../../../apis/project/valuation";

export default function ProjectFmvIncomeApproachPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="FMV">
        <ProjectFmvIncomeApproachView
          getProjectValuationCalculations={getProjectValuationCalculations}
          downloadProjectValuationCalculations_Report={
            downloadProjectValuationCalculations_Report
          }
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
